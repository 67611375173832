import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Button, Col, Input, InputGroup, Row } from "reactstrap";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import { withApollo } from "react-apollo";
import { CSVLink } from "react-csv";
import {
  OB_CreateGasReadings,
  updateGasReadingsBulk,
  weeklyValidationQuery,
} from "../../../../queries/Queries";
import Loading from "../../../../components/Loading";
import ExpiringAlert from "../../../../components/ExpiringAlert/ExpiringAlert";
import BulkApproveConfirmModal from "../../../../views/Clients/Hartree/BulkApproveConfirmModal/BulkApproveConfirmModal";
import ProcessingModal from "../../../../views/Clients/Hartree/ProcessingModal/ProcessingModal";
import { columns, READ_STATUS } from "./columns";

const READING_SUBMIT_PROCESS_360 = "DIO_48";
const READING_SUBMIT_CHANNEL_360 = "COCKPIT";

const bulkUpdateStatus = async (client, ids, status) => {
  await client.mutate({
    mutation: updateGasReadingsBulk,
    variables: { ids, status },
  });

  await client.query({
    query: weeklyValidationQuery,
    fetchPolicy: "network-only",
  });
};

const submitReadings = async (client, gasReadings) => {
  const processData = {
    channel: READING_SUBMIT_CHANNEL_360,
    process: READING_SUBMIT_PROCESS_360,
  };

  const response = await client.mutate({
    mutation: OB_CreateGasReadings,
    variables: { gasReadings, processData },
  });

  const resultData =
    response && response.data && response.data.OB_createGasReadings;

  if (!resultData) {
    throw new Error("Create readings mutation did not return expected data.");
  }

  const submittedReadings = resultData.submittedReadings || [];
  const errors = resultData.errors || [];

  if (submittedReadings.length > 0) {
    await bulkUpdateStatus(client, submittedReadings, "NOT_VALIDATED");
  } else if (errors.length > 0) {
    throw new Error(errors[0]);
  }

  return { submittedReadings, errors };
};

const prepareReading = (reading) => {
  const meterReading = parseFloat(reading.meter_reading);
  if (isNaN(meterReading)) {
    throw new Error("Invalid meter reading value");
  }

  return {
    readingIdentifier: reading.id,
    meterSerialNumber: reading.meter_serial_number,
    meterPointNumber: reading.mprn,
    correctorSerialNumber: reading.convertor_serial_number || null,
    readingValue: meterReading,
    ...(reading.corrected_rd1_reading &&
    !isNaN(parseFloat(reading.corrected_rd1_reading))
      ? { readingCorrectedValue: parseFloat(reading.corrected_rd1_reading) }
      : {}),
    ...(reading.uncorrected_rd1_reading &&
    !isNaN(parseFloat(reading.uncorrected_rd1_reading))
      ? { readingUncorrectedValue: parseFloat(reading.uncorrected_rd1_reading) }
      : {}),
    readingDate: moment(reading.date)
      .set({ hour: 9, minute: 0, second: 0 })
      .utc()
      .format(),
  };
};

const CustomSelectionHeader = ({
  mode,
  checked,
  indeterminate,
  onAllRowsSelect,
}) => (
  <div
    style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
  >
    <div>Select All</div>
    <div className='checkbox-wrapper'>
      <input
        type='checkbox'
        className='css-checkbox'
        checked={checked}
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        onChange={onAllRowsSelect}
      />
      <i
        className={`icon ${
          checked
            ? "ion-android-checkbox"
            : "ion-android-checkbox-outline-blank"
        }`}
        style={{ fontSize: "20px", cursor: "pointer" }}
      />
    </div>
  </div>
);

const SelectionCell = ({ row, checked, disabled, onChange }) => (
  <div className='checkbox-wrapper'>
    <input
      type='checkbox'
      className='css-checkbox'
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
    <i
      className={`icon ${
        checked ? "ion-android-checkbox" : "ion-android-checkbox-outline-blank"
      }`}
      style={{
        fontSize: "20px",
        cursor: disabled ? "default" : "pointer",
        opacity: disabled ? 0.5 : 1,
      }}
    />
  </div>
);

const WeeklyValidation = ({ client }) => {
  const [weeklyValidationData, setWeeklyValidationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(50);
  const [mutationPerformed, setMutationPerformed] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [showProcessingModal, setShowProcessingModal] = useState(false);
  const [processedReadings, setProcessedReadings] = useState({
    successful: [],
    failed: [],
    isProcessing: true,
  });

  useEffect(() => {
    setLoading(true);
    client
      .query({
        query: weeklyValidationQuery,
        fetchPolicy: "no-cache",
      })
      .then((res) =>
        setWeeklyValidationData(
          res.data.gas_readings.map((reading) => ({
            ...reading,
            id: reading.id,
          }))
        )
      )
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
        setMutationPerformed(false);
      });
  }, [client, mutationPerformed]);

  useEffect(() => {
    let data = [];
    const dateFilter = (date) =>
      moment(date).isBetween(
        moment(dateRange.startDate).format("MM/DD/YYYY"),
        moment(dateRange.endDate),
        undefined,
        "[]"
      );

    if (searchTerm.length > 0 && dateRange.startDate && dateRange.endDate) {
      data = weeklyValidationData.filter(
        (el) =>
          (el.mprn.includes(searchTerm) && dateFilter(el.date)) ||
          (el.site.includes(searchTerm) && dateFilter(el.date))
      );
    } else if (searchTerm.length > 0) {
      data = weeklyValidationData.filter(
        (el) =>
          el.mprn.includes(searchTerm) ||
          el.site.trim().toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else if (dateRange.startDate && dateRange.endDate) {
      data = weeklyValidationData.filter((el) => dateFilter(el.date));
    } else {
      data = weeklyValidationData.filter(
        (el) => READ_STATUS[el.status] === READ_STATUS.PENDING_APPROVAL
      );
    }

    setFilteredData(data);
  }, [
    searchTerm,
    dateRange.startDate,
    dateRange.endDate,
    weeklyValidationData,
  ]);

  const updateStatus = async (id, status) => {
    setLoading(true);
    try {
      await bulkUpdateStatus(client, [id], status);
      setMutationPerformed(!mutationPerformed);
    } catch (error) {
      console.error("Error updating status:", error);
      ReactDOM.render(
        <ExpiringAlert color='danger' message='Failed to update status' />,
        document.getElementById("alert")
      );
    } finally {
      setLoading(false);
    }
  };

  const submitReading = async (params) => {
    setLoading(true);
    let alertColor = "success";
    let alertMsg = "Reading submitted successfully";

    try {
      const reading = prepareReading(params);
      await submitReadings(client, [reading]);
    } catch (error) {
      alertColor = "danger";
      alertMsg = error.message.includes(" : ")
        ? error.message.split(" : ")[1]
        : error.message;
    } finally {
      setLoading(false);
      ReactDOM.render(
        <ExpiringAlert color={alertColor} message={alertMsg} />,
        document.getElementById("alert")
      );
    }
  };

  const extractErrorMessage = (error) => {
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      return error.graphQLErrors.map((err) => err.message).join(", ");
    }
    if (
      error.networkError &&
      error.networkError.result &&
      error.networkError.result.errors
    ) {
      return error.networkError.result.errors
        .map((err) => err.message)
        .join(", ");
    }
    if (error.networkError) {
      return error.networkError.message;
    }
    return "An unexpected error occurred.";
  };

  const handleBulkApprove = async () => {
    setShowProcessingModal(true);
    setProcessedReadings({
      successful: [],
      failed: [],
      isProcessing: true,
    });

    const startIndex = (currentPage - 1) * currentSizePerPage;
    const endIndex = startIndex + currentSizePerPage;
    const visibleRows = filteredData.slice(startIndex, endIndex);
    const visibleSelectedIds = selectedRows.filter((id) =>
      visibleRows.some((row) => row.id === id)
    );

    const selectedReadings = visibleSelectedIds.map((rowId) =>
      filteredData.find((r) => r.id === rowId)
    );

    try {
      const gasReadings = selectedReadings.map(prepareReading);
      const { submittedReadings, errors } = await submitReadings(
        client,
        gasReadings
      );

      const readingsById = selectedReadings.reduce((acc, reading) => {
        acc[reading.id] = reading;
        return acc;
      }, {});

      const successful = submittedReadings.map((id) => readingsById[id]);
      const failed = selectedReadings
        .filter((reading) => !submittedReadings.includes(reading.id))
        .map((reading) => ({
          ...reading,
          errorMessage:
            errors.find(
              (error) =>
                error.includes(reading.meter_serial_number) ||
                error.includes(reading.mprn)
            ) ||
            errors[0] ||
            "Unknown error occurred",
        }));

      setProcessedReadings({
        successful,
        failed,
        isProcessing: false,
      });

      setSelectedRows(
        selectedRows.filter((id) => !visibleSelectedIds.includes(id))
      );
      setMutationPerformed(true);
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      setProcessedReadings({
        successful: [],
        failed: selectedReadings.map((reading) => ({
          ...reading,
          errorMessage,
        })),
        isProcessing: false,
      });
    }
  };

  const handlePageChange = (page) => setCurrentPage(page);
  const handleSizePerPageChange = (sizePerPage) =>
    setCurrentSizePerPage(sizePerPage);

  const getHeaders = () => {
    const headers = [];
    for (const col of tableColumns) {
      if (col.dataField) headers.push({ label: col.text, key: col.dataField });
    }
    return headers;
  };

  const handleFileName = () => {
    if (dateRange.endDate) {
      const startDate = new Date(dateRange.startDate).toLocaleDateString();
      const endDate = new Date(dateRange.endDate).toLocaleDateString();
      return `${startDate} ${endDate}.csv`;
    }
    return "WeeklyValidation_Export.csv";
  };

  const tableColumns = columns(updateStatus, submitReading);

  const paginationOptions = paginationFactory({
    custom: true,
    page: currentPage,
    sizePerPage: currentSizePerPage,
    totalSize: filteredData.length,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: "75", value: 75 },
      { text: "100", value: 100 },
    ],
    onPageChange: handlePageChange,
    onSizePerPageChange: handleSizePerPageChange,
  });

  const handleSelectRow = (row, isSelect) => {
    if (READ_STATUS[row.status] !== READ_STATUS.PENDING_APPROVAL) return false;

    if (isSelect) {
      setSelectedRows((prevSelected) => [...prevSelected, row.id]);
    } else {
      setSelectedRows((prevSelected) =>
        prevSelected.filter((id) => id !== row.id)
      );
    }
    return true; // This ensures the checkbox can be toggled
  };

  const getVisibleSelectedCount = () => {
    const startIndex = (currentPage - 1) * currentSizePerPage;
    const endIndex = startIndex + currentSizePerPage;
    const visibleRows = filteredData.slice(startIndex, endIndex);
    return selectedRows.filter((id) => visibleRows.some((row) => row.id === id))
      .length;
  };

  const handleSelectAll = (isSelect, rows) => {
    if (isSelect) {
      const pendingRows = rows
        .filter(
          (row) => READ_STATUS[row.status] === READ_STATUS.PENDING_APPROVAL
        )
        .map((row) => row.id);
      setSelectedRows((prevSelected) => [...prevSelected, ...pendingRows]);
    } else {
      const rowsIds = rows.map((row) => row.id);
      setSelectedRows((prevSelected) =>
        prevSelected.filter((id) => !rowsIds.includes(id))
      );
    }
    return true;
  };

  return (
    <div>
      <Row className='subheader'>
        <Col className='col-8'>
          <h1>Weekly Validation</h1>
        </Col>
      </Row>
      <div className='dashboard-content hartree-hasura-grid'>
        <div className='table-filers-cnt'>
          <div className='table-filers-elements'>
            <Row>
              <Col xs='6' sm='6'>
                <InputGroup className='search'>
                  <Input
                    placeholder='Search by MPRN or Site'
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <i className='icon ion-ios-search-strong' />
                </InputGroup>
              </Col>
              <Col xs='4' sm='4'>
                <DateRangePicker
                  small
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
                  onDatesChange={(date) => setDateRange(date)}
                  displayFormat={"MM/DD/YYYY"}
                  focusedInput={focus}
                  onFocusChange={(focus) => setFocus(focus)}
                  showClearDates={true}
                  showDefaultInputIcon={true}
                  isOutsideRange={() => false}
                  noBorder={false}
                />
              </Col>
              <Col xs='2' sm='2' className='d-flex justify-content-end'>
                <CSVLink
                  className='export-csv'
                  filename={handleFileName()}
                  data={filteredData.sort(
                    (a, b) => new Date(b.date) - new Date(a.date)
                  )}
                  headers={getHeaders()}
                >
                  <Button color='primary'>Download CSV</Button>
                </CSVLink>
              </Col>
            </Row>
          </div>
        </div>

        <div>
          <Row>
            <Col xs='auto'>
              <ul className='efficiencyMethodLegend mb-0'>
                <li>
                  <span className='cns'></span> CNS Corrected Consumption
                </li>
                <li>
                  <span className='rd1'></span> RD1 Corrected Consumption
                </li>
              </ul>
            </Col>
            <Col xs='auto'>
              <Button
                color='primary'
                disabled={selectedRows.length === 0}
                onClick={() => setIsConfirmModalOpen(true)}
              >
                Bulk Approve ({getVisibleSelectedCount()})
              </Button>
            </Col>
          </Row>
        </div>

        {loading ? (
          <Loading />
        ) : (
          <PaginationProvider pagination={paginationOptions}>
            {({ paginationProps, paginationTableProps }) => (
              <div>
                <div className='d-flex'>
                  <div className='d-flex justify-content-start'>
                    <SizePerPageDropdownStandalone {...paginationProps} />
                    <PaginationTotalStandalone {...paginationProps} />
                  </div>
                  <div className='d-flex justify-content-end ml-auto'>
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </div>
                <BootstrapTable
                  headerClasses='custom-header-class'
                  wrapperClasses='table-responsive'
                  keyField='id'
                  columns={tableColumns}
                  data={filteredData}
                  defaultSorted={[
                    {
                      dataField: "date",
                      order: "desc",
                    },
                  ]}
                  noDataIndication={() => <div>No records in table</div>}
                  remote={{
                    filter: true,
                    pagination: false,
                    sort: false,
                    cellEdit: false,
                  }}
                  selectRow={{
                    mode: "checkbox",
                    clickToSelect: false,
                    selected: selectedRows,
                    onSelect: handleSelectRow,
                    onSelectAll: handleSelectAll,
                    nonSelectable: filteredData
                      .filter(
                        (row) =>
                          READ_STATUS[row.status] !==
                          READ_STATUS.PENDING_APPROVAL
                      )
                      .map((row) => row.id),
                    selectionHeaderRenderer: ({ mode, ...rest }) => (
                      <CustomSelectionHeader mode={mode} {...rest} />
                    ),
                    selectionRenderer: ({
                      checked,
                      disabled,
                      onChange,
                      row,
                    }) => (
                      <SelectionCell
                        row={row}
                        checked={checked}
                        disabled={disabled}
                        onChange={onChange}
                      />
                    ),
                    style: { backgroundColor: "#F7F9F9" },
                  }}
                  {...paginationTableProps}
                />
              </div>
            )}
          </PaginationProvider>
        )}

        {/* Modals */}
        <BulkApproveConfirmModal
          isOpen={isConfirmModalOpen}
          toggle={() => setIsConfirmModalOpen(false)}
          onConfirm={() => {
            setIsConfirmModalOpen(false);
            handleBulkApprove();
          }}
          selectedCount={getVisibleSelectedCount()}
        />
        <ProcessingModal
          isOpen={showProcessingModal}
          processedReadings={processedReadings}
          onClose={() => {
            setShowProcessingModal(false);
            setProcessedReadings({
              successful: [],
              failed: [],
              isProcessing: true,
            });
          }}
        />
      </div>
    </div>
  );
};

export default withApollo(WeeklyValidation);
