import React, {Component} from 'react';

import i18n from '../../views/Pages/Login/i18n';
import {Mutation, Query} from 'react-apollo';
import {getBusinessPartnerMiddleQuery, periodsQuery, startProcessMutation} from "../../queries/Queries";
import Form from "react-jsonschema-form";
import ReactDOM from "react-dom";
import ExpiringAlert from "../ExpiringAlert";
import {Button, FormGroup, Input} from "reactstrap";
import Loading from "../../components/Loading"
import CustomerSearch from "../CustomerSearch";
import moment from "moment";
import MaskInput from "react-maskinput";
import {SingleDatePicker} from "react-dates";
import 'react-dates/lib/css/_datepicker.css';
import {AsyncTypeahead} from "react-bootstrap-typeahead";

class Reading extends Component {

    render() {
        return (!this.props.formContext.businessPartner || !this.props.formContext.businessPartner.length) ? null : (
            <Query query={getBusinessPartnerMiddleQuery} variables={{
                orderBy: "name",
                where: `id = '${this.props.formContext.businessPartner}'`
            }}>
                {result => {
                    if (result.loading) return (<Loading/>);
                    const data = result.data.businessPartner;
                    const estateList = data[0].mDMBPEstateList;
                    this.state = this.state || {};
                    this.state = {
                        type: this.state.type || 'COU_C',
                        date: this.state.date || moment(),
                        submittingReading: false,
                        mDMBPEstate: this.state.mDMBPEstate ||
                        estateList && estateList.length == 1
                            ? estateList[0]
                            : {
                                mDMEstate:
                                    {
                                        mDMMeterPointList: []
                                    }
                            },
                        mDMMeterPoint: this.state.mDMMeterPoint ||
                            {
                                mDMMeterRegisterList: []
                            },
                        meter: this.state.meter || {}
                    };
                    return (<div className="reading-modal">
                        <FormGroup>
                            <label>{i18n.t('readings.address')}:&nbsp;</label>
                            <Input type="select" value={this.state.mDMBPEstate.id} onChange={(e) => {
                                this.setState({
                                    mDMBPEstate: estateList.filter(estate => estate.id === e.target.value).reduce((accumulator, currentValue) => currentValue || accumulator, {mDMEstate: {mDMMeterPointList: []}}),
                                    mDMMeterPoint: {
                                        mDMMeterRegisterList: []
                                    },
                                    meter: {}
                                }, () => this.props.onChange(this.state))
                            }
                            }>
                                <option value="">---</option>
                                {estateList.map(estate => <option
                                    value={estate.id}>{estate.mDMEstate.locationAddress.addressLine1}</option>)}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label>{i18n.t('readings.meterPoint')}:&nbsp;</label>
                            <Input type="select" value={this.state.mDMMeterPoint.id} onChange={(e) => {
                                console.log(e.target.value);
                                this.setState({
                                    mDMMeterPoint: this.state.mDMBPEstate.mDMEstate.mDMMeterPointList.filter(meterPoint => meterPoint.id === e.target.value).reduce((accumulator, currentValue) => currentValue || accumulator, {mDMMeterRegisterList: []})
                                }, () => this.props.onChange(this.state))
                            }}>
                                <option value="">---</option>
                                {this.state.mDMBPEstate.mDMEstate.mDMMeterPointList.map(meterPoint => <option
                                    value={meterPoint.id}>{meterPoint.name}</option>)}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label>{i18n.t('readings.meter')}:&nbsp;</label>
                            <Input type="select" value={this.state.meter.id}
                                   onChange={(e) => this.setState({meter: this.state.mDMMeterPoint.mDMMeterRegisterList.filter(({meter}) => meter.id === e.target.value).reduce((accumulator, currentValue) => currentValue || accumulator, {meter: ''}).meter}, () => this.props.onChange(this.state))}>
                                <option value="">---</option>
                                {this.state.mDMMeterPoint.mDMMeterRegisterList.map(({meter}) => meter).filter((meter, index, self) => {
                                    return self.findIndex(m => m.id === meter.id) === index;
                                }).map(meter => <option value={meter.id}>{meter.serialNumber}</option>)
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label>{i18n.t('readings.type')}:&nbsp;</label>
                            <Input type="select" value={this.state.type}
                                   onChange={(e) => this.setState({...this.state, type: e.target.value}, () => this.props.onChange(this.state))}>
                                <option value="COU_C">Customer</option>
                                <option value="COU_E">Estimated</option>
                            </Input>
                        </FormGroup>
                        {this.state.type !== "COU_E" && this.state.mDMMeterPoint.mDMMeterRegisterList.filter(({meter}) => meter.id === this.state.meter.id).map(mDMMeterRegister => {
                            const lastReading = mDMMeterRegister.meter.ammMeterReadingList
                                .filter(reading => (reading.customerReadType === "Customer" || reading.customerReadType === "Initial") && reading.product.id === mDMMeterRegister.mDMRegister.product.id)
                                .map(reading => ({
                                    ...reading,
                                    readingdate: moment(reading.readingdate, "YYYY-MM-DDTHH:mm:ssZ")
                                }))
                                .sort((a, b) => b.readingdate.isBefore(a.readingdate) ? -1 : 1)[0];
                            return (<FormGroup>
                                <div className="last-reading">
                                    <label>Last Read {lastReading ? lastReading.readingdate.format("L") : ""}</label>
                                    <p>{lastReading ? lastReading.newreadingmetervt : "N/A"}</p>
                                </div>
                                <label>{mDMMeterRegister.mDMRegister.product.name}:&nbsp;</label><br/>

                                <MaskInput value={this.state['reading' + mDMMeterRegister.id]}
                                           onChange={(e) => this.setState({
                                               ['reading' + mDMMeterRegister.id]: e.target.value,
                                               ['lastReading' + mDMMeterRegister.id]: lastReading
                                           }, () => this.props.onChange(this.state))}
                                           alwaysShowMask
                                           maskChar={"0"}
                                           mask={"0".repeat(mDMMeterRegister.meterReadingDigitsCount)}
                                           placeholder="20491"
                                           className={'reading-input'}
                                           style={{width: mDMMeterRegister.meterReadingDigitsCount * 24.2, paddingRight: '5px'}}
                                />
                            </FormGroup>)
                        })}
                        <FormGroup>
                            <label>{i18n.t('Readings Date')}:&nbsp;</label>
                            <SingleDatePicker
                                date={this.state.date} // momentPropTypes.momentObj or null
                                numberOfMonths={1}
                                onDateChange={date => {
                                    this.setState({...this.state, date}, () => this.props.onChange(this.state));
                                }}
                                isOutsideRange={day => day.isAfter(moment())}
                                focused={this.state.focused} // PropTypes.bool
                                onFocusChange={({focused}) => this.setState({focused})} // PropTypes.func.isRequired
                                id="cot-reading-date" // PropTypes.string.isRequired,
                            />
                        </FormGroup>


                    </div>);
                }}
            </Query>)
    }

}

class ProcessesDetail extends Component {

    constructor(arg) {
        super(arg);
        this.state = {
            buttonStyle: {visibility: "hidden", isLoading: false, period: null}
        }
    }

    render() {
        if (this.props.processDefinitionId === "changeOfTenancy") {
            const loadingIcon = this.state.isLoading ? (
                <span><i style={this.state.buttonStyle} className='fa fa-circle-o-notch fa-spin'></i> </span>) : null;
            return (<Form schema={{
                title: "Start Change Of Tenancy",
                type: "object",
                properties: {
                    moveOutDate: {
                        type: "string",
                        title: "Change of Tenancy Date",
                        default: moment().format('YYYY-MM-DD')
                    },
                    businessPartner: {
                        type: "string",
                        title: "Business Partner",
                        default: null
                    },
                    reading: {
                        type: "object"
                    },
                    customerType: {
                        type: "string",
                        title: "Customer Type",
                        enum: ["Private", "Company"],
                        enumNames: ["Private", "Company"],
                    },
                    firstName: {
                        type: "string",
                        title: "First Name",
                    },
                    lastName: {
                        type: "string",
                        title: "Last Name",
                    },
                    email: {
                        type: "string",
                        title: "email",
                    },
                    phone: {
                        type: "string",
                        title: "Phone",
                    }

                },
                dependencies: {
                    customerType: {
                        oneOf: [
                            {
                                properties: {
                                    customerType: {
                                        enum: ["Company"]
                                    },
                                    companyName: {
                                        "type": "string",
                                        "title": "Company Name"
                                    }
                                }
                            }
                        ]
                    }
                }
            }}
                          uiSchema={{
                              businessPartner: {
                                  "ui:widget": (props) => {
                                      return <CustomerSearch {...props} />
                                  },
                                  "ui:placeholder": "Select Customer",
                              },
                              moveOutDate: {
                                  "ui:widget": "date"
                              },
                              reading: {
                                  "ui:field": "readingField"
                              },
                              "ui:order": ["businessPartner", "moveOutDate", "reading", "customerType", "companyName", "firstName", "lastName", "email", "phone"]
                          }}
                          fields={{readingField: Reading}}
                          formContext={this.state.formContext || {}}
                          formData={this.state.formContext || {}}
                          onSubmit={({formData}) => {
                              /*this.setState({
                                  buttonStyle: {visibility: "visible"},
                                  isLoading: true,
                                  period: formData.period
                              });
                              axios.post({
                                  variables:
                                      {
                                          processId: this.props.processDefinitionId,
                                          variables: [
                                              {
                                                  name: "C_Period_ID",
                                                  type: "string",
                                                  value: formData.period
                                              },
                                              {
                                                  name: "periodid",
                                                  type: "string",
                                                  value: formData.period
                                              }
                                          ]
                                      }
                              }).then(response => {
                                  this.setState({buttonStyle: {visibility: "hidden", isLoading: false}});
                                  if (response.data.startProcess) {
                                      this.props.onClose();
                                      ReactDOM.render(<ExpiringAlert color="success"
                                                                     message={i18n.t('processes.successfulProcessStart')}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                  }
                              });*/
                              console.log(formData);
                          }}

                          onChange={({formData}, e) => {
                              console.log("formData", formData);
                              this.setState({formContext: formData})
                          }}>
                <div>
                    <Button type="submit" disabled={this.state.isLoading} color='primary'>{loadingIcon}Start
                        Process</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button type="button" onClick={() => this.props.onClose()}>Cancel</Button>
                </div>
            </Form>);
        }

        if (this.props.processDefinitionId === "cumulativeBillingProcess") return <Mutation
            mutation={startProcessMutation} refetchQueries={[]}>
            {(startProcess, {data}) => (<Query query={periodsQuery}>
                    {(result) => {
                        if (result.loading) return (<Loading/>);
                        if (result.error) return <div>{result.error} </div>;
                        const {data} = result;
                        const loadingIcon = this.state.isLoading ? (<span><i style={this.state.buttonStyle} className='fa fa-circle-o-notch fa-spin'></i> </span>) : null;
                        return <Form schema={{
                            title: "Start Billing Cycle",
                            type: "object",
                            properties: {
                                period: {
                                    type: "string",
                                    title: "Period",
                                    enum: data.periods.map(({id}) => id),
                                    enumNames: data.periods.map(({name}) => name),
                                    default: this.state.period ? this.state.period : data.periods[0].id
                                }
                            }
                        }}
                        onSubmit={({formData}) => {
                            this.setState({
                                buttonStyle: {visibility: "visible"}, 
                                isLoading: true, 
                                period: formData.period
                            });
                            startProcess({
                                variables:
                                    {
                                        processId: this.props.processDefinitionId,
                                        variables: [
                                            {
                                                name: "C_Period_ID",
                                                type: "string",
                                                value: formData.period
                                            },
                                        {
                                            name: "periodid",
                                            type: "string",
                                            value: formData.period
                                        }
                                    ]
                                }
                            }).then(response => {
                                this.setState({buttonStyle: {visibility: "hidden", isLoading: false}});
                                if (response.data.startProcess) {
                                    this.props.onClose();
                                    ReactDOM.render(<ExpiringAlert color="success"
                                         message={i18n.t('processes.successfulProcessStart')}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                    }
                                });

                             }}

                             onChange={(event) => {
                                 event.formData;
                             }}>
                        <div>
                            <Button type="submit" disabled={this.state.isLoading} color='primary'>{loadingIcon}Start Process</Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button type="button" onClick={() => this.props.onClose()}>Cancel</Button>
                        </div>
                    </Form>
                }
                }
            </Query>
        )
        }
    </Mutation>
    }
}


export default ProcessesDetail;
