import React from 'react';
import ReactDOM from 'react-dom';
import App from './apps/App';
import {} from './flatMap-polyfill';

import authentication from './modules/msal-b2c-react/msal-b2c-react';

console.log("window.config.consul", window.config.consul)
console.log("window.config.consul.MSAADB2C", window.config.consul.MSAADB2C);

if (window.config.consul && window.config.consul.MSAADB2C) {
    authentication.initialize({
        // use your b2clogin.com domain here
        instance: window.config.consul.MSAADB2C_INSTANCE,
        // your B2C tenant, you can also user tenants GUID here
        tenant: window.config.consul.MSAADB2C_TENANT,
        // the policy to use to sign in, can also be a sign up or sign in policy
        signInPolicy: window.config.consul.MSAADB2C_SIGN_IN_POLICY,
        // the policy to use for password reset
        resetPolicy: window.config.consul.MSAADB2C_RESET_PASSWORD_POLICY,
        // the the B2C application you want to authenticate with (that's just a random GUID - get yours from the portal)
        applicationId: window.config.consul.MSAADB2C_APPLICATION_ID,
        // where MSAL will store state - localStorage or sessionStorage
        cacheLocation: 'sessionStorage',
        // the scopes you want included in the access token
        scopes: window.config.consul.MSAADB2C_SCOPES,
        // optional, the redirect URI - if not specified MSAL will pick up the location from window.href
        redirectUri: window.location.origin,
        // optional, the URI to redirect to after logout
        // postLogoutRedirectUri: 'http://myapp.com',
        // optional, default to true, set to false if you change instance
        validateAuthority: false,
        // optional, default to false, set to true if you only want to acquire token silently and avoid redirections to login page
        silentLoginOnly: false,
        extraQueryParameters: location.hash.substr(location.hash.indexOf("?")+1).split("=").slice(1).join("=") || ""
    });

    authentication.run(() => {
        ReactDOM.render(<div><App/></div>, document.getElementById('root'));
        sessionStorage.mstoken = authentication.getAccessToken();
    });

} else {
    ReactDOM.render(<App/>,
        document.getElementById('root')
    );
}

if (module.hot) module.hot.accept();

