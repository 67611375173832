import React, {Component} from 'reactn';
import jsonata from "./../../../node_modules/jsonata/jsonata-es5";
import _ from 'lodash';

class BasicQueryComponent extends Component {
    constructor(props) {
        super(props);
        this.setParents = this.setParents.bind(this);
        this.transformData = this.transformData.bind(this);
        this.transformVariables = this.transformVariables.bind(this);
    }

    getFieldValue (obj, path){
        var pathArray = path.split(".");
        if(pathArray.length > 1) {
            if(pathArray[0] === "#"){
                let concatArr = [];
                for(let j=0; j<obj.length; j++){
                    concatArr = concatArr.concat(this.getFieldValue(obj[j], path.substring(path.indexOf(".") + 1)));
                }
                return concatArr;
            } else {
                return this.getFieldValue(obj[pathArray[0]], path.substring(path.indexOf(".") + 1));
            }
        } else {
            return obj[pathArray[0]];
        }
    }

    setParents(obj, parents){
        let newObj = JSON.parse(JSON.stringify(obj));
        const ownProps = Object.keys(newObj);
        for (let ownProp of ownProps) {
            if (newObj[ownProp] instanceof Object || newObj[ownProp] instanceof Array) {
                let newparents = parents.slice();
                newparents.unshift(obj);
                newObj[ownProp] = this.setParents(newObj[ownProp], newparents);
            }
        }
        newObj.__parents = parents;
        return newObj;
    }


    getDescendantProp (obj, desc) {
        if(desc) {
            var arr = desc.replace(']', '').split(/\[|\./);
            while (arr.length && (obj = obj[arr.shift()])) ;
            return obj;
        }
    }
    transformData(data, exp) {
        let expr = null;
        if (exp) {
            const compiled = _.template(exp);
            expr = compiled(this.props.match.params);
        }

        const expression = expr ? jsonata(expr) : null;
        const transformedData = expression ? expression.evaluate(data) : data;
        return transformedData;
    }

    transformVariables() {
        let variables;
        if (this.props.match.params["variables"]) {
            variables = JSON.parse(this.props.match.params["variables"][this.props.queryKey]);
        } else {
            variables = this.props.variables;
            if (variables) {
                const compiled = _.template(JSON.stringify(variables));
                variables = JSON.parse(compiled(this.props.match.params));
            }
        }
        return variables;
    }

    render() {

    }
}

export default BasicQueryComponent;