"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = exports.schemaFunction = exports.queryTypeMapping = void 0;
const queryTypeMapping = {
    G: [
        {
            type: "number",
            title: "Bill Query",
            enum: [ 1010, 569, 782, 1026, 885, 1008, 780, 929, 919, 888, 960, 892, 554, 772, 1009, 631, 794, 953, 830, 863, 1105, 1113, 652]
        }, {
            type: "number",
            title: "Meter Read Query",
            enum: [630, 943, 243, 242, 963, 843, 750, 941, 746, 762, 763, 784, 944]
        }, {
            type: "number",
            title: "Renew My Tariff",
            enum: [862, 789, 676]
        }, {
            type: "number",
            title: "Update My Details",
            enum: [621, 910, 583, 689, 900, 897, 896, 898, 899, 785, 887, 583, 653, 910, 911, 828]
        }, {
            type: "number",
            title: "Other",
            enum: [10000]
        },
    ],
    E: [
        {
            type: "number",
            title: "Bill Query",
            enum: [ 1010, 569, 782, 1026, 885, 1008, 780, 929, 919, 888, 960, 892, 554, 772, 1009, 631, 794, 953, 830, 863, 1105, 1113, 652]
        }, {
            type: "number",
            title: "Meter Read Query",
            enum: [630, 943, 243, 242, 963, 843, 750, 941, 746, 762, 763, 784, 944]
        }, {
            type: "number",
            title: "Renew My Tariff",
            enum: [862, 789, 676]
        }, {
            type: "number",
            title: "Update My Details",
            enum: [621, 910, 583, 689, 900, 897, 896, 898, 899, 785, 887, 583, 653, 910, 911, 828]
        }, {
            type: "number",
            title: "Other",
            enum: [10000]
        },
    ]
};

exports.queryTypeMapping = queryTypeMapping;

const schemaFunction = filtered => {
    let typeType = JSON.parse(JSON.stringify(queryTypeMapping));

    switch (filtered) {
        case "filter-hidden-first":
            typeType.G = queryTypeMapping.G.filter(t => !t.hidden).map(t => {
                return {
                    type: t.type,
                    title: t.title,
                    enum: t.enum
                };
            });
            typeType.E = queryTypeMapping.E.filter(t => !t.hidden).map(t => {
                return {
                    type: t.type,
                    title: t.title,
                    enum: t.enum
                };
            });
            break;
        case "filter-hidden-first-one-element":
            typeType.G = queryTypeMapping.G.filter(t => !t.hidden).map(t => {
                return {
                    type: t.type,
                    title: t.title,
                    enum: [t.enum[0]]
                };
            });
            typeType.E = queryTypeMapping.E.filter(t => !t.hidden).map(t => {
                return {
                    type: t.type,
                    title: t.title,
                    enum: [t.enum[0]]
                };
            });
            break;
        case "filter-first":
            typeType.G = queryTypeMapping.G.map(t => {
                return {
                    type: t.type,
                    title: t.title,
                    enum: [t.enum[0]]
                };
            });
            typeType.E = queryTypeMapping.E.map(t => {
                return {
                    type: t.type,
                    title: t.title,
                    enum: [t.enum[0]]
                };
            });
            break;
    }
    // DYCE-1935 - show consumer num instead of acc num
    const isDyceSSPCustomization = window.config.consul.CLIENT === "dyce-ssp";
    return {
        type: 'object',
        properties: {
            id: {
                type: 'string',
                title: 'Id'
            },
            description: {
                type: 'string',
                title: 'Description',
                placeholder: 'Query Description'
            },
            type: {
                type: 'number',
                title: 'Type',
                anyOf: typeType
            },
            customer: {
                type: 'string',
                title: 'Customer'
            },
            status: {
                type: 'string',
                title: 'Status',
                'default': 'Open',
                'enum': ['Open', 'Closed']
            },
            priority: {
                type: 'integer',
                title: 'Priority',
                'default': 0,
                'enum': [0, 50, 100],
                enumNames: ['Low', 'Medium', 'High']
            },
            contact_name: {
                type: 'string',
                title: 'Contact Name',
                format: 'lettersOnly'
            },
            contact_number: {
                type: 'string',
                title: 'Contact Number',
                format: 'phone'
            },
            contact_email: {
                type: 'string',
                title: 'Contact Email',
                format: 'email'
            },
            estate: {
                type: 'string',
                title: 'Site Address'
            },
            mprn: {
                type: 'string',
                title: 'Meter Point'
            },
            ...(isDyceSSPCustomization
                ? {
                    consumer_number: {
                        type: 'string',
                        title: "Account Number"
                    }
                } 
                : {
                    account_code: {
                        type: 'string',
                        title: 'Account Number'
                    }
                }),
            closed_by: {
                type: 'string',
                title: 'Completed by'
            },
            closed_on: {
                type: 'string',
                title: 'Completed on'
            }
        },
        required: ["type", "description", "contact_name", "contact_number", "contact_email", isDyceSSPCustomization ? "consumer_number" : "account_code"],
    };
};

exports.schemaFunction = schemaFunction;
const schema = schemaFunction("filter-hidden-first");
var _default = schema;
exports.default = _default;