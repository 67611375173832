import JSXEditor from "./JSXEditor";
import DatePicker from "./DatePicker";
import MonthPicker from "./MonthPicker";
import VendorPicker from "./VendorPicker";
import QueryPicker from "./QueryPicker";

const widgets = {
    jsxEditor: JSXEditor,
    datePicker: DatePicker,
    monthPicker: MonthPicker,
    queryPicker: QueryPicker,
    vendorPicker: VendorPicker
};

const fields = {};

export default {widgets, fields};
