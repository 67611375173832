import React, { Component } from 'reactn';
import DataGrid  from '../DataGrid/DataGrid';
import { compose, graphql } from "react-apollo";
import { Link } from 'react-router-dom'
import i18n from "../../views/Pages/Login/i18n";
import MoreInfoButton from "../MoreInfoButton/MoreInfoButton";
import { Col,  Row, FormGroup, InputGroup, Input} from "reactstrap";
import {getUsers} from "../../queries/Queries";
import AgreementsGrid from "../AgreementsGrid";

function enabledFormatter(cell, row) {
    return (
        <span> {cell ? i18n.t('header.active') : i18n.t('header.deactivated')} </span>
    );
}


function primaryPhoneFormatter(cell, row) {
    return (
        cell.filter(({primaryNumber}) => primaryNumber).map(({phoneNumber}) => phoneNumber).join()
    );
}

//---------------------------------------------------------

class UserInfoTemplate extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (<div><Col><Row><Col sm="2"><Row><img width="32" height="32" src={(window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))+'/avatar/'+this.props.username} style={{borderRadius: "50%"}}/></Row></Col><Col sm="10"><Row>{this.props.firstName+' '+(this.props.lastName ? this.props.lastName : "")}</Row><Row><small>{this.props.roles}</small></Row></Col></Row></Col></div>);
    }
}

const gqlWrapper = graphql(
    getUsers, {
        name: "dataQuery",
        props: (data) => data,
        options: {
            errorPolicy: 'all'
        }
    });


class NoSalesAgents extends Component {

    constructor(arg) {
        super(arg)
        if(document.getElementById('filters'))
         document.getElementById('filters').remove();
    }

    render() {
        return (
            <div className="nosalesagent"> 
                <i className="icon ion-person-stalker"></i>
                <p className="text">There are no sales agents created yet.</p>
                <Link to={this.props.organizationId
                    ? `/organization/${this.props.organizationId}/create-sales-agent`
                    : `/sales-agents/create-sales-agent`} className='btn btn-primary '> <i className="icon ion-plus-round"></i>&nbsp;&nbsp;{i18n.t('users.newSalesAgents')} </Link>
            </div>
        );
    }
}
//---------------------------------------------------------

class SalesAgentsGrid extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            userSearch:'',
            enabledSearch:''
        };
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    render() {
        const DataGridwithData = compose(gqlWrapper)(DataGrid);
        return (
   
                <div >
                    <Row className='filter-bar' id="filters1" >
                        <Col className='col-5'>
                            <InputGroup className='search-user'>
                                <Input className='grey-search-icon-input' placeholder={i18n.t('users.userSearch')} value={this.state.userSearch} onChange={(e) => this.setState({userSearch:e.target.value})}/>
                            </InputGroup>
                        </Col>
                        <Col className='col-5'>
                            <Input type="select" value={this.state.enabledSearch} placeholder=""
                                   onChange={(e) => this.setState({enabledSearch:e.target.value})}>{' '}
                                <option value="">{i18n.t('users.allUsers')}</option>
                                <option value="true">Active</option>
                                <option value="false">Deactivated</option>
                            </Input>
                        </Col>
                        <Col className='col-2'>
                            <FormGroup>
                                <Link to={this.props.organizationId
                                    ? `/organization/${this.props.organizationId}/create-sales-agent`
                                    : `/sales-agents/create-sales-agent`} className='btn btn-primary pull-right'> <i className="icon ion-plus-round"></i>&nbsp;&nbsp;{i18n.t('users.newSalesAgents')} </Link>
                            </FormGroup>
                        </Col>
                    </Row>
                    <DataGridwithData
                        columns={[{
                            dataField: 'firstName',
                            sort: true,
                            text: i18n.t('salesAgents.nameAndRole'),
                            formatter: (cell, row) => {
                                return <UserInfoTemplate username={row.username} firstName={row.firstName} lastName={row.lastName} avatarUrl={row.avatarUrl} roles={row.roles.length > 0 ? row.roles.map(({role}) => role.name).join(', ') : 'NO ROLES'} className="more-options-btn"/>
                            }
                        }, {
                            dataField: 'organizations',
                            sort: true,
                            text: i18n.t('salesAgents.salesOrganization'),
                            formatter: (cell, row) => {
                                return cell.reduce((acc, curr) => curr.businessPartnerId ? curr : acc, {name:''} ).name
                            }
                        }, {
                            dataField: 'username',
                            sort: true,
                            text:  i18n.t('salesAgents.email'),
                        }, {
                            dataField: 'phoneNumbers',
                            sort: true,
                            text: i18n.t('salesAgents.phone'),
                            formatter:primaryPhoneFormatter
                        }, {
                            dataField: 'enabled',
                            formatter: enabledFormatter,
                            sort: true,
                            text: i18n.t('salesAgents.status'),
                        }, {
                            dataField: 'options',
                            text: ' ',
                            formatter: (cell, row) => <MoreInfoButton user={row} className="more-options-btn" editUrl={"/sales-agents/"}/>

                        }]}
                        getData={(props) => props.dataQuery.getUsers
                            .filter(user => !props.organizationId && this.global.permissions.includes('Users.ViewAnyUser')
                                || user.organizations.filter(({businessPartnerId}) =>
                                    businessPartnerId === (props.organizationId ? props.organizationId : this.global.salesOrganization)).length > 0)
                            .filter(user => {
                                const roles = user.roles.map(({role})=>role.key);
                                return roles.includes("SALESADMIN")
                                    || roles.includes("SALESMANAGER")
                                    || roles.includes("SALESAGENT")
                            })}
                        filter = {user => {
                            const filter = this.state.userSearch.toLowerCase();
                            const enabledFilter = this.state.enabledSearch;
                            const fullName = user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : '';

                            return (filter.length == 0 || user.username.toLowerCase().indexOf(filter) > -1
                                || user.firstName && user.firstName.toLowerCase().indexOf(filter) > -1
                                || user.lastName && user.lastName.toLowerCase().indexOf(filter) > -1
                                || user.roles && user.roles.join('~').toLowerCase().indexOf(filter) > -1
                                || user.phoneNumbers && user.phoneNumbers.map(({phoneNumber}) => phoneNumber).join('~').toLowerCase().indexOf(filter) > -1
                                || fullName && fullName.toLowerCase().indexOf(filter) > -1
                                || user.username && user.username.toLowerCase().indexOf(filter) > -1
                                || user.jobTitle && user.jobTitle.toLowerCase().indexOf(filter) > -1)
                            && (enabledFilter === '' || user.enabled == (enabledFilter == 'true'))
                        }}
                        keyField="username"
                        organizationId={this.props.organizationId}
                        noDataView={<NoSalesAgents/>}
                    />
                </div>
           
        )
    }
}

export default SalesAgentsGrid;
