import React, {Component} from 'reactn';
import ReactDOM from 'react-dom';
import {compose, graphql} from "react-apollo";
import BootstrapTable from 'react-bootstrap-table-next';
import {
    Button,
    ButtonDropdown,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from 'reactstrap';
import paginationFactory from "react-bootstrap-table2-paginator";
import i18n from "../../views/Pages/Login/i18n";
import axios from "../../utils/Client";
import fileDownload from 'js-file-download';
import {createNoteMutation, deleteNoteMutation, updateNoteMutation} from "../../queries/Queries"
import {withApollo} from 'react-apollo';
import ExpiringAlert from "../ExpiringAlert";
import Loading from "../Loading";
import Dropzone from "react-dropzone";
import EventLogger from "../EventLogger";
import ErrLogger from "../ErrorLogger";

class DownloadButton extends Component {

    constructor(arg) {
        super(arg)
    }

    render() {
        return (<div className='android-download-icon' />);
    }
}

class MenuButton extends Component {

    constructor(arg) {
        super(arg)
        this.state = {dropdownOpen : false, sizePerPage : 5};
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    render() {
        return (<ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle>
                ...
            </DropdownToggle>
            <DropdownMenu>
                {this.props.row.type === "Note" && <DropdownItem onClick={this.props.setEditMode}>Edit</DropdownItem>}
                <DropdownItem onClick={this.props.delete}>Delete</DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>);
    }
}

class NotesAndAttachments extends Component {
    constructor(props) {
        super(props);
        this.state = {notesAndAttachments: [...this.props.noteList.map((note, index) => ({...note, type:"Note"})), ...this.props.attachmentList.map((att, index) => ({...att, type:"Attachment"}))].sort((a,b) => {
            return new Date(b.updated) - new Date(a.updated);
        } )};
    }
    componentWillReceiveProps(nextProps) {
        this.setState({page:1, notesAndAttachments: [...nextProps.noteList.map((note, index) => ({...note, type:"Note"})), ...nextProps.attachmentList.map((att, index) => ({...att, type:"Attachment"}))].sort((a,b) => {
                return new Date(b.updated) - new Date(a.updated);
            } )});

    }
    render() {



        const PaginationTable = ({ options, ...attributes }) => {

            const opt = paginationFactory({
                page: this.state.page,
                onPageChange: (page, sizePerPage) => {
                    this.state.page = page;
                    this.state.sizePerPage = sizePerPage;

                },
                sizePerPageList:[ {
                    text:"5", value: 5
                }, {
                    text:"10", value: 10
                }, {
                    text:"20", value: 20
                } ]
            });

            return (
                <BootstrapTable
                    wrapperClasses="table-responsive"
                    {...attributes}
                    pagination={ opt }
                />
            )
        }

        return (<Col>
                <Row className='filter-bar'>
                    <Col className='col-4'>
                        <h4>
                            Notes & Attachments
                        </h4>
                    </Col>
                    <Col className='col-2'>
                    </Col>
                    <Col className='col-6'>
                        <div className={"pull-right buttonPack"}>
                            <Button  color='primary' className='buttonSpace' onClick={()=> {
                                if (!this.state.notesAndAttachments[0] || this.state.notesAndAttachments[0].id !== "") {
                                    let newNotesAndAttachments = [...this.state.notesAndAttachments];
                                    newNotesAndAttachments.unshift({
                                        id: "",
                                        creationDate: new Date(),
                                        type: "Note",
                                        note: JSON.stringify({
                                            text: "",
                                            creationDate: new Date(),
                                            createdBy: this.global.username,
                                            createdByName: this.global.fullName,
                                            updatedByName: this.global.fullName
                                        })
                                    });
                                    this.setState({notesAndAttachments: newNotesAndAttachments, editableRowId: "", page: 1});
                                }
                            }}> <i className="icon ion-plus-round"></i>&nbsp;&nbsp;{i18n.t('notes.newNote')} </Button>

                            <Button color='primary' onClick={()=>{
                                if (!this.state.notesAndAttachments[0] || this.state.notesAndAttachments[0].id !== "") {
                                    let newNotesAndAttachments = [...this.state.notesAndAttachments];
                                    newNotesAndAttachments.unshift({
                                        id: "",
                                        creationDate: new Date(),
                                        type: "Attachment",
                                        text: JSON.stringify({
                                            creationDate: new Date(),
                                            createdBy: this.global.username,
                                            createdByName: this.global.fullName,
                                            updatedByName: this.global.fullName
                                        })
                                    });
                                    this.setState({notesAndAttachments: newNotesAndAttachments, editableRowId: "", page: 1});
                                }
                            }}> <i className="icon ion-plus-round"></i>&nbsp;&nbsp;{i18n.t('attachments.newAttachment')} </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
           <PaginationTable
               bordered={false}
               hover
               data={this.state.notesAndAttachments.map( (o,index) =>({...o, index:index}))}
               keyField={"id"}
               defaultSorted={[{
                   dataField: 'creationDate',
                   order: 'desc'
               }]}
                columns={[
               {
                   dataField: 'type',
                   text: i18n.t('common.type'),
                   formatter: (cell, row)=>{
                       return cell === "Note" ? <i className="icon ion-android-document" style={{fontSize:"18px"}} title={i18n.t('notes.note')}></i> : <i className="icon ion-android-attach"  style={{fontSize:"18px"}} title={i18n.t('attachments.attachment')}></i>
                   }
               }, {
                   dataField: 'note',
                   text: i18n.t('attachments.content'),
                   formatter: (cell, row)=>{
                       const note = row.note && row.note.startsWith('{') && row.note.endsWith('}') ? JSON.parse(row.note) : {text : row.note, createdBy: "Openbravo", createdByName: "Openbravo"};
                       const text = row.text && row.text.startsWith('{') && row.text.endsWith('}') ? JSON.parse(row.text) : {text : row.text, createdBy: "Openbravo", createdByName: "Openbravo"};
                       return this.state.editableRowId === row.id
                           ? (row.type === "Note" ?
                               <div>
                                   <Input type="textarea" defaultValue={note.text} onChange={(e) => {
                                       let newNotesAndAttachments = [...this.state.notesAndAttachments];
                                       newNotesAndAttachments[row.index] = {...row, note: JSON.stringify({text : e.target.value, creationDate:new Date(), createdBy: note.createdBy,  updatedBy: this.global.username , createdByName: note.createdByName, updatedByName: this.global.fullName})};
                                       this.state.notesAndAttachments = newNotesAndAttachments;
                                   }}/>
                                   <Button color="primary" className="buttonSpace buttonSpaceTop" onClick={()=>{
                                   if(row.id === ""){
                                       this.props.createNoteMutation({
                                           variables: {
                                               content: this.state.notesAndAttachments[row.index].note,
                                               contextTableName: this.props.tableName,
                                               recordId: this.props.recordId
                                           },
                                           refetchQueries :this.props.refetchQueries
                                       }).then(({ data }) => {
                                           let newNotesAndAttachments = [...this.state.notesAndAttachments];
                                           newNotesAndAttachments[row.index] = {...newNotesAndAttachments[row.index], id:data.createNote, updated:new Date()};
                                           this.setState({editableRowId: null, notesAndAttachments: newNotesAndAttachments});
                                           EventLogger(data, "SBMT_QUIRY_NOTE_OK");
                                           console.log('got data', data);
                                       }).catch((error) => {
                                           ReactDOM.render(<ExpiringAlert color="danger" message={error.message} />, document.getElementById('alert').appendChild(document.createElement("div")));
                                           ErrLogger(data, "SBMT_QUIRY_NOTE_ERR", error)
                                           console.log('there was an error sending the query', error);
                                       });
                                   } else {
                                       this.props.updateNoteMutation({

                                           variables: {
                                               content: this.state.notesAndAttachments[row.index].note,
                                               id: row.id
                                           },
                                           refetchQueries :this.props.refetchQueries
                                       }).then(({data}) => {
                                           delete this.state['oldNote'+row.id];
                                           let newNotesAndAttachments = [...this.state.notesAndAttachments];
                                           const newNote = "{ " + JSON.stringify(note).substr(1);
                                           newNotesAndAttachments[row.index] = {...newNotesAndAttachments[row.index], updated:new Date()};
                                           this.setState({editableRowId: null, notesAndAttachments: newNotesAndAttachments});
                                           EventLogger(data, "SBMT_QUIRY_NOTE_OK");
                                           console.log('got data', data);
                                       }).catch((error) => {
                                           ReactDOM.render(<ExpiringAlert color="danger" message={error.message} />, document.getElementById('alert').appendChild(document.createElement("div")));
                                           ErrLogger(data, "SBMT_QUIRY_NOTE_ERR", error)
                                           console.log('there was an error sending the query', error);
                                       });
                                   }
                                    }}>{i18n.t('common.save')}</Button>
                                   <Button color="secondary"  className="buttonSpaceTop" onClick={()=>{
                                       if(row.id === ""){
                                           let newNotesAndAttachments = [...this.state.notesAndAttachments];
                                           newNotesAndAttachments.shift();
                                           this.setState({editableRowId: null, notesAndAttachments: newNotesAndAttachments});
                                       } else {
                                           let newNotesAndAttachments = [...this.state.notesAndAttachments];
                                           newNotesAndAttachments[row.index] = this.state['oldNote'+row.id];
                                           this.setState({editableRowId: null, notesAndAttachments: newNotesAndAttachments, ['oldNote'+row.id]:null});
                                       }
                                   }}>{i18n.t('common.cancel')}</Button>
                               </div>
                               : <div>
                                   <Form>
                                       <FormGroup>
                                           <Row>
                                               <Col xs={"6"}>
                                                   <Label for="exampleFile">Select File to Attach</Label>
                                                   <div className={"task-details"}><div className={"files"}><Dropzone data-placeholder="Drop files to attach, or browse." className="droparea" onDrop={ files => {
                                                       let newNotesAndAttachments = [...this.state.notesAndAttachments];
                                                       newNotesAndAttachments[row.index] = {...row, name:files[0].name, text:JSON.stringify({creationDate:new Date(), createdBy: this.global.username, updatedBy: this.global.username, createdByName: this.global.fullName, updatedByName: this.global.fullName})};
                                                       this.setState({notesAndAttachments: newNotesAndAttachments, file: files[0]});
                                                   }}/>
                                                       {<span>{this.state.file ? this.state.file.name : null}</span>}
                                                   </div></div>
                                               </Col>
                                           </Row>
                                       </FormGroup>
                                   <Button color="primary" className="buttonSpace buttonSpaceTop" onClick={(e)=>{
                                       const data = new FormData();
                                       data.append('id', this.props.recordId);
                                       data.append('contextTableName', this.props.tableName);
                                       data.append('file', this.state.file);
                                       data.append('text', JSON.stringify(text));
                                       const config = {
                                           headers: {'Authorization': "Bearer " + localStorage.token}
                                       };
                                       axios.post(`${(window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/files`, data, config).then(({data}) => {
                                           let newNotesAndAttachments = [...this.state.notesAndAttachments];
                                           newNotesAndAttachments[row.index] = {...row, name:this.state.file.name, id:this.state.file.name};
                                           this.setState({notesAndAttachments: newNotesAndAttachments,editableRowId: null, file:null});
                                           this.props.refetchQueries.forEach( q => {
                                                   this.props.client.query({
                                                       ...q,
                                                       fetchPolicy: 'network-only'
                                                   }).then(({data}) => {
                                                       this.props.client.writeQuery({
                                                           ...q,
                                                           data: data
                                                       })
                                                   });
                                               }
                                           );
                                           console.log('got data', data);
                                       });
                                   }}>{i18n.t('common.submit')}</Button>
                                       <Button color="secondary"  className="buttonSpaceTop" onClick={()=>{
                                           let newNotesAndAttachments = [...this.state.notesAndAttachments];
                                           newNotesAndAttachments.shift();
                                           this.setState({editableRowId: null, notesAndAttachments: newNotesAndAttachments});
                                       }}>{i18n.t('common.cancel')}</Button></Form></div>)
                           : row.type === "Note"
                               ? note.text
                               : <a style={{cursor:"pointer"}} onClick={(e) => {
                                   axios.get(`${(window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/files/${row.name}?contextTableName=${this.props.tableName}&objectid=${this.props.recordId}`, {
                                       headers: {'Authorization': "Bearer " + localStorage.token},
                                       responseType: 'blob'
                                   }).then((response) => {
                                       fileDownload(response.data, row.name);
                                   })
                               }}>{row.name}&nbsp;<i className={"icon ion-android-download"}  style={{fontSize:"18px", marginLeft:"4px"}}></i>
                               </a>;
                   },
               }, {
                   dataField: 'creationDate',
                   text: i18n.t('common.dateCreated'),
                   formatter: (cell, row) => {
                       const createdByName = row.note
                           ? (row.note.startsWith('{') && row.note.endsWith('}')
                               ? JSON.parse(row.note).createdByName
                               : "Openbravo")
                           : row.text
                               ? (row.text.startsWith('{') && row.text.endsWith('}')
                                   ? JSON.parse(row.text).createdByName
                                   : "Openbravo")
                               : row.createdBy.name;
                       const createdBy = row.note
                           ? (row.note.startsWith('{') && row.note.endsWith('}')
                               ? JSON.parse(row.note).createdBy
                               : "Openbravo")
                           : row.text
                               ? (row.text.startsWith('{') && row.text.endsWith('}')
                                   ? JSON.parse(row.text).createdBy
                                   : "Openbravo")
                               : "placeholder"
                       return cell ? <span>
                       <img
                       width="32"
                       height="32"
                       style={{borderRadius: "50%"}}
                       src={`${(window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/avatar/${createdBy}`}
                       title={ createdByName }/>&nbsp;{new Date(cell).toLocaleDateString()}
                       </span> : null;},
                   sort: true
               },
                {
                    dataField: 'updated',
                    text: i18n.t('common.dateUpdated'),
                    formatter: (cell, row) => {
                        const updatedByName = row.note
                            ? (row.note.startsWith('{') && row.note.endsWith('}')
                                ? JSON.parse(row.note).updatedByName
                                : "Openbravo")
                            : row.text
                                ? (row.text.startsWith('{') && row.text.endsWith('}')
                                    ? JSON.parse(row.text).updatedByName
                                    : "Openbravo")
                                : row.updatedBy.name;
                        const updatedBy = row.note
                            ? (row.note.startsWith('{') && row.note.endsWith('}')
                                ? JSON.parse(row.note).updatedBy
                                : "Openbravo")
                            : row.text
                                ? (row.text.startsWith('{') && row.text.endsWith('}')
                                    ? JSON.parse(row.text).updatedBy
                                    : "Openbravo")
                                : "placeholder";
                        return (cell ?
                        <span>
                        <img
                        width="32"
                        height="32"
                        style={{borderRadius: "50%"}}
                        src={`${(window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/avatar/${updatedBy}`}
                        title={updatedByName}/>&nbsp;{new Date(cell).toLocaleDateString()}
                        </span> : null)
                    },
                    sort: true
                },
                {
                    dataField: 'menu',
                    text: '',
                    formatter: (cell, row) => {
                        return <MenuButton {...this.props} row={row}
                           setEditMode={()=> {
                               const oldNote = this.state.notesAndAttachments[row.index];
                               let newNotesAndAttachments = [...this.state.notesAndAttachments];
                               const newNote = "{ " + oldNote.note.substr(1);
                               newNotesAndAttachments[row.index] = {...row, note:newNote};
                               this.setState({editableRowId: row.id, ['oldNote'+row.id]:oldNote, notesAndAttachments: newNotesAndAttachments});
                           }}
                         delete={row.type === "Note"
                            ? (e) => {
                            if(confirm("Are you sure?")) {
                                this.props.deleteNoteMutation({

                                    variables: {
                                        id: row.id
                                    },
                                    refetchQueries :this.props.refetchQueries
                                }).then(({data}) => {
                                    let newNotesAndAttachments = [...this.state.notesAndAttachments];
                                    newNotesAndAttachments.splice(row.index, 1);
                                    this.setState({notesAndAttachments: newNotesAndAttachments, page:  Math.min(this.state.page, Math.ceil(newNotesAndAttachments.length / this.state.sizePerPage))});
                                    console.log('got data', data);
                                }).catch((error) => {
                                    ReactDOM.render(<ExpiringAlert color="danger" message={error.message} />, document.getElementById('alert').appendChild(document.createElement("div")));
                                    console.log('there was an error sending the query', error);
                                });
                            }
                            e.preventDefault();
                            e.stopPropagation();
                        }
                        : (e) => {
                                if (confirm("Are you sure?")) {
                                    axios.delete(`${(window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/files/${row.name}?contextTableName=${this.props.tableName}&objectid=${this.props.recordId}`, {
                                        headers: {'Authorization': "Bearer " + localStorage.token},
                                    }).then((response) => {
                                        let newNotesAndAttachments = [...this.state.notesAndAttachments];
                                        newNotesAndAttachments.splice(row.index, 1);
                                        this.setState({notesAndAttachments: newNotesAndAttachments, page: Math.min(this.state.page, Math.ceil(newNotesAndAttachments.length / this.state.sizePerPage))});
                                        this.props.refetchQueries.forEach( q => {
                                                ReactDOM.render(<Loading />, document.getElementById('alert'));
                                                this.props.client.query({
                                                        ...q,
                                                    fetchPolicy: 'network-only'
                                                }).then(({data}) => {
                                                    this.props.client.writeQuery({
                                                            ...q,
                                                        data: data
                                                    })
                                                    ReactDOM.render(null, document.getElementById('alert'));
                                                }).catch((error) => {
                                                    ReactDOM.render(<ExpiringAlert color="danger" message={error.message} />, document.getElementById('alert').appendChild(document.createElement("div")));
                                                    console.log('there was an error sending the query', error);
                                                })
                                            }
                                        );
                                    })
                                }
                            }
                        }/>;
                    }
                }
           ]}
           noDataIndication={() => <div>No records in table</div>}
           />
                </Col>
                </Row>
        </Col>

        );
    }
}

export default compose(
    graphql(createNoteMutation, { name: 'createNoteMutation' }),
    graphql(updateNoteMutation, { name: 'updateNoteMutation' }),
    graphql(deleteNoteMutation, { name: 'deleteNoteMutation' }))(withApollo(NotesAndAttachments));