import React, { useEffect, useState } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { SingleDatePicker } from "react-dates";
import { Col, Input, Label, Row } from "reactstrap";
import {
	findAllNominations,
} from "../../queries/Queries";
import Loading from "../../components/Loading/Loading";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const NominationsGrid = ({ client, billingAccounts}) => {

	const initialSort = [
		{
		  property: 'versionDate',
		  direction: 'desc'
		},
		{
		  property: 'startDateUTC',
		  direction: 'asc'
		}
	];

	const [selectedBillingAccount, setSelectedBillingAccount] = useState(null);
	const [selectedDate, setSelectedDate] = useState(moment());
	const [focus, setFocus] = useState(null);
	const [page, setPage] = useState(0);
  	const [sizePerPage, setSizePerPage] = useState(50);
	const [sort, setSort] = useState(initialSort);
	const [totalSize, setTotalSize] = useState(50);
	const [dataField, setDataField] = useState(null);
	const [order, setOrder] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [queryData, setQueryData] = useState([]);
	//use redux to trigger refetch of the query
	const refetchQueries = useSelector((state) => {
		return state.refetchQueries.refetchNominationsQuery;
	});

	const setPaging = ({page, sizePerPage}) => {
		setPage(page);
		setSizePerPage(sizePerPage);
	}

	const resetState = () => {
		setPage(0);
		setDataField(null);
		setOrder(null);
		setSort(initialSort);
	}

	const fetchData = async () => {
		if (!selectedBillingAccount) {
			setQueryData([]);
			setTotalSize(0);
			return;
		  }

		setLoading(true);
		try {
		  const { data } = await client.query({
			query: findAllNominations,
			variables: {
			  where: `executionContextIdentifier == '${selectedBillingAccount}' and settlementDay == ${selectedDate.format(
				moment.HTML5_FMT.DATE
			  )}`,
			  pageNumber: Math.max(0, page - 1),
			  pageSize: sizePerPage,
			  sort: {
				orders: sort
			  }
			},
			fetchPolicy: "no-cache",
		  });
	
		  setQueryData(data.findAllNominations.content);
		  setTotalSize(data.findAllNominations.totalElements);
		} catch (error) {
		  setError(error);
		  setLoading(false);
		} finally {
		  setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, [page, sizePerPage, dataField, order]);

	useEffect(() => {
		resetState(); //reset the page and sort properties of the table when refetching the query or new account / date is selected
		fetchData();
	}, [refetchQueries, selectedBillingAccount, selectedDate]);

	if(loading) return <Loading />;
	if(error) return <ErrorPage error={error} />;

	return (
		<div id="nominations-grid">
			<div className="table-filers-cnt">
				<div className="table-filers-elements">
					<Row className="mb-3 align-items-start">
						<Col xs="4" sm="4">
							<Label>Billing Account:</Label>
							<Input
								type="select"
								value={selectedBillingAccount}
								onChange={(e) => {
									setSelectedBillingAccount(e.target.value);
								}}
							>
								<option hidden value="default">Select billing account</option>
								{billingAccounts.map((acc) => (
									<option key={acc.billingAccountNumber} value={acc.billingAccountNumber}>
										{acc.billingAccountNumber}
									</option>
								))}
							</Input>
						</Col>
						<Col xs="2" sm="2">
							<Label>Date:</Label>
							<SingleDatePicker
								small
								date={selectedDate}
								onDateChange={(date) => setSelectedDate(date)}
								displayFormat={"DD/MM/YYYY"}
								focused={focus}
								onFocusChange={({ focused }) => setFocus(focused)}
								showDefaultInputIcon={true}
								noBorder={false}
								numberOfMonths={1}
								isOutsideRange={() => false}
							/>
						</Col>
					</Row>
				</div>
			</div>
			<BootstrapTable
				keyField="startDateUTC"
				classes={"text-center align-middle"}
				headerClasses="th-expand"
				wrapperClasses="table-responsive"
				noDataIndication={() => (
					<div>No records in table.</div>
				)}
				columns={[
					{
					 	dataField: "settlementDay",
						text: "Settlement Day",
						sort: true,
						formatter: (cell) => moment(cell).format("DD/MM/YYYY"),
					},
					{
						dataField: "quantity",
						text: "Quantity",
						sort: true,
					},
					{
						dataField: "startDateUTC",
						text: "Start Time",
						sort: true,
						formatter: (cell) =>
							moment.utc(cell).tz("Europe/London").format("DD/MM/YYYY HH:mm"),
					},
					{
						dataField: "endDateUTC",
						text: "End Time",
						sort: true,
						formatter: (cell) =>
							moment.utc(cell).tz("Europe/London").format("DD/MM/YYYY HH:mm"),
					},
					{
						dataField: "versionDate",
						text: "Submitted Date",
						sort: true,
						formatter: (cell) =>
							moment.utc(cell).tz("Europe/London").format("DD/MM/YYYY HH:mm"),
					},
					]}
					data={queryData}
					bordered={false}
					hover
					remote={{
						filter: true,
						pagination: true,
						sort: false,
						cellEdit: false,
					}}
					sort={{
                        dataField: dataField,
                        order: order,
                    }}
                    pagination={paginationFactory({
                        page: Math.max(1, page),
                        sizePerPage: sizePerPage,
                        sizePerPageList: [
                            {
                                text: "5",
                                value: 5,
                            },
                            {
                                text: "10",
                                value: 10,
                            },
                            {
                                text: "20",
                                value: 20,
                            },
							{
                                text: "50",
                                value: 50,
                            }
                            ],
                            showTotal: true, 
							totalSize: totalSize
						})}
					onTableChange={(type, newState) => {
						    
							setPaging({page: newState.page, sizePerPage: newState.sizePerPage});
							if (newState.sortField) {
								setDataField(newState.sortField);
								setOrder(newState.sortOrder);
								setSort([{
									property: newState.sortField,
									direction: newState.sortOrder
								}]);
							}
					}}
				/> 
		</div>
	);
};

export default withApollo(withRouter(NominationsGrid));
