import React, { useEffect, useState } from "react";
import i18n from "../../views/Pages/Login/i18n";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";

const QueryDropdownBtn = (props) => {
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [dropdownitems, setDropdownitems] = useState([]);

  const toggleDropdown = () => {
    setDropDownOpen(!dropdownOpen);
  };

  useEffect(() => {
    if (props.dropdownitems) {
      for (const item of props.dropdownitems) {
        setDropdownitems((items) => [
          ...items,
          <DropdownItem tag={Link} to={item.link} key={item.link}>
            {i18n.t(item.title)}
          </DropdownItem>,
        ]);
      }
    }
  }, [props.dropdownitems]);

  return (
    <div>
      <Dropdown isOpen={dropdownOpen} onClick={toggleDropdown}>
        <DropdownToggle className="pull-right" color="primary">
          <i className="icon ion-plus-round"></i>&nbsp;&nbsp;
          {i18n.t("header.new")}
        </DropdownToggle>
        <DropdownMenu>{dropdownitems}</DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default QueryDropdownBtn;
