import React, {Component} from 'reactn';
import {ResponsiveContainer} from "recharts";
import {Card, CardBody, Col, Row, Collapse, Button, ListGroup, ListGroupItem, Badge} from "reactstrap";
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from 'react-html-parser';

class CollapsableBars extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headerTitle: props.headerTitle,
            collapse: 0,
            cards: props.cards
        };
    }

    toggle = (e) => {
        const event = e.target.dataset.event;
        const allCards = [...this.state.cards]
            .map((card, index) => {
                Number(event - 1) === index ? (card.icon === '+' ? card.icon = '-' : card.icon = '+') : card.icon = '+';
                return {...card};
            });
        //     let singleCard = allCards[event - 1];
        //     singleCard.icon = singleCard.icon === '+' ? '-' : '+';
        this.setState({
            collapse: this.state.collapse === Number(event) ? 0 : Number(event),
            cards: allCards,
        });
    }

    render() {
        return (<Row style={{marginTop: "50px"}}>
            <Col sm={12}>
                <Card style={{
                    border: "0px"
                }}>
                    <CardBody style={{height: "auto", padding: ".25rem 1.25rem", border: "1px solid #e6e6e6"}}>
                        <ResponsiveContainer>
                            <div className="subheader row" style={{border: "none", padding: "20px 0"}}>
                                <h1 style={{padding: 0}}>
                                    {this.state.headerTitle}
                                </h1>
                                <div style={{padding: "0px 0px 0px 3px", width: "100%"}} class="dashboard-body">
                                    <div style={{lineHeight: "150%"}}>
                                        <br/>
                                        {this.state.cards.map((el, index) => {
                                            return (
                                                <div className="block" style={{marginBottom: "5px"}}>
                                                    <ListGroup key={el.id} data-event={el.id}>
                                                        <ListGroupItem onClick={this.toggle} data-event={el.id}
                                                                       style={{background: "#f5f4f4", border: "none"}}>
                                                            {el.title}
                                                            <span className="float-right"
                                                                  data-event={el.id}>{el.icon}</span>
                                                        </ListGroupItem>
                                                    </ListGroup>
                                                    <Collapse isOpen={this.state.collapse == el.id}>
                                                        <p className="card-body"
                                                           style={{background: "#fafafa"}}>{ReactHtmlParser(el.content)}</p>
                                                    </Collapse>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                {" "}
                            </div>
                        </ResponsiveContainer>
                    </CardBody>
                </Card>
            </Col>
        </Row>)

    }
}


export default CollapsableBars;
