import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

class TariffOverview extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const tariff =  this.props.quote.tariffName;
    let contDuration = this.props.quote.contractDuration;
    // Check if contractDuration has been set, if not get it from tarrifName.term
    if(contDuration === undefined || contDuration === null || contDuration === "") {
      contDuration = tariff.term;
    }

    let {commissiongasUnitRate, commissiongasStandingCharge} = this.props.quote;
    if(!commissiongasUnitRate){
        commissiongasUnitRate = this.props.quote.products.filter(({name})=> name === 'Gas').reduce((acc, curr) => curr.commissionUnitRate, {commissionUnitRate:0});
        commissiongasStandingCharge = this.props.quote.products.filter(({name})=> name === 'Gas').reduce((acc, curr) => curr.commissionStandingCharge, {commissionStandingCharge:0});
    }

    const aq = this.props.quote.annualgas ? this.props.quote.annualgas : (this.props.quote.aq ? this.props.quote.aq : 0);
    return (<div>
      <Row >
        <Col className='col-6'>
          <Card className="quote-preview-box">
            <CardBody>
              <Row>
                <Col>
                  <h4>GAS</h4>
                </Col>
                <Col className="col-12">
                  &nbsp;
                      </Col>
                <Col className="col-12">
                  <table className="rates" >
                    <tbody>
                      <tr>
                        <td>Unit Rate</td>
                        <td>{tariff.unitRate !== null && tariff.unitRate !== ''  ? (parseFloat(tariff.unitRate)+parseFloat(commissiongasUnitRate)).toFixed(3) +' p/kWh' : 'Price pending'}</td>
                      </tr>
                      <tr>
                        <td>Standing Charge</td>
                        <td>{tariff.sc !== null && tariff.sc !== ''  ? (parseFloat(tariff.sc)+parseFloat(commissiongasStandingCharge)).toFixed(2) +' p/day'  :  'Price pending'}</td>
                      </tr>
                      {/*<tr>*/}
                        {/*<td>Standing charge</td>*/}
                        {/*<td><i className="icon ion-checkmark"></i></td>*/}
                      {/*</tr>*/}
                      <tr>
                        <td>Contract length</td>
                        <td>{parseInt( contDuration)} months</td>
                      </tr>
                      <tr>
                        <td>Total annual cost</td>
                        <td>{tariff.sc !== null && tariff.sc !== '' ? (((parseFloat(tariff.sc ? tariff.sc : 0)+ parseFloat(commissiongasStandingCharge ? commissiongasStandingCharge : 0))*365
                            + parseFloat(tariff.unitRate ? tariff.unitRate : 0)*parseFloat(aq)
                            + parseFloat(commissiongasUnitRate ? commissiongasUnitRate : 0)*parseFloat(aq))/100).toLocaleString(undefined, {
                            style: "currency",
                            currency: 'GBP',
                            minimumFractionDigits: 2
                        }) : 'Price pending'}</td>
                      </tr>
                      {(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && <tr>
                        <td>Unit Rate Uplift</td>
                        <td>{ tariff.unitRate !== null && tariff.unitRate !== '' ? parseFloat(commissiongasUnitRate).toFixed(3) +' p/kWh'  : 'Price pending'}</td>
                      </tr>}
                      {(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && <tr>
                        <td>Standing Charge Uplift</td>
                        <td>{tariff.sc !== null && tariff.sc !== '' ? parseFloat(commissiongasStandingCharge) +' p/day' :  'Price pending'}</td>
                      </tr>}
                    </tbody>
                  </table>
                </Col>
              </Row>
              {/*<Row>*/}
                {/*<Col>*/}
                  {/*<h5>Third Party Costs Included <br />in the Rates Shown for each Meter</h5>*/}
                {/*</Col>*/}
                {/*<Col className="col-12">*/}
                  {/*&nbsp;*/}
                      {/*</Col>*/}
                {/*<Col className="col-12">*/}
                  {/*<table className="rates" >*/}
                    {/*<tbody>*/}
                      {/*<tr>*/}
                        {/*<td>Cost of Energy</td>*/}
                        {/*<td>Fixed</td>*/}
                      {/*</tr>*/}
                      {/*<tr>*/}
                        {/*<td>Transportation Charges</td>*/}
                        {/*<td>Pass-through</td>*/}
                      {/*</tr>*/}
                      {/*<tr>*/}
                        {/*<td>Metering Charges</td>*/}
                        {/*<td>Pass-through</td>*/}
                      {/*</tr>*/}
                      {/*<tr>*/}
                        {/*<td>Distribution Charges</td>*/}
                        {/*<td >Pass-through</td>*/}
                      {/*</tr>*/}
                    {/*</tbody>*/}
                  {/*</table>*/}
                {/*</Col>*/}
              {/*</Row>*/}
            </CardBody>
          </Card>
        </Col>
        {/*<Col className='col-6'>
          <Card className="quote-preview-box">
            <CardBody>
              <Row>
                <Col>
                  <h4>Additional Services</h4>
                </Col>
                <Col className="col-12">
                  &nbsp;
                      </Col>
                <Col className="col-sm-12">
                  <table className="rates" >
                    <tbody>
                      <tr>
                        <td>AMR</td>
                        <td><i className="icon ion-checkmark"></i></td>
                      </tr>
                      <tr>
                        <td>myEnergy</td>
                        <td><i className="icon ion-checkmark"></i></td>
                      </tr>
                      <tr>
                        <td>myPortal</td>
                        <td><i className="icon ion-checkmark"></i></td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
              <Row>
                <Col>
                  &nbsp;
                      </Col>
              </Row>
              <Row>
                <Col>
                  <h5>Billing</h5>
                </Col>
                <Col className="col-12">
                  &nbsp;
                      </Col>
                <Col className="col-12">
                  <table className="rates" >
                    <tbody>
                      <tr>
                        <td>Direct Debit</td>
                        <td><i className="icon ion-checkmark"></i></td>
                      </tr>
                      <tr>
                        <td>myBill</td>
                        <td><i className="icon ion-checkmark"></i></td>
                      </tr>
                      <tr>
                        <td>myReads</td>
                        <td><i className="icon ion-checkmark"></i></td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>*/}
      </Row>
      {/*<Row >
        <Col className='col-6'>
          <Card className="quote-preview-box">
            <CardBody>
              <Row>
                <Col>
                  <h4>Electricity</h4>
                </Col>
                <Col className="col-12">
                  &nbsp;
             </Col>
                <Col className="col-lg-8 col-md-8 col-sm-12">
                  <table className="rates" >
                    <tbody>
                      <tr>
                        <td>Unit Rate</td>
                        <td>14.5496</td>
                      </tr>
                      <tr>
                        <td>Standing charge</td>
                        <td>£10.91 / mth</td>
                      </tr>
                      <tr>
                        <td>Standing charge</td>
                        <td><i className="icon ion-checkmark"></i></td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
              <Row>
                <Col>
                  &nbsp;
             </Col>
              </Row>
              <Row>
                <Col>
                  <h5>Third Party Costs Included \u2028in the Rates Shown for each Meter</h5>
                </Col>
                <Col className="col-12">
                  &nbsp;
             </Col>
                <Col className="col-12">
                  <table className="rates" >
                    <tbody>
                      <tr>
                        <td>Assistance for Areas with High Electricity Distribution Costs (AAHEDC</td>
                        <td>Fixed</td>
                      </tr>
                      <tr>
                        <td>Balancing System Use of System Charges (BSUoS)</td>
                        <td>Fixed</td>
                      </tr>
                      <tr>
                        <td>Capacity Market (CM)</td>
                        <td>Fixed</td>
                      </tr>
                      <tr>
                        <td>CfD Costs (CfD)</td>
                        <td>Fixed</td>
                      </tr>
                      <tr>
                        <td>DA Charges</td>
                        <td>Fixed</td>
                      </tr>
                      <tr>
                        <td>DC Charges</td>
                        <td>Fixed</td>
                      </tr>
                      <tr>
                        <td>Distribution Use of System Charges (DUoS)</td>
                        <td>Fixed</td>
                      </tr>
                      <tr>
                        <td>Feed in Tariff costs (FiT)</td>
                        <td>Fixed</td>
                      </tr>
                      <tr>
                        <td>Renewables Obligation (RO)</td>
                        <td>Fixed</td>
                      </tr>
                      <tr>
                        <td>Transmission Network Use of System Charges (TNUoS)</td>
                        <td>Fixed</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>*/}
      <Row >
        <Col className='col-6'>
          {(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && !(window.config.consul.OSSO || (typeof OSSO !== 'undefined' ? OSSO : '' )) && <blockquote>*Prices are exclusive of CCL and VAT
            *Prices are subject to credit approval and Dyce Energy acceptance.
            *Prices are for online billing only; paper billing will be charged @ £2 per invoice per month.
            *Prices are subject to market movement and Dyce Energy acceptance
          </blockquote>}

          {!(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && !(window.config.consul.OSSO || (typeof OSSO !== 'undefined' ? OSSO : '' )) && <blockquote>
                Prices shown are exclusive of CCL & VAT
                Prices shown are subject to Credit Approval
                Price shown are subject to accepting our Terms and Conditions provided with the contract
              </blockquote>}
        </Col>
      </Row>
    </div>)
  }
}

export default TariffOverview;