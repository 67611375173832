const schemaHartree = {
    type: 'object',
    required: ['process'],
    properties: {
        id: {
            type: 'string',
            title: 'Id'
        },
        name: {
            type: 'string',
            title: ' ',
            placeholder: 'Task Name'
        },
        description: {
            type: 'string',
            title: 'Description',
            placeholder: 'Task Description'
        },
        mprn: {
            type: 'integer',
            title: 'Meter Point'
        },
        process: {
            type: 'string',
            title: 'Process',
            'enum': [
                ...!(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) ? [
                    'Block meter from billing',
                    'Complains',
                    'Change tariff',
                    'Contract termination',
                    'Meter measurement',
                    'Reading estimation',
                    'Live Billing',
                    'Special needs gas and electricity',
                    'Change meter technical details',
                    'Change of ownership'] : [],
                ...(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) ? [
                    'Sales',
                    'Meter Read',
                    'Meter Query',
                    'Bill Query',
                    'Commission Query',
                    'Contract Query',
                    'Change of Tenancy',
                    'General Query'] : []
            ]
        },
        assignee: {
            type: 'string',
            title: 'Assignee'
        },
        customer: {
            type: 'string',
            title: 'Customer'
        },
        dueDate: {
            type: 'string',
            title: 'Due Date',
            default: ""
        },
        status: {
            type: 'string',
            title: 'Status',
            'default': 'Open',
            'enum': [
                'Open',
                'In Progress',
                'Closed',
                'Cancelled'
            ]
        },
        priority: {
            type: 'integer',
            title: 'Priority',
            'default': 0,
            'enum': [
                0,
                50,
                100
            ],
            enumNames: [
                'Low',
                'Medium',
                'High'
            ]
        }
    }
};

export default schemaHartree;