import React, { Component } from 'reactn';
import { Query, withApollo } from 'react-apollo';
import gql from "graphql-tag";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { withRouter } from 'react-router';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import jsonata from "jsonata/jsonata-es5";
import moment from 'moment';
import Loading from '../Loading/Loading';

class SimpleDateQuery extends Component {

    state = {
        date: moment(),
        focused: false
    };

    setParents = (obj, parents) => {
        let newObj = JSON.parse(JSON.stringify(obj));
        const ownProps = Object.keys(newObj);
        for (let ownProp of ownProps) {
            if (newObj[ownProp] instanceof Object || newObj[ownProp] instanceof Array) {
                let newparents = parents.slice();
                newparents.unshift(obj);
                newObj[ownProp] = this.setParents(newObj[ownProp], newparents);
            }
        }
        newObj.__parents = parents;
        return newObj;
    }

    getFieldValue = (obj, path) => {
        if (obj) {
            var pathArray = path.split(".");
            if (pathArray.length > 1) {
                if (pathArray[0] === "#") {
                    let concatArr = [];
                    for (let j = 0; j < obj.length; j++) {
                        concatArr = concatArr.concat(this.getFieldValue(obj[j], path.substring(path.indexOf(".") + 1)));
                    }
                    return concatArr;
                } else {
                    return this.getFieldValue(obj[pathArray[0]], path.substring(path.indexOf(".") + 1));
                }
            } else {
                return obj[pathArray[0]];
            }
        } else {
            return [];
        }
    }

    render() {

        let query;

        if (this.props.query) {
            query = this.props.query;
        }
        else if (this.props.queries[this.props.queryKey]) {
            query = this.props.queries[this.props.queryKey];
        }
        else {
            query = null;
            console.log('Missing query for role and key: ' + this.props.queryKey);
        }

        console.log('this.state', this.state);

        return <Query query={gql`${query}`} variables={{
            date: this.state.date.format('YYYY-MM-DD')
        }}>
            {result => {
                if (result.loading) return <Loading />
                if (result.error) return <div> {result.error.message} </div>;

                const { data } = !result.loading && result || { data: { [this.props.dataPath]: [] } };
                const expression = this.props.expression ? jsonata(this.props.expression) : null;
                const transformedData = expression ? expression.evaluate(data) : data;
                const dataWithParents = this.setParents(transformedData, []);
                console.log('dataWithParents', dataWithParents);

                const displayData = this.getFieldValue(dataWithParents, this.props.dataPath);

                console.log('displayData', displayData);

                return <div className="page-cnt">
                    <Col sm="12" style={{
                        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif !important',
                        fontSize: '1 rem',
                        paddingLeft: 0,
                        paddingRight: 0
                    }}>
                        <Card style={{ marginBottom: 0 }}>
                            <CardHeader style={{
                                padding: ".25rem 1.25rem"
                            }}>
                                <CardTitle style={{
                                    fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif !important",
                                    fontSize: 15,
                                    lineHeight: 1.5,
                                    marginBottom: 0,
                                    padding: '6px 0',
                                    color: '#666'
                                }}>
                                    <div>
                                        <span style={{
                                            padding: "5px",
                                            color: "#666",
                                            fontWeight: 'bold',
                                            fontSize: '1 rem'
                                        }}>Information</span>
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardBody style={{ padding: ".25rem 1.25rem", height: '60px !important' }}>
                                <Row style={{ padding: "10px 0", alignItems: "flex-start" }}>
                                    <Col sm={6}>
                                        <Row>
                                            <Col sm={6}>
                                                <div className="override" >
                                                    <SingleDatePicker
                                                        small
                                                        style={{zIndex: '9'}}
                                                        date={this.state.date}
                                                        onDateChange={date => this.setState({ date })}
                                                        displayFormat={"DD/MM/YYYY"}
                                                        numberOfMonths={1}
                                                        focused={this.state.focused}
                                                        onFocusChange={({ focused }) => { this.setState({ focused }) }}
                                                        isOutsideRange={day => day.isAfter(moment())}
                                                        showDefaultInputIcon={true}
                                                        noBorder={false}
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm={6} style={{ paddingTop: 10 }}>
                                                <span style={{ fontWeight: 'bold' }}>Total: </span>{this.props.currency || <span>&euro;</span>} {displayData || 0}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={6} style={{ paddingTop: 10 }}>
                                        <p>{this.props.description}</p>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
            }}
        </Query>
    }
}

export default withRouter(withApollo(SimpleDateQuery))