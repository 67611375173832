import React, {Component} from "react";
import {
    Card,
    CardText,
    CardTitle,
    CardHeader,
    CardBody,
    Col,
    Form,
    FormGroup,
    FormText,
    Label,
    Row,
    DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown, Dropdown
} from "reactstrap";
import i18n from '../../views/Pages/Login/i18n';
import _ from "underscore";
import {withRouter} from "react-router-dom";
import {createUserMutation2} from "../../queries/Queries";
import gql from "graphql-tag";
import {withApollo} from "react-apollo";
import ErrLogger from "../ErrorLogger";
import EventLogger from "../EventLogger";

class GridDropDownMenu extends React.Component {
    constructor(props) {
        super(props);

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.applyTemplateToObject = this.applyTemplateToObject.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    applyTemplateToObject(obj, variables) {
        if (typeof obj === "string") {
            const compiled = _.template(obj);
            return compiled(variables);
        }
        for (var i in obj) {
            if (obj.hasOwnProperty(i)) {
                obj[i] = this.applyTemplateToObject(obj[i], variables);
            }
        }
        return obj;
    };

    render() {
        return (<ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}
            style={{
                position: "static"
            }}
            >
            <DropdownToggle
                className="btn btn-secondary">
                ...
            </DropdownToggle>
            <DropdownMenu>
                {this.props.formatExtraData.menus.map(menuItem => (
                        <DropdownItem
                            onClick={() => {
                                if (menuItem.confirm) {
                                    const compiled = _.template(menuItem.confirm);
                                    if (!confirm(compiled(this.props.row))) {
                                        retrun
                                    }
                                }
                                let eventName = menuItem.eventName ?  menuItem.eventName : '';
                                let eventErrorName = menuItem.eventErrorName ? menuItem.eventErrorName : '';
                                switch (menuItem.type) {
                                    case 'link':
                                        const compiled = _.template(menuItem.url);
                                        this.props.history.push(compiled(this.props.row));
                                        break;
                                    case 'query':
                                        this.props.client.mutate({
                                            mutation: gql`${menuItem.mutation}`,
                                            variables: this.applyTemplateToObject({...menuItem.variables}, this.props.row)
                                        }).then(({data}) => {
                                            if(eventName !== '') {EventLogger(data.disableAuthUser.username, eventName);}
                                            const refetchQuery = menuItem.updateKey && sessionStorage[menuItem.updateKey] && JSON.parse(sessionStorage[menuItem.updateKey]);
                                            if (refetchQuery) {
                                                refetchQuery.query = refetchQuery.query && gql`${refetchQuery.query}`;
                                                this.props.client.query({...refetchQuery, fetchPolicy:'network-only'}).then((data) => {

                                                })
                                            }
                                        }).catch((error) => (eventErrorName !== '' ? ErrLogger(data.disableAuthUser.username, eventErrorName, error) : ''));
                                        break;
                                }
                            }
                            }>{menuItem.name}</DropdownItem>
                    )
                )}
            </DropdownMenu>
        </ButtonDropdown>);
    }
}

export default withApollo(withRouter(GridDropDownMenu));
