import React, { Component } from 'react';
import {withRouter} from "react-router";
import { graphql } from "react-apollo";
import BootstrapTable from 'react-bootstrap-table-next';
import QueryDataGrid from "../QueryDataGrid";
import i18n from '../../views/Pages/Login/i18n';

import { productFormatter, priceFormatter, dateFormatter, periodFormatter, agreementsPriceFormatter } from "../../utils/Formatters";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardHeader, CardBody, CardText, Row, Col, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import Loading from '../Loading';
import NotesAndAttachments from "../../components/NotesAndAttachments";
import {getBusinessPartnerQuery, getContractQuery} from "../../queries/Queries"


class ContractsDetail extends Component {
    render() {
        if (!this.props.getContract.loading && this.props.getContract.contract) {
            return (<div style={{padding:"20px"}} className="agreemen-details">
                <Row>
                    <Col sm="12" className="header">
                        <Button onClick={this.props.history.goBack} className="btn btn-default"><i className="icon ion-arrow-left-b"></i>{i18n.t('businessPartner.contract')}:&nbsp;&nbsp;{this.props.getContract.contract.documentnumber}</Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm="4">
                        <Card>
                            <CardHeader>
                                <CardTitle>{i18n.t('businessPartner.agreementDetails')}</CardTitle>
                            </CardHeader>
                            <CardBody>

                                <Row>
                                    <Col>
                                        <Form>
                                            <FormGroup>
                                                <FormText >{i18n.t('businessPartner.agreementStatus')}</FormText>
                                                <Label>{this.props.getContract.contract.mDMContractStatus.name}</Label>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormText >{i18n.t('businessPartner.agreementNumber')}</FormText>
                                                <Label>{this.props.getContract.contract.documentnumber}</Label>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormText></FormText>
                                                <FormText>{i18n.t('contract.tariff')}</FormText>
                                                <Label>{this.props.getContract.contract.tariff}</Label>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormText></FormText>
                                                <FormText>{i18n.t('businessPartner.agreementDuration')}</FormText>
                                                <Label>{this.props.getContract.contract.dyceContractDurationInMonths}</Label>
                                            </FormGroup>
                                            {!(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && <FormGroup>
                                                <FormText>{i18n.t('businessPartner.billingAccount')}</FormText>
                                                <Label>{this.props.getContract.contract.businessPartner.bILLBillingAccountList[0].billingAccountNumber}</Label>
                                                {/* TODO OGI - Add Payment method */}
                                            </FormGroup>
                                            }
                                        </Form>
                                    </Col>
                                    <Col>
                                        <Form>
                                            <FormGroup>
                                                <FormText >{i18n.t('businessPartner.agreementSignDate')}</FormText>
                                                <Label>{this.props.getContract.contract.signdate ? new Date(this.props.getContract.contract.signdate).toLocaleDateString() : ''}</Label>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormText >{i18n.t('businessPartner.agreementStartDate')}</FormText>
                                                <Label>{this.props.getContract.contract.startDate ? new Date(this.props.getContract.contract.startDate).toLocaleDateString() : ''}</Label>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormText >{i18n.t('businessPartner.agreementEndDate')}</FormText>
                                                <Label>{this.props.getContract.contract.expirationdate ? new Date(this.props.getContract.contract.expirationdate).toLocaleDateString() : ''}</Label>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormText >{i18n.t('businessPartner.paymentMethod')}</FormText>
                                                <Label>{this.props.getContract.contract.businessPartner.bILLBillingAccountList[0] ? this.props.getContract.contract.businessPartner.bILLBillingAccountList[0].paymentMethod.name : null}</Label>
                                                {/* TODO OGI - Add Payment method */}
                                            </FormGroup>
                                            {!(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && <FormGroup>
                                                <FormText>{i18n.t('businessPartner.billingAccountStatus')}</FormText>
                                                <Label>{this.props.getContract.contract.businessPartner.bILLBillingAccountList[0].currentStatus
                                                    ? ({"Draft Contract": "Gain Started", "Signed Contract": "Live"})[ this.props.getContract.contract.businessPartner.bILLBillingAccountList[0].currentStatus.status ] || this.props.getContract.contract.businessPartner.bILLBillingAccountList[0].currentStatus.status
                                                    : null}</Label>
                                            </FormGroup>
                                            }
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="8">
                        <Card>
                            <CardHeader>
                                <CardTitle>{i18n.t('businessPartner.contractProducts')}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <QueryDataGrid className="invoice-table"
                                               dataPath={'contract.mDMContractPriceVersionList.#.priceListVersion.mDMProductPriceList'}
                                               keyField={'id'}
                                               query={`
                            query contractQuery {
                                contract(startRow: 0, offset: 100, where: "id = '${this.props.match.params.contractid}'", orderBy: "id") {
                                    id
                                    mDMContractPriceVersionList{
                                        priceListVersion{
                                            priceList{
                                                currency{
                                                    symbol
                                                    iSOCode
                                                    mdmCoins
                                                    standardPrecision
                                                }
                                            }
                                            mDMProductPriceList{
                                                id
                                                marketingProduct{
                                                    name
                                                    uOM{
                                                        name
                                                    }
                                                    isMdmDisplayCoins
                                                }
                                                standardPrice
                                            }
                                        }
                                        product{
                                            name
                                        }

                                    }

                                    noteList{
                                        id
                                        note
                                        creationDate
                                        updated
                                        createdBy{
                                            name
                                        }
                                        updatedBy{
                                            name
                                        }
                                    }
                                    attachmentList{
                                        id
                                        name
                                        creationDate
                                        updated
                                        text
                                        createdBy{
                                            name
                                        }
                                        updatedBy{
                                            name
                                        }
                                    }
                                }
                            }

                        ` }
                                               filterFunction={(data) => {
                                                   data = JSON.parse(JSON.stringify(data));
                                                   data.contract.mDMContractPriceVersionList = data.contract.mDMContractPriceVersionList
                                                       .filter(({product}) => product.name.indexOf('CCL Gas') === -1 && product.name.indexOf('Commision') === -1);
                                                   data.contract.mDMContractPriceVersionList = data.contract.mDMContractPriceVersionList
                                                       .map(a => {a.priceListVersion.mDMProductPriceList = a.priceListVersion.mDMProductPriceList
                                                                    .filter(({marketingProduct}) => marketingProduct.name.indexOf('CCL Gas') === -1 );
                                                                    return a;});
                                                   return data;}
                                               }
                                    columns={[{
                                        dataField: 'marketingProduct.name',
                                        text: i18n.t('product.name'),
                                        sort: true
                                    }, {
                                        dataField: 'marketingProduct.uOM.name',
                                        text: i18n.t('product.unit'),
                                        sort: true
                                    }, {
                                        dataField: 'standardPrice',
                                        text: i18n.t('product.price'),
                                        sort: true,
                                        align: "right",
                                        headerAlign: "right",
                                        formatter: agreementsPriceFormatter
                                    }]}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <NotesAndAttachments noteList={this.props.getContract.contract.noteList} attachmentList={this.props.getContract.contract.attachmentList} recordId={this.props.match.params.contractid} tableName={"MDM_Contract"}
                                         refetchQueries={[{query: getBusinessPartnerQuery, variables: { startRow: 0, offset: 10, where: "id = '" + this.props.match.params.id + "'", orderBy: "name" }},
                                         {query:getContractQuery, variables: { startRow: 0, offset: 10, where: "id = '" + this.props.match.params.contractid + "'", orderBy: "id" }}]}/>
                </Row>
            </div>)
        } else {
            return (<Loading/>);
        }
    }
}


export default graphql(getContractQuery, {
    name: 'getContract',
    options: props => ({
        variables: { where: "id = '" + props.match.params.contractid + "'", orderBy: "id" }
    }),
})(withRouter(ContractsDetail));
