import React, { Component } from 'reactn';
import ReactDOM from 'react-dom';
import ModalDialog from "../../components/ModalDialog";

class LinkButton extends Component {
    constructor(props) {

        super(props);
        this.state = { tooltipOpen: false };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ tooltipOpen: !this.state.tooltipOpen });
    }

    render() {

        return (
            <React.Fragment>
                <div
                    style={{
                        backgroundColor: 'none',
                    }}
                >
                    <div className="link-btn">
                        <a href={this.props.url || '#'} target="_blank" ref="noopener noreferer">
                            {this.props.label || ""}
                        </a>
                    </div>
                    <div className="info-icon" onClick={() => {
                        const modalDialog = (
                            <ModalDialog
                                text={this.props.tooltipText || ""}
                                opened={true}
                            />
                        );

                        ReactDOM.render(modalDialog, document.getElementById('alert').appendChild(document.createElement("div")));
                    }}>
                        <i className="fa fa-info-circle"></i>
                        <span>
                            &nbsp;More about payments
                        </span>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default LinkButton;