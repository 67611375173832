import React, {Component} from 'reactn';
import {Query, withApollo} from 'react-apollo';
import TasksDetail from "../../components/TasksDetail"
import {
    Button,
    Col,
    FormGroup,
    ListGroup,
    ListGroupItem,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row
} from "reactstrap";
import {getBusinessPartnerShortQuery, getTasksQuery} from "../../queries/Queries";
import CreateTask from "../CreateTask";
import TasksComments from "../../components/TasksComments";
import Loading from "../../components/Loading";
import {Typeahead} from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import moment from "moment";
import i18n from '../../views/Pages/Login/i18n';
import schemaHartree from "../../schemas/task-hartree.schema"

class Tasks extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            data: [],
            showCreateTask: false,
            filters: {
                priority: [],
                status: [{id: "Open", label: "Open"}, {id: "In Progress", label: "In Progress"}],
                owner: [],
                assignee: [{id: this.global.username, label: "Me"}]
            },
            taskId: null,
            page: 1,
            tasksPerPage: 10
        };
    }

    toggle() {
        this.setState({
            showCreateTask: !this.state.showCreateTask
        });
    }

    async componentDidMount() {
        const d = await this.props.client.query({
            query: getBusinessPartnerShortQuery,
            variables: { 'where': "businessPartnerCategory.name!='Vendor'", 'orderBy': "name"}
        })

        this.setState({...this.state, data: d.data.businessPartner})
    }

    render() {
        return (
            <div className="manage-users tasks-page">
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>Tasks</h1>
                    </Col>
                    <Col className='col-4'>
                        <FormGroup>
                            <Button onClick={() => this.setState({showCreateTask: true})} className='pull-right'
                                    color="primary"> <i className="icon ion-plus-round"></i>&nbsp;&nbsp;Add Task
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
                <div className="page-cnt">
                    <Row className='filter-bar'>
                        <Col>
                            <label className="filter-label">{i18n.t('tasks.priority')}</label>
                            <Typeahead
                                className="select"
                                multiple
                                placeholder={i18n.t('tasks.priority')}
                                defaultSelected={[]}
                                onChange={(selected) => {
                                    this.setState({task: null, filters: {...this.state.filters, priority: selected}})
                                }}
                                options={[{id: 0, label: "Low"}, {id: 50, label: "Medium"}, {id: 100, label: "High"}]}/>
                        </Col>
                        <Col>
                            <label className="filter-label">{i18n.t('tasks.status')}</label>
                            <Typeahead
                                className="select"
                                multiple
                                placeholder={i18n.t('tasks.status')}
                                defaultSelected={[{id: "Open", label: "Open"}, {
                                    id: "In Progress",
                                    label: "In Progress"
                                }]}
                                onChange={(selected) => {
                                    this.setState({task: null, filters: {...this.state.filters, status: selected}})
                                }}
                                options={[{id: "Open", label: "Open"},
                                    {id: "In Progress", label: "In Progress"},
                                    {id: "Closed", label: "Closed"},
                                    {id: "Cancelled", label: "Cancelled"}]}/>
                        </Col>
                        <Col>
                            <label className="filter-label">{i18n.t('tasks.process')}</label>
                            <Typeahead
                                className="select"
                                multiple
                                placeholder={i18n.t('tasks.process')}
                                onChange={(selected) => {
                                    this.setState({task: null, filters: {...this.state.filters, process: selected}})
                                }}
                                options={schemaHartree.properties.process.enum.map(process => ({
                                    id: process,
                                    label: process
                                }))}/>
                        </Col>
                        <Col>
                            <label className="filter-label">{i18n.t('tasks.owner')}</label>
                            <Typeahead
                                className="select"
                                multiple
                                placeholder={i18n.t('tasks.owner')}
                                defaultSelected={[]}
                                onChange={(selected) => {
                                    this.setState({task: null, filters: {...this.state.filters, owner: selected}})
                                }}
                                options={[{id: this.global.username, label: "Me"}]}/>
                        </Col>
                        <Col>
                            <label className="filter-label">{i18n.t('tasks.assignee')}</label>
                            <Typeahead
                                className="select"
                                multiple
                                placeholder={i18n.t('tasks.assignee')}
                                defaultSelected={[{id: this.global.username, label: "Me"}]}
                                onChange={(selected) => {
                                    this.setState({task: null, filters: {...this.state.filters, assignee: selected}})
                                }}
                                options={[{id: this.global.username, label: "Me"}, {id: "", label: "Nobody"}]}/>
                        </Col>
                    </Row>
                    <Query query={getTasksQuery}>
                        {({loading, error, data}) => {
                            if (loading) return <Loading/>;
                            if (error) return `Error! ${error.message}`;
                            if (data.getOwnTasks) {

                                const filteredTasks = data.getOwnTasks.map(task => {
                                    let task1 = {
                                        ...task, ...task.variables.reduce((accumulator, {name, value}) => {
                                            return {...accumulator, [name]: value};
                                        }, {})
                                    };
                                    task1.dueDate = task.dueDate ? task.dueDate.substring(0, 10) : "";
                                    task1.priority = task1.priority ? Math.floor(task1.priority / 50) * 50 : 0;
                                    task1.assignee = task1.assignee == null ? "" : task1.assignee;
                                    task1.status = task1.status == null ? "Open" : task1.status;
                                    return task1;
                                }).filter(task => Object.entries(this.state.filters)
                                    .map(entry => entry[1].length === 0 || entry[1].map(({id}) => id).indexOf(task[entry[0]]) > -1)
                                    .reduce((previousValue, currentValue) => previousValue && currentValue, true))
                                    .sort((a, b) => moment(a.createTime, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment(b.createTime, "YYYY-MM-DDTHH:mm:ssZ")) ? 1 : -1);

                                const numberOfPages = Math.ceil(filteredTasks.length / this.state.tasksPerPage);
                                const pagedTasks = filteredTasks.slice((this.state.page - 1) * this.state.tasksPerPage, this.state.page * this.state.tasksPerPage);

                                return (
                                    <Row className="tasks-cnt row-eq-height ">
                                        <Col sm={4} className="tasks-list">
                                            <div className="task-list-header clearfix">
                                                <span className="task-in-list pull-right">
                                                    {Math.min((this.state.page - 1) * this.state.tasksPerPage + 1, filteredTasks.length)} - {(this.state.page - 1) * this.state.tasksPerPage + pagedTasks.length} of {filteredTasks.length} {i18n.t('tasks.tasks')}
                                                </span>
                                            </div>
                                            <ListGroup>
                                                {pagedTasks.map((task, key) => {
                                                    return <ListGroupItem
                                                        key={key}
                                                        className={[task.id === this.state.taskId ? "selected" : "", task.status === 'Closed' || task.status === "Cancelled" ? "closed" : ""]}
                                                        onClick={() => this.setState({taskId: task.id})}>
                                                        {task.status === 'Closed' ?
                                                            <i className="icons ion-android-checkbox-outline primary leading"></i> :
                                                            <i className="icons ion-android-checkbox-outline-blank leading"></i>}
                                                        <span title={task.name}>{task.name}</span>
                                                        {task.priority === 100 ?
                                                            <i className="icons ion-android-arrow-up danger"></i> : ''}
                                                        {moment(task.dueDate, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment()) ?
                                                            <i className='icons ion-android-time danger'></i> : ""}
                                                    </ListGroupItem>
                                                })}
                                            </ListGroup>
                                            <div>
                                                {numberOfPages > 1 &&
                                                    <Pagination aria-label="Page navigation example" size="sm">
                                                        <PaginationItem>
                                                            <PaginationLink previous
                                                                            onClick={() => this.setState({page: Math.max(this.state.page - 1, 1)})}/>
                                                        </PaginationItem>
                                                        {Array.apply(null, Array(numberOfPages)).map((v, index) => (
                                                            <PaginationItem key={index}
                                                                            active={this.state.page === (index + 1)}>
                                                                <PaginationLink
                                                                    onClick={() => this.setState({page: index + 1})}>
                                                                    {index + 1}
                                                                </PaginationLink>
                                                            </PaginationItem>))}
                                                        <PaginationItem>
                                                            <PaginationLink next
                                                                            onClick={() => this.setState({page: Math.min(this.state.page + 1, numberOfPages)})}/>
                                                        </PaginationItem>
                                                    </Pagination>
                                                }
                                            </div>
                                        </Col>

                                        <Col sm={8} className="task-details-cnt">
                                            {this.state.taskId &&
                                                <TasksDetail
                                                    task={filteredTasks.filter(({id}) => id === this.state.taskId)[0]}
                                                    resetView={() => this.setState({taskId: null})}></TasksDetail>
                                            }
                                            <TasksComments
                                                task={filteredTasks.filter(({id}) => id === this.state.taskId)[0]}/>
                                        </Col>
                                        <CreateTask modalOpen={this.state.showCreateTask}
                                                    onClose={this.toggle}
                                                    client={window.config.consul.CLIENT}
                                        />
                                    </Row>);
                            } else {
                                return "No tasks";
                            }
                        }}

                    </Query>
                </div>

            </div>

        )
    }
}

export default withApollo(Tasks);
