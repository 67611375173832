import React, { Component } from "reactn";
import { Redirect, Route, Switch, Link, withRouter } from "react-router-dom";
import { Query, withApollo } from "react-apollo";
import { Button, Container, Modal, ModalBody, ModalHeader } from "reactstrap";
import Header from "../../components/Header/";
import Sidebar from "../../components/Sidebar/";
import Footer from "../../components/Footer/";

import BulkNomination from "../../views/BulkNomination/";
import Dashboard from "../../views/Dashboard/";
import SalesAgents from "../../views/SalesAgents/";
import CreateAgreement from "../../views/CreateAgreement/";
import CreateQuote from "../../views/CreateQuote/";
import Agreements from "../../views/Agreements/";
import Organisation from "../../views/Organisation/";
import Tariffs from "../../views/TariffsView/";
import Organisations from "../../views/Organisations/";
import CreateOrganisation from "../../views/CreateOrganisation/";
import Quotes from "../../views/Quotes/";
import Users from "../../views/Users/";
import CreateUser from "../../views/CreateUser/";
import EditUser from "../../views/EditUser/";
import ViewUser from "../../views/ViewUser/";
import BusinessPartner from "../../views/BusinessPartner";
import BusinessPartnersList from "../../views/BusinessPartnersList";
import ChangePassword from "../../views/ChangePassword";
import ResetPasswordTool from "../../views/ResetPasswordTool";
import GenericView from "../../components/GenericView";
import Loading from "../../components/Loading";
import Translations from "../../views/Translations";
import Transports from "../../views/Transports";
import Tasks from "../../views/Tasks";
import Roles from "../../views/Roles";
import {
  createSalesUserMutation,
  createUserMutation,
  getUserQuery,
  uiConfigurationQuery,
  getNumberOfUnreadMessages,
  updateSalesUserMutation,
  updateUserMutation,
} from "../../queries/Queries";
import Processes from "../../views/Processes";
import Systems from "../../views/Systems";
import QuoteOverviewWrapper from "../../views/QuoteOverviewWrapper";
import ContractsDetail from "../../components/ContractsDetail";

import ContractsDetailLocal from "../../components/ContractsDetailLocal";
import UsefulLinks from "../../views/UsefulLinks";
import StartProcess from "../../components/StartProcess";
import BPMNEditor from "../../views/BPMNEditor";
import CamundaLinks from "../../views/CamundaLinks";
import Reports from "../../views/Reports";
import UsersUpload from "../../views/UsersUpload";
import ConfigurableReports from "../../components/ConfigurableReports";
import BulkUploadReadings from "../../views/BulkUploadReadings";
import CronGenerator from "../../components/CronGenerator";
import ScheduledReports from "../../components/ScheduledReports";
import DownloadResource from "../../components/ScheduledReports/DownloadResource";
import gql from "graphql-tag";
import authentication from "../../modules/msal-b2c-react/msal-b2c-react";
import loadable from "@loadable/component";
import EditProfile from "../../views/EditProfile";
import MovingOut from "../../components/MovingOut/MovingOut";
import SupplyComparison from "../../views/Clients/Hartree/SupplyComparison/SupplyComparison";
import ComparisonGrid from "../../views/Clients/Hartree/ComparisonGrid/ComparisonGrid";
import Customers from "../../views/Clients/Hartree/Customers/Customer";
import ElexonInvoices from "../../views/Clients/Hartree/Invoices/ElexonInvoices";
import DNOInvoices from "../../views/Clients/Hartree/Invoices/DNOInvoices";
import WeeklyValidation from "../../views/Clients/Hartree/WeeklyValidation/WeeklyValidation";
import SupersetDashboard from "../../components/SupersetDashboard/SupersetDashboard";
import DocumentTypes from "../../views/DocumentTypes/DocumentTypes";
import DocumentTypeDetail from "../../views/DocumentTypesDetail/DocumentTypesDetail";
import DocumentTypesEditView from "../../views/DocumentTypesEdit/DocumentTypesEdit";
import CreateDocumentType from "../../components/CreateDocumentTypes/CreateDocumentType";
import CatalogPricesView from "../../views/CatalogPrices/CatalogPricesView";
import BillingSchemaListView from "../../views/BillingSchemaListView/BillingSchemaListView";
import BillingSchemaDetailsView from "../../views/BillingSchemaDetailsView/BillingSchemaDetailsView";
import MeterPointsList from "../../views/Clients/Hartree/MeterPointsList/MeterPointsList";
import BillingSchemaEditView from "../../views/BillingSchemaEditView/BillingSchemaEditView";
import GasInvoices from "../../views/Clients/Hartree/Invoices/GasInvoices";
import CreateBillingSchema from "../../components/CreateBillingSchema/CreateBillingSchema";
import Popup from "../../components/Popup/Popup";
import Graphs from "../../components/Graphs/Graphs";
import ContactUs from "../../gabrovo-ssp/ContactUs/ContactUs";
import NDMDashboard from "../../views/Clients/Hartree/NDMAllocations/NDMDashboard";

const LazyComponent = loadable((props) =>
  import(
    "./../../" +
      window.config.consul.CLIENT +
      "/" +
      (props.type || "views") +
      "/" +
      props.component
  ).catch(() =>
    import("./../../" + (props.type || "views") + "/" + props.component).catch(
      () => import("./../../views/Missing")
    )
  )
);

const CreateReadingView = React.lazy(() =>
  import("../../total/views/CreateReading/CreateReadingView")
);
const FlogasCreateReadingView = React.lazy(() =>
  import("../../views/Flogas/CreateReading/CreateReadingView")
);

const CreateTaskView = React.lazy(() =>
  import("../../views/CreateTask/CreateTaskView")
);
const FlogasCreateTaskView = React.lazy(() =>
  import("../../views/Flogas/CreateTask/CreateTaskView")
);

const CreatePaymentView = React.lazy(() =>
  import("../../views/CreatePayment/CreatePaymentView")
);

class AsyncLoad extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      component: null,
    };
  }

  componentDidMount() {
    let props = this.props;
    if (props.module === "cockpit-ui-dataflows") {
      import("cockpit-ui-dataflows")
        .then((Components) => {
          this.setState({ component: Components[props.name] });
        })
        .catch((error) => {
          console.log(error.toLocaleString());
        });
    }
  }

  render() {
    const C = this.state.component;
    return C ? <C {...this.props.componentProps} /> : null;
  }
}

const DataflowsRouter = (props) => (
  <AsyncLoad
    module={"cockpit-ui-dataflows"}
    name={"DataflowsRouter"}
    componentProps={props}
  />
);

class Full extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogoutWarning: false,
      unreadMessages: 0,
    };
    this.getDefaultPath = this.getDefaultPath.bind(this);
    this.fetchMessageCount = this.fetchMessageCount.bind(this);
    this.isEnabledPopup = this.isEnabledPopup.bind(this);
  }

  componentDidMount() {
    if (!!window.config.consul.IS_PORTAL_MESSAGES_ENABLED) this.fetchMessageCount();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !this.state.timeout ||
      this.state.timeout.pathname !== this.props.location.pathname
    ) {
      const resetTimeouts = () => {
        if (this.state.timeout) {
          clearTimeout(this.state.timeout.logoutTimeout);
          clearTimeout(this.state.timeout.warningTimeout);
        }
        const tout = parseInt(window.config.consul.INACTIVITY_TIMEOUT) || 30;
        const logoutTimeout = setTimeout(
          () => this.props.history.push("/logout"),
          1000 * 60 * tout
        );
        const warningTimeout = setTimeout(
          () => this.setState({ showLogoutWarning: true }),
          1000 * 60 * (tout - 1)
        );
        this.setState({
          timeout: {
            pathname: this.props.location.pathname,
            logoutTimeout,
            warningTimeout,
            clearTimeouts: () => resetTimeouts(),
          },
          showLogoutWarning: false,
        });
      };
      resetTimeouts();
    }
  }

  isEnabledPopup() {
    // Get all popups from consul and check if they are enabled
    // Map the filtered popups to Popup components
    return Object.entries(window.config.consul)
      .reduce((acc, [key, value]) => {
        if (key.includes("POPUP") && value.status) acc.push(value);

        return acc;
      }, [])
      .map((popup, i) => <Popup key={i} popup={popup} />);
  }

  fetchMessageCount() {
    this.props.client
      .query({
        query: getNumberOfUnreadMessages,
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        if (this.state.unreadMessages !== data.UnreadNotificationCount) {
          this.setState({ unreadMessages: data.UnreadNotificationCount });
        }
      });
  }

  getDefaultPath(items) {
    for (let i = 0; i < items.length; i++) {
      if (items[i].defaultMenu) return items[i].url;
      if (items[i].children) return this.getDefaultPath(items[i].children);
    }
  }

  render() {
    return (
      <Query query={uiConfigurationQuery}>
        {(confResult) => {
          if (confResult.loading) return <Loading />;
          if (confResult.error) {
            document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            confResult.client.resetStore();
            localStorage.clear();
            this.props.history.push("/");
            return (
              <h1>
                Cockpit Core is down. Please contact your system administrator.
              </h1>
            );
          }

          const transformUiMenuItemOrder = (uiMenuItemOrder) => ({
            title: uiMenuItemOrder.uiMenuItem.type === "title",
            divider: uiMenuItemOrder.uiMenuItem.type === "divider",
            name: uiMenuItemOrder.uiMenuItem.defaultLabel,
            url: uiMenuItemOrder.uiMenuItem.url,
            icon: uiMenuItemOrder.uiMenuItem.icon,
            order: uiMenuItemOrder.order,
            class: uiMenuItemOrder.uiMenuItem.cssClass,
            badge: {
              variant: uiMenuItemOrder.uiMenuItem.badgeVariant,
              text: uiMenuItemOrder.uiMenuItem.badgeText,
            },
            children:
              uiMenuItemOrder.children && uiMenuItemOrder.children.length > 0
                ? uiMenuItemOrder.children
                    .map(transformUiMenuItemOrder)
                    .sort(sortChildrensByOrder)
                : null,
            defaultMenu: uiMenuItemOrder.defaultMenu,
          });

          const sortChildrensByOrder = (children1, children2) =>
            children1.order - children2.order;

          if (confResult.data.getUIConfiguration) {
            const items =
              confResult.data.getUIConfiguration.uiMenus.uiMenuItemOrders.map(
                transformUiMenuItemOrder
              );
            let defaultPath = this.getDefaultPath(items);
            defaultPath =
              defaultPath && defaultPath != "/" ? defaultPath : "/dashboard";

            return (
              <Query query={getUserQuery}>
                {({ loading, error, data }) => {
                  if (loading) return null;
                  if (error) return `Error! ${error.message}`;
                  localStorage.roles = data.getUser.roles.map(
                    ({ role }) => role.key
                  );
                  const org = data.getUser.organizations.reduce(
                    (acc, curr) =>
                      curr.businessPartnerId != null ? curr : acc,
                    {}
                  );
                  if (!this.global.user) {
                    this.setGlobal({
                      user: data.getUser,
                      username: data.getUser.username,
                      fullName:
                        data.getUser.firstName + " " + data.getUser.lastName,
                      roles: data.getUser.roles.map(({ role }) => role.key),
                      permissions: [
                        ...new Set(
                          data.getUser.roles
                            .flatMap(({ role }) => role.permissions)
                            .map(({ permission }) => permission.key)
                        ),
                      ],
                      salesOrganization: org.businessPartnerId,
                      ultimateParentOrganization: org.ultimateParent
                        ? org.ultimateParent.businessPartnerId
                        : null,
                    });
                  }

                  const getUserQueryContent = (
                    <div className='app'>
                      <div className='app-body'>
                        <Sidebar
                          {...this.props}
                          unreadMessages={this.state.unreadMessages}
                          nav={{ items: items }}
                        />
                        <main className='main'>
                          <Header currentUser={data.getUser} />
                          <Container fluid>
                            <Switch>
                              <Route
                                path='/reports'
                                exact
                                name='Configurable Reports'
                                render={(props) => (
                                  <ConfigurableReports
                                    configuration={{
                                      ...confResult.data.getUIConfiguration,
                                      global: this.global,
                                    }}
                                  />
                                )}
                              />
                              <Route
                                path='/scheduled-reports'
                                exact
                                name='Scheduled Reports'
                                render={(props) => (
                                  <ScheduledReports
                                    configuration={{
                                      ...confResult.data.getUIConfiguration,
                                      global: this.global,
                                    }}
                                  />
                                )}
                              />
                              <Route
                                path='/resetUserPassword'
                                exact
                                name='Reset User Password'
                                component={ResetPasswordTool}
                              />
                              <Route
                                path='/download-reports/:reportId'
                                exact
                                name='Download report'
                                component={DownloadResource}
                              />
                              <Route
                                path='/invoicePrint/:invoiceId'
                                exact
                                name='Download invoice'
                                component={DownloadResource}
                              />
                              <Route
                                path='/invoice/:invoiceId/download'
                                exact
                                name='Download Invoice'
                                render={(props) => {
                                  const FileDownload = require("js-file-download");

                                  axios
                                    .get(
                                      `/essence-services/essence-services/v1/invoice/${props.match.params.invoiceId}/download`,
                                      {
                                        headers: {
                                          Authorization:
                                            "Bearer " + localStorage.token,
                                        },
                                        responseType: "blob",
                                      }
                                    )
                                    .then((response) => {
                                      FileDownload(
                                        response.data,
                                        "invoice.pdf"
                                      );
                                    });
                                  return (
                                    <Dashboard currentUser={data.getUser} />
                                  );
                                }}
                              />
                              <Route
                                path='/start/bp/:businessPartnerId'
                                name='StartProcess'
                                component={StartProcess}
                              />
                              <Route
                                path='/start/contract/:contractId'
                                name='StartProcess'
                                component={StartProcess}
                              />
                              <Route
                                path='/dashboard'
                                name='Dashboard'
                                render={() => (
                                  <LazyComponent
                                    component={"Dashboard/Dashboard"}
                                    currentUser={data.getUser}
                                  />
                                )}
                              />
                              <Route
                                path='/changepassword'
                                exact
                                name='Users'
                                component={ChangePassword}
                              />
                              <Route
                                path='/translations'
                                exact
                                name='Translations'
                                component={Translations}
                              />
                              <Route
                                path='/transports'
                                exact
                                name='Transports'
                                component={Transports}
                              />
                              <Route
                                path='/systems'
                                exact
                                name='Systems'
                                component={Systems}
                              />
                              <Route
                                path='/roles'
                                exact
                                name='Roles'
                                component={Roles}
                              />
                              <Route
                                path='/users'
                                exact
                                name='Users'
                                component={Users}
                              />
                              <Route
                                path='/users/createuser'
                                name='Create User'
                                render={(props) => (
                                  <CreateUser
                                    {...props}
                                    currentUser={data.getUser}
                                    createUserMutation={createUserMutation}
                                    setCurrentUser={(user) =>
                                      this.setCurrentUser(user)
                                    }
                                    organizationRequired={false}
                                    backTo={"/users"}
                                    showSystems={true}
                                  />
                                )}
                              />
                              <Route
                                path='/users-admin/edituser/:username'
                                name='Edit User'
                                render={(props) => (
                                  <EditUser
                                    {...props}
                                    currentUser={data.getUser}
                                    updateUserMutation={updateUserMutation}
                                    setCurrentUser={(user) =>
                                      this.setCurrentUser(user)
                                    }
                                    allowedRoles={
                                      this.global.permissions.includes(
                                        "Users.AssignSalesManagerRole"
                                      ) ||
                                      this.global.permissions.includes(
                                        "Users.AssignRoles"
                                      )
                                        ? [
                                            "userAdmin",
                                            "MultipleCustomers",
                                            "SingleCustomer",
                                          ]
                                        : null
                                    }
                                    organizationRequired={false}
                                    backTo={"/users"}
                                    showSystems={true}
                                  />
                                )}
                              />
                              <Route
                                path='/users/edituser/:username'
                                name='Edit User'
                                render={(props) => (
                                  <EditProfile
                                    {...props}
                                    currentUser={data.getUser}
                                    updateUserMutation={updateUserMutation}
                                    setCurrentUser={(user) =>
                                      this.setCurrentUser(user)
                                    }
                                    allowedRoles={
                                      this.global.permissions.includes(
                                        "Users.AssignSalesManagerRole"
                                      )
                                        ? [
                                            "SALESADMIN",
                                            "SALESMANAGER",
                                            "SALESAGENT",
                                          ]
                                        : null
                                    }
                                    organizationRequired={false}
                                    backTo={"/users"}
                                    showSystems={true}
                                  />
                                )}
                              />
                              <Route
                                path='/users/viewuser/:username'
                                name='View User'
                                render={(props) => (
                                  <ViewUser
                                    {...props}
                                    currentUser={data.getUser}
                                  />
                                )}
                              />
                              <Route
                                path='/users/viewuser/'
                                name='View User'
                                render={(props) => (
                                  <ViewUser
                                    {...props}
                                    currentUser={data.getUser}
                                  />
                                )}
                              />
                              <Route
                                path='/bp/:id'
                                name='Business Partner'
                                component={BusinessPartner}
                              />
                              <Route
                                path='/view/:name/:param/:param1?/:param2?/:param3?/:param4?/:param5?'
                                render={(props) => (
                                  <GenericView
                                    {...props}
                                    configuration={{
                                      ...confResult.data.getUIConfiguration,
                                      global: this.global,
                                    }}
                                  />
                                )}
                              />
                              <Route
                                path='/view/:name/:param'
                                render={(props) => (
                                  <GenericView
                                    {...props}
                                    configuration={
                                      confResult.data.getUIConfiguration
                                    }
                                  />
                                )}
                              />
                              <Route
                                path='/view/:name/'
                                render={(props) => (
                                  <GenericView
                                    {...props}
                                    configuration={
                                      confResult.data.getUIConfiguration
                                    }
                                  />
                                )}
                              />
                              <Route
                                path='/supply-comparison'
                                name='Supply Comparison'
                                component={SupplyComparison}
                              />
                              {/*<Route path="/DNO-comparison" name="DNO Comparison" component={DNOComparison} />*/}
                              <Route
                                path='/DNO-Invoices'
                                name='INVOICES'
                                render={(props) => <DNOInvoices {...props} />}
                              />
                              <Route
                                path='/Elexon-Invoices'
                                name='INVOICES'
                                render={(props) => (
                                  <ElexonInvoices {...props} />
                                )}
                              />
                              <Route
                                path='/Gas-Invoices'
                                name='Gas Invoices'
                                render={(props) => <GasInvoices {...props} />}
                              />
                              <Route
                                path='/NDM-Dashboard'
                                name='NDM Dashboard'
                                render={(props) => <NDMDashboard {...props} />}
                              />
                              <Route
                                path='/Weekly-Validation'
                                name='Weekly Validation'
                                render={(props) => (
                                  <WeeklyValidation {...props} />
                                )}
                              />
                              <Route
                                path='/comparison-grid'
                                name='Comparison Grid'
                                component={ComparisonGrid}
                              />
                              <Route
                                path='/customers'
                                name='Customers'
                                component={Customers}
                              />
                              <Route
                                path='/bpl'
                                name='Business Partners'
                                component={BusinessPartnersList}
                              />
                              <Route
                                path='/bpl/:name'
                                name='Business Partner Search Results'
                                component={BusinessPartnersList}
                              />
                              <Route
                                path='/contact-us'
                                name='Contact Us'
                                component={ContactUs}
                              />
                              <Route
                                path='/tasks'
                                exact
                                name='Tasks'
                                render={(props) => (
                                  <Tasks
                                    {...props}
                                    configuration={
                                      confResult.data.getUIConfiguration
                                    }
                                    queryKey={"getTasksQuery"}
                                    queryPath={"getOwnTasks"}
                                  />
                                )}
                              />
                              <Route
                                path='/queries'
                                exact
                                name='Tasks'
                                component={Tasks}
                              />
                              <Route
                                path='/queries/:queryId'
                                exact
                                name='Tasks'
                                component={Tasks}
                              />
                              <Route
                                path='/processes'
                                exact
                                name='Processes'
                                component={Processes}
                              />
                              <Route
                                path='/moving-out'
                                exact
                                name='Moving Out'
                                component={MovingOut}
                              />
                              <Route
                                path='/dataflows'
                                name='Dataflows'
                                component={DataflowsRouter}
                              />
                              <Route
                                path='/quotes'
                                exact
                                name='Quotes'
                                component={Quotes}
                              />
                              <Route
                                path='/usersUpload'
                                exact
                                name='Users Upload'
                                component={UsersUpload}
                              />
                              <Route
                                path='/readsUpload'
                                exact
                                name='Reads Upload'
                                component={BulkUploadReadings}
                              />
                              <Route
                                path='/createPayment'
                                exact
                                name='Create Payment'
                                render={(props) => {
                                  return (
                                    <React.Suspense
                                      fallback={<div>Loading...</div>}
                                    >
                                      <CreatePaymentView />
                                    </React.Suspense>
                                  );
                                }}
                              />
                              <Route
                                path='/createReading'
                                exact
                                name='Create Reading' // component={CreateReadingView}
                                render={(props) => (
                                  <LazyComponent
                                    component={
                                      "CreateReading/CreateReadingView"
                                    }
                                  />
                                )}
                              />
                              <Route
                                path='/createTask'
                                exact
                                name='Create Task'
                                render={(props) => {
                                  if (true) {
                                    // Enter flogas process variable

                                    return (
                                      <React.Suspense
                                        fallback={<div>Loading...</div>}
                                      >
                                        <FlogasCreateTaskView
                                          configuration={{
                                            ...confResult.data
                                              .getUIConfiguration,
                                            global: this.global,
                                          }}
                                        />
                                      </React.Suspense>
                                    );
                                  }

                                  return (
                                    <React.Suspense
                                      fallback={<div>Loading...</div>}
                                    >
                                      <CreateTaskView
                                        configuration={{
                                          ...confResult.data.getUIConfiguration,
                                          global: this.global,
                                        }}
                                      />
                                    </React.Suspense>
                                  );
                                }}
                              />
                              <Route
                                path='/sales-agents'
                                exact
                                name='SalesAgents'
                                component={SalesAgents}
                              />
                              <Route
                                path='/organization/:id/create-sales-agent'
                                exact
                                name='CreateSalesAgent'
                                render={(props) => (
                                  <CreateUser
                                    {...props}
                                    currentUser={data.getUser}
                                    rolesInUserMutation={true}
                                    createUserMutation={createSalesUserMutation}
                                    allowedRoles={
                                      this.global.permissions.includes(
                                        "Users.AssignSalesManagerRole"
                                      )
                                        ? ["SALESMANAGER", "SALESAGENT"]
                                        : ["SALESAGENT"]
                                    }
                                    setCurrentUser={(user) =>
                                      this.setCurrentUser(user)
                                    }
                                    backTo={"/organization/:id"}
                                    organizationRequired={true}
                                    showSystems={false}
                                  />
                                )}
                              />
                              <Route
                                path='/sales-agents/create-sales-agent'
                                exact
                                name='CreateSalesAgent'
                                render={(props) => (
                                  <CreateUser
                                    {...props}
                                    currentUser={data.getUser}
                                    rolesInUserMutation={true}
                                    createUserMutation={createSalesUserMutation}
                                    allowedRoles={
                                      this.global.permissions.includes(
                                        "Users.AssignSalesManagerRole"
                                      )
                                        ? ["SALESMANAGER", "SALESAGENT"]
                                        : ["SALESAGENT"]
                                    }
                                    setCurrentUser={(user) =>
                                      this.setCurrentUser(user)
                                    }
                                    backTo={"/sales-agents"}
                                    organizationRequired={true}
                                    showSystems={false}
                                  />
                                )}
                              />
                              <Route
                                path='/sales-agents/:username'
                                exact
                                name='SalesAgent'
                                render={(props) => (
                                  <EditUser
                                    {...props}
                                    currentUser={data.getUser}
                                    rolesInUserMutation={true}
                                    updateUserMutation={updateSalesUserMutation}
                                    allowedRoles={
                                      this.global.permissions.includes(
                                        "Users.AssignSalesManagerRole"
                                      )
                                        ? ["SALESMANAGER", "SALESAGENT"]
                                        : ["SALESAGENT"]
                                    }
                                    setCurrentUser={(user) =>
                                      this.setCurrentUser(user)
                                    }
                                    organizationRequired={true}
                                    backTo={"/sales-agents"}
                                    showSystems={false}
                                  />
                                )}
                              />
                              <Route
                                path='/agreements-old/:contractid'
                                exact
                                name='Agreement'
                                component={ContractsDetail}
                              />
                              <Route
                                path='/agreements/:contractid'
                                exact
                                name='Agreement'
                                component={ContractsDetailLocal}
                              />
                              <Route
                                path='/agreements'
                                exact
                                name='Agreements'
                                component={Agreements}
                              />
                              <Route
                                path='/create-agreement/:processInstanceId'
                                exact
                                name='CreateAgreement'
                                component={CreateAgreement}
                              />
                              <Route
                                path='/quotes/:quoteId'
                                name='ViewQuote'
                                component={QuoteOverviewWrapper}
                              />
                              <Route
                                path='/create-quote/:quoteId'
                                name='CreateQuote'
                                component={CreateQuote}
                              />
                              <Route
                                path='/create-quote'
                                name='CreateQuote'
                                component={CreateQuote}
                              />
                              <Route
                                path='/price-quote/:quoteId'
                                name='CreateQuote'
                                render={(props) => (
                                  <CreateQuote priceQuote={true} {...props} />
                                )}
                              />
                              <Route
                                path='/organization/:id'
                                exact
                                name='Organisation'
                                render={(props) => (
                                  <Organisation
                                    salesOrganization={org.businessPartnerId}
                                    {...props}
                                  />
                                )}
                              />
                              <Route
                                path='/organization'
                                exact
                                name='Organisation'
                                render={(props) => (
                                  <Organisation
                                    salesOrganization={org.businessPartnerId}
                                    {...props}
                                  />
                                )}
                              />
                              <Route
                                path='/organizations'
                                exact
                                name='Organisations'
                                component={Organisations}
                              />
                              <Route
                                path='/tariffs'
                                exact
                                name='Tariffs'
                                component={Tariffs}
                              />
                              <Route
                                path='/catalog-prices'
                                exact
                                name='catalog-prices'
                                component={CatalogPricesView}
                              />
                              <Route
                                path='/create-organisation'
                                exact
                                name='CreateOrganisation'
                                component={CreateOrganisation}
                              />
                              <Route
                                path='/bulk-nomination'
                                exact
                                name='BulkNomination'
                                component={BulkNomination}
                              />
                              <Route
                                path='/bulk-nomination/progress'
                                exact
                                name='BulkNomination'
                                component={BulkNomination}
                              />
                              <Route
                                path='/links'
                                exact
                                name='UsefulLinks'
                                component={UsefulLinks}
                              />
                              <Route
                                path='/bpmn-editor'
                                exact
                                name='BPMN Editor'
                                component={BPMNEditor}
                              />
                              <Route
                                path='/camunda-links'
                                exact
                                name='Camunda Links'
                                component={CamundaLinks}
                              />
                              <Redirect from='/' exact to={defaultPath} />
                              <Route
                                path='/reports'
                                exact
                                name='Configurable Reports'
                                render={(props) => (
                                  <ConfigurableReports
                                    configuration={{
                                      ...confResult.data.getUIConfiguration,
                                      global: this.global,
                                    }}
                                  />
                                )}
                              />
                              <Route
                                path='/service-requests'
                                exact
                                name='Service Requests'
                                render={(props) => (
                                  <ServiceRequests
                                    configuration={{
                                      ...confResult.data.getUIConfiguration,
                                      global: this.global,
                                    }}
                                  />
                                )}
                              />
                              {
                                // map superset dashboards from config to routes
                                window.config.consul.BI_DASHBOARD_IDS &&
                                  window.config.consul.BI_DASHBOARD_IDS.map(
                                    (dashboard, index) => {
                                      return !dashboard.doNotRenderSingleRoute ? (
                                        <Route
                                          key={index}
                                          path={dashboard.path}
                                          exact
                                          name={dashboard.name}
                                          render={(props) => (
                                            <SupersetDashboard
                                              id={dashboard.id}
                                              supersetDomain={
                                                window.config.consul
                                                  .BI_SUPERSET_DOMAIN
                                              }
                                              dashboardName={dashboard.name}
                                            />
                                          )}
                                        />
                                      ) : null;
                                    }
                                  )
                              }
                              <Route
                                exact
                                path='/document-types'
                                component={DocumentTypes}
                              />
                              <Route
                                path='/document-types/create'
                                component={CreateDocumentType}
                              />
                              <Route
                                path='/document-types/edit/:id'
                                component={DocumentTypesEditView}
                              />
                              <Route
                                path='/document-types/:id'
                                component={DocumentTypeDetail}
                              />{" "}
                              <Route
                                path='/schema-document/edit/:id'
                                component={BillingSchemaEditView}
                              />{" "}
                              <Route
                                path='/schema-document/create'
                                component={CreateBillingSchema}
                              />
                              <Route
                                path='/schema-document/:id'
                                component={BillingSchemaDetailsView}
                              />{" "}
                              <Route
                                path='/schema-document'
                                component={BillingSchemaListView}
                              />
                              <Route
                                path='/meter-points-list'
                                exact
                                name='Meter Points List'
                                component={MeterPointsList}
                              />
                              <Route
                                path='/graphs'
                                render={(props) => (
                                  <Graphs
                                    username={this.global.username}
                                    {...props}
                                  />)}
                              />
                            </Switch>
                          </Container>
                        </main>
                        {/*<Aside/>*/}
                      </div>
                      {!(
                        window.config.consul.DYCE ||
                        (typeof DYCE !== "undefined" ? DYCE : "")
                      ) && <Footer />}
                      <Modal isOpen={this.state.showLogoutWarning}>
                        <ModalHeader>
                          You will be logged out due to inactivity
                        </ModalHeader>
                        <ModalBody>
                          <div>
                            You will be logged out in 60 seconds. Click
                            "Continue" to extend your session.
                          </div>
                          <Button
                            onClick={() => this.state.timeout.clearTimeouts()}
                            color='primary'
                            style={{ float: "right" }}
                          >
                            Continue
                          </Button>
                        </ModalBody>
                      </Modal>
                      {this.isEnabledPopup()}
                    </div>
                  );
                  if (
                    Array.isArray(confResult.data.getUIConfiguration.uiQueries)
                  ) {
                    const [accountPermissions] =
                      confResult.data.getUIConfiguration.uiQueries.filter(
                        (uiQuery) => uiQuery.name === "accountPermissions"
                      );

                    const jwt = require("jsonwebtoken");

                    if (
                      false &&
                      jwt.decode(sessionStorage.mstoken).isUserBlocked
                    ) {
                      return (
                        <div className='app'>
                          <div className='app-body-info'>
                            <Header hideNavigation currentUser={data.getUser} />
                            <Container fluid className='main-content'>
                              <Link
                                to={""}
                                onClick={() => authentication.signOut()}
                                className={"float-right "}
                                activeClassName='active'
                              >
                                Logout
                              </Link>
                              <div className='info-message-fixed'>
                                <div>
                                  <h1>Information</h1>
                                  <p>
                                    You are not allowed to log to the portal
                                    from this IP address. For more information
                                    please contact our Customer Care Team on{" "}
                                    <a href='tel:0412149500'>041 2149500</a>.
                                  </p>
                                </div>
                              </div>
                            </Container>
                          </div>
                        </div>
                      );
                    }

                    if (accountPermissions && accountPermissions.query) {
                      const accountPermissionsQuery = accountPermissions.query;
                      return (
                        <Query
                          query={gql`
                            ${accountPermissionsQuery}
                          `}
                          variables={{
                            user_email: jwt.decode(sessionStorage.mstoken)
                              .emails[0],
                          }}
                        >
                          {(accountResult) => {
                            if (accountResult.loading) return null;
                            if (accountResult.error)
                              return `Error! ${error.message}`;

                            if (
                              accountResult.data.flogasv_contact &&
                              Array.isArray(
                                accountResult.data.flogasv_contact
                              ) &&
                              accountResult.data.flogasv_contact.length === 0
                            ) {
                              return (
                                <div className='app'>
                                  <div className='app-body-info'>
                                    <Header
                                      hideNavigation
                                      currentUser={data.getUser}
                                    />
                                    <Container fluid className='main-content'>
                                      <Link
                                        to={""}
                                        onClick={() => authentication.signOut()}
                                        className={"float-right "}
                                        activeClassName='active'
                                      >
                                        Logout
                                      </Link>
                                      <div
                                        className='card mb-3'
                                        style={{
                                          maxWidth: "100%",
                                          marginTop: "70px",
                                          background: "#f8f9fa",
                                          border: "none",
                                        }}
                                      >
                                        <div className='row g-0'>
                                          <div
                                            className='col-md-5'
                                            style={{ overflow: "hidden" }}
                                          >
                                            <img
                                              src={
                                                "/img/flogas/splash-page.jpeg"
                                              }
                                              alt='Flogas Splash Page Image'
                                              style={{
                                                height: "100%",
                                                position: "absolute",
                                                left: "-90px",
                                              }}
                                            />
                                          </div>
                                          <div className='col-md-6 mt-3'>
                                            <div
                                              className='card-body'
                                              style={{
                                                lineHeight: "1.2",
                                                marginLeft: "5px",
                                              }}
                                            >
                                              <h4
                                                className='card-title mb-4'
                                                style={{ fontWeight: "500" }}
                                              >
                                                Congratulations, you are almost
                                                ready to access your customer
                                                portal!
                                              </h4>
                                              <div
                                                className='border-bottom'
                                                style={{
                                                  borderBottom:
                                                    "2.5px solid #5a2a82",
                                                  width: "65px",
                                                  marginBottom: "15px",
                                                  marginTop: "-10px",
                                                }}
                                              ></div>
                                              <p className='card-text mb-4'>
                                                The next step is:
                                              </p>
                                              <p className='card-text mb-4'>
                                                Please contact us to complete
                                                the sign up process by either
                                                emailing{" "}
                                                <a href='mailto:customersupport@flogas.ie'>
                                                  customersupport@flogas.ie
                                                </a>{" "}
                                                or call us directly{" "}
                                                <a href='tel:0412149500'>
                                                  041-2149500
                                                </a>
                                                , with your portal access
                                                request, customer account number
                                                and email address you wish to
                                                register with.
                                              </p>
                                              <p className='card-text mb-4'>
                                                <em>
                                                  <b>
                                                    Please ensure the same email
                                                    is used for paperless
                                                    billing and our Flogas
                                                    customer portal
                                                  </b>
                                                </em>
                                              </p>
                                              <p className='card-text mb-4'>
                                                If you would like to speak
                                                directly with a member of our
                                                customer care team please call{" "}
                                                <a href='tel:0412149500'>
                                                  041-2149500
                                                </a>{" "}
                                                or email us at{" "}
                                                <a href='mailto:customersupport@flogas.ie'>
                                                  customersupport@flogas.ie
                                                </a>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Container>
                                  </div>
                                </div>
                              );
                            } else {
                              return getUserQueryContent;
                            }
                          }}
                        </Query>
                      );
                    }
                  }

                  return getUserQueryContent;
                }}
              </Query>
            );
          } else {
            document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            confResult.client.resetStore();
            localStorage.clear();
            this.props.history.push("/");
            return (
              <h1>
                Cockpit Core is down. Please contact your system administrator.
              </h1>
            );
          }
        }}
      </Query>
    );
  }
}

export default withApollo(withRouter(Full));
