import React, { Component, Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HashRouter, Switch } from "react-router-dom";
import { Translation } from "react-i18next";
import i18n from "../../views/Pages/Login/i18n";
import { createBrowserHistory } from "history";
import SwitchingForm from "../../views/SwitchingForm/";
// Styles
// Import Font Awesome Icons Set
import "segoe-fonts/segoe-fonts.css";
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Temp fix for reactstrap
import "../../../scss/core/_dropdown-menu-right.scss";
// Containers
import Full from "../../containers/Full/";
// Views
import Login from "../../views/Pages/Login/";
import Logout from "../../views/Pages/Logout/";

import configureStore from "../../store/configureStore";

import PrivateRoute from "../../components/PrivateRoute";
import LoginRoute from "../../components/LoginRoute";

import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

import { setContext } from "apollo-link-context";
import ForgottenPassword from "../../views/Pages/ForgottenPassword";
import ResetPassword from "../../views/Pages/ResetPassword";
import ChangeExpiredPassword from "../../views/ChangeExpiredPassword";
import RegisterUser from "../../views/RegisterUser";
import ExpiringAlert from "../../components/ExpiringAlert/ExpiringAlert";
import Loading from "../../components/Loading";

// Import Main styles for this application
if (typeof CLIENT !== "undefined" ? CLIENT : "") {
	const cl = `${CLIENT}`;

	if (cl === "metro") {
		import("../../../scss/metro-style.scss");
	} else if (cl === "osso") {
		import("../../../scss/osso-style.scss");
	} else if (cl === "dyce" || cl === "dyce-ssp") {
		import("../../../scss/dyce-style.scss");
	} else if (cl === "onebill") {
		import("../../../scss/onebill-style.scss");
	} else if (cl === "overgas") {
		import("../../../scss/overgas-style.scss");
	} else if (cl === "flogas") {
		import("../../../scss/flogas-style.scss");
	} else if (cl === "hartree") {
		import("../../../scss/hartree-style.scss");
	} else if (cl === "energy24") {
		import("../../../scss/energy24-style.scss");
	} else {
		import("../../../scss/style.scss");
	}

	console.log("CLIENT >>>>>>>>> ", cl);
}

// Store
const store = configureStore();

const httpLink = createHttpLink({
	uri: `${
		window.config.consul.GRAPHQL_URL ||
		(typeof GRAPHQL_URL !== "undefined" ? GRAPHQL_URL : "")
	}/graphql`,
});

const getCookie = function getCookie(cname) {
	var name = cname + "=";
	var decodedCookie = document.cookie;
	var ca = decodedCookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
};

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = sessionStorage.mstoken
		? sessionStorage.mstoken
		: localStorage.token;
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : null,
			msToken: sessionStorage.mstoken,
		},
	};
});

const errorLink = onError(
	({ graphQLErrors, networkError, forward, operation, response }) => {
		if (graphQLErrors) {
			console.error(`[GraphQL errors]: ${graphQLErrors}`);
			graphQLErrors.map((error) => {
				if (error.extensions && error.extensions.code === "UNAUTHENTICATED") {
					window.location = "/#/login/";
					const expiringAlert = (
						<ExpiringAlert color="danger" message={error.message} />
					);
					ReactDOM.render(expiringAlert, document.getElementById("alert"));
				} else if (error.message && error.message.includes("401")) {
					window.location = "/#/logout/";
					const expiringAlert = (
						<ExpiringAlert color="danger" message={error.message} />
					);
					ReactDOM.render(expiringAlert, document.getElementById("alert"));
				}
			});
		}
		if (networkError) {
			console.error(`[Network error]: ${networkError}`);

			// Handle 302
			if (networkError.name === "ServerParseError") {
				const expiringAlert = (
					<ExpiringAlert color="danger" message={i18n.t("error.redirect")} />
				);
				ReactDOM.render(expiringAlert, document.getElementById("alert"));
			}
		}
	}
);

const client = new ApolloClient({
	link: authLink.concat(errorLink).concat(httpLink),
	cache: new InMemoryCache({
		addTypename: false,
	}),
	shouldBatch: true,
	defaultOptions: {
		watchQuery: {
			fetchPolicy: "cache-and-network",
			errorPolicy: "ignore",
		},
	},
});

const canAccess = () => !!getCookie("token") || sessionStorage["msal.idtoken"];

class App extends Component {
	constructor(props) {
		super(props);
		if (
			window.config.consul.OSSO ||
			(typeof OSSO !== "undefined" ? OSSO : "")
		) {
			document.querySelectorAll("link[rel*='icon']").forEach((link) => {
				link.type = "image/x-icon";
				link.rel = "shortcut icon";
				link.href = link.href.replace("osso/", "").replace("img/", "img/osso/");
				document.getElementsByTagName("head")[0].appendChild(link);
			});
		} else if (
			window.config.consul.DYCE ||
			(typeof DYCE !== "undefined" ? DYCE : "")
		) {
			document.querySelectorAll("link[rel*='icon']").forEach((link) => {
				link.type = "image/x-icon";
				link.rel = "shortcut icon";
				link.href = link.href.replace("dyce/", "").replace("img/", "img/dyce/");
				document.getElementsByTagName("head")[0].appendChild(link);
			});
		}
	}

	render() {
		//Additional step for mobile device login
		window.addEventListener("load", (event) => {
			if (window.nsWebViewBridge) {
				document.body.classList.add("ns-webview");
				if (localStorage.token) {
					document.cookie = "token=true;path=/";
					window.location.href = "/#/";
				}
			}
		});
		const history = createBrowserHistory();
		window.gtag &&
			history.listen((location) => {
				const path = location.pathname + location.search;
				console.log(location);
				window.gtag("config", window.config.consul.tracking["ga-code"], {
					page_title: location.title,
					page_path: path,
				});
			});
		return (
			<div>
				<Suspense fallback={<Loading />}>
					<Translation>
						{() => (
							<HashRouter>
								<Provider store={store}>
									<ApolloProvider client={client}>
										<Switch>
											<LoginRoute
												canAccess={canAccess}
												exact
												path="/login"
												name="Login Page"
												render={(props) => <Login {...props} />}
											/>
											<LoginRoute
												canAccess={canAccess}
												exact
												path="/login/:url+"
												name="Login Page"
												component={Login}
											/>
											<LoginRoute
												canAccess={canAccess}
												exact
												path="/reset-password/:token"
												name="Reset Password Page"
												component={ResetPassword}
											/>
											<LoginRoute
												canAccess={canAccess}
												exact
												path="/reset-password"
												name="Forgotten Password Page"
												component={ForgottenPassword}
											/>
											<LoginRoute
												canAccess={canAccess}
												exact
												path="/change-expired-password"
												name="Change Password Page"
												component={ChangeExpiredPassword}
											/>
											<LoginRoute
												canAccess={() => false}
												path="/switching-form/:name"
												exact
												name="SwitchingForm"
												render={(props) => <SwitchingForm {...props} />}
											/>
											<LoginRoute
												canAccess={() => false}
												path="/switching-form"
												exact
												name="SwitchingForm"
												component={SwitchingForm}
											/>
											<LoginRoute
												canAccess={() => false}
												path="/registration-electricity"
												exact
												name="Registration Electricity"
												component={SwitchingForm}
											/>
											<LoginRoute
												canAccess={() => false}
												path="/registration-electricity/:name"
												exact
												name="SwitchingForm"
												render={(props) => <SwitchingForm {...props} />}
											/>
											<LoginRoute
												canAccess={canAccess}
												exact
												path="/register/:token"
												name="Register User Page"
												component={RegisterUser}
											/>
											<PrivateRoute
												canAccess={canAccess}
												path="/logout"
												name="Logout"
												component={Logout}
											/>
											<PrivateRoute
												canAccess={canAccess}
												path="/"
												name="Home"
												component={Full}
											/>
										</Switch>
									</ApolloProvider>
								</Provider>
							</HashRouter>
						)}
					</Translation>
				</Suspense>
			</div>
		);
	}
}

export default App;
