import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import moment from "moment";

const ProcessingModal = ({ isOpen, processedReadings, onClose }) => {
  const getReadingSpecificError = (reading) => {
    if (!reading.errorMessage) return "Unknown error occurred";

    const errorArray = Array.isArray(reading.errorMessage)
      ? reading.errorMessage
      : [reading.errorMessage];

    const uniqueErrors = [...new Set(errorArray)].filter(
      (error) => error && error.trim() !== ""
    );

    const readingSpecificError = uniqueErrors.find(
      (error) =>
        error.includes(reading.meter_serial_number) ||
        error.includes(reading.mprn)
    );

    return readingSpecificError || uniqueErrors[0] || "Unknown error occurred";
  };

  return (
    <Modal isOpen={isOpen} backdrop='static' size='lg'>
      <ModalHeader>Bulk Approval Processing</ModalHeader>
      <ModalBody>
        {processedReadings.isProcessing ? (
          <div className='text-center'>
            <div className='spinner-border text-primary' role='status'>
              <span className='sr-only'>Processing...</span>
            </div>
            <p className='mt-2'>Processing bulk approval...</p>
          </div>
        ) : (
          <div>
            <div className='mb-4'>
              <h5 className='text-success'>
                Successfully Processed ({processedReadings.successful.length})
              </h5>
              {processedReadings.successful.length > 0 ? (
                <div className='table-responsive'>
                  <table className='table table-sm'>
                    <thead>
                      <tr>
                        <th>MPRN</th>
                        <th>Site</th>
                        <th>Date</th>
                        <th>Reading</th>
                      </tr>
                    </thead>
                    <tbody>
                      {processedReadings.successful.map((reading) => (
                        <tr key={reading.id}>
                          <td>{reading.mprn}</td>
                          <td>{reading.site}</td>
                          <td>{moment(reading.date).format("DD/MM/YYYY")}</td>
                          <td>{reading.meter_reading}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p>No readings were processed successfully.</p>
              )}
            </div>
            <div>
              <h5 className='text-danger'>
                Failed to Process ({processedReadings.failed.length})
              </h5>
              {processedReadings.failed.length > 0 ? (
                <div className='table-responsive'>
                  <table className='table table-sm'>
                    <thead>
                      <tr>
                        <th>MPRN</th>
                        <th>Site</th>
                        <th>Date</th>
                        <th>Reading</th>
                        <th>Error Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      {processedReadings.failed.map((reading) => (
                        <tr key={reading.id}>
                          <td>{reading.mprn}</td>
                          <td>{reading.site}</td>
                          <td>{moment(reading.date).format("DD/MM/YYYY")}</td>
                          <td>{reading.meter_reading}</td>
                          <td>{getReadingSpecificError(reading)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p>No readings failed processing.</p>
              )}
            </div>
          </div>
        )}
      </ModalBody>
      {!processedReadings.isProcessing && (
        <ModalFooter>
          <Button color='primary' onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default ProcessingModal;
