import React from "react";
import { Card, CardBody, CardText, CardTitle, Col, Row } from "reactstrap";

const ContactUs = () => {
	return (
		<div>
			<div className="bp busines-parnter-cnt">
				<div
					className="subheader row"
					style={{ padding: "25px 50px 25px 50px" }}
				>
					<div className="col-sm-12 col-md-9 contact-us-header">
						<h1>Контакти</h1>
					</div>
				</div>
			</div>

			<Row className="contact-us">
				<Col sm={7} className="contact-us-content">
					<Card className="contact-us-card">
						<CardBody className="contact-us-card-body">
							<CardTitle className="contact-us-card-title" tag="h2">
								ЕНЕРГИЙНА ОБЩНОСТ ГАБРОВО - РДНО
							</CardTitle>

							<CardText className="contact-us-card-text">
								гр.Габрово, пл. ВЪЗРАЖДАНЕ №3
							</CardText>

							<CardText className="contact-us-card-text">
								Телефонен номер: +359 889230369
							</CardText>

							<CardText
								className="contact-us-card-text"
								style={{ marginBottom: 0 }}
							>
								Visit our website |{" "}
								<a
									href="https://gabrovo.bg/bg/page/1625"
									target="_blank"
									rel="noopener noreferrer"
								>
									ЕНЕРГИЙНА ОБЩНОСТ ГАБРОВО - РДНО
								</a>
							</CardText>
						</CardBody>
					</Card>
				</Col>

				<Col sm={5}>
					<img src="./img/gabrovo_company_logo.png" alt="Gabrovo Logo" />
				</Col>
			</Row>
		</div>
	);
};

export default ContactUs;
