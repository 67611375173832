import React, {Component} from 'reactn';
import {Col, FormGroup, Input, Label, Row} from 'reactstrap';
import axios from "../../utils/Client";

class SelectTariffs extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Tariffs: [],
        isLoading: true
    };
  }

    componentDidMount(){
      const contractDurationinMonths = parseInt(this.props.quote.contractDuration);
      console.log(this.props.quote);
        const params = {
            LDZ: this.props.quote.supplyAddress.ldz,
            AQ: parseInt(this.props.quote.annualgas),
            Date: this.props.quote.startDate,
        }

        axios.post(TARIFFS_URL + '/tariffs/', params).then(response => {
              const Tariffs = [];
              const entries = [];

              response.data.forEach(
                      entry => {
                          entries.push(entry);
                      }
              )
              var firstElement;
            // Get the parameters field of the object -
            firstElement = entries[0];
            // Determine which is the index of unit rate/standing charge
            var scIndex;
            var unitRateIndex;

            var productName = firstElement.productPrices[0].product.searchKey;
            var productName2 = firstElement.productPrices[1].product.searchKey;

            if(productName === "standing charge" && productName2 === "gas consumption") {
                scIndex = 0;
                unitRateIndex = 1;
            } else if(productName === "gas consumption" && productName2 == "standing charge") {
                scIndex = 1;
                unitRateIndex = 0;
            }
              response.data.forEach(
                price => {
                    Tariffs.push({
                        title: 'GAS ' + (TARIFFS_MASK ? price.name.match(new RegExp(TARIFFS_MASK))[0] : price.name),
                        value: JSON.stringify(price),
                        active: true,
                        rates: [
                            {
                                name: 'Gas',
                                unitRate: price.productPrices[unitRateIndex].price + ' p/kWh',
                                sc: price.productPrices[scIndex].price + ' p/day'
                            }
                        ]
                    })
                }
              )
              this.setState({Tariffs : Tariffs, isLoading: false});
          })
          .catch(error =>  {
              console.log(error);
              this.setState({isLoading: false});
          })
          ;
  }

  render() {
    return (
      <div className={"tariff-boxes " + this.props.className} >
        {!this.global.permissions.includes("Quotes.AlwaysUseBespokePrice") && (this.props.quote.annualgasIndustry ? this.props.quote.annualgasIndustry : this.props.quote.annualgas) <= parseInt(AQ_GAS_BESPOKE_THRESHOLD) && this.state.Tariffs.map((e) => (
          <div key={e.value} className="tariff-box">
            <Input type="radio" onChange={(ev) => {
                        this.props.setTariff(JSON.parse(ev.target.value))}}
                   name={this.props.name ? this.props.name : 'tariffName'} required
                   disabled={!e.active} id={e.value} value={e.value}
                   checked={this.props.value.name === JSON.parse(e.value).name}/>
            <Label htmlFor={e.value} style={{cursor: 'pointer'}}>
              <Row>
                <Col className="col-12 header">
                  <i className="icon ion-android-radio-button-off"/>
                  <i className="icon ion-android-radio-button-on"/>
                  <h2>{e.title}</h2>
                </Col>
                {e.rates.map((rate) => {
                  return (
                    <Col key={rate.name} className="col-xs-12">
                      <h4>{rate.name}</h4>
                      <table className="rates">
                        <tbody>
                          <tr>
                            <td>Unit Rate</td>
                            <td>{rate.unitRate}</td>
                          </tr>
                          <tr>
                            <td>Standing Charge</td>
                            <td>{rate.sc}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  );
                })}
              </Row>
            </Label>
          </div>
        ))}
          {((this.props.quote.annualgasIndustry ? this.props.quote.annualgasIndustry : this.props.quote.annualgas) > parseInt(AQ_GAS_BESPOKE_THRESHOLD) || this.global.permissions.includes("Quotes.AlwaysUseBespokePrice") || this.global.permissions.includes("Quotes.AllowBespokePrice"))
          && <Row>
              <Col className='col-6'>
                  <FormGroup>
                      <Label for="unitRate">Unit Rate (p/kWh)</Label>
                      <Input type="number" name="unitRate" id="unitRate" step="0.001" min="0.001" onChange={(ev) => {
                          const updatedTariff = {
                              ...this.props.value,
                              name: "Bespoke price",
                              unitRate: parseFloat(ev.target.value),
                              bespokePrice: true
                          };
                          this.props.setTariff(updatedTariff);
                      }
                      }
                             value={this.props.value.unitRate} placeholder="Unit Rate" required/>
                  </FormGroup>
                  <FormGroup>
                      <Label for="sc">Standing Charge (p/day)</Label>
                      <Input type="number" name="sc" id="sc" step="0.001" min="0" onChange={(ev) => {
                          this.props.setTariff({
                              ...this.props.value,
                              name: "Bespoke price",
                              sc: parseFloat(ev.target.value),
                              bespokePrice: true
                          })
                      }}
                             value={this.props.value.sc} placeholder="Standing Charge" required/>
                  </FormGroup>
              </Col>
          </Row>
          }

          {!this.global.permissions.includes("Quotes.AlwaysUseBespokePrice") && this.props.quote.annualgas <= parseInt(AQ_GAS_BESPOKE_THRESHOLD) && !this.state.isLoading && (!this.state.Tariffs || this.state.Tariffs.length === 0)
          && <Row>
              <Col className='col-6'>
                  <div className="alert alert-danger alert-dismissible fade show">
                      <button type="button" className="close" data-dismiss="alert">×</button>
                      <h4 className="alert-heading">Missing Tariff!</h4> We could not find tariff for the customer. The Quote cannot be completed.
                  </div>
              </Col>
          </Row>}


      </div>
    );
  }
}

export default SelectTariffs;