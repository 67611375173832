import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";

i18next
	.use(XHR)
	.use(initReactI18next)
	.init(
		{
			backend: {
				loadPath:
					(window.config.consul.CORE_URL ||
						(typeof CORE_URL !== "undefined" ? CORE_URL : "")) +
					"/translations/{{lng}}",
				parse: (data) =>
					JSON.parse(data).reduce((accumulator, currentValue) => {
						const keyPath = currentValue.key.split(".");
						if (keyPath[1]) {
							if (!accumulator[keyPath[0]]) {
								accumulator[keyPath[0]] = {};
							}
							accumulator[keyPath[0]][keyPath[1]] = currentValue.translation;
						} else {
							accumulator[keyPath[0]] = currentValue.translation;
						}
						return accumulator;
					}, {}),
			},
			interpolation: {
				// React already does escaping
				escapeValue: false,
			},
			react: {
				useSuspense: true,
			},
			lng: window.config.consul.DEFAULT_LOCALE || "en", // 'en' | 'es'
			// char to separate namespace from key - causes issues when there's
			// colon in strings and we don't use namespaces so disabling it
			nsSeparator: false,
		},
		(err, t) => {
			if (err) {
				console.error(err);
				console.warn(i18next.store.data);
			}
		}
	);

export default i18next;
