import React, { Component } from 'react';

import { withRouter } from 'react-router';
import i18n from '../../views/Pages/Login/i18n';
import { Row, Col, Button } from 'reactstrap';
import gql from "graphql-tag";
import BasicQueryComponent from '../BasicQueryComponent';
import { Query } from 'react-apollo';
import Loading from "../Loading";

class QueryLink extends BasicQueryComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return (
        <Query query={gql`${this.props.queries[this.props.queryKey]}`} variables={this.transformVariables()}>
            {(result) => {
                if (result.loading) return <Loading/>;
                if (result.error) return <div>{result.error} </div>;
                const dataWithParents = this.setParents(result.data, []);
                const data = this.transformData(dataWithParents, this.props.expression);
                return (<Row>
                    <Col sm="12" className="querylink header">
                        <Button
                            onClick={() => { console.log(this.transformData(dataWithParents,this.props.url));  return this.props.url ? this.props.history.push(this.transformData(dataWithParents,this.props.url)) : this.props.history.goBack()}}
                            className="btn-goback"><i className={"icon " + (this.props.icon || "ion-arrow-left-b")}></i>{i18n.t(this.props.titleToTranslate)}:&nbsp;&nbsp;{data && data.display}
                        </Button>
                    </Col>
                </Row>);
            }}
        </Query>
        );
    }
}

export default withRouter(QueryLink);

