import React, {Component} from 'react';
import i18n from "../Pages/Login/i18n";
import {Col, Row, Button} from "reactstrap";
import CSVReader from 'react-csv-reader';
import gql from 'graphql-tag';
import {Mutation, withApollo} from "react-apollo";
import Loading from "../../components/Loading";
import {withRouter} from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ReactDOM from "react-dom";
import ExpiringAlert from "../../components/ExpiringAlert";


const SAVE_USERS = gql`
    mutation saveUsers($data: [UserExtInput]) {
        saveUsers(data: $data) {
            username
            userDetails {
                communicationChannel
            }
        }
    }
`;


//---------------------------------------------------------

const papaparseOptions = {
    header: true,
    dynamicTyping: false,
    skipEmptyLines: true,
    transformHeader: header =>
        header
            .toLowerCase()
            .replace(/\W/g, '_')
            .replace('communicationchannel', 'communicationChannel')
}

class UsersUpload extends Component {
    constructor() {
        super();
        this.state = {
            results: [],
            loading: false
        }
    }

    render() {
        return (
            <div className="busines-parnter-cnt">
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>{i18n.t('users.upload')}</h1>
                    </Col>
                    <Col className="col-4">
                        <div className="dropdown show">
                            <Button className="pull-right" color="primary"
                                    onClick={() => this.props.history.push('/view/users-admin')}><i
                                className="ion ion-ios-arrow-back"/> {i18n.t('users.back-to-list')}</Button>
                        </div>
                    </Col>
                </Row>
                <div className="page-cnt">
                    <Mutation mutation={SAVE_USERS}>
                        {(saveUsers, {data}) => (
                            <CSVReader
                                cssClass="csv-reader-input btn btn-primary"
                                label={[<i className="fa fa-cloud-upload"></i>, ' Select CSV with Users']}
                                onFileLoaded={async (data, fileName) => {
                                    this.setState({loading: true, results: []});
                                    document.querySelector('.csv-input').value = null;
                                    console.log(data);

                                    const result = data.map(d => {
                                        const {account, customer, consultant, role, ...transform} = d;

                                        const orgs = [];

                                        account && orgs.push(...(`${account}`.split(' ').map(c => {return {organization: {id: c}}})));
                                        customer && orgs.push(...(`${customer}`.split(' ').map(c => {return {organization: {name: c}}})));
                                        consultant && orgs.push(...(`${consultant}`.split(' ').map(c => {return {organization: {id: c}}})));

                                        return {
                                            ...transform,
                                            password: "",
                                            roles: {role: {key: role}},
                                            tenant: {id: "total", name: "Total"},
                                            organizations: orgs
                                        }
                                    })
                                    for (let r of result) {
                                        try {
                                            await saveUsers({variables: {data: [r]}});
                                        } catch (e) {
                                            r.pos = result.indexOf(r) + 2;
                                            this.setState({results: [...this.state.results, r]});
                                        }
                                        this.setState({loading: false});

                                    }
                                    ReactDOM.render(<ExpiringAlert
                                        color="success"
                                        message={"File successfully uploaded"}/>, document.getElementById('alert'));

                                }}
                                onError={(error) => ReactDOM.render(<ExpiringAlert
                                    color="danger"
                                    message={error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")))}
                                parserOptions={papaparseOptions}
                                inputId="userUpload"
                                inputStyle={{color: 'red'}}
                            />
                        )}</Mutation>
                    {this.state.loading && <Loading/>}
                    {this.state.results.length > 0 && <div style={{marginTop: "20px"}}><h3>The following csv lines produced errors:</h3>
                        <BootstrapTable wrapperClasses="table-responsive" data={this.state.results}
                                        keyField="pos"
                                        columns={[{
                                            text: 'Line',
                                            dataField: 'pos'
                                        }, {
                                            text: 'Username',
                                            dataField: 'username'
                                        }]}/>
                    </div>}
                </div>

            </div>
        )
    }
}

export default withRouter(withApollo(UsersUpload));
