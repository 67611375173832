import React from 'react';
import {withApollo} from "react-apollo";
import {Col, Row} from "reactstrap";
import PaginatedQueryDataGridHasura from "../../../../components/PaginatedQueryDataGrid/PaginatedQueryDataGridHasura";
import {businesspartner} from "../../../../queries/Queries";

const Customers = (props) => {
    return (
        <div>
            <Row className='subheader'>
                <Col className='col-8'><h1>Customers</h1></Col>
            </Row>
            <PaginatedQueryDataGridHasura
                query={businesspartner}
                dataPath={'businessPartner'}
                paginationPath={'data'}
                variables={{"pageNumber": 0, "pageSize": 5, "sort": {"orders": {"property": "name", "direction": "asc"}},"where": "businessPartnerCategory.name!='Vendor'", "orderBy":"name"}}
                keyField={'id'}
                align={'left'}
                recordsPerPage={10}
                filters={false}
                setStyles={{cursor: 'pointer'}}
                sizePerPageList={[
                    {
                        text: "10",value: 10
                    },
                    {
                        text: "20", value: 20
                    },
                    {
                        text: "30", value: 30
                    }
                ]}
                columns={[
                    {
                        dataField: 'name',
                        text: 'Name',
                        sort: true,
                    },
                    {
                        dataField: 'mdmContragentno',
                        text: 'Contragent number',
                        sort: true,
                    },
                ]}
            />
        </div>
    )
}

export default withApollo(Customers);
