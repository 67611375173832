/*Created by Geargi Staykov 15.11.2022*/

import React, {useState, useEffect} from 'react';
import {Query, withApollo} from "react-apollo";
import {Button, Col, FormGroup, Input, Row} from "reactstrap";
import {SingleDatePicker} from "react-dates";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {versionGQL, meterPoints, settlementRunGQL} from "../../../../queries/Queries";
import Loading from "../../../../components/Loading";
import ComparisonGrid from "../ComparisonGrid/ComparisonGrid";

const sr = ['II', 'SF', 'R1', 'R2', 'R3', 'RF', 'DF'];

const SupplyComparison = (props) => {
    const [date, setDate] = useState({date: null});
    const [gridData, setGridData] = useState([
        {name: 'Site', value: null},
        {name: 'MPAN', value: null, allSearchKeys: []},
        {name: 'Date', date: null},
        {name: 'Settlement Run', value1: null, value2: null},
        {name: 'Version', value1: null, value2: null},
        {name: 'Counterparty', useForSearch: false, value: null, counterparty_id: null},
    ]);
    const [comparisonItems, setComparisonItems] = useState({});
    const [filterMPAN, setFilterMPAN] = useState();
    const [filterCounterParty, setFilterCounterParty] = useState();
    const [filterVersion, setFilterVerion] = useState();
    const [load, setLoad] = useState(false);
    const [focused, setFocused] = useState(false);
    const [shortQuery, setShortQuery] = useState(true);

    const getComparisonItems = async (counterpartyID, mpan, date) => {
        setLoad(true);

        // const sr = await props.client.query({
        //     query: settlementRunGQL,
        //     variables: {
        //         ...(mpan && {meter_point_identifier: mpan}),
        //         ...(counterpartyID && {counterparty_id: counterpartyID}),
        //         valuedate: date.format('YYYY-MM-DD')
        //     }
        // })
        const ver = await props.client.query({
            query: versionGQL,
            variables: {
                ...(mpan && {meter_point_identifier: mpan}),
                ...(counterpartyID && {counterparty_id: counterpartyID}),
                valuedate: date.format('YYYY-MM-DD')
            }
        });

        setComparisonItems({
            // 'settlementRunGQL': sr.data.meter_point_settlement_run,
            'versionGQL': ver.data.meter_point_versions_ex,
        });

        setLoad(false);
    }

    const removeDuplicates = (duplicates) => {
        return duplicates.filter((v,i,a)=> a.findIndex(v2 => (v2.version === v.version)) === i);
    }

    const clearForm = () => {
        setDate({date: null});
        setComparisonItems({});
        setGridData(() =>
            [
                {name: 'Site', value: null},
                {name: 'MPAN', value: null, allSearchKeys: []},
                {name: 'Date', date: null},
                {name: 'Settlement Run', value1: null, value2: null},
                {name: 'Version', value1: null, value2: null},
                {name: 'Counterparty', useForSearch: false, value: null, counterparty_id: null},
            ]
        )
    };

    useEffect(() => {
        (!gridData[1].value && (gridData[5].counterparty_id && date.date)) && getComparisonItems(gridData[5].counterparty_id, null, date.date);
        (!gridData[5].counterparty_id && (gridData[1].value && date.date)) && getComparisonItems(null, gridData[1].value, date.date);
        (gridData[5].counterparty_id && gridData[1].value && date.date) && getComparisonItems(null, gridData[1].value, date.date);
    }, [gridData[5].counterparty_id, gridData[1].value, date.date]);


    return (
        <Query query={meterPoints}>
            {({data, loading, error}) => {
                if (loading) return <Loading/>

                return <div className='supply-comparison-header'>
                    <Row className='subheader'>
                        <Col className='col-8'><h1>Supply Comparison</h1></Col>
                    </Row>
                    <div className='supply-comparison-content'>
                        <div className='supply-comparison-body'>
                            <FormGroup>
                                <label>Counterparty*</label>
                                <AsyncTypeahead
                                    isLoading={loading}
                                    multiple={true}
                                    required
                                    clearButton
                                    selected={!gridData[5].value && []}
                                    placeholder={gridData[1].value !== '' ? gridData[5].value : ''}
                                    onSearch={e => {
                                        const filter = data.meter_points_ex
                                            .filter(el => !gridData[1].value
                                                ? el.counterparty_name.toLowerCase().includes(e)
                                                : el.ui_search_key.includes(gridData[1].value) && el.counterparty_name)
                                            .filter((el, i, arr) => arr.findIndex(s => el.counterparty_name === s.counterparty_name) === i);
                                        setFilterCounterParty(filter);
                                    }}
                                    options={filterCounterParty}
                                    filterBy={() => true}
                                    labelKey={'counterparty_name'}
                                    emptyLabel={'No matches found.'}
                                    onChange={selected => {
                                        setGridData([...gridData].map(obj => {
                                            if (obj.name === 'Counterparty') {
                                                return {
                                                    ...obj,
                                                    useForSearch: !!selected.length > 0,
                                                    value: selected.length === 0 ? null : selected[0].counterparty_name,
                                                    counterparty_id: selected.length === 0 ? null : selected[0].counterparty_id
                                                };
                                            }

                                            // create allSearchKeys property for MPAN and Site dropdown Input
                                            if (obj.name === 'MPAN') {
                                                return {
                                                    ...obj,
                                                    value: selected.length === 0 ? null : null,
                                                    allSearchKeys: selected.length === 0 ? '' : data.meter_points_ex.filter(el => (el.counterparty_name.includes(selected[0].counterparty_name)) && el.ui_search_key)
                                                };
                                            }
                                            if (obj.name === 'Site') {
                                                return {
                                                    ...obj,
                                                    value: selected.length === 0 ? null : null,
                                                }
                                            }

                                            return obj;
                                        }));

                                        if (selected.length === 0) clearForm();
                                    }}
                                />
                            </FormGroup>


                            {/*Show MPAN and Site name like search if Counterparty is not picked or like dropdown if is !!!!! */}

                            {!gridData[5].value || gridData[1].allSearchKeys.length === 0 ?
                                (
                                    <FormGroup>
                                        <label>MPAN and Site name*</label>
                                        <AsyncTypeahead
                                            isLoading={loading}
                                            multiple={true}
                                            required
                                            clearButton
                                            selected={(!gridData[0].value && !gridData[1].value && !gridData[5].value) && []}
                                            onSearch={(e) => {
                                                const filter = data.meter_points_ex.filter(el => !gridData[5].value
                                                    ? el.ui_search_key.toLowerCase().includes(e)
                                                    : el.counterparty_name.includes(gridData[5].value) && el.meter_point_identifier
                                                );

                                                setFilterMPAN(filter);
                                            }}
                                            options={filterMPAN}
                                            filterBy={() => true}
                                            labelKey='ui_search_key'
                                            emptyLabel={'No matches found.'}
                                            onChange={selected => {
                                                setGridData([...gridData].map(obj => {
                                                    if (obj.name === 'Site') return {
                                                        ...obj,
                                                        value: selected.length === 0 ? null : selected[0].site_name
                                                    };
                                                    if (obj.name === 'MPAN') return {
                                                        ...obj,
                                                        value: selected.length === 0 ? null : selected[0].meter_point_identifier
                                                    };
                                                    if (obj.name === 'Counterparty') return {
                                                        ...obj,
                                                        value: selected.length === 0 ? null : selected[0].counterparty_name
                                                    };

                                                    return obj;
                                                }));

                                                if (selected.length === 0) clearForm();
                                            }}
                                        />
                                    </FormGroup>
                                ) : (
                                    <FormGroup>
                                        <label>MPAN and Site name*</label>
                                        <Input type="select" name="select" id="site-mpan"
                                               onChange={e => {
                                                   setGridData([...gridData].map(obj => {
                                                       const mpanSite = e.target.value;
                                                       const index = mpanSite.lastIndexOf('-');
                                                       const site = mpanSite.slice(0, index);
                                                       const mpan = mpanSite.slice(index + 1);
                                                       if (obj.name === 'Site') return {
                                                           ...obj,
                                                           value: site ? site : null
                                                       };
                                                       if (obj.name === 'MPAN') return {
                                                           ...obj,
                                                           value: mpan ? mpan : null
                                                       };
                                                       return obj;
                                                   }));
                                               }}
                                        >
                                            <option value=''></option>
                                            {gridData[1].allSearchKeys.map((el, i) => <option key={i}
                                                                                              value={el.ui_search_key}>{el.ui_search_key}</option>)}
                                        </Input>
                                    </FormGroup>
                                )
                            }

                            <FormGroup>
                                <label>Date*</label>
                                <SingleDatePicker
                                    openDirection={"down"}
                                    date={date.date}
                                    numberOfMonths={1}
                                    onDateChange={date => {
                                        setDate({name: 'Date', date: date});
                                        setGridData([...gridData].map(obj => {
                                                if (obj.name === 'Date') return {...obj, name: 'Date', date: date};
                                                return obj;
                                            })
                                        );
                                        if (!date) clearForm();
                                    }}
                                    onFocusChange={({focused}) => setFocused(focused)}
                                    required
                                    showClearDate={true}
                                    displayFormat={"YYYY-MM-DD"}
                                    focused={focused}
                                    isOutsideRange={() => false}
                                    id="create-reading-date"
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Select Settlement Run*</label>
                                <Input type="select" name="select" id="date"
                                       value={gridData[3].value1 ? gridData[3].value1 : ''}
                                       disabled={!comparisonItems.versionGQL}
                                       onChange={e =>
                                           setGridData([...gridData].map(obj => {
                                               if (obj.name === 'Settlement Run') return {
                                                   ...obj,
                                                   value1: e.target.value
                                               };
                                               return obj;
                                           }))
                                       }
                                >
                                    <option value=''></option>
                                    {sr.map(el => <option key={el} value={el}>{el}</option>)}
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <label>Select Version*</label>
                                <AsyncTypeahead
                                    isLoading={load}
                                    disabled={!comparisonItems.versionGQL}
                                    selected={!gridData[4].value1 && []}
                                    required
                                    clearButton
                                    caseSensitive={false}
                                    multiple={true}
                                    onSearch={(e) => {
                                        const filter = comparisonItems.versionGQL.filter((el, i) => {
                                            return el.version.includes(e);
                                        });
                                        setFilterVerion(removeDuplicates(filter));
                                    }}
                                    options={filterVersion}
                                    placeholder={'YYYY-MM-DD'}
                                    filterBy={() => true}
                                    labelKey='version'
                                    emptyLabel={'No matches found.'}
                                    onChange={selected => {
                                        setGridData([...gridData].map(obj => {
                                            if (obj.name === 'Version') return {
                                                ...obj,
                                                value1: selected.length === 0 ? null : selected[0].version
                                            };
                                            if (obj.name === 'Counterparty') return {
                                                ...obj,
                                                counterparty_id: selected.length === 0 ? null : selected[0].counterparty_id
                                            };
                                            return obj;
                                        }));
                                    }}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Compare to Settlement Run</label>
                                <Input type="select" name="select" id="compare-sr"
                                       disabled={!comparisonItems.versionGQL}
                                       value={gridData[3].value2 ? gridData[3].value2 : ''}
                                       onChange={e => {
                                           e.persist();
                                           setGridData([...gridData].map(obj => {
                                               if (obj.name === 'Settlement Run') return {
                                                   ...obj,
                                                   value2: e.target.value
                                               };
                                               return obj;
                                           }));
                                           e.target.value ? setShortQuery(false) : setShortQuery(true);
                                       }}
                                >
                                    <option value=''></option>
                                    {sr.map(el => <option key={el} value={el}>{el}</option>)}
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <label>Compare to Version</label>
                                <AsyncTypeahead
                                    isLoading={load}
                                    disabled={!comparisonItems.versionGQL}
                                    clearButton
                                    multiple={true}
                                    caseSensitive={false}
                                    selected={!gridData[4].value2 && []}
                                    onSearch={(e) => {
                                        const filter = comparisonItems.versionGQL.filter((el, i) => {
                                            return el.version.includes(e);
                                        });
                                        setFilterVerion(removeDuplicates(filter));
                                    }}
                                    placeholder={'YYYY-MM-DD'}
                                    options={filterVersion}
                                    filterBy={() => true}
                                    labelKey='version'
                                    emptyLabel={'No matches found.'}
                                    onChange={selected => {
                                        setGridData([...gridData].map(obj => {
                                            if (obj.name === 'Version') return {
                                                ...obj,
                                                value2: selected.length === 0 ? null : selected[0].version
                                            };
                                            return obj;
                                        }));
                                        selected.length !== 0 ? setShortQuery(false) : setShortQuery(true);
                                    }}
                                />
                            </FormGroup>

                            <Button color='primary' onClick={() => {clearForm()}}>Clear</Button>
                        </div>

                        {((gridData[2].date && gridData[5].counterparty_id && (gridData[1].value || gridData[5].value) && (gridData[3].value1 && gridData[4].value1)) ||
                          (gridData[2].date && gridData[5].counterparty_id && (gridData[1].value || gridData[5].value) && (gridData[3].value1 && gridData[4].value1) && (gridData[3].value2 && gridData[4].value2))) &&
                            <ComparisonGrid state={gridData} shortQuery={shortQuery}/>
                        }
                    </div>
                </div>
            }}
        </Query>
    )
}

export default withApollo(SupplyComparison);
