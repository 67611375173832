import React from 'react';
import axios from "axios";

function ErrLogger(data, eventName, error) {
    axios.post(`${CORE_URL}/log/error`, {
        message: {
            data: data,
            userAgent: navigator.userAgent
        },
        stackTrace: error,
        eventName: eventName
    }).then((response) => {
        console.log(response);
    }).catch(error => {
        console.log(error);
    });
}

export default ErrLogger;