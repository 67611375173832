import React, { useState, useMemo } from "react";
import { Row, Col, FormGroup, Button, CardBody } from "reactstrap";
import { Query } from "react-apollo";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Loading from "../Loading/Loading";
import EditDeleteButton from "../EditDeleteButton";
import InfoCard from "../InfoCard";
import gql from "graphql-tag";
import {
  dateFormatter,
  documentCategoryFormatter,
  executionContextFormatter,
} from "../../utils/Formatters";
import { getDocumentTypeDetailsQuery } from "../../queries/Queries";
import DocumentLineModal from "../CreateDocumentLineModal/DocumentLineModal";
import EditDocumentLineModal from "../EditDocumentLineModal/EditDocumentLineModal";

const deleteDocumentLineMutation = gql`
  mutation deleteDocumentLineMutation($id: UUID) {
    deleteDocumentRow(id: $id)
  }
`;

const DocumentTypeDetail = ({ match, history }) => {
  const { id } = match.params;
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editRowData, setEditRowData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortField, setSortField] = useState("index");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    setCurrentPage(page);
    setPageSize(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
  };

  const columnsDocumentLines = useMemo(
    () => [
      { dataField: "index", text: "Priority", sort: true },
      {
        dataField: "lineFactoryName",
        text: "Line Factory",
        sort: true,
        formatter: documentCategoryFormatter,
      },
      { dataField: "productName", text: "Product", sort: true },
      { dataField: "name", text: "Name", sort: true },
      {
        dataField: "created",
        text: "Created",
        sort: true,
        formatter: dateFormatter,
      },
      { dataField: "createdBy", text: "Created By", sort: true },
      {
        dataField: "updated",
        text: "Updated",
        sort: true,
        formatter: dateFormatter,
      },
      { dataField: "updatedBy", text: "Updated By", sort: true },
      {
        dataField: "actions",
        text: "",
        formatter: (cell, row) => (
          <EditDeleteButton
            id={row.id}
            history={history}
            editUrl={`/document-types/${row.id}/edit`}
            deleteMutation={deleteDocumentLineMutation}
            deleteRefetchQueries={[
              {
                query: getDocumentTypeDetailsQuery,
                variables: {
                  id,
                  pageNumber: currentPage - 1,
                  pageSize,
                  sort: {
                    orders: [{ property: sortField, direction: sortOrder }],
                  },
                },
              },
            ]}
            onEdit={() => {
              setEditRowData(row.id);
              setShowEditModal(true);
            }}
          />
        ),
      },
    ],
    [history, id, currentPage, pageSize, sortField, sortOrder]
  );

  return (
    <Query query={getDocumentTypeDetailsQuery} variables={{ id }}>
      {({ loading, error, data, refetch }) => {
        if (loading) return <Loading />;
        if (error) return <p>Error: {error.message}</p>;

        const documentType = data && data.findDocumentType;

        if (!documentType) return <p>No document type found for ID: {id}</p>;

        const documentLines = (documentType.rows || []).map((row) => ({
          ...row,
          productName: row.product ? row.product.name : " ",
          name: row.name || " ",
        }));

        return (
          <div className='invoice-details'>
            <Row className='subheader'>
              <Col className='col-8'>
                <h1>Billing Details</h1>
              </Col>
            </Row>
            <div className='px-5 py-4'>
              <Row>
                <Col sm='12' className='header'>
                  <Button
                    onClick={() => history.push("/document-types")}
                    className='btn btn-default'
                  >
                    <i className='icon ion-arrow-left-b'></i>
                    Billing Document Type: {documentType.name}
                  </Button>
                </Col>
              </Row>
              <CardBody className='px-0'>
                <InfoCard
                  title='Billing Document Type'
                  info={{
                    name: { key: "Name", value: documentType.name },
                    documentCategory: {
                      key: "Document Category",
                      value: documentCategoryFormatter(
                        documentType.documentCategory
                      ),
                    },
                    executionContext: {
                      key: "Execution Context",
                      value: executionContextFormatter(
                        documentType.executionContext
                      ),
                    },
                  }}
                  columns={3}
                />
              </CardBody>

              <Row>
                <Col>
                  <Row className='my-4'>
                    <Col sm='6'>
                      <h4>Document Lines</h4>
                    </Col>
                    <Col sm='6'>
                      <FormGroup>
                        <Link
                          to=''
                          onClick={(e) => {
                            e.preventDefault();
                            setShowModal(true);
                          }}
                          className='btn btn-primary pull-right'
                        >
                          <i className='icon ion-plus-round'></i>&nbsp;&nbsp;New
                        </Link>
                      </FormGroup>
                    </Col>
                  </Row>
                  <BootstrapTable
                    classes='table-hover'
                    wrapperClasses='table-responsive'
                    keyField='id'
                    bordered={false}
                    data={documentLines}
                    columns={columnsDocumentLines}
                    pagination={paginationFactory({
                      page: currentPage,
                      sizePerPage: pageSize,
                      sizePerPageList: [
                        { text: "5", value: 5 },
                        { text: "10", value: 10 },
                        { text: "20", value: 20 },
                      ],
                    })}
                    onTableChange={handleTableChange}
                    rowStyle={{ cursor: "pointer" }}
                  />
                </Col>
              </Row>
            </div>
            {showModal && (
              <DocumentLineModal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                documentType={documentType}
                refetchDocumentDetails={refetch}
              />
            )}
            {showEditModal && editRowData && (
              <EditDocumentLineModal
                isOpen={showEditModal}
                onClose={() => setShowEditModal(false)}
                documentType={documentType}
                documentLineId={editRowData}
                refetchDocumentDetails={refetch}
              />
            )}
          </div>
        );
      }}
    </Query>
  );
};

export default withRouter(DocumentTypeDetail);
