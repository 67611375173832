import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';
import i18n from '../../views/Pages/Login/i18n';
import {Link} from "react-router-dom";
import QueryDropdownBtn from '../QueryDropdownBtn';

class TableHeader extends Component {

    constructor() {
        super();
    }

    render() {

        return (
            <Row className='subheader'>
                <Col className='col-8'>
                    <h1>{i18n.t(this.props.title)}</h1>
                </Col>
                <Col className='col-4'>
                    <QueryDropdownBtn dropdownitems={this.props.items}/>
                </Col>
            </Row>
        )
    }
}

export default TableHeader;
