import React, {Component} from 'reactn';
import i18n from "../../views/Pages/Login/i18n";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import QueryDataGrid from "../QueryDataGrid/QueryDataGrid";
import 'react-dates/initialize';
import {SingleDatePicker} from 'react-dates';
import moment from "moment";
import 'react-dates/lib/css/_datepicker.css';
import axios from '../../utils/Client';
import {dateFormatter} from "../../utils/Formatters";
import {withApollo} from "react-apollo";
import gql from "graphql-tag";
import ReactDOM from "react-dom";
import ExpiringAlert from '../../components/ExpiringAlert';
import fileDownload from "js-file-download";
import PaginatedQueryDataGrid from "../PaginatedQueryDataGrid";

class NoTariffs extends Component {
    constructor(props) {
        super(props)
        if (document.getElementById('filters-Tariffs')) {
            document.getElementById('filters-Tariffs').remove();
        }
    }

    render() {

        return (
            <div className="nosalesagent">
                <i className="icon ion-upload"></i>
                <p className="text">There are no tariffs uploaded yet.</p>
                <Button className='btn btn-primary' color="primary" onClick={this.props.previewUploadTariff}>Upload
                    Tariffs</Button>
            </div>
        );
    }
}

class UploadTariffs extends Component {

    errorHandler(error) {
        console.log('there was an error sending the query', error.response.data.message);
        let errorMessage = error.response.data.message;

        ReactDOM.render(<ExpiringAlert color="danger" message={errorMessage}></ExpiringAlert>, document.getElementById('alert').appendChild(document.createElement("div")));
        this.setState({isLoading: false});
    }

    constructor(props) {
        super(props)
        this.state = {
            tariff: {}
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({isLoading: true});
        const newId = this.fileUpload(this.state.tariff.name, this.state.tariff.file, "DYCE", "", this.state.startDate, this.state.endDate);
        newId.then((r) => {
            this.props.previewUploadTariff(false);
            //reload the grid
            window.location.reload();
        }).catch(this.errorHandler.bind(this));

        // newId.then((r) => {
        //     console.log(r)
        //     this.props.client.query({
        //         query: gql`query Tariff($pageNumber: Int, $pageSize: Int, $sort: SortInput, $where: String) {
        //             Tariff(spec: {where:$where}, page:{pageNumber: $pageNumber, pageSize: $pageSize, sort:$sort}) {
        //                 content {
        //                     id
        //                     brokerId
        //                     created
        //                     createdBy
        //                     name
        //                     updated
        //                     updatedBy
        //                     urn
        //                     startDate
        //                     endDate
        //                     status
        //                 }
        //                 totalElements
        //                 totalPages
        //             }
        //         }`,
        //         fetchPolicy: 'network-only',
        //         variables:
        //             {
        //                 brokerId: "DYCE",
        //                 page: {pageNumber: 0, pageSize: 100}
        //             }
        //     }).then((data) => {
        //         this.setState({isLoading: false});
        //
        //     }).catch(this.errorHandler.bind(this));
        // }).catch(this.errorHandler.bind(this));
    }

    handleChange(name, value, name1, value1) {
        if (name1) {
            this.setState({tariff: {...this.state.tariff, [name]: value, [name1]: value1}});
        } else {
            this.setState({tariff: {...this.state.tariff, [name]: value}});
        }
    }

    fileUpload(name, file, brokerId, status, startDate, endDate) {
        const url = window.config.consul.TARIFFS_URL + '/tariff/upload';
        const formData = new FormData();
        formData.append('name', name);
        formData.append('file', file);
        formData.append('brokerId', brokerId);
        formData.append('status', status);
        formData.append('startDate', startDate);
        formData.append('endDate', endDate);
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.token}`,
                'content-type': 'multipart/form-data'
            }
        }
        return axios.post(url, formData, config)
    }

    render() {
        const loadingIcon = this.state.isLoading ? (
            <span><i style={this.state.buttonStyle} className='fa fa-circle-o-notch fa-spin'></i> </span>) : null;
        return (
            <Form className="step upload-Tariffs" onSubmit={this.handleSubmit}>
                <Row>
                    <Col className='col-12 step-header'>
                        <h2>Upload Tariff File</h2>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <FormGroup>
                            <Row>
                                <Col xs="4">
                                    <Label className='user-label'>
                                        Name
                                    </Label>
                                </Col>
                                <Col xs="8">
                                    <Input type="text" value={this.state.tariff.name}
                                           placeholder="Enter Tariff Name"
                                           onChange={(e) => this.handleChange("name", e.target.value)}/>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup tag="fieldset">
                            <Row>
                                <Col xs="4">
                                    <Label htmlFor="startDate">Start Date</Label>
                                </Col>
                                <Col xs="8">
                                    <SingleDatePicker
                                        date={this.state.startDate ? moment(this.state.startDate) : null} // momentPropTypes.momentObj or null
                                        numberOfMonths={1}
                                        onDateChange={date => {
                                            this.setState({
                                                startDate: date ? date.format("YYYY-MM-DD") : null,
                                            })
                                        }}
                                        isOutsideRange={() => false}
                                        displayFormat="DD/MM/YY"
                                        showDefaultInputIcon={true}
                                        inputIconPosition="after"
                                        focused={this.state.focusedstart} // PropTypes.bool
                                        onFocusChange={({focused}) => this.setState({focusedstart: focused})} // PropTypes.func.isRequired
                                        id="startDate" // PropTypes.string.isRequired,
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col xs="4">
                                    <Label htmlFor="endDate">End Date</Label>
                                </Col>
                                <Col xs="8">
                                    <SingleDatePicker
                                        date={this.state.endDate ? moment(this.state.endDate) : null} // momentPropTypes.momentObj or null
                                        numberOfMonths={1}
                                        onDateChange={date => {
                                            this.setState({
                                                endDate: date ? date.format("YYYY-MM-DD") : null,
                                            })
                                        }}
                                        displayFormat="DD/MM/YY"
                                        showDefaultInputIcon={true}
                                        inputIconPosition="after"
                                        isOutsideRange={() => false}
                                        focused={this.state.focusedend} // PropTypes.bool
                                        onFocusChange={({focused}) => this.setState({focusedend: focused})} // PropTypes.func.isRequired
                                        id="endDate" // PropTypes.string.isRequired,
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col xs="4">
                                    <Label className='user-label'>
                                        Attach File
                                    </Label>
                                </Col>
                                <Col xs={"8"}>
                                    <Input
                                        type='file' label='Upload' style={{display: "inline"}}
                                        onChange={(e) => {
                                            this.handleChange("fileUrl", URL.createObjectURL(e.target.files[0]), "file", e.target.files[0]);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="btn-cnt">
                    <Col xs="12" sm="12" className="spacer"></Col>
                    <Col xs="4" sm="4" md="2" lg="2" xl="2"></Col>
                    <Col xs="8" sm="8">
                        <Button color="primary" type='submit' disabled={this.state.isLoading}
                                onClick={(event) => this.handleSubmit(event)}>{loadingIcon}Upload</Button>&nbsp;
                        <Input className="btn btn-secondary" type="button" value={i18n.t('header.cancel')}
                               onClick={(event) => {
                                   this.props.previewUploadTariff(false)
                               }}/>
                    </Col>
                </Row>
            </Form>
        );
    }
}

const UploadTariffsWithClient = withApollo(UploadTariffs);

class Tariffs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showUploadTariff: false
        }
    }

    render() {
        return (
            <div>
                {this.state.showUploadTariff ? <UploadTariffsWithClient previewUploadTariff={(e) => {
                        this.setState({showUploadTariff: e})
                    }}/> :
                    <div className="tariffs">

                        <PaginatedQueryDataGrid paginationPath={'Tariff'} dataPath={'Tariff.content'} keyField={'id'}

                                                query={`query Tariff($pageNumber: Int, $pageSize: Int, $sort: SortInput, $where: String) {
                                                Tariff(spec: {where:$where}, page:{pageNumber: $pageNumber, pageSize: $pageSize, sort:$sort}) {
                                                    content {
                                                      id
                                                      brokerId
                                                      created
                                                      createdBy
                                                      name
                                                      updated
                                                      updatedBy
                                                      urn
                                                      startDate
                                                      endDate
                                                      status
                                                    }
                                                    first
                                                    last
                                                    number
                                                    numberOfElements
                                                    size
                                                    totalElements
                                                    totalPages
                                                    pageable {
                                                      pageNumber
                                                      pageSize
                                                      paged
                                                      offset
                                                    }
                                                  }
                                                }
                                                
                                                `}

                                       filters={[ {
                                           type: 'date',
                                           label: 'Start',
                                           fields: ['startDate'],
                                           cols: 4
                                       },
                                       {
                                            type: 'date',
                                            label: 'End',
                                            fields: ['endDate'],
                                            cols: 4
                                       }
                                       ]}
                                                variables={{"page": {"pageNumber": 0, "pageSize": 100}}}
                                                defaultSorted={{ dataField: 'status', order: 'asc' }}
                                       columns={[
                                                    {
                                                        dataField: 'id',
                                                        text: i18n.t('Tariffs.id'),
                                                        sort: false,
                                                        hidden: true
                                                    },
                                                    {
                                                        dataField: 'name',
                                                        text: i18n.t('Tariffs.Name'),
                                                        sort: true
                                                    },
                                                    {
                                                        dataField: 'startDate',
                                                        text: i18n.t('Tariffs.StartDate'),
                                                        sort: true,
                                                        formatter: dateFormatter
                                                    },
                                                    {
                                                        dataField: 'endDate',
                                                        text: i18n.t('Tariffs.EndDate'),
                                                        sort: true,
                                                        formatter: dateFormatter
                                                    },
                                                    {
                                                        dataField: 'status',
                                                        text: i18n.t('Tariffs.Status'),
                                                        sort: true
                                                    },
                                                    {
                                                        dataField: 'urn',
                                                        text: i18n.t('Tariffs.File'),
                                                        formatter: (cell, row) =>
                                                        (<i className={"icon ion-android-download"} style={{cursor: "pointer"}}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();

                                                                    if (typeof REST_URL === 'undefined') {
                                                                        var REST_URL = "";
                                                                    }
                                                                    axios.get(`${REST_URL}/cdn/cdn/resource/${row.urn}`, {
                                                                        headers: {'Authorization': "Bearer " + localStorage.token},
                                                                        responseType: 'blob'
                                                                    }).then((response) => {
                                                                        fileDownload(response.data, row.name + '.xlsx');
                                                                    })
                                                                }}/>)
                                                    }, {
                                                        dataField: 'test',
                                                        text: "",
                                                        formatter: disableTariffFormatter
                                                    }
                                                ]}
                                                selectorButtons={this.global.permissions.includes('Tariffs.UploadTariff') ?
                                                    <Button onClick={() => {
                                                        this.setState({showUploadTariff: true});
                                                    }} className='btn btn-primary pull-right' color="primary"> <i
                                                        className="icon ion-plus-round"></i>&nbsp;&nbsp; Upload
                                                        Tariff</Button> : null}
                                       noDataView={<NoTariffs previewUploadTariff={() => {
                                                    this.setState({showUploadTariff: true})
                                                }}/>}
                        />
                    </div>
                }
            </div>
        )
    }
}

function disableTariffFormatter(cell, row) {
    if (row.status === "Active") {
        return <Button className={"btn btn-primary pull-right"} color="primary"
                       onClick={(e) => {
                           e.preventDefault();
                           e.stopPropagation();

                           axios.put(window.config.consul.TARIFFS_URL+`/tariff/${row.id}/disable`, {}, {
                               headers: {'Authorization': "Bearer " + localStorage.token},
                               responseType: 'blob'
                           }).then((response) => {
                               // Refresh the page
                               window.location.reload();
                           })
                       }}> Disable </Button>
    }
    return null;
}

export default withApollo(Tariffs);
