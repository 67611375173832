import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';
import axios from "../../utils/Client";

import {
    Button,
    Breadcrumb,
    BreadcrumbItem,
    Card,
    CardBody,
    CardGroup,
    Col,
    Container,
    Row,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import ExpiringAlert from "../../components/ExpiringAlert";


class Translations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            locale: '',
            translations: []
        };
    }

    render() {
        return (
            <div>
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>Translations</h1>
                    </Col>
                    <Col className='col-4'>
                        <Breadcrumb>
                        </Breadcrumb>
                    </Col>
                </Row>
                <div className="page-cnt">
                    <div className='table-filers-cnt'>

                        <Row className='filter-bar'>
                            <Col className='col-5'>
                                <InputGroup className="search">
                                    <Input type="select" value={this.state.locale}
                                           onChange={(e) => {
                                               e.target.value === '' && this.setState({translations: [], locale: ''});
                                               this.setState({locale: e.target.value});

                                               e.target.value && axios.get(`${(window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : ''))}/translations/${e.target.value}`).then((response) => {
                                                   this.setState({translations: response.data})
                                               });
                                           }}>
                                        <option value={""}>Select language</option>
                                        <option value={"en"}>English</option>
                                        <option value={"bg"}>български</option>
                                    </Input>
                                </InputGroup>
                            </Col>
                            <Col className='col-5'>
                            </Col>
                            <Col className='col-2'>
                                <Button color={"primary"} className={"pull-right"} onClick={() => {
                                    const newTranslations = [...this.state.translations];
                                    newTranslations.splice((this.state.page - 1) * 10, 0, {
                                        locale: this.state.locale,
                                        key: 'New.Key',
                                        translation: 'New Translation'
                                    });
                                    this.setState({page: 1, translations: newTranslations})
                                }} value="Add translation">Add translation</Button>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col sm="12">
                            <BootstrapTable columns={[{
                                dataField: "id",
                                text: "Id"
                            }, {
                                dataField: "key",
                                text: "Key",
                                filter: textFilter()
                            }, {
                                dataField: "translation",
                                text: "Translation",
                                filter: textFilter()
                            }]}
                                            keyField={"id"}
                                            data={this.state.translations}
                                            cellEdit={cellEditFactory({
                                                mode: 'click',
                                                blurToSave: true,
                                                beforeSaveCell: () => this.setState({page: this.state.page}),
                                                afterSaveCell: () => this.setState({page: this.state.page})
                                            })}
                                            filter={filterFactory()}
                                            pagination={paginationFactory({
                                                // page: this.state.page,
                                                onPageChange: (page, sizePerPage) => {
                                                    this.state.page = page;
                                                },
                                                sizePerPageList: [{
                                                    text: "10", value: 10
                                                }]
                                            })}
                                            noDataIndication={() => <div>No records in table</div>}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color={"primary"} className="buttonSpace buttonSpaceTop pull-right" onClick={() => {
                                if (this.state.translations.filter(translation => translation.key && translation.key !== "").length !== this.state.translations.length) {
                                    ReactDOM.render(<ExpiringAlert color="danger"
                                                                   message="Please fill all keys"/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                    return;
                                }
                                if (Array.from(new Map(this.state.translations.map((i) => [i.key, i]))).length !== this.state.translations.length) {
                                    ReactDOM.render(<ExpiringAlert color="danger"
                                                                   message="Duplicate key detected"/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                    return;
                                }
                                if (this.state.locale === '') {
                                    ReactDOM.render(<ExpiringAlert color="danger"
                                                                   message="You must select a language before submitting"/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                    return;
                                }

                                axios.post(`${(window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : ''))}/translations`, this.state.translations).then((response) => {
                                    this.setState({translations: response.data})
                                });
                            }
                            } value="Submit">Submit</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default Translations;
