import * as Msal from 'msal';
import React from 'react';

const logger = new Msal.Logger(loggerCallback, {
  level: Msal.LogLevel.Warning
});
const state = {
  noScopes: false,
  launchApp: null,
  idToken: null,
  accessToken: null,
  userName: ""
}
var appConfig = {
  instance: null,
  tenant: null,
  signInPolicy: null,
  resetPolicy: null,
  applicationId: null,
  cacheLocation: null,
  scopes: [],
  redirectUri: null,
  postLogoutRedirectUri: null,
  validateAuthority: null,
  silentLoginOnly: false,
  extraQueryParameters: '&login_hint&domain_hint=organizations'
};

function loggerCallback(logLevel, message, piiLoggingEnabled) {
  console.log(message);
}

function authCallback(errorDesc, token, error, tokenType) {
  if (errorDesc && errorDesc.indexOf('AADB2C90118') > -1) {
    redirect();
  } else if (errorDesc) {
    console.log(error + ':' + errorDesc);
  } else { }
}

function redirect() {
  const localMsalApp = window.msal;
  const instance = appConfig.instance ? appConfig.instance : 'https://login.microsoftonline.com/tfp/';
  const authority = `${instance}${appConfig.tenant}/${appConfig.resetPolicy}`;
  localMsalApp.authority = authority;
  loginAndAcquireToken();
}

function loginAndAcquireToken(successCallback) {
  const localMsalApp = window.msal;
  console.log('window.msal',window.msal);
  let user = localMsalApp.getUser(appConfig.scopes);

  if (!user) {
    if (state.noScopes) {
      if (appConfig.silentLoginOnly) {
        if (state.errorApp)
          state.errorApp();
      } else
        localMsalApp.loginRedirect(appConfig.scopes, appConfig.extraQueryParameters);
    } else {
      localMsalApp.acquireTokenSilent(appConfig.scopes, null, null, "&login_hint&domain_hint=organizations" + appConfig.extraQueryParameters).then(accessToken => {
        state.accessToken = accessToken;
        user = localMsalApp.getUser(appConfig.scopes);
        state.idToken = user.idToken;
        state.userName = user.name;
        if (state.launchApp) {
          state.launchApp();
        }
        if (successCallback) {
          successCallback();
        }
      }, error => {
        if (error) {
          if (appConfig.silentLoginOnly)
            state.errorApp();
          else
            localMsalApp.loginRedirect(appConfig.scopes, appConfig.extraQueryParameters);
        }
      });
    }

  } else {
    state.idToken = user.idToken;
    state.userName = user.name;
    if (state.noScopes) {
      if (state.launchApp) {
        state.launchApp();
      }
      if (successCallback) {
        successCallback();
      }
    } else {
      localMsalApp.acquireTokenSilent(appConfig.scopes, null, null, appConfig.extraQueryParameters).then(accessToken => {
        state.accessToken = accessToken;
        if (state.launchApp) {
          state.launchApp();
        }
        if (successCallback) {
          successCallback();
        }
      }, error => {
        if (error) {
          localMsalApp.acquireTokenRedirect(appConfig.scopes);
        }
      });
    }
  }
}

const authentication = {
  initialize: (config) => {
    console.log('initializing');
    appConfig = config;
    const instance = config.instance ? config.instance : 'https://login.microsoftonline.com/tfp/';
    const authority = `${instance}${config.tenant}/${config.signInPolicy}`;
    const validateAuthority = (config.validateAuthority != null) ? config.validateAuthority : true;
    let scopes = config.scopes;
    if (!scopes || scopes.length === 0) {
      console.log('To obtain access tokens you must specify one or more scopes. See https://docs.microsoft.com/en-us/azure/active-directory-b2c/active-directory-b2c-access-tokens');
      state.noScopes = true;
    }
    state.scopes = scopes;

    new Msal.UserAgentApplication(
      config.applicationId,
      authority,
      authCallback, {
      logger: logger,
      cacheLocation: config.cacheLocation,
      postLogoutRedirectUri: config.postLogoutRedirectUri,
      redirectUri: config.redirectUri,
      validateAuthority: validateAuthority
    }
    );
  },
  run: (launchApp, errorApp) => {
    state.launchApp = launchApp
    if (errorApp)
      state.errorApp = errorApp;
    if (!window.msal.isCallback(window.location.hash) && window.parent === window && !window.opener) {
      loginAndAcquireToken();
    }
  },
  required: (WrappedComponent, renderLoading) => {
    return class extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          signedIn: false,
          error: null,
        };
      }

      componentWillMount() {
        loginAndAcquireToken(() => {
          this.setState({
            ...this.state,
            signedIn: true
          });
        });
      };

      render() {
        if (this.state.signedIn) {
          return (< WrappedComponent {
            ...this.props
          }
          />);
        };
        return typeof renderLoading === 'function' ? renderLoading() : null;
      };
    };
  },
  signOut: () => {
    window.msal.logout()
  },
  getIdToken: () => {
    return state.idToken;
  },
  getAccessToken: () => {
    return state.accessToken;
  },
  getUserName: () => {
    return state.userName;
  }
}

export default authentication;