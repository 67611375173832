import React, {Component} from 'reactn';
import ReactDOM from 'react-dom';
import {Button, Col, FormGroup, Row, UncontrolledAlert} from "reactstrap";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import BpmnModeler from "../../containers/BpmnBoard";
import axios from "../../utils/Client";
import Select from "react-select";
import consul from "consul";
import {Query} from "react-apollo";
import {getBpmServiceInstancesQuery} from "../../queries/Queries";
import Loading from "../../components/Loading";
import ExpiringAlert from "../../components/ExpiringAlert";
import i18n from "../Pages/Login/i18n";


class BPMNEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data:"",
            instances: [],
            deployments:[],
            deploymentResources:[]
        };
    }

    render() {
        return (
            <div className={"bpmn-container"}>

                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>BPMN Editor</h1>
                    </Col>
                    <Col className='col-4'>
                        <FormGroup>
                            <Button onClick={() => this.setState({showCreateTask:true})} className='pull-right' color="primary"> <i className="icon ion-plus-round"></i>&nbsp;&nbsp;Add Process </Button>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Query query={getBpmServiceInstancesQuery}>
                            {result => {
                                if (result.loading) return <Loading/>;
                                if (result.error) return result.error;
                                return <Select options = {
                                        result.data.getBpmServiceInstances
                                            .filter((instance, index, array) => array.indexOf(instance) === index)
                                            .map(instance => ({
                                            label: instance,
                                            value: instance
                                        }))
                                    }
                                    onChange = {(instanceOption) => {
                                        this.setState({instanceOption, deploymentResources:[]});
                                        const config = {headers: {"Authorization": "Bearer " + localStorage.token}};
                                        axios.get(`${(window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/bpm/${instanceOption.value}/rest/engine/default/deployment`, config)
                                            .then(({data}) => Promise.all(data.map(deployment => axios.get(`${(window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/bpm/${this.state.instanceOption.value}/rest/engine/default/deployment/${deployment.id}/resources`, config).then(({data}) => Promise.resolve(data.map(resource => ({deployment, resource})))))))
                                                .then(result => this.setState({deploymentResources: result.flatMap(res => res)}));


                                        }
                                    }
                                    value = {this.state.instanceOption}
                                    placeholder = {"Select BPM Instance"}
                                    clearable = {false} />
                            }
                            }
                        </Query>
                        {/*<i className="icons ion-android-arrow-forward"/>*/}
                    </Col>
                    {/*<Col>*/}
                    {/*    <Select options={this.state.deployments.sort((deployment1, deployment2) => new Date(deployment2.deploymentTime).getTime() - new Date(deployment1.deploymentTime).getTime())*/}
                    {/*        .map(deployment => ({label: deployment.name+' ('+new Date(deployment.deploymentTime).toLocaleString()+')', value: deployment}))}*/}
                    {/*            onChange={(deploymentOption) => {*/}
                    {/*                this.setState({deploymentOption});*/}
                    {/*                axios.get(`${this.state.instanceOption.value.uri}/rest/engine/default/deployment/${deploymentOption.value.id}/resources`, {headers:{"Authorization":"Bearer "+localStorage.token}})*/}
                    {/*                    .then(({data}) => this.setState({deploymentResources: data, deploymentResourceOption: null, data: null}));*/}
                    {/*            }}*/}
                    {/*            value={this.state.deploymentOption}*/}
                    {/*            placeholder={"Select Deployment"}*/}
                    {/*            disabled={!this.state.instanceOption}*/}
                    {/*            clearable={false} />*/}
                    {/*    /!*<i className="icons ion-android-arrow-forward"/>*!/*/}
                    {/*</Col>*/}
                    <Col>
                        <Select options={this.state.deploymentResources.filter(deploymentResource => deploymentResource.resource.name.endsWith('.bpmn'))
                            .sort((deployment1, deployment2) => new Date(deployment2.deployment.deploymentTime).getTime() - new Date(deployment1.deployment.deploymentTime).getTime())
                            .map(deploymentResource => ({label: deploymentResource.resource.name+' ('+new Date(deploymentResource.deployment.deploymentTime).toLocaleString()+')', value: deploymentResource}))}
                                onChange={(deploymentResourceOption) => {
                                    this.setState({deploymentResourceOption});
                                    axios.get(`${(window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/bpm/${this.state.instanceOption.value}/rest/engine/default/deployment/${deploymentResourceOption.value.deployment.id}/resources/${deploymentResourceOption.value.resource.id}/data`, {headers:{"Authorization":"Bearer "+localStorage.token}})
                                        .then(({data}) => this.setState({data}));
                                }}
                                disabled={!this.state.instanceOption}
                                value={this.state.deploymentResourceOption}
                                placeholder={"Select Deployment Resource"}
                                clearable={false}
                        style={{zIndex:15}}/>
                    </Col>
                </Row>
                <div className="bpmn-editor">
                    <BpmnModeler
                        xml={this.state.data}
                        onSave={(err, xml) => {
                            let name = prompt('Enter resource name', this.state.deploymentResourceOption ? this.state.deploymentResourceOption.value.resource.name : '');
                            if (name) {
                                if (!name.endsWith('.bpmn')) {
                                    name = name + '.bpmn';
                                }
                                const data = new FormData();
                                data.append('file', new File([new Blob([xml])], name));
                                data.append('deployment-name', 'Cockpit');
                                data.append('enable-duplicate-filtering', 'true');
                                axios.post(`${(window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/bpm/${this.state.instanceOption.value}/rest/engine/default/deployment/create`, data, {headers: {"Authorization": "Bearer " + localStorage.token}})
                                    .then(({data}) => ReactDOM.render(<ExpiringAlert color="success" message={i18n.t('bpmnEditor.successfulSave')}  />, document.getElementById('alert').appendChild(document.createElement("div"))))
                                    .catch((error => {
                                        ReactDOM.render(<UncontrolledAlert color={'danger'}>{error.response.data.message}</UncontrolledAlert>, document.getElementById('alert').appendChild(document.createElement("div")))
                                    }));
                            }
                        }
                        }
                    />

                </div>
            </div>
        )
    }
}

export default BPMNEditor;
