import React from "react";
import axios from "../../utils/Client";
import moment from "moment";
import Loading from "../../components/Loading";
import i18n from '../../views/Pages/Login/i18n';

class DataView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...props.formData, isLoading: true};
    }

    onChange(name) {
        return (event) => {
            this.setState({
                [name]: event.target.value
            }, () => this.props.onChange(this.state));
        };
    }

    async componentDidMount() {
        // Load async data.
        console.log(this.props)
        const {formContext} = this.props;
        const details = formContext["Service " + formContext.service];
        console.log(JSON.stringify(details));
        try {
            const aq = !details.assessConsumption || details.assessConsumption === "Yes" ? {AQ: 6 * details.consumption.consumptionSummer + 6 * details.consumption.consumptionWinter} : {}
            const data = {
                "tariffConditions": {
                    "DSO": details.distributor,
                    "Type of customer": formContext.customerType === "Legal entity" ? "sp" : "b",
                    "Date": moment().format('YYYY-MM-DD')
                },
                "ruleParameters": {
                    "typeOfCustomer": formContext.customerType === "Legal entity" ? "sp" : "b",
                    "voltageLevel": formContext["Service Electricity"] ? (formContext["Service Electricity"].voltageLevel === "Low" ? "0" : formContext["Service Electricity"].voltageLevel === "Medium" ? "1" : "36") : ""
                },
                "peopleCount": details.rooms ? parseInt(details.rooms) : 0,
                "Products": [{"searchKey": "Service " + this.props.formContext.service, ...aq}]
            };
            const response = await axios.post(`${(window.config.consul.GRAPHQL_URL || (typeof GRAPHQL_URL !== 'undefined' ? GRAPHQL_URL : '' ))}/tarifs/com.methodia.ts.bill.budget-plan-v2`, data);
            this.setState({
                ...this.state, ...{
                    isLoading: false,
                    response
                }
            }, () => this.props.onChange(this.state.response.data));
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }


    }

    render() {
        const {formContext} = this.props;
        const {response, isLoading} = this.state;
        if (!!isLoading) return <Loading/>
        console.log(this.state)
        return (
            <div>
                <table className="table">
                    <tbody>
                    <tr>
                        <td>{i18n.t('switching-form.offer-monthly-cost')}</td>
                        <td>{(response.data.Products[0].annualCostWithVat / 12).toFixed(2)} lei</td>
                    </tr>
                    <tr>
                        <td>{i18n.t('switching-form.offer-name')}</td>
                        <td>{response.data.Products[0].tariffName}</td>
                    </tr>
                    <tr>
                        <td>{i18n.t('switching-form.offer-contract-period')}</td>
                        <td>12 luni</td>
                    </tr>
                    </tbody>
                </table>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">{i18n.t('switching-form.product')}</th>
                        <th scope="col">{i18n.t('switching-form.unit-price')}</th>
                        <th scope="col">{i18n.t('switching-form.monthly-value')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {response.data.Products[0].basicProducts.map(p =>
                        (
                            <tr>
                                <td>{p.name}</td>
                                <td>{!p.searchKey.startsWith('Excise') && (p.unitPrice + " lei")} </td>
                                <td>{(p.annualCost/12).toFixed(2)}  lei</td>
                            </tr>)
                    )}
                        <tr>
                            <td>{i18n.t('switching-form.offer-without-vat')}</td>
                            <td></td>
                            <td>{(response.data.Products[0].annualCost/12).toFixed(2)} lei</td>
                        </tr>
                        <tr>
                            <td>{i18n.t('switching-form.offer-vat')}</td>
                            <td></td>
                            <td>{(response.data.Products[0]['VAT amount']/12).toFixed(2)} lei</td>
                        </tr>
                        <tr>
                            <td>{i18n.t('switching-form.offer-with-vat')}</td>
                            <td></td>
                            <td>{(response.data.Products[0].annualCostWithVat/12).toFixed(2)} lei</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}


export default DataView;