import React from "react";
import { Input } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import {insertPortalEntityStatus } from '../../queries/Queries';
import Loading from '../Loading';

class GridDropDown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        if (this.state.loading) {
            return <Loading />
        }

        return (
            <Input
                type="select" style={{ width: 'fit-content', paddingRight: '25px' }}
                value={this.state.value || this.props.cell || this.props.formatExtraData.defaultValue}
                onChange={event => {

                    this.setState({ loading: true, value: event.target.value }, () => {

                        const variables = {
                            ...this.props.formatExtraData.variables,
                            record_id: ""+this.props.row.id,
                            comment: "",
                            created: new Date(),
                            createdby: JSON.parse(atob(sessionStorage.getItem('mstoken').split('.')[1])).emails[0],
                            status: this.state.value
                        }

                        this.props.client.mutate({
                            mutation: insertPortalEntityStatus,
                            variables: variables
                        })

                        .then(({ data }) => {
                            console.log(data);
                            this.setState({loading: false});
                        })

                        .catch(error => {
                            console.error(error);
                            this.setState({loading: false});
                        });

                    });
                }}
            >
                {
                    this.props.formatExtraData &&
                    this.props.formatExtraData.options &&
                    Array.isArray(this.props.formatExtraData.options) &&
                    this.props.formatExtraData.options.length > 0 &&
                    this.props.formatExtraData.options.map((option, key) => (
                        <option value={option.value} key={key}>
                            {option.label}
                        </option>
                    ))
                }
            </Input>
        );
    }
}

export default withApollo(withRouter(GridDropDown));