import React, { useGlobal, useState } from 'reactn';
import { withApollo, Query } from 'react-apollo';
import {
  Col,
  Row,
  Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  Button,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Loading from '../../../../components/Loading/Loading';
import ErrorPage from '../../../../components/ErrorPage';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import {
  ndmDashboard,
  insertReportActionMutation,
} from '../../../../queries/Queries';
import classnames from 'classnames';
import { CSVLink } from 'react-csv';
import { DateRangePicker } from 'react-dates';

const ReportActions = {
  Approve: 'approve',
  Cancel: 'cancel',
};

const defaultFilters = {
  sncwv: '',
  ndmValue: '',
  site: '',
  entityName: '',
  mprn: '',
  sap: '',
  rollingAQ: '',
  alp: '',
  daf: '',
  cwv: '',
};

const NDMDashboard = ({ client }) => {
  const [username, setUsername] = useGlobal('username');
  const [isApprovedTabOpen, setIsApprovedTabOpen] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [focus, setFocus] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  const handleReportAction = (reportId, action) => {
    client.mutate({
      variables: { report_id: reportId, action: action, createdBy: username },
      mutation: insertReportActionMutation,
      refetchQueries: [{ query: ndmDashboard }],
    });
  };

  const inputFields = [
    { label: 'Site', key: 'site' },
    { label: 'Entity', key: 'entityName' },
    { label: 'MPRN', key: 'mprn' },
    { label: 'SAP', key: 'sap' },
    { label: 'AQ', key: 'rollingAQ' },
    { label: 'ALP', key: 'alp' },
    { label: 'DAF', key: 'daf' },
    { label: 'CWV', key: 'cwv' },
    { label: 'SNCWV', key: 'sncwv' },
    { label: 'NDM', key: 'ndmValue' },
  ];

  const getHeaders = () => {
    const headers = [{ label: 'Date', key: 'date' }, ...inputFields];
    return headers;
  };

  const handleFileName = () => {
    if (dateRange.endDate) {
      const startDate = new Date(dateRange.startDate).toLocaleDateString();
      const endDate = new Date(dateRange.endDate).toLocaleDateString();
      return `${startDate} ${endDate}.csv`;
    }
    return 'NDMAllocations_Export.csv';
  };

  const getCSVData = (inReviewData) =>
    inReviewData
      .filter((item) => {
        const reportDate = new Date(item.reportdate);
        const { startDate, endDate } = dateRange;
        const adjustedStartDate = startDate ? new Date(startDate) : null;
        if (adjustedStartDate) {
          adjustedStartDate.setHours(0, 0, 0, 0);
        }
        const adjustedEndDate = endDate ? new Date(endDate) : new Date();
        if (adjustedEndDate) {
          adjustedEndDate.setHours(23, 59, 59, 999);
        }
        return (
          adjustedStartDate &&
          adjustedEndDate &&
          reportDate >= adjustedStartDate &&
          reportDate <= adjustedEndDate
        );
      })
      .sort((a, b) => new Date(a.reportdate) - new Date(b.reportdate))
      .flatMap((item) =>
        item.reportcontent.map((contentItem) => ({
          date: item.reportdate,
          site: contentItem.site_name,
          entityName: contentItem.entity,
          mprn: contentItem.mprn,
          sap: contentItem.SAP,
          rollingAQ: contentItem.annual_quantity,
          alp: contentItem.annual_load_profile,
          daf: contentItem.daily_adjustments_factor,
          cwv: contentItem.composite_weather_value,
          sncwv: contentItem.seasonal_normal_value,
          ndmValue: contentItem.calculated_value,
        }))
      );

  const expandRow = {
    renderer: (row) => {
      const filteredRates = row.reportcontent.filter((item) => {
        const filterKeys = {
          site: 'site_name',
          entityName: 'entity',
          mprn: 'mprn',
          sap: 'SAP',
          rollingAQ: 'annual_quantity',
          alp: 'annual_load_profile',
          daf: 'daily_adjustments_factor',
          cwv: 'composite_weather_value',
          sncwv: 'seasonal_normal_value',
          ndmValue: 'calculated_value',
        };

        return Object.keys(filterKeys).every((key) => {
          const filterValue = filters[key];
          const itemValue = item[filterKeys[key]];
          return (
            !filterValue ||
            (itemValue &&
              itemValue
                .toString()
                .toLowerCase()
                .includes(filterValue.toLowerCase()))
          );
        });
      });

      return (
        <Row>
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {inputFields.map((field, index) => (
                    <th key={index}>{field.label}</th>
                  ))}
                </tr>
                <tr>
                  {inputFields.map((field, index) => (
                    <th key={index}>
                      <Input
                        style={{
                          minWidth: '135px',
                        }}
                        type='text'
                        placeholder={`Filter by ${field.label}`}
                        value={filters[field.key]}
                        onChange={(e) =>
                          handleFilterChange(field.key, e.target.value)
                        }
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredRates.map((item, index) => (
                  <tr key={index}>
                    <td>{item.site_name}</td>
                    <td>{item.entity}</td>
                    <td>{item.mprn || 0}</td>
                    <td>{item.SAP || 0}</td>
                    <td>{item.annual_quantity || 0}</td>
                    <td>{item.annual_load_profile || 0}</td>
                    <td>{item.daily_adjustments_factor || 0}</td>
                    <td>{item.composite_weather_value || 0}</td>
                    <td>{item.seasonal_normal_value || 0}</td>
                    <td>{item.calculated_value || 0}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      );
    },
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    onExpand: () => {
      if (Object.values(filters).some((filter) => filter)) {
        setTimeout(() => {
          setFilters(defaultFilters);
        }, 100);
      }
    },
  };

  return (
    <div className='organization'>
      <Row className='subheader'>
        <Col className='col-8'>
          <h1>NDM Allocations</h1>
        </Col>
      </Row>
      <Query query={ndmDashboard}>
        {({ loading, error, data, refetch }) => {
          if (loading) return <Loading />;
          if (error) return <ErrorPage />;

          const reviewColumns = [
            {
              dataField: 'reportdate',
              text: 'Date',
              formatter: (cell) => moment(cell).format('DD/MM/YYYY'),
              sort: true,
              sortValue: (cell) => moment(cell).valueOf(),
            },
            {
              dataField: 'recalculation',
              text: 'recalculation',
              formatter: (cell, row) => {
                return row.reportstatus === 'In Review' ? (
                  <Row className='justify-content-around'>
                    <i
                      style={{
                        cursor: 'pointer',
                      }}
                      className={'icon ion-android-refresh'}
                      onClick={() =>
                        handleReportAction(row.id, ReportActions.Cancel)
                      }
                    ></i>
                  </Row>
                ) : null;
              },
              headerStyle: { textAlign: 'center' },
              style: { textAlign: 'center' },
            },
            {
              dataField: 'approve',
              text: 'approve',
              formatter: (cell, row) => {
                return row.reportstatus === 'In Review' ? (
                  <Row className='justify-content-around'>
                    <i
                      style={{
                        cursor: 'pointer',
                      }}
                      className={'icon ion-android-send'}
                      onClick={() =>
                        handleReportAction(row.id, ReportActions.Approve)
                      }
                    ></i>
                  </Row>
                ) : null;
              },
              headerStyle: { textAlign: 'center' },
              style: { textAlign: 'center' },
            },
            {
              dataField: 'reportstatus',
              text: 'Status',
              sort: true,
            },
          ];

          const approvedColumns = [
            ...reviewColumns.filter(
              (column) =>
                column.dataField !== 'recalculation' &&
                column.dataField !== 'approve'
            ),
            {
              dataField: 'approved_by',
              text: 'Approved By',
              sort: true,
              headerStyle: { textAlign: 'center' },
              style: { textAlign: 'center' },
            },
          ];

          const approvedData = [];
          const inReviewData = [];
          if (data.ndm_dashboard) {
            data.ndm_dashboard.forEach((item) => {
              if (item.reportstatus === 'Sent') {
                approvedData.push(item);
              } else if (item.reportstatus === 'Sending') {
                approvedData.push({ ...item, reportstatus: 'Approved' });
              } else if (item.reportstatus === 'Review') {
                inReviewData.push({
                  ...item,
                  reportstatus: 'In Review',
                });
              } else if (item.reportstatus === 'Recalculating') {
                inReviewData.push(item);
              }
            });
          }
          return (
            <div className='page-cnt'>
              <Row>
                <Col className='col-12'>
                  <div className='bp-nav-items'>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: !isApprovedTabOpen })}
                          onClick={() => {
                            setIsApprovedTabOpen(false);
                            refetch();
                          }}
                        >
                          In Review
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: isApprovedTabOpen })}
                          onClick={() => {
                            setIsApprovedTabOpen(true);
                            refetch();
                          }}
                        >
                          Approved
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Col>
                <Col className='col-12 mt-4 mb-2'>
                  <Row className='d-flex justify-content-between'>
                    <Col xs='6' className='d-flex align-items-center'>
                      <h3>
                        {isApprovedTabOpen
                          ? 'Approved NDM Allocations'
                          : 'NDM Allocations In Review'}
                      </h3>
                    </Col>
                    {!isApprovedTabOpen ? (
                      <Col
                        xs='6'
                        className='d-flex justify-content-end align-items-center'
                      >
                        <DateRangePicker
                          small
                          startDate={dateRange.startDate}
                          endDate={dateRange.endDate}
                          onDatesChange={(date) => setDateRange(date)}
                          displayFormat={'MM/DD/YYYY'}
                          focusedInput={focus}
                          onFocusChange={(focus) => setFocus(focus)}
                          showClearDates={true}
                          showDefaultInputIcon={true}
                          isOutsideRange={() => false}
                          noBorder={false}
                        />
                        <CSVLink
                          style={{ marginLeft: '1rem' }}
                          className='export-csv'
                          filename={handleFileName()}
                          data={getCSVData(inReviewData)}
                          headers={getHeaders()}
                        >
                          <Button color='primary'>Download CSV</Button>
                        </CSVLink>
                      </Col>
                    ) : null}
                  </Row>
                </Col>
                <Col className='col-12'>
                  <BootstrapTable
                    classes='table-hover'
                    wrapperClasses='table-responsive'
                    noDataIndication={() => <div>No records in table.</div>}
                    columns={
                      isApprovedTabOpen ? approvedColumns : reviewColumns
                    }
                    data={isApprovedTabOpen ? approvedData : inReviewData}
                    expandRow={expandRow}
                    keyField={'id'}
                    loading={<Loading />}
                    bordered={false}
                    hover
                    pagination={paginationFactory({
                      page: 1,
                      sizePerPage: 10,
                      totalSize: isApprovedTabOpen
                        ? approvedData.length
                        : inReviewData.length,
                      sizePerPageList: [
                        { text: '5', value: 5 },
                        { text: '10', value: 10 },
                        { text: '20', value: 20 },
                      ],
                    })}
                  />
                </Col>
              </Row>
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default withApollo(NDMDashboard);
