import React from 'react';
import axios from "axios";

function EventLogger(data, eventName) {
    axios.post(`${window.config.consul.CORE_URL}/log`, {
        data: data,
        userAgent: navigator.userAgent,
        eventName: eventName
    }).then((response) => {
        console.log(response);
    }).catch(error => {
        console.log(error);
    });
}

export default EventLogger;