import React, {Component} from 'reactn';
import FormWizzard from '../../components/FormWizzard/FormWizzard';
import Mpan from '../../components/Mpan/Mpan';
import FindAddress from '../../components/FindAddress/FindAddress';
import SelectBoxes from '../../components/SelectBoxes/SelectBoxes';
import SelectTariffs from '../../components/SelectTariffs/SelectTariffs';
import {Button, Col, Form, FormFeedback, FormGroup, Input, Label, Popover, PopoverBody, Row} from 'reactstrap';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import {graphql, Mutation, Query, withApollo} from "react-apollo";
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {
    createUpdateQuote,
    getLdz,
    getQuote,
    getQuoteById,
    getQuotesQuery,
    getUsers,
    salesOrganizationsQuery
} from "../../queries/Queries";
import Loading from "../../components/Loading";
import ReactDOM from "react-dom";
import ExpiringAlert from "../../components/ExpiringAlert";
import QuoteOverview from "../../components/QuoteOverview";
import i18n from "../Pages/Login/i18n";
import {SingleDatePicker} from "react-dates";
import moment from 'moment';

const QuoteTypes = [{
  title: 'Single Meter Point',
  icon: 'icon ion-location',
  value: 'single',
  active: true
},
{
  title: 'Multiple Meter Point',
  icon: 'icon-atom ion-Multiple-Locations',
  value: 'multiple',
  active: false
}].filter(({value}) => (window.config.consul.QUOTE_TYPES || (typeof QUOTE_TYPES !== 'undefined' ? QUOTE_TYPES : '' )) && (window.config.consul.QUOTE_TYPES || (typeof QUOTE_TYPES !== 'undefined' ? QUOTE_TYPES : '' )).includes(value));
const QuoteServices = [{
  title: 'Gas Quote',
  icon: 'icon-atom ion-Gas',
  value: 'gas',
  active: true
},
{
  title: 'Electricity Quote',
  icon: 'icon-atom ion-Electricity',
  value: 'electricity',
  active: true
},
{
  title: 'Dual Quote',
  icon: 'icon-atom ion-Electricity-Gas',
  value: 'electricitygas',
  active: false
}].filter(({value}) => (window.config.consul.QUOTE_SERVICES || (typeof QUOTE_SERVICES !== 'undefined' ? QUOTE_SERVICES : '' )) && (window.config.consul.QUOTE_SERVICES || (typeof QUOTE_SERVICES !== 'undefined' ? QUOTE_SERVICES : '' )).includes(value));
const saleTypes = [{
    name: "Acquisition",
    value: "Acquisition"
}];
class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {query:""};
    this.isValidated = this.isValidated.bind(this);
  }
  isValidated() {
      if(!document.createquote.dataAgreement.validity.valid){
          ReactDOM.render(<ExpiringAlert color="danger" message={"Please check \"The client agreed to provide their data\""} />, document.getElementById('alert').appendChild(document.createElement("div")));
      }
    return (document.createquote.clientName.validity.valid
        && document.createquote.companyNumber.validity.valid
        && document.createquote.dataAgreement.validity.valid
        && (!this.global.permissions.includes("Quotes.CreateQuoteOnBehalfOfAgent") || document.createquote.ownerOrganizationId.validity.valid
        && document.createquote.ownerUserId.validity.valid))
  }
  render() {
    return (
      <Row className='step'>
        <Col className='col-12 step-header'>
          <h2>Quote Details</h2>
        </Col>
        <Col className='col-xl-5 col-lg-6 col-md-8 col-sm-12 step-body'>
          <FormGroup>
            <Label for="clientName">{i18n.t('quote.companyName')}</Label>
            <Input type="text"
                   name="clientName"
                   id="clientName"
                   autoFocus
                   value={this.props.quote.state.quote.clientName}
                   maxLength={40}
                   onChange={(e) => this.props.onChange(e)}
                   placeholder="Enter Company Name"
                   disabled={this.props.priceQuote}
                   required minLength="3" />
            <p className="info">Which client is this quote for?</p>
          </FormGroup>
          <FormGroup>
            <Label for="companyNumber">{i18n.t('quote.companyNumber')}</Label>
            <Input type="text"
                   name="companyNumber"
                   id="companyNumber"
                   value={this.props.quote.state.quote.companyNumber}
                   onChange={(e) => this.props.onChange(e)}
                   disabled={this.props.priceQuote}
                   placeholder="Enter Reference Number" />
          </FormGroup>

            <FormGroup>
                <Input type="checkbox" name="dataAgreement" checked={this.props.quote.state.quote.dataAgreement}
                       onChange={(e) => this.props.onChange(e)}  id="dataAgreement" required disabled={this.props.priceQuote}/>
                <label htmlFor="dataAgreement" className="css-label"><i
                    className="icon ion-android-checkbox"/>{i18n.t('quote.clientDataAgreement')}</label>
                {(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && <span><i style={{fontSize:'16px', padding:'10px', cursor:'pointer'}} className="icon ion-information-circled" id="Popover1" onClick={() => this.setState({popoverOpen: !this.state.popoverOpen})}/>
                <Popover trigger="focus" placement="bottom" target="Popover1" isOpen={this.state.popoverOpen} toggle={() => this.setState({popoverOpen: !this.state.popoverOpen})}>
                    <PopoverBody>Under the General Data Protection Act you should be able to prove that your client has given sufficient permission to use their personal data. Industry held data is information which may be held centrally on energy approved databases. The information held can include such information as the business meter serial number or unique meter point reference number. This information in combination with consumption information is considered ‘personal data’ and therefore you should ensure that you have the client’s approval to use that information to generate the quotation. If the client does not consent, then please be aware that your quotation may not be as accurate and therefore may be declined. You may also be in breach of the General Data Protection Act.</PopoverBody>
                </Popover></span> }
            </FormGroup>
            { this.global.permissions.includes("Quotes.CreateQuoteOnBehalfOfAgent") &&
            <div>
                <div>
                    As a Sales admin you can create quotes only on behalf of sales organisations and agents.
                </div>
                <FormGroup>
                    <Label for="ownerOrganizationId">{i18n.t('quote.salesOrganization')}</Label>
                    <Query query={salesOrganizationsQuery} variables={{pageNumber: 0, pageSize:100, where:`name=='*${this.state.query}*',businessPartnerId=='${this.props.quote.state.quote.ownerOrganizationId}'`, sort:{orders:[{property:'name', direction:'ASC'}]}}}>
                        { result => <AsyncTypeahead ref="typeahead" placeholder={i18n.t('quote.searchPartner')}
                                                    onSearch={(query) => {
                                                        this.setState({query});
                                                        this.props.onChange({target:{name:"ownerOrganizationId", value: null}});
                                                    }}
                                                    name="ownerOrganizationId"
                                                    id="ownerOrganizationId"
                                                    cssclassName="md-12"
                                                    options={result.loading ? [] : result.data.getSalesOrganizations.content}
                                                    filterBy={["name"]}
                                                    labelKey="name"
                                                    selectHintOnEnter={true}
                                                    onChange={(selected) => {
                                                        if (selected[0]) {
                                                            this.props.onChange({target:{name:"ownerOrganizationId", value: selected[0].businessPartnerId}});
                                                        }
                                                    }}
                                                    required={this.global.permissions.includes("Quotes.CreateQuoteOnBehalfOfAgent")}
                                                    disabled={this.props.priceQuote}
                                                    selected={[{
                                                        businessPartnerId: this.props.quote.state.quote.ownerOrganizationId,
                                                        name: !result.loading && result.data.getSalesOrganizations.content ? (result.data.getSalesOrganizations.content.reduce( (acc, curr) => curr.businessPartnerId === this.props.quote.state.quote.ownerOrganizationId ? curr : acc, {name : this.state.query}).name) : this.state.query
                                                    }]}
                        />}
                    </Query>
                </FormGroup>
                <FormGroup>
                    <Label for="ownerUserId">{i18n.t('quote.salesAgent')}</Label>
                    <Query query={getUsers}>
                    {(result) => {
                        if (result.loading) return <Loading/>;
                        if (result.error) return <div>{result.error} </div>;
                        let {data} = result;
                        return <Input type="select" name="ownerUserId" id="ownerUserId" placeholder="Choose Sales Agent"
                                      required={this.global.permissions.includes("Quotes.CreateQuoteOnBehalfOfAgent")}
                                      value={this.props.quote.state.quote.ownerUserId}  onChange={(e) => this.props.onChange(e)} disabled={this.props.priceQuote}>
                            {[{id:null, name: ""}, ...data.getUsers
                                .filter(({organizations}) => organizations.map(({businessPartnerId}) => businessPartnerId).includes(this.props.quote.state.quote.ownerOrganizationId))
                                .filter(user => {
                                const roles = user.roles.map(({role})=>role.key);
                                return roles.includes("SALESADMIN")
                                    || roles.includes("SALESMANAGER")
                                    || roles.includes("SALESAGENT");
                            })].map((option, index) => <option value={option.username} key={index}>{option.firstName} {option.lastName}</option>)}
                        </Input>
                    }}
                    </Query>
                </FormGroup>
            </div>
            }
            <FormGroup>
                <Label for="salesAgent">{i18n.t('quote.type')}</Label>
                <SelectBoxes disabled={this.props.priceQuote}
                    onChange={this.props.onChange}
                    name="quotetype" options={QuoteTypes}
                    className="quotetype"
                    value={this.props.quote.state.quote.quotetype}/>
            </FormGroup>
            {(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' ))
                ? <FormGroup>
                <Label for="salesAgent">{i18n.t('quote.typeInfoText')} </Label>
                </FormGroup>
                : null
            }
        </Col>
      </Row>
    )
  }
};


class Step2 extends Component {
  constructor(props) {
    super(props);
    this.isValidated = this.isValidated.bind(this);

  }
  isValidated() {
      if(!this.props.quote.state.quote.supplyAddress || !this.props.quote.state.quote.supplyAddress.postalCode){
          ReactDOM.render(<ExpiringAlert color="danger" message={"Please select an address"} />, document.getElementById('alert').appendChild(document.createElement("div")));
      }
      this.props.quote.props.client.query({
          query: getLdz,
          variables: {
              postcode: this.props.quote.state.quote.supplyAddress.postalCode
          }
      }).then(({data}) => {
          const quote = this.props.quote.state.quote;
          quote.supplyAddress.ldz = data.getLdz.ldz;
          quote.supplyAddress.exz = data.getLdz.exz;
          this.props.quote.setState({quote: quote});
      });

    return document.getElementsByName('createquote')[0].reportValidity() && this.props.quote.state.quote.supplyAddress && !!this.props.quote.state.quote.supplyAddress.postalCode;
  }
  render() {
    return (
      <Row className='step'>
        <Col className='col-12 step-header'>
          <h2>Supply Address</h2>
        </Col>
        <Col className='col-xl-5 col-lg-6 col-md-8 col-sm-12 step-body'>
          <FormGroup>
            <FindAddress
                name="supplyAddress"
                address={this.props.quote.state.quote.supplyAddress}
                onChange={(address) => this.props.setAddress('supplyAddress', address)}
                disabled={this.props.priceQuote}/>
          </FormGroup>
        </Col>
      </Row>
    )
  }
};

class Step3 extends Component {
  constructor(props) {
    super(props);
    this.state = { };
    this.isValidated = this.isValidated.bind(this);
    this.props.quote.state.quote.saleType = 'Acquisition';
  }
  isValidated() {
      const endDate = this.props.quote.state.quote.endDate;
      const startDate = this.props.quote.state.quote.startDate;
      const validStartAndEndDates = !(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) || !endDate || moment(endDate).isBefore(moment(startDate));
      if (!startDate) {
          ReactDOM.render(<ExpiringAlert color="danger" message={"Please provide Proposed Supply Start Date"} />, document.getElementById('alert').appendChild(document.createElement("div")));
      }
      if(!validStartAndEndDates){
          ReactDOM.render(<ExpiringAlert color="danger" message={"Proposed Supply Start Date must be after Current Supplier Contract End Date"} />, document.getElementById('alert').appendChild(document.createElement("div")));
      }
      if(!this.props.quote.state.quote.supplyAddress.mprn){
          ReactDOM.render(<ExpiringAlert color="danger" message={"Please select MPRN"} />, document.getElementById('alert').appendChild(document.createElement("div")));
      }
      return !!this.props.quote.state.quote.supplyAddress.mprn && document.createquote.annualgas.validity.valid && validStartAndEndDates && !!startDate;
  }
  render() {
      const durations = [12];
      return (
      <Row className='step'>
        <Col className='col-12 step-header'>
          <h2>Select Services</h2>
        </Col>
        <Col className='col-xl-8 col-lg-8 col-md-8 col-sm-12 step-body'>
          <Row>
            <Col className='col-xl-6 col-lg-8 col-md-10 col-sm-12'>
              <FormGroup>
                <SelectBoxes
                    onChange={this.props.onChange}
                    name="services"
                    options={QuoteServices}
                    className="services"
                    disabled={this.props.priceQuote}
                    value={this.props.quote.state.quote.services}/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className='col-xl-6 col-lg-8 col-md-10 col-sm-12'>
                {this.props.quote.state.quote.services.includes('gas') && <div>
                    <FormGroup>
                        <Label for="mprn">MPRN</Label>
                        {this.props.quote.state.quote.supplyAddress.meterData && this.props.quote.state.quote.supplyAddress.meterData.map( (meterData) => <p>
                            <Input onChange={(e) => this.props.quote.setState({quote:  {...this.props.quote.state.quote,
                                mprn:meterData.mprn,
                                supplyAddress: {
                                    ...this.props.quote.state.quote.supplyAddress,
                                    mprn:meterData.mprn,
                                    gas_meter_serial_number: meterData.gas_meter_serial_number}}})}
                                 type="radio" name="mprn"
                                 id={'mprn_'+meterData.mprn}
                                 value={JSON.stringify(meterData)}
                                 disabled={this.props.priceQuote}
                                 checked={meterData.mprn === this.props.quote.state.quote.mprn}/>
                            <label htmlFor={'mprn_'+meterData.mprn} className="css-label">
                                <i className="icon ion-android-radio-button-off"/>
                                <i className="icon ion-android-radio-button-on"/>
                                &nbsp; &nbsp;{meterData.mprn}
                            </label>
                        </p>
                         )}
                        {!this.props.quote.state.quote.supplyAddress.foundByMprn && <p><Input onChange={(e) => this.props.quote.setState({quote:  {...this.props.quote.state.quote,
                                mprn:null,
                                supplyAddress: {
                                    ...this.props.quote.state.quote.supplyAddress,
                                    mprn:null,
                                    gas_meter_serial_number: null}}})}
                                  type="radio" name="mprn"
                                  id={'mprn_new'}
                                  value={'new'}
                                  disabled={this.props.priceQuote}
                                  checked={!this.props.quote.state.quote.supplyAddress.gas_meter_serial_number}/>
                            <label htmlFor={'mprn_new'} className="css-label">
                                <i className="icon ion-android-radio-button-off"/>
                                <i className="icon ion-android-radio-button-on"/>&nbsp;&nbsp;
                                <span style={{display:"inline-block"}}><Input type="text" name="mprn" id="mprn" value={!this.props.quote.state.quote.supplyAddress.gas_meter_serial_number ? this.props.quote.state.quote.mprn : null}
                                       onChange={(e) => this.props.quote.setState({quote:  {...this.props.quote.state.quote,
                                               mprn:e.target.value.replace(/\s/g, ""),
                                               supplyAddress: {
                                                   ...this.props.quote.state.quote.supplyAddress,
                                                   mprn:e.target.value,
                                                   gas_meter_serial_number: null}}})} placeholder="Enter MPRN number"
                                       disabled={this.props.quote.state.quote.supplyAddress.gas_meter_serial_number}/></span>
                            </label>
                        </p>}
                    </FormGroup>
                        <FormGroup>
                            <Label for="annualgas">Annual Quantity in kWh (AQ) for Gas</Label>
                            <Input type="number" name="annualgas" id="annualgas"
                                   value={this.props.quote.state.quote.annualgas} onChange={(e) => this.props.onChange(e)}
                                   placeholder="Enter Gas Annual Quantity in kWh (AQ)" required
                                   disabled={this.props.priceQuote} valid={this.global.permissions.includes("Quotes.AlwaysUseBespokePrice") || !this.props.quote.state.quote.annualgas || this.props.quote.state.quote.annualgas <= parseInt(AQ_GAS_BESPOKE_THRESHOLD)}/>
                            {!this.props.priceQuote &&  <FormFeedback style={{position:"static"}}>
                                Please request a bespoke price from your utility supplier(s).
                            </FormFeedback>}
                        </FormGroup>
                    {this.global.permissions.includes('Quotes.StartManualNomination')
                    && !this.props.priceQuote
                    && (!this.props.quote.state.quote.annualgas || parseInt(this.props.quote.state.quote.annualgas) < parseInt(AQ_GAS_BESPOKE_THRESHOLD)) && <FormGroup>
                        <p>
                            <Input type="checkbox" name="manualNomination" checked={this.props.quote.state.quote.manualNomination}
                                   onChange={(e) => this.props.onChange(e)}  id="manualNomination"
                                   disabled={this.props.priceQuote}/>
                            <label htmlFor="manualNomination" className="css-label"><i className="icon ion-android-checkbox"/>{i18n.t('quote.startManualNomination')}</label>
                        </p>
                    </FormGroup>}
                    {this.props.quote.state.quote.annualgasIndustry && <FormGroup>
                        <Label for="annualgasIndustry">Annual Quantity in kWh (AQ) for Gas Reported by the Industry</Label>
                        <Input type="number" name="annualgasIndustry" id="annualgasIndustry"
                               value={this.props.quote.state.quote.annualgasIndustry}
                               disabled={true}/>
                    </FormGroup>
                    }
                    </div>
                }
                {this.props.quote.state.quote.services.includes('electricity') && <div>
              <FormGroup>
                <Label for="mpan">MPAN</Label>
                <Mpan mpan={this.props.quote.state.quote.mpan || ''}
                      disabled={this.props.priceQuote}/>
              </FormGroup>
              <FormGroup>
                <Label for="annualelectricity">Annual Quantity in kWh (AQ) for Electricity</Label>
                <Input type="number" name="annualelectricity"
                       id="annualelectricity"
                       value={this.props.quote.state.quote.annualelectricity}
                       onChange={(e) => this.props.onChange(e)} required
                       disabled={this.props.priceQuote}
                       placeholder="Annual Quantity in kWh (AQ) for Electricity" />
              </FormGroup>
                </div>}
            </Col>
          </Row>
          <hr />

            {((window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) || (window.config.consul.OSSO || (typeof OSSO !== 'undefined' ? OSSO : '' ))) && <Row>
                <Col className='col-xl-6 col-lg-8 col-md-10 col-sm-12'>
                    <Label>Proposed Supply Start Date</Label><br/>
                    <SingleDatePicker className={this.state.focusedstartdate ? 'focused' : ''}
                                      date={this.props.quote.state.quote.startDate ? moment(this.props.quote.state.quote.startDate) : null} // momentPropTypes.momentObj or null
                                      numberOfMonths={1}
                                      onDateChange={date => {
                                          // this.props.onChange({target:{name:'startDate', value: date ? date.format("YYYY-MM-DD") : null,}});
                                          this.props.quote.setState({
                                              quote: {
                                                  ...this.props.quote.state.quote,
                                                  startDate: date ? date.format("YYYY-MM-DD") : null,
                                              }
                                          })
                                      }}
                                      displayFormat="DD/MM/YY"
                                      openDirection="up"
                                      showDefaultInputIcon={true}
                                      isOutsideRange={day => (window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && (day.isBefore(moment().add(typeof SUPPLY_START_DATE_OFFSET !== 'undefined' ? SUPPLY_START_DATE_OFFSET : 17, 'd')) || day.isAfter(moment().add(6, 'months')))}
                                      inputIconPosition="after"
                                      focused={this.state.focusedstartdate} // PropTypes.bool
                                      onFocusChange={({focused}) => this.setState({focusedstartdate: focused})} // PropTypes.func.isRequired
                                      id="quote-start-date" // PropTypes.string.isRequired,
                    />
                </Col>
            </Row>
            }
            {((window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) || (window.config.consul.OSSO || (typeof OSSO !== 'undefined' ? OSSO : '' ))) &&
            <Row>
                <Col className='col-xl-6 col-lg-8 col-md-10 col-sm-12'>
                    <Label>Current Supplier Contract End Date</Label><br/>
                    <SingleDatePicker className={this.state.focusedEnd ? 'focused' : ''}
                                      date={this.props.quote.state.quote.endDate ? moment(this.props.quote.state.quote.endDate) : null} // momentPropTypes.momentObj or null
                                      numberOfMonths={1}
                                      onDateChange={date => {

                                          this.props.onChange({
                                              target: {
                                                  name: 'endDate',
                                                  value: date ? date.format("YYYY-MM-DD") : null,
                                              }
                                          });
                                      }}

                                      displayFormat="DD/MM/YY"
                                      openDirection="up"
                                      focused={this.state.focusedEnd} // PropTypes.bool
                                      onFocusChange={({focused}) => this.setState({focusedEnd: focused})} // PropTypes.func.isRequired
                                      id="quote-end-date" // PropTypes.string.isRequired,
                                      showDefaultInputIcon={true}
                                      isOutsideRange={() => false}
                                      inputIconPosition="after"
                    />
                </Col>
            </Row>
            }
            <Row>
                <Col className='col-xl-6 col-lg-8 col-md-10 col-sm-12'>
                    <Label for="saleType">Sale Type: </Label>
                    <Input type="select" name="saleType" id="saleType" placeholder="Select Sale Type" value={this.props.quote.state.quote.saleType} onChange={(e) => this.props.onChange(e)}>
                        {[...saleTypes].map((option, index) => <option value={option.value} key={index}>{option.name}</option>)}
                    </Input>

                </Col>
            </Row>
        </Col>
      </Row>
    )
  }
};
class Step4 extends Component {
  constructor(props) {
    super(props);
    this.isValidated = this.isValidated.bind(this);
  }
  isValidated() {
      if(this.props.quote.state.quote.tariffName == undefined || !this.props.quote.state.quote.tariffName.name){
          ReactDOM.render(<ExpiringAlert color="danger" message={"Please select a tariff"} />, document.getElementById('alert').appendChild(document.createElement("div")));
      }
      if(document.createquote.commissiongasUnitRate){
          if(document.createquote.commissiongasUnitRate.validity.rangeOverflow){
              document.createquote.commissiongasUnitRate.setCustomValidity("Unfortunately, your commission level is higher than expected. Please contact us to discuss why a higher commission is needed for this contract.")
          } else{
              document.createquote.commissiongasUnitRate.setCustomValidity('');
          }
      }
      if(document.createquote.commissiongasStandingCharge){
          if(document.createquote.commissiongasStandingCharge.validity.rangeOverflow){
              document.createquote.commissiongasStandingCharge.setCustomValidity("Unfortunately, your commission level is higher than expected. Please contact us to discuss why a higher commission is needed for this contract.")
          } else {
              document.createquote.commissiongasStandingCharge.setCustomValidity('');
          }
      }
    return !!this.props.quote.state.quote.tariffName.name
        && (!(this.props.quote.state.quote.tariffName == undefined) && document.createquote.tariffName)
        && (!document.createquote.unitRate || document.createquote.unitRate.validity.valid)
        && (!document.createquote.sc || document.createquote.sc.validity.valid)
        && (!document.createquote.commissiongasUnitRate || document.createquote.commissiongasUnitRate.validity.valid)
        && (!document.createquote.commissiongasStandingCharge || document.createquote.commissiongasStandingCharge.validity.valid)
        && (!document.createquote.commissionelectricity || document.createquote.commissionelectricity.validity.valid);
  }
  render() {
    return (
      <Row className='step'>
        <Col className='col-12 step-header'>
          <h2>Select tariff details</h2>
        </Col>
        <Col className='col-12 step-body'>
          <Row >
            <Col className='col-6'>
                {this.props.quote.state.quote.services.includes('gas') && !(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && <FormGroup tag="fieldset">
                    <Label htmlFor="gastariff">Select Gas Tariff Options</Label><br/>
                    <p>
                        <Input type="checkbox" name="greengas" checked={this.props.quote.state.quote.greengas}
                               onChange={(e) => this.props.onChange(e)}  id="greengas"/>
                        <label htmlFor="greengas" className="css-label"><i
                            className="icon ion-android-checkbox"/> Green Gas</label>
                    </p>
                    <p>
                        <Input type="checkbox" name="amr" checked={this.props.quote.state.quote.amr}
                               onChange={(e) => this.props.onChange(e)}  id="amr"/>
                        <label htmlFor="amr" className="css-label"><i
                            className="icon ion-android-checkbox"/>AMR</label>
                    </p>
                </FormGroup>
                }
                {this.props.quote.state.quote.services.includes('electricity') && <FormGroup tag="fieldset">
                    <Label htmlFor="gastariff">Select Electricity Tariff Options</Label><br/>
                    <p>
                        <Input type="checkbox" name="greenenergy" checked={this.props.quote.state.quote.greenenergy}
                               onChange={(e) => this.props.onChange(e)}  id="greenenergy"/>
                        <label htmlFor="greenenergy" className="css-label"><i
                            className="icon ion-android-checkbox"/> Green Energy</label>
                    </p>
                </FormGroup>
                }
            </Col>
          </Row>
          <Row >
            <Col className='col-12'>
              <SelectTariffs setTariff={this.props.setTariff} value={this.props.quote.state.quote.tariffName} quote={this.props.quote.state.quote }/>
            </Col>
          </Row>
          <Row >
            <Col className='col-6'>
                {this.props.quote.state.quote.services.includes('gas') && <FormGroup>
                    <Label for="commissiongasUnitRate">Commission Amount for Unit Rate (p/kWh)</Label>
                    <Input type="number" step={(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) ? 0.01 : 0.001} min="0" max={(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && !this.global.permissions.includes("Quotes.UnlimitedCommissionUnitRate") ? 3 : Number.MAX_SAFE_INTEGER} name="commissiongasUnitRate" id="commissiongasUnitRate" onChange={(e) => this.props.onChange(e)}
                           value={this.props.quote.state.quote.commissiongasUnitRate} placeholder="Commission Amount for Unit Rate" required/>
                </FormGroup>
                }

                {this.props.quote.state.quote.services.includes('gas') && <FormGroup>
                    <Label for="commissiongasStandingCharge">Commission Amount for Standing Charge (p/day)</Label>
                    <Input type="number" step={(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) ? 1 : 0.001} min="0" max={(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && !this.global.permissions.includes("Quotes.UnlimitedCommissionStandingCharge") ? 100 : Number.MAX_SAFE_INTEGER} name="commissiongasStandingCharge" id="commissiongasStandingCharge" onChange={(e) => this.props.onChange(e)}
                           value={this.props.quote.state.quote.commissiongasStandingCharge} placeholder="Commission Amount for Standing Charge" required/>
                </FormGroup>
                }
                {this.props.quote.state.quote.services.includes('electricity') && <FormGroup>
                    <Label for="commissionelectricity">Commission Amount for Electricity</Label>
                    <Input type="number" name="commissionelectricity" id="commissionelectricity"
                           value={this.props.quote.state.quote.commissionelectricity} onChange={(e) => this.props.onChange(e)}
                           placeholder="Commission Amount for Electricity" required/>
                </FormGroup>
                }
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
};

class Step5 extends Component {
  constructor(props) {
    super(props);
    this.isValidated = this.isValidated.bind(this);
  }
  isValidated() {
    return true;
  }
  render() {
    const tariff = this.props.quote.state.quote.tariffName;
    return (
      <Row className='step'>
        <Col className='col-12 step-header'>
            <h2>Gas {tariff.name !== 'Bespoke price' ? (TARIFFS_MASK ? tariff.name.match(new RegExp(TARIFFS_MASK))[0] : tariff.name) : tariff.name}</h2>
        </Col>
        <Col className='col-12'>
          <QuoteOverview quote={this.props.quote.state.quote}/>
          <Row >
            <Col className='col-6'>
              <FormGroup>
                <Label for="acceptance">The client accepted the above quote</Label>
                <p>
                  <Input type="checkbox" name="acceptance" checked={this.props.quote.state.quote.acceptance} onChange={(e) => this.props.onChange(e)} id="acceptance" />
                  <label htmlFor="acceptance" className="css-label"><i className="icon ion-android-checkbox"/>Yes, the client accepted it.</label>
                </p>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
};
class Step6 extends Component {
  constructor(props) {
    super(props);
    this.isValidated = this.isValidated.bind(this);

  }
  isValidated() {
    return true;
  }
  render() {
    return (
        <Row className='step'>
          <div className="done-step">
              <i className="icon ion-android-checkmark-circle"></i>
              <p className="text">Thank you! Your quote is created. </p>
              <Button color={"primary"} onClick={() => this.props.quote.setState({key: Math.random(), quote: null})}> <i className="icon ion-plus-round"></i>&nbsp;&nbsp; Add New Quote </Button>
          </div>
        </Row>
    )
  }
};

class CreateQuote extends Component {
  constructor(props) {
      super(props);
      this.handleInput = this.handleInput.bind(this);
    this.state = {key: Math.random()};
  };
  handleInput(e) {
    let state = {...this.state.quote};

    if (e.target.name === '') {
      state['mpan'] = (document.createquote.mpan && document.createquote.mpan.value);
    } else {
      if (e.target.type === 'checkbox') {
        state[e.target.name] = !state[e.target.name];
      } else {
        state[e.target.name] = e.target.value;
      }
    }
    this.setState({
      quote: state
    })
  }

  render() {
      document.querySelectorAll('input[type="number"]').forEach(target => target.addEventListener('wheel', (e) => e.target.blur()));
      if(!this.state.quote) {
          if(this.props.match.params.quoteId) {
              if (!this.props.getQuote.loading && this.props.getQuote.getQuote) {
                  let {id, extraData, ...quote} = {...this.props.getQuote.getQuote};

                  if (quote && quote.products) {
                      quote.services = quote.products.length > 0 ? quote.products.filter(({name}) => name === "Electricity").length > 0 ? "electricity" : ""
                      + quote.products.filter(({name}) => name === "Gas").length > 0 ? "gas" : "" : (QuoteServices.length === 1 ? QuoteServices[0].value : '');
                      quote = quote.products.reduce((acc, curr) => {
                          let {name, commission, commissionUnitRate, commissionStandingCharge, meterPoint, annualQuantity, annualQuantityIndustry, greenEnergy, greenGas, amr} = curr;
                          return {
                              ...acc,
                              commissionelectricity: commission,
                              commissiongasUnitRate: commissionUnitRate,
                              commissiongasStandingCharge: commissionStandingCharge,
                              [name === 'Gas' ? 'mprn' : 'mpan']: meterPoint,
                              ['annual' + name.toLowerCase()]: annualQuantity,
                              ['annual' + name.toLowerCase()+'Industry']: annualQuantityIndustry,
                              greengas: greenGas,
                              greenenergy: greenEnergy,
                              amr: amr
                          }
                      }, quote);
                      quote.quotetype = QuoteTypes.length === 1 ? QuoteTypes[0].value : quote.products.length === 1 ? 'single' : 'multiple';
                  } else {
                      quote = {};
                      quote.services = QuoteServices.length === 1 ? QuoteServices[0].value : '';
                      quote.quotetype = QuoteTypes.length === 1 ? QuoteTypes[0].value : '';
                  }
                  if (!quote.tariffName) {
                      quote.tariffName = {};
                  }
                  quote = {...JSON.parse(extraData), ...quote};
                  if (this.props.priceQuote) {
                      quote.id = id;
                  } else {
                      quote.sourceQuoteId = id;
                  }
                  quote.supplyAddress = quote.supplyAddress
                      ? quote.supplyAddress
                      : {
                          city: null,
                          street: null,
                          dependentStreet: null,
                          block: null,
                          houseNumber: null,
                          postalCode: null
                      }
                  this.setState({quote: quote})
              }
          } else {
              this.setState({ quote: {
                    clientName: "",
                      companyNumber: "",
                      mprn: "",
                      mpan: "",
                      services: QuoteServices.length === 1 ? QuoteServices[0].value : '',
                      quotetype:  QuoteTypes.length === 1 ? QuoteTypes[0].value : '',
                      supplyAddress: {},
                        tariffName: {}
              } });
          }
          return <Loading/>;
      } else {
          let {id, quotetype, acceptance, mpan, mprn, annualelectricity, annualgas, annualgasIndustry, commissionelectricity, commissiongasUnitRate, commissiongasStandingCharge, services, amr, greenenergy, greengas, ...quote} = this.state.quote;
          if (services === "gas") {
              quote.products = [{
                  name: "Gas",
                  commissionUnitRate: parseFloat(commissiongasUnitRate),
                  commissionStandingCharge: parseFloat(commissiongasStandingCharge),
                  meterPoint: mprn,
                  annualQuantity: parseFloat(annualgas),
                  annualQuantityIndustry: parseFloat(annualgasIndustry),
                  greenGas: greengas,
                  amr: amr
              }]
          } else if (services === "electricity") {
              quote.products = [{
                  name: "Electricity",
                  commission: parseFloat(commissionelectricity),
                  meterPoint: mpan,
                  annualQuantity: parseFloat(annualelectricity),
                  greenEnergy: greenenergy
              }]
          } else if (services === "electricitygas") {
              quote.products = [{
                  name: "Electricity",
                  commission: parseFloat(commissionelectricity),
                  meterPoint: mpan,
                  annualQuantity: parseFloat(annualelectricity),
                  greenEnergy: greenenergy
              },
              {
                  name: "Gas",
                  commissionUnitRate: parseFloat(commissiongasUnitRate),
                  commissionStandingCharge: parseFloat(commissiongasStandingCharge),
                  meterPoint: mprn,
                  annualQuantity: parseFloat(annualgas),
                  annualQuantityIndustry: parseFloat(annualgasIndustry),
                  greenGas: greengas,
                  amr: amr
              }]
          }
          if (quote.contractDuration) {
              quote.contractDuration = parseInt(quote.contractDuration);
          }
          quote.ownerOrganizationId = quote.ownerOrganizationId
              ? quote.ownerOrganizationId
              : this.global.ultimateParentOrganization !== null
                  ? this.global.ultimateParentOrganization
                  : this.global.salesOrganization;
          quote.subOwnerOrganizationId = quote.subOwnerOrganizationId
              ? quote.subOwnerOrganizationId
              : this.global.ultimateParentOrganization !== null
                  ? this.global.salesOrganization
                  : null;
          quote.ownerUserId = quote.ownerUserId ? quote.ownerUserId : this.global.username;
          let {clientName, ownerOrganizationId, subOwnerOrganizationId, ownerUserId, startDate, endDate, ...extraData} = quote;
          const status = acceptance
              ? {key: 'ACCEPTED'}
              : extraData.tariffName.name
                  ? {key: 'DRAFT'}
                  : {key: 'PENDING_PRICING'};
          const quoteFinal = {
              id,
              clientName,
              ownerOrganizationId,
              subOwnerOrganizationId,
              ownerUserId,
              status,
              startDate,
              endDate,
              extraData: JSON.stringify(extraData)
          };

          let steps = [];

          steps = steps.concat([{
              name: i18n.t('quote.start'),
              component: <Step1 quote={this} onChange={this.handleInput} priceQuote={this.props.priceQuote}/>
          }]);
          const siteStep = {
              name: i18n.t('quote.site'),
              component: <Step2 quote={this} onChange={this.handleInput} priceQuote={this.props.priceQuote}
                                setAddress={(addressType, address) =>
                                    this.setState({
                                        ...this.state,
                                        quote: {...this.state.quote, mprn: address.mprn, [addressType]: address}
                                    })}/>
          };
          const servicesStep = {
              name: i18n.t('quote.quoteServices'),
              component: <Step3 quote={this} onChange={this.handleInput} priceQuote={this.props.priceQuote}/>
          };
          steps = steps.concat(/*(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' ))*/ false ? [servicesStep, siteStep] : [siteStep,
              servicesStep]);

          if (this.global.permissions.includes("Quotes.AlwaysUseBespokePrice") || this.props.priceQuote || !annualgas || annualgas <= parseInt(AQ_GAS_BESPOKE_THRESHOLD) && !this.state.quote.manualNomination) {
              steps = steps.concat([{
                  name: i18n.t('quote.tariff'),
                  component: <Step4 quote={this} bespokePrice={this.props.priceQuote}
                                    onChange={this.handleInput}
                                    setTariff={(tariff) => {
                                        // Determine which is the index of unit rate/standing charge
                                        var scIndex;
                                        var unitRateIndex;

                                        var productName = tariff.productPrices[0].product.searchKey;
                                        var productName2 = tariff.productPrices[1].product.searchKey;

                                        if(productName === "standing charge" && productName2 === "gas consumption") {
                                            scIndex = 0;
                                            unitRateIndex = 1;
                                        } else if(productName === "gas consumption" && productName2 == "standing charge") {
                                            scIndex = 1;
                                            unitRateIndex = 0;
                                        }

                                        var standingCharge = 0.00;
                                        var unitRate = 0.00;
                                        if (tariff.bespokePrice === true) {
                                            standingCharge = tariff.sc;
                                            unitRate = tariff.unitRate;
                                        }   else {
                                            standingCharge=tariff.productPrices[scIndex].price;
                                            unitRate = tariff.productPrices[unitRateIndex].price;
                                        }

                                        this.setState({
                                            ...this.state,
                                            quote: {...this.state.quote, "tariffName": {...tariff, "id":"1", "aqLow":tariff.parameters.AQmin, "aqHigh":tariff.parameters.AQmax, "sc":standingCharge, "unitRate":unitRate, "billingFrequency": tariff.parameters["Billing Frequency"], "brokerId":"DYCE", "earliestStartDate":tariff.parameters["Earliest Start date"], "latestStartDate":tariff.parameters["Latest Start Date"], "ldz":tariff.parameters.LDZ, "tariffCode":tariff.name, "term":tariff.parameters.Term}, "contractDuration" : tariff.parameters.Term}
                                        })
                                    }
                                    }
                  />

              },
                  {
                      name: i18n.t('quote.overview'),
                      component: <Step5 quote={this} onChange={this.handleInput}/>
                  },
              ]);
          }
          steps = steps.concat([
                  {
                      name: i18n.t('quote.done'),
                      component: <Step6 quote={this} onChange={this.handleInput}/>
                  }
              ]
          );


          return (
              <div className="" key={this.state.key}>
                  <Row className='subheader'>
                      <Col className='col-8'>
                          <h1>Get a Quote</h1>
                      </Col>
                  </Row>
                  <Form name="createquote" onSubmit={(e) => {
                      e.preventDefault()
                  }}>
                      <Mutation mutation={createUpdateQuote} variables={{quote: quoteFinal}}
                                refetchQueries={[{query: getQuotesQuery}, {query: getQuotesQuery, variables:{organizationId: ownerOrganizationId, username:null}}, {query: getQuotesQuery, variables:{organizationId: ownerOrganizationId, username: this.global.username}}]}>
                          {(createQuote, {data}) => (
                              <FormWizzard steps={steps} startAtStep={this.props.priceQuote ? 2 : 0} onLastStep={() => {
                                  createQuote().then((response) => console.log(response))
                              }}/>
                          )}
                      </Mutation>
                  </Form>
              </div>
          )
      }
  }
}

export default graphql(getQuoteById, {
    name: 'getQuote',
    options: props => ({
        variables: { id : parseInt(props.match.params.quoteId) }
    }),
}) (withApollo(CreateQuote));
