import React, {Component} from 'react';
import {Col, Input, Label, Row} from "reactstrap";
import {changePasswordMutation, getUserQuery} from "../../queries/Queries";
import {withApollo} from "react-apollo";
import {withRouter} from 'react-router';

class ResetPasswordTool extends Component {

    constructor(props) {
        super(props);

        const params = this.props.location.search.split('=');
        const email = params.length == 2 ? params[1] : '';

        this.state = {user: {}, email: email, password: '', message: ''};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.generatePassword = this.generatePassword.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.getRandomString = this.getRandomString.bind(this);
    }

    handleChange(event) {
        this.setState({email: event.target.value});
    }

    handleSubmit(event) {
        this.generatePassword();
        event.preventDefault();
    }

    generatePassword() {
        this.props.client.query({
            query: getUserQuery,
            variables: {username: this.state.email}
        }).then((response) => {
            if (response.data.getUser.username) {
                const pass = this.getRandomString();
                response.data.getUser.password = pass;
                this.setState({user: response.data.getUser, message: '', password: pass})
                this.updatePassword();
            }
        }).catch((error) => {
            this.setState({message: 'No user found with username ' + this.state.email});
        });
    }

    updatePassword() {
        this.props.client.mutate({
            mutation: changePasswordMutation,
            variables: {user: this.state.user}
        }).then((response) => {
            this.setState({message: 'Password successfully updated'});
        }).catch((error) => {
            this.setState({message: 'Something went wrong'});
        });
    }

    getRandomString() {
        let randomNumbers = '0123456789';
        let randomLetters = 'abcdefghijklmnopqrstuvwxyz';
        let randomCapitalLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let randomSymbols = '@#$%&!';
        let result = '';
        for (let i = 0; i < 5; i++) {
            result += randomNumbers.charAt(Math.floor(Math.random() * randomNumbers.length)) + randomCapitalLetters.charAt(Math.floor(Math.random() * randomCapitalLetters.length)) + randomLetters.charAt(Math.floor(Math.random() * randomLetters.length)) + randomSymbols.charAt(Math.floor(Math.random() * randomSymbols.length));
        }
        return result;
    }

    copyCodeToClipboard = () => {
        const el = document.getElementById('generated-password');
        el.select()
        document.execCommand("copy")
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <Row className='row-email-address'>
                    <Col className='col-user-label' lg="4" xs="6">
                        <Label className='user-label'>
                            Email address*
                        </Label>
                    </Col>
                    <Col lg="8" xs="6">
                        <Input type="text" value={this.state.email} required
                               placeholder={'User email address'}
                               onChange={this.handleChange}/>
                    </Col>
                </Row>
                <br/>
                <Row className='row-generated-password'>
                    <Col className='col-user-label' lg="4" xs="6">
                        <Label className='user-label'>
                            Generated password
                        </Label>
                    </Col>
                    <Col lg="8" xs="6" >
                        <Input type="text" id="generated-password" readOnly={true} value={this.state.password}/>
                    </Col>
                </Row>
                <Row className='gen-coppy'>
                    <Col lg="12" xs="12">
                        <input type="submit" value="Generate Password"/>
                    </Col>
                    <Col className='col-clipboard' lg="12" xs="12">
                        <input type="button" value="Copy password to clipboard" onClick={() => this.copyCodeToClipboard()} />
                    </Col>
                </Row>
                <Label className='password-update-msg' >{this.state.message}</Label>
            </form>
        )
    }
}

export default withApollo(withRouter(ResetPasswordTool));