import React, { Component } from 'reactn';
import { Col, Row } from "reactstrap";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Query} from "react-apollo";
import {getBpmServiceInstancesQuery} from "../../queries/Queries";
import Loading from "../../components/Loading";


class CamundaLinks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data:"",
            instances: [],
            deployments:[],
            deploymentResources:[]
        };
    }

    render() {
        return (
            <div className={"bpmn-container"}>

                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>Camunda BPM Links</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>

                        <div className="page-cnt create-user ">
                        <Query query={getBpmServiceInstancesQuery}>
                            {result => {
                                if (result.loading) return <Loading/>;
                                if (result.error) return result.error;
                                return result.data.getBpmServiceInstances
                                    .filter((instance, index, array) => array.indexOf(instance) === index)
                                    .map(instance => <a href={`${(window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/bpm/${instance}/app/cockpit/default/#/login`} target={'_blank'}><h2>{instance} Camunda Cockpit</h2></a>);
                            }
                            }
                        </Query>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default CamundaLinks;