import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import {Query} from "react-apollo";
import {agreementQuery, getQuoteById} from "../../queries/Queries";
import Loading from "../../components/Loading";
import AgreementOverview from "../../components/AgreementOverview";
import QuoteOverview from "../../components/QuoteOverview";

const QuoteTypes = [{
    title: 'Single Meter Point',
    icon: 'icon ion-location',
    value: 'single',
    active: true
},
    {
        title: 'Multiple Meter Point',
        icon: 'icon-atom ion-Multiple-Locations',
        value: 'multiple',
        active: false
    }].filter(({value}) => (window.config.consul.QUOTE_TYPES || (typeof QUOTE_TYPES !== 'undefined' ? QUOTE_TYPES : '' )) && (window.config.consul.QUOTE_TYPES || (typeof QUOTE_TYPES !== 'undefined' ? QUOTE_TYPES : '' )).includes(value));
const QuoteServices = [{
    title: 'Gas Quote',
    icon: 'icon-atom ion-Gas',
    value: 'gas',
    active: true
},
    {
        title: 'Electricity Quote',
        icon: 'icon-atom ion-Electricity',
        value: 'electricity',
        active: true
    },
    {
        title: 'Dual Quote',
        icon: 'icon-atom ion-Electricity-Gas',
        value: 'electricitygas',
        active: false
    }].filter(({value}) => (window.config.consul.QUOTE_SERVICES || (typeof QUOTE_SERVICES !== 'undefined' ? QUOTE_SERVICES : '' )) && (window.config.consul.QUOTE_SERVICES || (typeof QUOTE_SERVICES !== 'undefined' ? QUOTE_SERVICES : '' )).includes(value));
class QuoteOverviewWrapper extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return <div style={{padding:"20px"}}><Query query={getQuoteById} variables={{id: this.props.match.params.quoteId}} >
            {({loading, error, data}) => {
                if (loading) return <Loading/>;
                if (error) return `Error! ${error.message}`;
                let {id, extraData, ...quote} = {...data.getQuote};

                if (quote && quote.products) {
                    quote.services = quote.products.length > 0 ? quote.products.filter(({name}) => name === "Electricity").length > 0 ? "electricity" : ""
                    + quote.products.filter(({name}) => name === "Gas").length > 0 ? "gas" : "" : (QuoteServices.length === 1 ? QuoteServices[0].value : '');
                    quote = quote.products.reduce((acc, curr) => {
                        let {name, commission, commissionUnitRate, commissionStandingCharge, meterPoint, annualQuantity, annualQuantityIndustry, greenEnergy, greenGas, amr} = curr;
                        return {
                            ...acc,
                            commissionelectricity: commission,
                            commissiongasUnitRate: commissionUnitRate,
                            commissiongasStandingCharge: commissionStandingCharge,
                            [name === 'Gas' ? 'mprn' : 'mpan']: meterPoint,
                            ['annual' + name.toLowerCase()]: annualQuantity,
                            ['annual' + name.toLowerCase()+'Industry']: annualQuantityIndustry,
                            greengas: greenGas,
                            greenenergy: greenEnergy,
                            amr: amr
                        }
                    }, quote);
                    quote.quotetype = QuoteTypes.length === 1 ? QuoteTypes[0].value : quote.products.length === 1 ? 'single' : 'multiple';
                } else {
                    quote = {};
                    quote.services = QuoteServices.length === 1 ? QuoteServices[0].value : '';
                    quote.quotetype = QuoteTypes.length === 1 ? QuoteTypes[0].value : '';
                }
                if (!quote.tariffName) {
                    quote.tariffName = {};
                }
                quote = {...JSON.parse(extraData), ...quote};
                if (this.props.priceQuote) {
                    quote.id = id;
                } else {
                    quote.sourceQuoteId = id;
                }
                quote.supplyAddress = quote.supplyAddress
                    ? quote.supplyAddress
                    : {
                        city: null,
                        street: null,
                        dependentStreet: null,
                        block: null,
                        houseNumber: null,
                        postalCode: null
                    }
                return <QuoteOverview quote={quote}/>;
            }
            }
        </Query></div>
    }
}

export default withRouter(QuoteOverviewWrapper);