/**
 * Created by ogi on 1/22/18.
 */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { Query } from 'react-apollo';
import gql from "graphql-tag";
import JsxParser from 'react-jsx-parser';
import Widget01 from '../../views/Widgets/Widget01';
import Loading from '../Loading'
import _ from 'lodash';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Input, InputGroup, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import 'react-dates/initialize';
import {DateRangePicker, SingleDatePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import ExpiringAlert from '../ExpiringAlert';
import { CSVLink } from "react-csv";
//import {moment} from 'moment'

import jsonata from "./../../../node_modules/jsonata/jsonata-es5"


class QueryDataGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {page:1, sizePerPage:10, loadedRecords: 100};
        this.getColumns = this.getColumns.bind(this);
        this.getHeaders = this.getHeaders.bind(this);
        this.filter = this.filter.bind(this);
        this.getFieldValue = this.getFieldValue.bind(this);
        this.setParents = this.setParents.bind(this);
    }

    getQuery() {
        return this.props.query;
    }

    getColumns() {
        for (const col of this.props.columns) {
            if (col.template) {
                col.formatter = (cell, row, rowIndex, formatExtraData) =>
                    (<JsxParser
                        bindings={{ row }}
                        components={{ Widget01, MoreInfoButton }}
                        jsx={_.template(this.props.columns[col].template)(row)}
                        showWarnings={true}
                        key={rowIndex + "_" + col}
                    />)
            }
            col.headerTitle = true;
        }

        return this.props.columns;
    }

    getHeaders() {
        const headers = []
        for (const col of this.props.columns) {
            headers.push({label: col.text, key: col.dataField});
        }
        return headers;
    }

    getUniqueValues(data, fieldPath) {
        return data.flatMap(dataElem => this.getFieldValue(dataElem, fieldPath)).filter(function (value, index, self) {
            return self.map(e => ''+e).indexOf(''+value) === index;
        });
    };

    setParents(obj, parents) {
        let newObj = JSON.parse(JSON.stringify(obj));
        const ownProps = Object.keys(newObj);
        for (let ownProp of ownProps) {
            if (newObj[ownProp] instanceof Object || newObj[ownProp] instanceof Array) {
                let newparents = parents.slice();
                newparents.unshift(obj);
                newObj[ownProp] = this.setParents(newObj[ownProp], newparents);
            }
        }
        newObj.__parents = parents;
        return newObj;
    }

    getFilters(data) {
        if (this.props.filters) {
            let result = [];
            for (let i = 0; i < this.props.filters.length; i++) {

                const filter1 = this.props.filters[i];
                let elem = null;
                switch (filter1.type) {
                    case "text":
                        elem = <InputGroup className={filter1.classes}>
                            <Input placeholder={filter1.placeholder} value={this.state['search' + i]}
                                onChange={(e) => this.setState({ ['search' + i]: e.target.value })} />
                            <i className='icon ion-ios-search-strong' />
                        </InputGroup>;
                        break;
                    case "date":
                        elem = <div className={filter1.classes}><DateRangePicker small noBorder
                            startDate={this.state['searchStart' + i]} // momentPropTypes.momentObj or null,
                            startDateId={"searchStart" + i} // PropTypes.string.isRequired,
                            endDate={this.state['searchEnd' + i]} // momentPropTypes.momentObj or null,
                            endDateId={"searchEnd" + i} // PropTypes.string.isRequired,
                            onDatesChange={({ startDate, endDate }) => {
                                this.setState({
                                    ["searchStart" + i]: startDate,
                                    ["searchEnd" + i]: endDate
                                });
                            }} // PropTypes.func.isRequired,
                            focusedInput={this.state['focusedInput'+i]} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={focusedInput => this.setState({ ['focusedInput'+i]:focusedInput })} // PropTypes.func.isRequired,
                            showClearDates={true}
                            isOutsideRange={() => false}
                            noBorder={false}
                            displayFormat={"DD-MM-YY"}
                            startDatePlaceholderText={filter1.label+" From"}
                            endDatePlaceholderText={"To"}
                        /></div>;
                        break;
                    case "dropdown":

                        elem = <InputGroup className={filter1.classes}><Input type="select" value={this.state['search' + i]}
                            onChange={(e) => this.setState({ ['search' + i]: e.target.value })}>
                            <option key={-1} className='default-option' value="">{filter1.placeholder}</option>
                            {this.getUniqueValues(this.getFieldValue(data, this.props.dataPath), filter1.fields[0]).map((value,index) =>
                                <option key={index}>{filter1.valueMappings && filter1.valueMappings[value] ? filter1.valueMappings[value] : value}</option>)}
                        </Input></InputGroup>;
                        break;
                }
                result.push(<Col key={i} sm={filter1.cols}>{elem}</Col>);

            }

            return <div className="table-filers-elements"><Row>{result}</Row></div>;
        } else {
            return null;
        }
    }
    getTitle() {
        if (this.props.title) {
            return (
                <div className="table-filers-title" >
                    <h3>{this.props.title}</h3>
                </div>
            );
        } else {
            return null;
        }
    }
    getButtons() {
        if (this.props.buttons) {
            return (
                <div className="table-filers-buttons" >
                    {this.props.buttons}
                </div>
            );
        } else {
            return null;
        }
    }

    getFieldValue(obj, path) {
        if(obj){
            var pathArray = path.split(".");
            if (pathArray.length > 1) {
                if (pathArray[0] === "#") {
                    let concatArr = [];
                    for (let j = 0; j < obj.length; j++) {
                        concatArr = concatArr.concat(this.getFieldValue(obj[j], path.substring(path.indexOf(".") + 1)));
                    }
                    return concatArr;
                } else {
                    return this.getFieldValue(obj[pathArray[0]], path.substring(path.indexOf(".") + 1));
                }
            } else {
                return obj[pathArray[0]];
            }
        } else {
            return [];
        }
    }



    filter(element) {

        const moment = require('moment');
        let result = true;
        if (this.props.filters) {
            for (let i = 0; i < this.props.filters.length; i++) {
                let result1 = false;
                const filter1 = this.props.filters[i];
                for (let j = 0; j < filter1.fields.length; j++) {
                    const fieldName = filter1.fields[j];

                    const fieldValue = this.getFieldValue(element, fieldName);
                    const searchTerm = this.state['search' + i];
                    const searchTermStart = this.state["searchStart" + i];
                    const searchTermEnd = this.state["searchEnd" + i];
                    switch (filter1.type) {
                        case "text":
                            result1 = result1 || !searchTerm || fieldValue && fieldValue.toString().toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
                            break;
                        case "date":
                            const fieldValueMoment = moment(fieldValue).utc();
                            result1 = result1
                                || (!searchTermStart || searchTermStart.isSameOrBefore(fieldValueMoment, "day"))
                                && (!searchTermEnd || searchTermEnd.isSameOrAfter(fieldValueMoment, "day"));
                            break;
                        case "dropdown":
                            result1 = result1
                                || !searchTerm
                                || searchTerm.length === 0
                                || fieldValue === searchTerm
                                || (filter1.valueMappings && filter1.valueMappings[fieldValue] ? filter1.valueMappings[fieldValue] : fieldValue) === (searchTerm);
                            break;
                    }
                }
                result = result && result1;
            }
        }
        this.state.page = 1;
        return result;
    }

    getRemoveDuplicatesFunction(key, removeDuplicates){
        return removeDuplicates ? (value, index, self) => self.findIndex(v => v[key] === value[key]) === index : () => true;
    }

    render() {
        const dataPathArr = this.props.dataPath.split('.');
        const lastDataPathPart = dataPathArr[dataPathArr.length-1];
        // const paginatedQuery = this.props.query.loc.source.body.replace(new RegExp(lastDataPathPart+'\\s\\(startRow: 0'), `${lastDataPathPart} (startRow: ${(this.state.loadedRecords)*100}`);

        return <Query query={gql`${this.props.query}`} variables={this.props.variables} notifyOnNetworkStatusChange={true}>
            {(result) => {
                if (result.loading) return <Loading/>;
                if (result.networkStatus === 4) return <Loading/>;
                if (result.error) return <div>{result.error} </div>;
                let { data } = result;
                //data[this.props.dataPath] = []; //To test with now data manualy
                if(data && data[this.props.dataPath] && data[this.props.dataPath].length === 0) {
                    return this.props.noDataView? this.props.noDataView : <div>No records in table</div>
                }

                const expression = this.props.expression ? jsonata(this.props.expression) : null;
                let transformedData = expression ? expression.evaluate(data) : data;
                if(this.props.filterFunction) {
                    transformedData = this.props.filterFunction(transformedData, data, this.props);
                }
                const dataWithParents = this.setParents(transformedData, []);
                return <div className={(this.props.className || "") + (this.props.onSelectOpenPage || this.props.onSelect ? " table-with-links" : "")}>
                    {this.props.export && (<CSVLink data={this.getFieldValue(dataWithParents, this.props.dataPath)
                        .filter(this.getRemoveDuplicatesFunction(this.props.keyField, this.props.removeDuplicates))
                        .filter(this.filter)
                        .map((v,i) => ({...v, genId: i}))} headers={this.getHeaders()}>Download me</CSVLink>)}
                    <div className='table-filers-cnt'>
                        {this.getTitle()}
                        {this.getFilters(data)}
                        {this.getButtons()}

                    </div>
                    {this.props.reload || !this.props.reload}
                    <BootstrapTable
                        wrapperClasses="table-responsive"
                        key={Math.random()}
                        keyField={"genId"}
                        data={this.getFieldValue(dataWithParents, this.props.dataPath)
                            .filter(this.getRemoveDuplicatesFunction(this.props.keyField, this.props.removeDuplicates))
                            .filter(this.filter)
                            .map((v,i) => ({...v, genId: i}))}
                        columns={this.getColumns()}
                        bordered={false}
                        rowEvents={{
                            onMouseMove: (e, row, rowIndex) => {
                                e.target.move = true;
                            },
                            onMouseDown: (e, row, rowIndex) => {
                                e.target.move = false;
                            },
                            onClick: (e, row, rowIndex) => {
                                if (e.target.move) return;
                                if (this.props.onSelectOpenPage) {
                                    this.props.history.push('/bp/' + this.props.match.params.id + '/' + this.props.onSelectOpenPage + '/' + row.id);
                                } else if (this.props.onSelect){
                                    this.props.onSelect(row);
                                }
                            }
                        }}

                        hover
                        defaultSorted={this.props.defaultSorted ? [this.props.defaultSorted] : []}
                        pagination={paginationFactory({
                            page: this.state.page,
                            sizePerPage: this.state.sizePerPage,
                            onPageChange: (page, sizePerPage) => {
                                this.state.page =  page;
                            },
                            sizePerPageList:[ {
                                text:"5", value: 5
                            },{
                                text:"10", value: 10
                            }, {
                                text:"20", value: 20
                            } ]
                        })}
                        noDataIndication={() => this.props.noDataView? this.props.noDataView : <div>No records in table</div>}

                    />{this.props.serverSidePaging && <Button style={{float:"right"}} onClick={() => result.fetchMore({
                    query : gql`${this.props.query.loc.source.body.replace(lastDataPathPart + '(startRow: 0', lastDataPathPart+'(startRow: '+this.state.loadedRecords)}`,
                    variables: this.props.variables,
                    updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        this.state.loadedRecords += 100;
                        return Object.assign({}, prev, {
                            [this.props.dataPath]: [...prev[this.props.dataPath], ...fetchMoreResult[this.props.dataPath]]
                        });
                    }
                })}>Load 100 more records</Button>}</div>;
            }
            }
        </Query>
    }
}

export default withRouter(QueryDataGrid)