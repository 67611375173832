import React, {Component} from 'react';

import i18n from '../../views/Pages/Login/i18n';
import {Mutation, Query} from 'react-apollo';
import {getBusinessPartnerMiddleQuery, periodsQuery, startProcessMutation, Properties } from "../../queries/Queries";
import Form from "react-jsonschema-form";
import ReactDOM from "react-dom";
import ExpiringAlert from "../ExpiringAlert";
import {Breadcrumb, BreadcrumbItem, Button, Col, FormGroup, Input, Row} from "reactstrap";
import Loading from "../../components/Loading"
import moment from "moment";
import MaskInput from "react-maskinput";
import 'react-dates/lib/css/_datepicker.css';
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import axios from "axios";
import jsonata from "./../../../node_modules/jsonata/jsonata-es5";
import {  filterMeterPointsByActiveRegisters } from '../../utils/Helpers';

const jwt = require('jsonwebtoken');
const token = sessionStorage.mstoken ? sessionStorage.mstoken : localStorage.token;
const decodedToken = jwt.decode(token);


class Reading extends Component {

    render() {
        const getOrganizationId = decodedToken && decodedToken.organizations ? decodedToken.organizations[0] : '';

        return (
            <Query query={getBusinessPartnerMiddleQuery} variables={{
                orderBy: "name",
                where: `id = '${getOrganizationId}'`
            }}>
                {result => {
                    if (result.loading) return (<Loading/>);
                    const data = result.data.businessPartner;
                    const estateList = data[0].mDMBPEstateList;
                    return (<div className="reading-modal">
                        <FormGroup>
                            <label>{i18n.t('readings.address')}:&nbsp;</label>
                            <Input type="select" value={this.state && this.state.mDMBPEstate.id} onChange={(e) => {
                                this.setState({
                                    mDMBPEstate: estateList.filter(estate => estate.id === e.target.value).reduce((accumulator, currentValue) => currentValue || accumulator, {mDMEstate: {mDMMeterPointList: []}}),
                                    mDMMeterPoint: {
                                        mDMMeterRegisterList: []
                                    },
                                    meter: {}
                                }, () => this.props.onChange(this.state))
                            }
                            }>
                                <option value="">---</option>
                                {estateList.map(estate => <option
                                    value={estate.id}>{estate.mDMEstate.locationAddress.addressLine1}</option>)}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label>{i18n.t('readings.meterPoint')}:&nbsp;</label>
                            <Input type="select" value={this.state && this.state.mDMMeterPoint.id} onChange={(e) => {
                                this.setState({
                                    mDMMeterPoint: this.state.mDMBPEstate.mDMEstate.mDMMeterPointList.filter(meterPoint => meterPoint.id === e.target.value).reduce((accumulator, currentValue) => currentValue || accumulator, {mDMMeterRegisterList: []})
                                }, () => this.props.onChange(this.state))
                            }}>
                                <option value="">---</option>
                                {this.state && this.state.mDMBPEstate.mDMEstate.mDMMeterPointList.map(meterPoint => <option
                                    value={meterPoint.id}>{meterPoint.name}</option>)}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label>{i18n.t('readings.meter')}:&nbsp;</label>
                            <Input type="select" value={this.state && this.state.meter.id}
                                   onChange={(e) => this.setState({meter: this.state.mDMMeterPoint.mDMMeterRegisterList.filter(({meter}) => meter.id === e.target.value).reduce((accumulator, currentValue) => currentValue || accumulator, {meter: ''}).meter}, () => this.props.onChange(this.state))}>
                                <option value="">---</option>
                                {this.state && this.state.mDMMeterPoint.mDMMeterRegisterList.map(({meter}) => meter).filter((meter, index, self) => {
                                    return self.findIndex(m => m.id === meter.id) === index;
                                }).map(meter => <option value={meter.id}>{meter.serialNumber}</option>)
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label>{i18n.t('readings.type')}:&nbsp;</label>
                            <Input type="select" value={this.state && this.state.type}
                                   onChange={(e) => this.setState({...this.state, type: e.target.value}, () => this.props.onChange(this.state))}>
                                <option value="COU_C">Customer</option>
                                <option value="COU_E">Estimated</option>
                            </Input>
                        </FormGroup>
                        {this.state && this.state.type !== "COU_E" && this.state.mDMMeterPoint.mDMMeterRegisterList.filter(({meter}) => meter.id === this.state.meter.id).map(mDMMeterRegister => {
                            const lastReading = mDMMeterRegister.meter.ammMeterReadingList
                                .filter(reading => (reading.customerReadType === "Customer" || reading.customerReadType === "Initial") && reading.product.id === mDMMeterRegister.mDMRegister.product.id)
                                .map(reading => ({
                                    ...reading,
                                    readingdate: moment(reading.readingdate, "YYYY-MM-DDTHH:mm:ssZ")
                                }))
                                .sort((a, b) => b.readingdate.isBefore(a.readingdate) ? -1 : 1)[0];
                            return (<FormGroup>
                                <label>{mDMMeterRegister.mDMRegister.product.name}:&nbsp;</label><br/>
                                <div className="final-reading-box">
                                <span className="final-reading"><label>Final reading</label></span>
                                <MaskInput value={this.state['reading' + mDMMeterRegister.id]}
                                           onChange={(e) => this.setState({
                                               ['reading' + mDMMeterRegister.id]: e.target.value,
                                               ['lastReading' + mDMMeterRegister.id]: lastReading || null
                                           }, () => {this.props.onChange(this.state)})}
                                           alwaysShowMask
                                           maskChar={"0"}
                                           mask={"0".repeat(mDMMeterRegister.meterReadingDigitsCount)}
                                           placeholder="20491"
                                           className={'reading-input'}
                                           style={{width: mDMMeterRegister.meterReadingDigitsCount * 24.2, paddingRight: '5px'}}
                                />
                                </div>
                            </FormGroup>)
                        })}
                    </div>);
                }}
            </Query>)
    }

}

class ReadingFromEssence extends Component {

    constructor(arg) {
        super(arg);
        this.state = {
            mDMBPEstate: {id: ''},
            mDMMeterPoint: {id: ''},
            meter: {id: ''},
            type: '',
        };
        this.clearReadingLastReading = this.clearReadingLastReading.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (Object.keys(prevProps.formContext).length !== 0 &&
            this.props.formContext.reading &&
            Object.keys(this.props.formContext.reading).length === 0 &&
            Object.keys(this.props.formContext.reading).length < Object.keys(prevProps.formContext.reading).length) {

            this.clearReadingLastReading();
            this.setState({
                mDMBPEstate: {id: ''},
                mDMMeterPoint: {id: ''},
                meter: {id: ''},
                type: '',
            })
        }
    }

    clearReadingLastReading() {
        this.setState(current => {
            current.mDMBPEstate.site && current.mDMBPEstate.site.meterPoints[0].registers.filter(register => {
                let lastReading = `lastReading${register.id}`;
                let reading = `reading${register.id}`;

                delete current[lastReading];
                delete current[reading];
            });
        })
    }

    filterMeterPoints(value) {
        const meterPoints = this.state.mDMBPEstate.site.meterPoints
            .filter(meterPoint => meterPoint.id === value)
            .reduce((accumulator, currentValue) => {
                accumulator.push(currentValue);
                return accumulator;
            }, []);
        
        return  filterMeterPointsByActiveRegisters(meterPoints);
    }

    render() {
        const getOrganizationId = decodedToken && decodedToken.organizations ? decodedToken.organizations[0] : '';

        return (
            <Query query={Properties} variables={
                {
                    "pageSize": 200,
                    "pageNumber": 0,
                    "sort": {
                        "orders": [
                            {
                                "property": "id",
                                "direction": "asc"
                            }
                        ]
                    },
                    "where": null
                }
            }>
                {result => {
                    if (result.loading) return (<Loading/>);
                    const data = result.data.Property;
                    const estateList = data.content;
                    return (<div className="reading-modal">
                        <FormGroup>
                            <label>{i18n.t('readings.address')}:&nbsp;</label>
                            <Input required type="select" value={this.state.mDMBPEstate.id} onChange={(e) => {
                                this.clearReadingLastReading();
                                this.setState(
                                    {mDMBPEstate: estateList
                                        .filter(estate => {
                                            return estate.id === e.target.value
                                        })
                                        .reduce((accumulator, currentValue) => currentValue || accumulator, {mDMEstate: {mDMMeterPointList: []}}),
                                    mDMMeterPoint: {id: ''},
                                    meter: {id: ''},
                                    type: '',
                                },
                                () => {
                                    this.props.onChange(this.state)
                                })
                            }
                            }>
                                {!this.props.formData.mDMBPEstate && <option value="">---</option>}
                                {estateList.map(estate => {
                                    const exp = "$replace($replace($eval($).(door & ', ' & street & ', ' & countryCode & ', ' & houseNumberAdd & ', ' & streetType & ', ' & dependentStreet & ', ' & doubleDependentCity & ', ' & dependentCity & ', ' & city & ', ' & region & ', ' & postalCode),'null, ',''),', null','')";
                                    const localExpr = jsonata(exp)
                                    const addressValue = localExpr.evaluate(estate.site.address)
                                    return <option value={estate.id}>{addressValue}</option>
                                })}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label>{i18n.t('readings.meterPoint')}:&nbsp;</label>
                            <Input required type="select" value={this.state.mDMMeterPoint.id} onChange={(e) => {
                                const meterPoint = this.filterMeterPoints(e.target.value);
                                this.clearReadingLastReading();
                                this.setState({
                                    mDMMeterPoint: meterPoint.length > 0 ? meterPoint[0] : {id: ''},
                                    meter: {id: ''},
                                    type: '',
                                },
                                () => {
                                    this.props.onChange(this.state)
                                })
                            }}>
                                <option value="">---</option>
                                {this.state.mDMBPEstate.site && this.state.mDMBPEstate.site.meterPoints.map(meterPoint => <option
                                    value={meterPoint.id}>{meterPoint.identifier}</option>)}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label>{i18n.t('readings.meter')}:&nbsp;</label>
                            <Input required type="select" value={this.state.meter.id}
                                   onChange={(e) => {
                                       this.clearReadingLastReading();
                                       this.setState({
                                            meter: this.state.mDMMeterPoint.registers.filter(({meter}) => meter.id === e.target.value).reduce((accumulator, currentValue) => currentValue || accumulator, {meter: ''}).meter,
                                            type: '',
                                       },
                                       () => {
                                            this.props.onChange(this.state)
                                       })
                                   }}>
                                <option value="">---</option>
                                {this.state.mDMMeterPoint.registers && this.state.mDMMeterPoint.registers
                                    .map(({meter}) => meter)
                                    .filter((meter, index, self) => {
                                        return self.findIndex(m => m.id === meter.id) === index;
                                    })
                                    .map(meter => <option value={meter.id}>{meter.serialNumber}</option>)
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label>{i18n.t('readings.type')}:&nbsp;</label>
                            <Input required type="select" disabled={this.props.formData.mDMBPEstate ? false : true} value={this.state.type}
                                   onChange={(e) => {
                                       this.clearReadingLastReading();
                                           this.setState({
                                               type: e.target.value
                                           },
                                           () => this.props.onChange(this.state))
                                   }}>
                                <option value="">---</option>
                                <option value="COU_C">Customer</option>
                                <option value="COU_E">Estimated</option>
                            </Input>
                        </FormGroup>
                        {(this.state.type !== "COU_E" && this.state.type !== '' && this.state.mDMMeterPoint.registers) && 
                            this.state.mDMMeterPoint.registers.filter(({meter}) => meter.id === this.state.meter.id).map(mDMMeterRegister => {

                            const lastReading = mDMMeterRegister.readings
                                .filter(reading => (reading.type === "C" || reading.type === "I"))
                                .map(reading => ({
                                    ...reading,
                                    readingdate: moment(reading.readingdate, "YYYY-MM-DDTHH:mm:ssZ")
                                }))
                                .sort((a, b) => b.readingdate.isBefore(a.readingdate) ? -1 : 1)[0];
                            return (<FormGroup>
                                <label>{mDMMeterRegister.rate}:&nbsp;</label><br/>
                                <div className="final-reading-box">
                                    <span className="final-reading"><label>Final reading</label></span>
                                    <MaskInput value={this.state['reading' + mDMMeterRegister.id]}
                                               onChange={(e) => this.setState({
                                                   ['reading' + mDMMeterRegister.id]: e.target.value,
                                                   ['lastReading' + mDMMeterRegister.id]: lastReading || null
                                               }, () => {this.props.onChange(this.state)})}
                                               alwaysShowMask
                                               maskChar={"0"}
                                               mask={"0".repeat(mDMMeterRegister.digits)}
                                               placeholder="20491"
                                               className={'reading-input'}
                                               style={{width: mDMMeterRegister.digits * 24.2, paddingRight: '5px'}}
                                    />
                                </div>
                            </FormGroup>)
                        })}
                    </div>);
                }}
            </Query>)
    }

}

const schema = {
    title: "",
    type: "object",
    required: [
        "moveOutDate",
        "moveOutReason",
        "reading",
    ],
    properties: {
        moveOutDate: {
            type: "string",
            title: "Move out date",
            default: moment().format('YYYY-MM-DD')
        },
        moveOutReason: {
            type: "string",
            title: "Move out reason",
        },
        reading: {
            type: "object"
        },
        helpText: {
            title: "Incoming Customer Information",
            type: "null",
        },
        customerType: {
            type: "string",
            title: "Customer Type",
            enum: ["Private", "Company"],
            enumNames: ["Domestic", "Business"],
        },
        contactEmail: {
            type: "string",
            title: "Contact Email",
        },
        contactPhone: {
            type: "number",
            title: "Phone",
        },
        description: {
            type: "string",
            title: "Notes",
        }
    },
    dependencies: {
        customerType: {
            oneOf: [
                {
                    properties: {
                        customerType: {
                            enum: ["Company"]
                        },
                        companyName: {
                            "type": "string",
                            "title": "Company Name"
                        },
                        firstName: {
                            type: "string",
                            title: "Contact First Name"
                        },
                        lastName: {
                            type: "string",
                            title: "Contact Last Name",
                        }
                    }
                },
                {
                    properties: {
                        customerType: {
                            enum: ["Private"]
                        },
                        firstName: {
                            type: "string",
                            title: "Contact First Name"
                        },
                        lastName: {
                            type: "string",
                            title: "Contact Last Name",
                        }
                    }
                }
            ]
        }
    }
}
const uiSchema= {
    moveOutDate: {
        "ui:widget": "date"
    },
    moveOutReason: {
        "ui:widget": "textarea"
    },
    description: {
        "ui:widget": "textarea"
    },
    reading: {
        "ui:field": "readingField"
    },
    helpText: {
        classNames: "helpText"
    },
    "ui:order": ["moveOutDate", "moveOutReason", "reading", "customerTitle", "helpText", "customerType", "companyName", "firstName", "lastName", "contactPhone", "contactEmail", "description"]
}

class MovingOut extends Component {

    constructor(arg) {
        super(arg);
        this.state = {
            buttonStyle: {visibility: "hidden", isLoading: false, period: null}
        }
        this.clearFields = this.clearFields.bind(this);
    }

    clearFields() {
        this.setState({
            buttonStyle: this.state.buttonStyle,
            formContext: {
                moveOutDate: this.state.formContext.moveOutDate,
                reading: {}
            }
        })
    }

    translate(obj) {

        if (obj && typeof obj === 'object' && !Array.isArray(obj)) {

            if (!obj.props) {

                for (let [key, value] of Object.entries(obj)) {

                    if (typeof value === "object") {
                        this.translate(value);
                    } else if (typeof value === "array") {

                        if (key === "enumNames") {
                            obj[key] = value.map(e => i18n.t(e));
                        } else {
                            obj[key] = value.map(e => this.translate(e));
                        }
                    } else if (typeof value === "string") {
                        if (key === "title" || key === "description") obj[key] = i18n.t(obj[key]);
                    }
                }
            }

        } else if (obj && Array.isArray(obj)) {

            obj.forEach((value, key) => {
                if (typeof value === 'object' && !Array.isArray(value)) {
                    this.translate(value);
                } else if (Array.isArray(value)) {

                    if (key === "enumNames") {
                        obj[key] = value.map(e => i18n.t(e));
                    } else {
                        obj[key] = value.map(e => this.translate(e));
                    }
                } else if (typeof value === "string") {
                    if (key === "title" || key === "description") obj[key] = i18n.t(obj[key]);
                }
            });
        }

        return obj;
    }

    render() {
        const translatedSchema = this.translate(schema);
        const loadingIcon = this.state.isLoading ? (<span><i style={this.state.buttonStyle} className='fa fa-circle-o-notch fa-spin'></i> </span>) : null;

            return (
                <div>
                <Row className='subheader'>
                    <Col xs={6} md={8}>
                        <h1>Moving Out</h1>
                    </Col>
                    <Col xs={6} md={4}>
                        <Breadcrumb>
                            <BreadcrumbItem><i className="icon ion-android-home"></i><a href="#">Home</a></BreadcrumbItem>
                            <BreadcrumbItem active>Moving Out</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </Row>
                <div className="page-cnt col-12 col-md-6">
                <Form schema={translatedSchema}
                      uiSchema={uiSchema}
                          fields={{readingField: ReadingFromEssence}}
                          formContext={this.state.formContext || {}}
                          formData={this.state.formContext || {}}
                          onSubmit={({formData}) => {
                              this.setState({
                                  buttonStyle: {visibility: "visible"},
                                  isLoading: true,
                                  period: formData.period
                              });
                              const { companyName, customerType, contactEmail,
                                      firstName, lastName, moveOutDate,
                                      moveOutReason, description, contactPhone, reading } = formData;
                              const transformedData = {
                                  moveOutDate: moveOutDate,
                                  moveOutReason: moveOutReason,
                                  newTenant: {
                                      phone: contactPhone,
                                      email: contactEmail,
                                      notes: description,
                                      customerType: customerType ? customerType.toLowerCase() : "",
                                      companyName: companyName,
                                      firstName: firstName,
                                      lastName: lastName
                                  },
                                  bpEstate: {
                                      meters: [
                                          {
                                              meterId: reading.meter.id,
                                              productKey: reading.mDMMeterPoint.purpose,
                                              finalReading: {
                                                    date: (new Date()).toISOString(),
                                                    readingValue: reading["reading" + reading.mDMMeterPoint.registers[0].id],
                                                    meterReadingType: reading.type || "COU_C"
                                                }
                                          }
                                      ],
                                      bpEstateNumber: reading.mDMBPEstate.metadata.consumerNumber
                                  },
                                  starter: window.config.consul.COT_TASKS_ASSIGNEE || "admin@methodia.com",
                                  properties: reading,

                              }
                              axios.post("/movingOut", transformedData, {
                                  headers: {
                                      Authorization: `Bearer ${localStorage.token}`
                                  }}).then(response => {
                                  this.setState({
                                      buttonStyle: {visibility: "hidden", isLoading: false},
                                      isLoading: false
                                  });
                                  if (response.data === "Message sent") {
                                      this.clearFields();

                                      ReactDOM.render(<ExpiringAlert color="success"
                                                                     message={i18n.t('processes.successfulProcessStart')}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                  } else if (response.data === 'Access denied!') {
                                      ReactDOM.render(<ExpiringAlert color="danger"
                                                                     message={response.data}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                  }
                              })
                          }}

                          onChange={({formData}, e) => {
                              this.setState({formContext: formData})
                          }}>
                <div>
                    <Button type="submit" disabled={this.state.isLoading} color='primary'>{loadingIcon}Submit</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button type="button" color="btn secondary" onClick={() => {this.clearFields()}}>Form Reset</Button>
                </div>
            </Form>
            </div>
            </div>
        );
    }
}

export default MovingOut;