import React, {Component} from 'reactn';
import ReactDOM from 'react-dom';
import {withRouter} from 'react-router';
import i18n from "../../views/Pages/Login/i18n";
import {
    Button,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Popover,
    PopoverBody,
} from "reactstrap";
import {
    agreementsQuery,
    getAgreementStatusesQuery,
    getAgreementStatusFlowFromQuery,
    setAgreementStatusMutation,
    updateAgreementStartDateQuery
} from "../../queries/Queries";
import {ApolloConsumer, ApolloProvider, Mutation, Query} from "react-apollo";
import axios from "../../utils/Client";
import PaginatedQueryDataGrid from "../PaginatedQueryDataGrid";
import ExpiringAlert from "../ExpiringAlert";
import moment from "moment";
import {InMemoryCache} from "apollo-cache-inmemory";
import {setContext} from "apollo-link-context";
import {ApolloClient} from "apollo-client";
import {createHttpLink} from 'apollo-link-http';

//---------------------------------------------------------

class NoAgreements extends Component {
    constructor(props) {
        super(props)
        // if (document.getElementById('filters-Tariffs')) {
        //     document.getElementById('filters-Tariffs').remove();
        // }
    }

    render() {
        return (
            <div className="nosalesagent">
                <i className="icon ion-android-bookmark"></i>
                <p className="text">There are no Agreements uploaded yet.</p>
            </div>
        );
    }
}

//---------------------------------------------------------
const httpLink = createHttpLink({
    uri: `${(window.config.consul.GRAPHQL_URL || (typeof GRAPHQL_URL !== 'undefined' ? GRAPHQL_URL : '' ))}/graphql`,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.token;
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : null,
        }
    }
});

// Inserting additional Apollo client to make query mutations and refetching of certain queries afterwards possible
const agreementsClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
        addTypename: true}),
    shouldBatch: true,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
            errorPolicy: 'ignore',
        },
    }
});

class AgreementsGrid extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            userSearch: '',
            enabledSearch: '',
            organizationId: props.organizationId,
            username: props.username,
            agreementStartDate: moment().add(15, 'd').format('YYYY-MM-DD') + "T00:00:00Z",
            popoverOpen: false,
        };
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    toggleChangeStartDate = () => {
        this.setState({changeStartDateOpen: !this.state.changeStartDateOpen})
    }

    toggleModal = () => {
        this.setState({modalOpen: !this.state.modalOpen})
    }

    toggleReasonModal = (statusToSet, setAgreementStatus, agreementId) => {
        this.setState({
            reasonModalOpen: !this.state.reasonModalOpen,
            statusToSet: statusToSet,
            setAgreementStatus,
            agreementId
        })
    }

    toggleUploadModal = (statusToSet, setAgreementStatus, agreementId, processInstanceId) => {
        this.setState({
            uploadModalOpen: !this.state.uploadModalOpen,
            statusToSet,
            setAgreementStatus,
            agreementId,
            processInstanceId
        })
    }

    updateStatus = (statusToSet, setAgreementStatus, agreementId, processInstanceId) => {
        agreementsClient.mutate({
            mutation: setAgreementStatusMutation,
            variables: {
                agreementId: agreementId,
                agreementStatus: {key: statusToSet.key}
            },
            refetchQueries: [
                { query: getAgreementStatusFlowFromQuery, variables: { agreement: agreementId } },
                { query: agreementsQuery },
                { query: getAgreementStatusesQuery }
            ]
        });
    }

    render() {
        const agreementNumberCol = {
            dataField: 'extraData',
            text: i18n.t('agreement.number'),
            // sort: true,
            formatter: (cell, row) => {
                return JSON.parse(cell).documentNumber;
            }
        };
        const dateCol = {
            dataField: 'date',
            text: i18n.t('agreement.creationDate'),
            sort: true,
            formatter: (cell, row) => new Date(cell.time).toLocaleDateString()
        };
        const clientNameCol = {
            dataField: 'clientName',
            text: i18n.t('agreement.clientName'),
            sort: true
        };
        const ownerOrganizationNameCol = {
            dataField: 'ownerOrganizationName',
            text: i18n.t('agreement.salesOrganization'),
            sort: true,

        };
        const subOwnerOrganizationCol = {
            dataField: 'subOwnerOrganizationName',
            text: i18n.t('agreement.associateOrganization'),
            sort: true,
            hidden: !this.global.permissions.includes('Quotes.ViewSubbrokerColumn')
        };
        const postalCodeCol = {
            dataField: 'extraData',
            text: i18n.t('agreement.postalCode'),
            // sort: true,
            formatter: (cell, row) => {
                return JSON.parse(cell).supplyAddress.postalCode
            }
        };
        const meterPointCol = {
            dataField: 'extraData',
            text: i18n.t('agreement.meterPoint'),
            // sort: true,
            formatter: (cell, row) => {
                return JSON.parse(cell).meterPoint
            }
        };
        const tariffCol = {
            dataField: 'tariff',
            text: i18n.t('agreement.tariffName'),
            sort: true
        };
        const productsCol = {
            dataField: 'products',
            text: i18n.t('agreement.products'),
            formatter: (cell, row) => {
                return row.products.map(e => e.name === 'Gas' ? 'Contract for Gas' : e.name).join(', ')
            }
        };
        const contractDurationCol = {
            dataField: 'extraData',
            text: i18n.t('agreement.contractDuration'),
            // sort: true,
            formatter: (cell, row) => {
                return JSON.parse(cell).contractDuration
            }
        };
        const aqCol = {
            dataField: 'extraData',
            text: i18n.t('agreement.aq'),
            // sort: true,
            formatter: (cell, row) => {
                return JSON.parse(cell).aq
            }
        };
        const statusCol = {
            dataField: 'status.name',
            text: i18n.t('agreement.status'),
            sort: true,
            formatter: cell => i18n.t(cell)
        };
        const startDateCol = {
            dataField: 'startDate',
            text: i18n.t('agreement.startDate'),
            sort: true,
            // editable: (content, row, rowIndex, columnIndex) => content > 2101,
            formatter: (cell, row) => {
                return cell ? new Date(cell).toLocaleDateString() : null;
            }
        };
        const endDateCol = {
            dataField: 'endDate',
            text: i18n.t('agreement.endDate'),
            sort: true,
            formatter: (cell, row) => {
                return cell ? new Date(cell).toLocaleDateString() : null;
            }
        };
        const columns = (window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) ? [
            dateCol,
            clientNameCol,
            ownerOrganizationNameCol,
            subOwnerOrganizationCol,
            meterPointCol,
            productsCol,
            contractDurationCol,
            aqCol,
            statusCol,
            startDateCol
        ] : [
            agreementNumberCol,
            dateCol,
            clientNameCol,
            ownerOrganizationNameCol,
            subOwnerOrganizationCol,
            postalCodeCol,
            meterPointCol,
            tariffCol,
            productsCol,
            statusCol,
            startDateCol,
            endDateCol
        ];
        return (
            <ApolloProvider client={agreementsClient}>
            <ApolloConsumer>
                {client => {
                    return <div><Mutation mutation={setAgreementStatusMutation}>
                    {(setAgreementStatus) => (<div><Query query={getAgreementStatusesQuery}>
                            {(result) => <PaginatedQueryDataGrid dataPath={'getAgreements.content'}
                                                                 paginationPath={'getAgreements'} keyField={'id'}
                                                                 serverSidePaging={true}
                                                                 query={agreementsQuery}
                                                                 variables={{
                                                                     organizationId: this.props.organizationId,
                                                                     username: this.props.username
                                                                 }}
                                                                 columns={columns}
                                                                 menuItems={async (obj) => {
                                                                     const {data} = await client.query({
                                                                         query: getAgreementStatusFlowFromQuery,
                                                                         variables: {
                                                                             agreement: obj.id
                                                                         }
                                                                     });
                                                                     return [
                                                                         ...(
                                                                         obj.status.key === "DRAFT"
                                                                         && !(moment(obj.startDate).add(15, 'd').format('YYYY-MM-DD') >= moment().add(15, 'd').format('YYYY-MM-DD'))
                                                                             ? [{
                                                                                 name: i18n.t('Edit Start Date'),
                                                                                 callback: (obj) => {
                                                                                     this.setState({
                                                                                         agreementId: obj.id,
                                                                                     });
                                                                                     this.toggleChangeStartDate();
                                                                                     return Promise.resolve('done');
                                                                                 }
                                                                             }]
                                                                             : []
                                                                     ),
                                                                         ...data.getAgreementStatusFlowFrom.map(({to}) => ({
                                                                         name: i18n.t(to.action), callback: () => {
                                                                             if (to.key === "SIGNED") {
                                                                                 this.toggleUploadModal(to, setAgreementStatus, obj.id, obj.processInstanceId);
                                                                                 const promise = new Promise((resolve, reject) => {
                                                                                     this.setState({statusPromiseResolve: resolve});
                                                                                 });

                                                                                 return promise;
                                                                             } else if (!to.requireReason) {
                                                                                         this.updateStatus(to, setAgreementStatus, obj.id);
                                                                                         return new Promise((resolve, reject) => {
                                                                                             this.setState({statusPromiseResolve: resolve});
                                                                                         });
                                                                             } else {
                                                                                 this.toggleReasonModal(to, setAgreementStatus, obj.id);
                                                                                 return new Promise((resolve, reject) => {
                                                                                     this.setState({statusPromiseResolve: resolve});
                                                                                 });
                                                                             }
                                                                         }
                                                                     })), {
                                                                             name: i18n.t('agreementStatus.detailsAction'),
                                                                             callback: (obj) => {
                                                                                 if (obj.obId) {
                                                                                     this.props.history.push('/agreements-old/' + obj.obId);
                                                                                 } else {

                                                                                     this.props.history.push('/agreements/' + obj.id);
                                                                                 }
                                                                                 return Promise.resolve('done');
                                                                             }
                                                                         },
                                                                         ...(obj.documentNumber === null ? [{
                                                                             name: i18n.t('agreementStatus.printAction'),
                                                                             callback: (obj) => {
                                                                                 return axios.get(`${(window.config.consul.SALESPORTAL_URL || (typeof SALESPORTAL_URL !== 'undefined' ? SALESPORTAL_URL : '' ))}/contractPrint/${obj.id}`, {
                                                                                     headers: {'Authorization': "Bearer " + localStorage.token},
                                                                                     responseType: 'blob'
                                                                                 }).then((response) => {
                                                                                     fileDownload(response.data, 'Contract ' + obj.clientName + '.pdf');
                                                                                 })
                                                                             }
                                                                         }] : [])
                                                                     ];


                                                                 }}
                                                                 noDataView={<NoAgreements/>}

                                                                 defaultSorted={{
                                                                     dataField: 'date',
                                                                     order: 'desc'
                                                                 }}
                                                                 filters={[
                                                                     {
                                                                         type: 'text',
                                                                         fields: ['extraData.supplyAddress', 'extraData.documentNumber', 'clientName', 'ownerOrganizationName', 'subOwnerOrganizationName', 'extraData', 'extraData.products'],
                                                                         placeholder: i18n.t("agreement.searchPrompt"),
                                                                         classes: "search",
                                                                         cols: 2
                                                                     },
                                                                     {
                                                                         type: 'dropdown',
                                                                         fields: ['extraData.products'],
                                                                         placeholder: "All Products",
                                                                         possibleValues: [{key: 'Gas', value: 'Gas'}],
                                                                         classes: "all-products",
                                                                         cols: 2
                                                                     },
                                                                     {
                                                                         type: 'dropdown',
                                                                         isExactMatch: true,
                                                                         fields: ['status.key'],
                                                                         possibleValues: result.loading ? [] : result.data.getAgreementStatuses.map(({key, name}) => ({
                                                                             key,
                                                                             value: i18n.t(name)
                                                                         })),
                                                                         placeholder: "All Statuses",
                                                                         classes: "all-statuses",
                                                                         cols: 2
                                                                     },
                                                                     {
                                                                         type: 'date',
                                                                         label: 'Start',
                                                                         fields: ['startDate'],
                                                                         classes: "agreement-start",
                                                                         cols: 3
                                                                     },
                                                                     {
                                                                         type: 'date',
                                                                         label: 'End',
                                                                         fields: ['endDate'],
                                                                         classes: "agreement-end",
                                                                         cols: 3
                                                                     }
                                                                 ]}
                            />
                            }
                        </Query>
                            <Modal isOpen={this.state.changeStartDateOpen} toggle={this.toggleChangeStartDate}>
                                <ModalHeader toggle={this.toggleChangeStartDate}>
                                    Change Start Date
                                </ModalHeader>
                                <ModalBody>
                                    <p>First possible start date: <span>{moment().add(15, 'd').format('LL')}</span></p>
                                    <label htmlFor="start-date">Select new start date:</label>
                                    <br/>
                                    <input type="date"
                                           id="start-date"
                                           onChange={event => {
                                               this.setState({
                                                   agreementStartDate: event.target.value + "T00:00:00Z"
                                               })
                                           }}
                                           value={moment(this.state.agreementStartDate).format('YYYY-MM-DD')}
                                           min={moment().add(15, 'd').format('YYYY-MM-DD')}
                                    />
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" id="submit-button" onClick={async () => {
                                        if (this.state.agreementStartDate >= moment().add(15, 'd').format('YYYY-MM-DD')){
                                            await client.mutate({
                                                mutation: updateAgreementStartDateQuery,
                                                variables: {
                                                    agreementId: this.state.agreementId,
                                                    date: this.state.agreementStartDate
                                                },
                                                refetchQueries: [
                                                    { query: agreementsQuery },
                                                    { query: getAgreementStatusesQuery },
                                                    { query: getAgreementStatusFlowFromQuery, variables: { agreement: this.state.agreementId } },
                                                    ],
                                            });
                                            this.setState({
                                                agreementStartDate: moment().add(15, 'd').format('YYYY-MM-DD'),
                                                popoverOpen: false
                                            })
                                            this.toggleChangeStartDate();
                                        } else {
                                            this.setState({
                                                popoverOpen: true
                                            })
                                        }
                                    }}>Submit</Button>{' '}
                                    <Popover placement="top" isOpen={this.state.popoverOpen} target="submit-button" toggle={this.state.popoverOpen}>
                                        <PopoverBody>Plaease insert a valid date</PopoverBody>
                                    </Popover>
                                    <Button color="secondary" onClick={() => {
                                        this.setState({
                                            agreementStartDate: moment().add(15, 'd').format('YYYY-MM-DD'),
                                            popoverOpen: false
                                        });
                                        this.toggleChangeStartDate();
                                    }}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                            <Modal isOpen={this.state.reasonModalOpen} toggle={this.toggleReasonModal}
                                   className="quote-preview-model">
                                <ModalHeader toggle={this.toggleReasonModal}>
                                    Enter reason:
                                </ModalHeader>
                                <ModalBody>
                                    <Form>
                                        <FormGroup>
                                            <Input type={"select"} value={this.state.statusReason}
                                                   onChange={(e) => this.setState({statusReason: e.target.value})}>
                                                <option/>
                                                {AGREEMENT_REJECTION_REASONS.split(",").map(reason => <option
                                                    value={reason}>{reason}</option>)}
                                            </Input>
                                        </FormGroup>
                                    </Form>

                                    <Button color='primary' onClick={() => {
                                        const {__typename, ...statusToSet} = this.state.statusToSet;
                                        this.state.setAgreementStatus({
                                            variables: {
                                                agreementId: this.state.agreementId,
                                                agreementStatus: statusToSet,
                                                // statusReason: this.state.statusReason
                                            }
                                        }).then(result => this.state.statusPromiseResolve());
                                        this.toggleReasonModal(null, null, null);
                                        this.setState({statusReason: null});
                                    }}>Submit</Button>

                                    <Button color='secondary' onClick={() => {
                                        this.toggleReasonModal(null, null, null);
                                        this.setState({statusReason: null});
                                    }}>Cancel</Button>
                                </ModalBody>
                            </Modal>
                            <Modal isOpen={this.state.uploadModalOpen} toggle={this.toggleUploadModal}
                                   className="quote-preview-model">
                                <ModalHeader toggle={this.toggleUploadModal}>
                                    Attach Signed Contract:
                                </ModalHeader>
                                <ModalBody>
                                    <Form>
                                        <FormGroup>
                                            <Input type={"file"}
                                                   onChange={(e) => this.setState({file: e.target.files[0]})}>
                                            </Input>
                                        </FormGroup>
                                    </Form>

                                    <Button color='primary' onClick={() => {
                                        if (this.state.file) {
                                            const data = new FormData();
                                            data.append('file', this.state.file);
                                            axios.post((window.config.consul.SALESPORTAL_URL || (typeof SALESPORTAL_URL !== 'undefined' ? SALESPORTAL_URL : '' )) + "/attachment/" + this.state.processInstanceId + "/CONTRACT",
                                                data,
                                                {headers: {'Authorization': "Bearer " + localStorage.token}}).then(
                                                () => {
                                                    const {__typename, ...statusToSet} = this.state.statusToSet;
                                                    this.state.setAgreementStatus({
                                                        variables: {
                                                            agreementId: this.state.agreementId,
                                                            agreementStatus: statusToSet,
                                                            // statusReason: this.state.statusReason
                                                        }
                                                    }).then(result => this.state.statusPromiseResolve());
                                                    this.toggleUploadModal(null, null, null);
                                                    this.setState({statusReason: null});
                                                }
                                            ).catch((error) => {
                                                ReactDOM.render(<ExpiringAlert color="danger"
                                                                               message={error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                                console.log('There was an error uploading the file', error.message);
                                            });
                                        } else {
                                            const {__typename, ...statusToSet} = this.state.statusToSet;
                                            this.state.setAgreementStatus({
                                                variables: {
                                                    agreementId: this.state.agreementId,
                                                    agreementStatus: statusToSet,
                                                    // statusReason: this.state.statusReason
                                                }
                                            }).then(result => this.state.statusPromiseResolve()).catch((error) => {
                                                ReactDOM.render(<ExpiringAlert color="danger"
                                                                               message={error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                                console.log('There was an error changing Agreement status', error.message);
                                            });
                                            ;
                                            this.toggleUploadModal();
                                            this.setState({statusReason: null});
                                        }
                                    }}>Mark Contract As Signed</Button>

                                    <Button color='secondary' onClick={() => {
                                        this.toggleUploadModal();
                                        this.setState({statusReason: null});
                                    }}>Cancel</Button>
                                </ModalBody>
                            </Modal>
                        </div>
                    )}
                </Mutation>
                </div>}}
            </ApolloConsumer>
            </ApolloProvider>
        )
    }
}

export default withRouter(AgreementsGrid);
