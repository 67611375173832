import { createStore, combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

/**
 * Reducer that handle refetch of queries after an action
 * has been performed.
 * TODO: move out to separate file once it gets big enough
 * @param {Object} state
 * @param {Object} action
 * @returns state
 */
const refetchQueriesReducer = (
	state = { refetchNominationsQuery: false },
	action
) => {
	switch (action.type) {
		case "TOGGLE_REFETCH_NOMINATIONS_QUERY":
			return {
				...state,
				refetchNominationsQuery: !state.refetchNominationsQuery,
			};
		default:
			return state;
	}
};

export const createReducer = (asyncReducers = {}) =>
	combineReducers({
		form: formReducer,
		refetchQueries: refetchQueriesReducer,
		...asyncReducers,
	});

export default function configureStore() {
	const store = createStore(
		createReducer(),
		window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
	);
	store.asyncReducers = {};
	return store;
}

export const injectAsyncReducer = (store, name, asyncReducer) => {
	store.asyncReducers[name] = asyncReducer;
	store.replaceReducer(createReducer(store.asyncReducers));
};
