{/*Created by Geargi Staykov 15.11.2022*/}
import React, {useEffect, useState} from 'react';
import ReactDOM from "react-dom";
import {Query, withApollo} from "react-apollo";
import BootstrapTable from 'react-bootstrap-table-next';
import fileDownload from "js-file-download";
import axios from "axios";
import moment from "moment";
import {curveComparison, shortCurveComparison} from "../../../../queries/Queries";
import Loading from "../../../../components/Loading";
import ExpiringAlert from "../../../../components/ExpiringAlert";


const ComparisonGrid = (props, fromDNOcomparison) => {
    const [variables, setVariables] = useState({});
    const [loading, setLoading] = useState(false);

    const gridHeader = (data) => {
        let MPANvalue = null;
        let CounterpartyValue = null;
        let Date = null;
        const length = Object.keys(data).length - 1;

        return Object.values(data).map((el, i) => {
            if (el.name === 'MPAN' && el.value !== '') MPANvalue = el.value;
            if (el.name === 'Counterparty' && el.value !== '') CounterpartyValue = el.value;
            if (el.name === 'Date') Date = el.date.format('YYYY-MM-DD');

            if (length === i) {
                if (!MPANvalue) return (
                    <div key={i}>
                        <p>{CounterpartyValue}</p>
                        <p>{Date}</p>
                        <p className='comparison-grid-download' onClick={() => downloadCSV()}>HH Consumption <i className='ion-android-download'/></p>
                    </div>
                );
                if (!CounterpartyValue) return (
                    <div key={i}>
                        <p>{MPANvalue}</p>
                        <p>{Date}</p>
                        <p className='comparison-grid-download' onClick={() => downloadCSV()}>HH Consumption <i className='ion-android-download'/></p>
                    </div>
                );
                if (MPANvalue && CounterpartyValue) return (
                    <div key={i}>
                        <p>{CounterpartyValue}</p>
                        <p>{MPANvalue}</p>
                        <p>{Date}</p>
                        <p className='comparison-grid-download' onClick={() => downloadCSV()}>HH Consumption <i className='ion-android-download'/></p>
                    </div>
                );
            }
        })
    }

    const downloadCSV = () => {
        let reportParams = "layout=consumptionReport";
        let submittedParams = {
            mpan: props.state[1].value ? props.state[1].value : '',
            startDate: new Date(props.state[4].value1) > new Date(props.state[4].value2) ? props.state[4].value1 : props.state[4].value2,
            endDate: !!props.state[4].value2
                ? new Date(props.state[4].value2) < new Date(props.state[4].value1) ? props.state[4].value2 : props.state[4].value1
                : props.state[4].value1
        };

        for (let key in submittedParams) {
            if (submittedParams[key].includes('&')) {
                let re = new RegExp('&', 'g');
                submittedParams[key] = submittedParams[key].replace(re, '%26');
            }
            reportParams += '&' + key + '=' + submittedParams[key];
        }
        reportParams += '&format=' + 'csv';
        setLoading(true);

        axios.get(`core/report/HH Consumption?${reportParams}`, {
            headers: {'Authorization': "Bearer " + localStorage.token},
            responseType: 'blob'
        }).then((response) => {
            setLoading(false);
            fileDownload(response.data, 'HH Consumption' + moment(new Date()).format('YYYYMMDD-HHmm') + '.' + 'csv');
        }).catch((error) => {
            setLoading(false);
            ReactDOM.render(<ExpiringAlert color="danger"
                                           message={'There was an error executing the report'}/>, document.getElementById('alert'));
        });
    }

    useEffect(() => {
        setVariables({
            meter_point_identifier: props.state[1].value ? props.state[1].value : null,
            valuedate: props.state[2].date.format('YYYY-MM-DD'),
            sr: props.state[3].value1,
            ...!props.shortQuery && {other_sr: props.state[3].value2},
            version: props.state[4].value1,
            ...!props.shortQuery && {other_version: props.state[4].value2},
            counterparty_id: props.state[5].useForSearch ? props.state[5].counterparty_id : null,
        })
    }, [props.state])

    return (
        <Query query={props.shortQuery ? shortCurveComparison : curveComparison} variables={variables}>
            {({data, loading, error}) => {
                if (loading) return <Loading/>;

                return (
                    <div className='comparison-grid'>
                        <BootstrapTable
                            classes={`text-left align-middle`}
                            headerClasses="th-expand"
                            wrapperClasses="table-responsive"
                            keyField={'id'}
                            noDataIndication={() => <div>No records in table</div>}
                            loading={true}
                            bordered={false}
                            hover
                            data={props.shortQuery ? data.meter_point_curves : data.curve_comparison_ex}
                            columns={[
                                {
                                    dataField: 'curve_name',
                                    text: gridHeader(props.state),
                                },
                                {
                                    dataField: 'value',
                                    text: (
                                        <div>
                                            <p><span style={{marginBottom: '0', fontSize: '1.1em'}}></span>
                                                <span>{variables.sr}</span></p>
                                            <p><span style={{marginBottom: '0', fontSize: '1.1em'}}></span>
                                                <span>{variables.version}</span></p>
                                        </div>
                                    )
                                },
                                {
                                    dataField: 'other_value',
                                    text: (
                                        <div>
                                            <p>{variables.other_sr}</p>
                                            <p>{variables.other_version}</p>
                                        </div>
                                    )
                                },
                                {
                                    dataField: 'diff',
                                    text: (!props.shortQuery && 'Differences'),
                                    style: (cell, row) => {
                                        if (row.diff < 0) return {background: '#d9fcee'}
                                        if (row.diff > 0) return {background: '#fae1f6'}
                                    }
                                }
                            ]}
                        />
                    </div>
                )
            }}
        </Query>
    )
}

export default withApollo(ComparisonGrid);