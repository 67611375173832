import React, {Component, resetGlobal} from 'reactn';
import {Redirect} from 'react-router-dom';
import {withApollo} from 'react-apollo';
import axios from "../../../utils/Client";
//import EventLogger from "../../../components/EventLogger";

class Logout extends Component {
    componentWillMount() {
        try {
            const token = localStorage.getItem('token');
            const d = new Date();
            let ms = d.getUTCMilliseconds();
            const isExpired = JSON.parse(atob(token.split('.')[1]));
            if (isExpired.exp * 1000 < ms)
                axios.delete('/oauth/spring-security-oauth-server/token/current', {headers: {'Authorization': 'Bearer ' + localStorage.token}});
        } finally {
            //EventLogger(this.global.user.username, "LOGOUT");

            document.cookie = "token=false;expires=" + (new Date()).toUTCString() + ";path=/";
            delete localStorage.token;
            resetGlobal();
            this.props.client.resetStore();
            delete localStorage.org;
        }
    }

    render() {
        return (<Redirect to={{
            pathname: '/login',
        }}/>);
    }
}

export default withApollo(Logout);