import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Col, Label, Row} from 'reactstrap';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from "react-accessible-accordion";
import TariffOverview from "../TariffOverview/TariffOverview";
import {withRouter} from "react-router-dom";
import AddressOverview from "../AddressOverview/AddressOverview";
import i18n from "../../views/Pages/Login/i18n";


class QuoteOverview extends Component {
  constructor(props) {
      super(props);
      this.state = {quote: props.quote};
  };

  render() {

          return (
              <Row>
                  <Col className='col-12'>
                      {!(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) ?
                          <Accordion className="overview" accordion={false}>
                              <AccordionItem>
                                  <AccordionItemTitle>
                                      {i18n.t('quote.companyDetails')}
                                      <i className="icon ion-chevron-down"></i>
                                  </AccordionItemTitle>
                                  <AccordionItemBody>
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Company Name</label>
                                              <p>{this.state.quote.clientName}</p>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label class="">Reference Number</label>
                                              <p>{this.state.quote.companyNumber}</p>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>The client agreed to provide their data</label>
                                              <p>{this.state.quote.dataAgreement ? 'Yes' : 'No'}</p>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Quote Type</label>
                                              <p style={{textTransform: "capitalize"}}>{this.state.quote.quotetype}</p>
                                          </Col>
                                      </Row>
                                      {(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && <Row>
                                          <Col className="col-sm-6">
                                              <label>Supply address</label>
                                              <AddressOverview address={this.state.quote.supplyAddress}/>
                                          </Col>
                                      </Row>
                                      }
                                  </AccordionItemBody>
                              </AccordionItem>
                              {!(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) &&
                              <AccordionItem>
                                  <AccordionItemTitle>
                                      Supply Address
                                      <i className="icon ion-chevron-down"></i>
                                  </AccordionItemTitle>
                                  <AccordionItemBody>
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Supply address</label>
                                              <AddressOverview address={this.state.quote.supplyAddress}/>
                                          </Col>
                                      </Row>
                                  </AccordionItemBody>
                              </AccordionItem>
                              }
                              <AccordionItem>
                                  <AccordionItemTitle>
                                      Selected Services
                                      <i className="icon ion-chevron-down"></i>
                                  </AccordionItemTitle>
                                  <AccordionItemBody>
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Selected Services:</label>
                                              <p style={{textTransform: "capitalize"}}>{this.state.quote.services}</p>
                                          </Col>
                                      </Row>
                                      {this.state.quote.services.includes('gas') && <Row>
                                          <Col className="col-sm-6">
                                              <label class="">MPRN:</label>
                                              <p>{this.state.quote.mprn}</p>
                                          </Col>
                                      </Row>
                                      }
                                      {this.state.quote.services.includes('gas') && <Row>
                                          <Col className="col-sm-6">
                                              <label>Annual Quantity in kWh (AQ) for Gas:</label>
                                              <p>{this.state.quote.annualgas}</p>
                                          </Col>
                                      </Row>
                                      }
                                      {this.state.quote.services.includes('electricity') && <Row>
                                          <Col className="col-sm-6">
                                              <label>MPAN:</label>
                                              <p>{this.state.quote.mpan}</p>
                                          </Col>
                                      </Row>
                                      }
                                      {this.state.quote.services.includes('electricity') && <Row>
                                          <Col className="col-sm-6">
                                              <label>Annual Quantity in kWh (AQ) for Electricity:</label>
                                              <p>{this.state.quote.annualelectricity}</p>
                                          </Col>
                                      </Row>
                                      }
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Contract duration:</label>
                                              <p>{this.state.quote.contractDuration}</p>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Sale type:</label>
                                              <p>{this.state.quote.saleType}</p>
                                          </Col>
                                      </Row>
                                  </AccordionItemBody>
                              </AccordionItem>
                              <AccordionItem>
                                  <AccordionItemTitle>
                                      Tariff details
                                      <i className="icon ion-chevron-down"></i>
                                  </AccordionItemTitle>
                                  <AccordionItemBody>
                                      {this.state.quote.services.includes('gas') && !(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && <Row>
                                          <Col className="col-sm-6">
                                              <label>Green Gas:</label>
                                              <p>{this.state.quote.greengas ? 'Yes' : 'No'}</p>
                                          </Col>
                                      </Row>
                                      }
                                      {this.state.quote.services.includes('gas') && !(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && <Row>
                                          <Col className="col-sm-6">
                                              <label>AMR:</label>
                                              <p>{this.state.quote.amr ? 'Yes' : 'No'}</p>
                                          </Col>
                                      </Row>
                                      }
                                      {this.state.quote.services.includes('electricity') && <Row>
                                          <Col className="col-sm-6">
                                              <label>Green Energy:</label>
                                              <p>{this.state.quote.greenenergy ? 'Yes' : 'No'}</p>
                                          </Col>
                                      </Row>
                                      }
                                      <Row>
                                          <Col className="col-sm-12">
                                              <TariffOverview quote={this.state.quote}/>
                                          </Col>
                                      </Row>
                                  </AccordionItemBody>
                              </AccordionItem>
                          </Accordion>
                          :
                          <div>
                              <Card className={'overview'}>
                              <CardHeader>
                                  {i18n.t('quote.companyDetails')}
                              </CardHeader>
                              <CardBody>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Company Name</label>
                                          <p>{this.state.quote.clientName}</p>
                                      </Col>
                                      <Col className="col-sm-6">
                                          <label class="">Reference Number</label>
                                          <p>{this.state.quote.companyNumber}</p>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Quote Type</label>
                                          <p style={{textTransform: "capitalize"}}>{this.state.quote.quotetype}</p>
                                      </Col>
                                      <Col className="col-sm-6">
                                          <label>The client agreed to provide their data</label>
                                          <p>{this.state.quote.dataAgreement ? 'Yes' : 'No'}</p>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Supply address</label>
                                          <AddressOverview address={this.state.quote.supplyAddress}/>
                                      </Col>
                                  </Row>
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Selected Services:</label>
                                              <p style={{textTransform: "capitalize"}}>{this.state.quote.services}</p>
                                          </Col>
                                      </Row>
                                      {this.state.quote.services.includes('gas') && <div><Row>
                                          <Col className="col-sm-6">
                                              <label class="">MPRN:</label>
                                              <p>{this.state.quote.mprn}</p>
                                          </Col>
                                          <Col className="col-sm-6">
                                              <label>Annual Quantity in kWh (AQ) for Gas:</label>
                                              <p>{this.state.quote.annualgas}</p>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Annual Quantity in kWh (AQ) for Gas Reported by the Industry:</label>
                                              <p>{this.state.quote.annualgasIndustry}</p>
                                          </Col>
                                      </Row>
                                      </div>
                                      }
                                      {this.state.quote.services.includes('electricity') && <Row>
                                          <Col className="col-sm-6">
                                              <label>MPAN:</label>
                                              <p>{this.state.quote.mpan}</p>
                                          </Col>
                                          <Col className="col-sm-6">
                                              <label>Annual Quantity in kWh (AQ) for Electricity:</label>
                                              <p>{this.state.quote.annualelectricity}</p>
                                          </Col>
                                      </Row>
                                      }
                                      {/*<Row>*/}
                                          {/*<Col className="col-sm-6">*/}
                                              {/*<label>Contract duration:</label>*/}
                                              {/*<p>{this.state.quote.contractDuration}</p>*/}
                                          {/*</Col>*/}
                                      {/*</Row>*/}
                                      {this.state.quote.services.includes('gas') && !(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && <Row>
                                          <Col className="col-sm-6">
                                              <label>Green Gas:</label>
                                              <p>{this.state.quote.greengas ? 'Yes' : 'No'}</p>
                                          </Col>
                                      </Row>
                                      }
                                      {this.state.quote.services.includes('gas') && !(window.config.consul.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && <Row>
                                          <Col className="col-sm-6">
                                              <label>AMR:</label>
                                              <p>{this.state.quote.amr ? 'Yes' : 'No'}</p>
                                          </Col>
                                      </Row>
                                      }
                                      {this.state.quote.services.includes('electricity') && <Row>
                                          <Col className="col-sm-6">
                                              <label>Green Energy:</label>
                                              <p>{this.state.quote.greenenergy ? 'Yes' : 'No'}</p>
                                          </Col>
                                      </Row>
                                      }

                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Sale type:</label>
                                              <p>{this.state.quote.saleType}</p>
                                          </Col>
                                      </Row>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Current Supplier Contract End Date:</label>
                                          <p>{this.state.quote.endDate ? new Date(this.state.quote.endDate).toLocaleDateString() : null}</p>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Proposed Supply Start Date:</label>
                                          <p>{this.state.quote.startDate ? new Date(this.state.quote.startDate).toLocaleDateString() : null}</p>
                                      </Col>
                                  </Row>
                                      <Row>
                                          <Col className="col-sm-12">
                                              <TariffOverview quote={this.state.quote}/>
                                          </Col>
                                      </Row>
                                  </CardBody>
                              </Card>
                          </div>
                      }
                  </Col>
              </Row>
          )
  }
}

export default withRouter(QuoteOverview);