import React, {Component} from 'react';
import paginationFactory from "react-bootstrap-table2-paginator";
import {dateFormatter, periodFormatter, priceFormatter} from "../../utils/Formatters";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import i18n from "../../views/Pages/Login/i18n";
import QueryDataGrid from "../QueryDataGrid";
import axios from "../../utils/Client";


class QueryBillsTable extends Component {
    constructor(props) {
        super(props);

    }
    render() {

        const id = this.props.match.params.id;
        return (<div>
            <QueryDataGrid columns={[{
                dataField: 'documentNo',
                text: i18n.t('invoice.document'),
                sort: true
            }, {
                dataField: 'totalamount',
                text: i18n.t('invoice.amount'),
                formatter: priceFormatter,
                align: "right",
                headerAlign: "right",
                sort: true
            }, {
                dataField: 'invoiceDate',
                text: i18n.t('invoice.dateIssued'),
                formatter: dateFormatter,
                sort: true
            }, {
                dataField: 'period',
                text: i18n.t('invoice.period'),
                formatter: periodFormatter,
                sort: true
            }, {
                dataField: 'dueDate',
                text:i18n.t('invoice.dueDate'),
                formatter: dateFormatter,
                sort: true
            }, {
                dataField: 'isPaymentComplete',
                text: i18n.t('invoice.paid'),
                formatter: function(cell, row) {
                    return (
                        cell ?<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z"/></svg>: null
                    );
                }
                //sort: true
            }, {
                dataField: 'amount',
                text: i18n.t('invoice.outstanding'),
                formatter: priceFormatter,
                align: "right",
                headerAlign: "right",
                sort: true
            }, {
                dataField: 'download',
                formatter: (cell, row) => <i className={"icon ion-android-download"} style={{fontSize:"18px", color:"#0DAEFF"}} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    let win = null;
                    if (!(window.navigator && window.navigator.msSaveOrOpenBlob)) { // for IE
                        win = window.open('', '_blank');
                        win.document.write("Loading invoice...");
                    }
                    axios.get(`${(window.config.consul.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/invoice/${row.id}`, {
                        headers: {'Authorization': "Bearer " + localStorage.token},
                        responseType: 'blob'
                    }).then((response) => {
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                            window.navigator.msSaveOrOpenBlob(response.data, row.id+'.pdf');
                        } else {
                            win.location.href = URL.createObjectURL(response.data);
                            win.focus();
                        }
                    })
                }}>
                </i>
                ,
                text: i18n.t('invoice.file')
            }, {
                formatter: (cell, row) => {
                    return row.invoice.noteList.length > 0 ? <i className="icon ion-android-document" title={i18n.t("notes.notes")} style={{fontSize:"18px"}} ></i> : null
                }
            }, {
                formatter: (cell, row) => {
                    return row.invoice.attachmentList.length > 0 ? <i className="icon ion-android-attach" title={i18n.t("attachments.attachments")} style={{fontSize:"18px"}} ></i> : null
                }
            }]}
               defaultSorted={{
                   dataField: 'invoiceDate',
                   order: 'desc'
               }}
                pagination={paginationFactory({
                    paginationSize: 4,
                    pageStartIndex: 1,
                    sizePerPageList: [10]
                })}
                filters={[
                    {
                        type: "text",
                        fields: ["documentNo", "totalamount"],
                        placeholder: i18n.t("businessPartner.searchBilling"),
                        classes:'search'
                    },
                    {
                        type: "date",
                        fields: ["invoiceDate"],
                        placeholder: i18n.t("businessPartner.searchBilling"),
                        classes: 'pull-right'
                    }
                ]}
                keyField={'documentNo'}
                dataPath={'businessPartner.0.bILLInvoicesPenaltiesVList'}
                query={`
                query businessPartnerQuery {
                    businessPartner(startRow: 0, offset: 100, where: "id = '${id}'", orderBy: "name") {
                        bILLInvoicesPenaltiesVList(startRow: 0, offset: 100) {
                            id
                            organization {
                                name
                            }
                            totalPaid
                            totalamount
                            invoiceDate
                            documentNo
                            amount
                            totalPaid
                            invoice{
                                noteList{
                                    id
                                }
                                attachmentList{
                                    id
                                }
                            }
                            billingDocumentType {
                                documentName
                                documentType{
                                    name
                                }
                            }
                            currency {
                                symbol
                                iSOCode
                            }
                            flowtype
                            dueDate
                            isLicensing
                            isProform
                            isPenalty
                            isPaymentComplete
                            estate {
                                name
                                consumernumber
                            }

                            isActive
                            period {
                                id
                                creationDate
                                endingDate
                                startingDate
                            }
                            businessPartner {
                                id
                                name
                            }
                            collStatus
                            noteList{
                                id
                                note
                                creationDate
                                updated
                                createdBy{
                                    name
                                }
                                updatedBy{
                                    name
                                }
                            }
                            attachmentList{
                                id
                                name
                                creationDate
                                updated
                                text
                                createdBy{
                                    name
                                }
                                updatedBy{
                                    name
                                }
                            }
                        }
                    }
                }
            ` }

                           onSelect={row =>  this.props.history.push('/view/customer/' + this.props.match.params.id + '/bill/' + row.id)} />
        </div>

        );
    }
}

export default QueryBillsTable;