import React from 'react';
import Form from 'react-jsonschema-form';
import formExtensions from './formExtensions';
import i18n from '../../views/Pages/Login/i18n';
import axios from "../../utils/Client";
import {withApollo} from "react-apollo";
import {withRouter} from 'react-router';
import {
    completeTaskMutation,
    getTasksQuery,
    insertFormDataSfSessionMutation,
    insertSfSessionMutation,
    updateSfSessionMutation
} from "../../queries/Queries";
import Validate from "./Validate";


let uuid;

class FormA extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...props.steps[0].data, ...props.formData};

        this.validate = Validate.bind(this);
    }

    componentDidMount() {

    }

    render() {

        const {step, steps, next, previous, children, schema, uiSchema, outputVariableName, ...otherProps} = this.props;
        const numberOfSteps = steps.length;

        const addUrlParametersToFormData = () => {
            const params = this.props.location.search.slice(1).replace('&', '=');
            const paramPairs = params.split('=');
            for (let i = 0; i < paramPairs.length; i++) {
                const key = paramPairs[i];
                const value = paramPairs[++i];

                uiSchema[key] = {"ui:widget": "hidden"};
                schema.properties[key] = {"type": "string", "default": decodeURI(value)};
                if (uiSchema.hasOwnProperty('ui:order')) {
                    uiSchema['ui:order'].push(key);
                }
            }
        }

        const generateUUID = () => {
            let uuid = "";
            let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (let i = 0; i < 32; i++)
                uuid += chars.charAt(Math.floor(Math.random() * chars.length));

            return uuid;
        }

        const formattedTimestamp = () => {
            const d = new Date()
            const date = d.toISOString().split('T')[0];
            const time = d.toTimeString().split(' ')[0];
            return `${date} ${time}`
        }

        const updateUserSession = (submitted, response) => {
            const timestamp = formattedTimestamp();
            this.props.client.mutate({
                variables: {"UUID": uuid, "submitted": submitted, "response": response, "timestamp": timestamp},
                mutation: updateSfSessionMutation
            })
        }

        const trackGoogleEvents = () => {
            if (this.props.gaEvent) {
                console.log(steps.indexOf(step));
                console.log("Event " + this.props.gaEvent);
                const params = {};
                const paramPairs = this.props.gaEvent.split(',');
                for (let i = 0; i < paramPairs.length; i++) {
                    const key = paramPairs[i];
                    let value = paramPairs[++i];
                    if (i === paramPairs.length - 1) {
                        value = parseInt(value);
                    }
                    params[key] = value;
                }
                ga('send', 'event', params);
            }
        }

        const saveFormData = () => {
            if (this.props.urlParams) {
                if (steps.indexOf(step) === 0) {
                    addUrlParametersToFormData();
                }
            }
            if (this.props.uuid) {
                if (steps.indexOf(step) === 0) {
                    uuid = generateUUID(uuid);
                    this.props.client.mutate({
                        variables: {"UUID": uuid},
                        mutation: insertSfSessionMutation
                    });
                    this.state.uuid = uuid;
                }
                if (steps.indexOf(step) > 0) {
                    this.props.client.mutate({
                        variables: {"content": steps[0].data, "session_id": uuid, "step": step.id},
                        mutation: insertFormDataSfSessionMutation
                    })
                }
            }
        }

        const saveData = () => {
            saveFormData();
            trackGoogleEvents();
        }

        const translate = obj => {

            if (obj && typeof obj === 'object' && !Array.isArray(obj)) {

                if (!obj.props) {

                    for (let [key, value] of Object.entries(obj)) {

                        if (typeof value === "object") {
                            translate(value);
                        } else if (typeof value === "array") {

                            if (key === "enumNames") {
                                obj[key] = value.map(e => i18n.t(e));
                            } else {
                                obj[key] = value.map(e => translate(e));
                            }
                        } else if (typeof value === "string") {
                            if (key === "title" || key === "description") obj[key] = i18n.t(obj[key]);
                        }
                    }
                }

            } else if (obj && Array.isArray(obj)) {

                obj.forEach((value, key) => {
                    if (typeof value === 'object' && !Array.isArray(value)) {
                        translate(value);
                    } else if (Array.isArray(value)) {

                        if (key === "enumNames") {
                            obj[key] = value.map(e => i18n.t(e));
                        } else {
                            obj[key] = value.map(e => translate(e));
                        }
                    } else if (typeof value === "string") {
                        if (key === "title" || key === "description") obj[key] = i18n.t(obj[key]);
                    }
                });
            }

            return obj;
        }

        const translated = translate(schema);
        const widgets = Object.assign({}, ...Object.entries(formExtensions.widgets).map(([key, value]) => {
            return {[key]: value.bind(this)}
        }));
        return (
            <div id="sf-main">
                <Form widgets={widgets}
                      fields={formExtensions.fields}
                      schema={translated}
                      uiSchema={uiSchema}
                      showErrorList={false}
                      formData={this.state}
                      formContext={this.state}
                      noHtml5Validate={true}
                      validate={this.validate}
                      onChange={({formData}, e) => {
                          this.state = formData;
                      }}
                      {...otherProps}
                      onSubmit={({formData}, e) => {
                          console.log(uiSchema);
                          steps[0].data = {...this.state, ...formData};
                          const props = this.props;
                          next && next();
                          console.log(formData, e, props);
                          if (props.submitUrl || steps.indexOf(step) === steps.length - 1) {
                              steps[0].data.service = ["Service " + steps[0].data.service];
                              if (props.completeTask) {
                                  const vars = [{
                                      name: props.taskDetails.outputVariableName || "formData",
                                  value: JSON.stringify(steps[0].data),
                                      type: 'Json'
                                  }];
                                  props.client.mutate({
                                      variables: {
                                          task: {id: props.taskDetails.id, variables: vars},
                                          service: props.taskDetails.service
                                      },
                                      mutation: completeTaskMutation,
                                      refetchQueries: [{query: getTasksQuery}]
                                  })
                                      .then(result => {
                                          if (props.successUrl) window.location.href = props.successUrl
                                      })
                                      .catch(error => {
                                          if (props.errorUrl) window.location.href = props.errorUrl
                                      });
                              } else {
                                  axios.post(`${(window.config.consul.GRAPHQL_URL || (typeof GRAPHQL_URL !== 'undefined' ? GRAPHQL_URL : '' ))}${props.submitUrl}`, steps[0].data).then(
                                      r => {
                                          if (props.successUrl) window.location.href = props.successUrl;
                                          updateUserSession(true, r.status);
                                      }
                                  ).catch(
                                      e => {
                                          if (props.errorUrl) window.location.href = props.errorUrl;
                                          console.log(e);
                                          updateUserSession(false, e.status);
                                      }
                                  );
                              }
                          }
                          if (this.props.children) {
                              saveFormData();
                          }
                      }}
                      transformErrors={(errors) =>
                          errors.map(error => {
                              const message = i18n.t("switching-form.error-" + error.name, error.params);
                              error.message = message;

                              if (error.name === "enum" || error.name === "oneOf") {
                                  return Object.assign({}, error, {
                                      message: ""
                                  });
                              }

                              return error;
                          })
                      }
                >
                    {steps.indexOf(step) > 0 && !this.props.hideBackButton && (
                        <button className="btn btn-primary"
                                onClick={previous}>{i18n.t("switching-form.previous")}</button>)}
                    {children}
                    {!children && steps.indexOf(step) < steps.length - 1 && (
                        <button className="float-right btn btn-primary" type="submit"
                                onClick={saveData()}>{i18n.t("switching-form.next")}</button>)}
                    {(!children || numberOfSteps === 1) && steps.indexOf(step) === steps.length - 1 && (
                        <button className={this.props.btnStyle ? this.props.btnStyle : "float-right btn btn-primary"}
                                onClick={saveData()}
                                type="submit">{i18n.t("switching-form.switch")}</button>)}
                </Form>
            </div>
        )
    }
}

export default withApollo(withRouter(FormA));
