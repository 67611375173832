import React from 'reactn';
import {withApollo} from "react-apollo";
import {withRouter} from 'react-router';
import BasicQueryComponent from "../BasicQueryComponent";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import * as i18n from "i18next";


class ServiceHeader extends BasicQueryComponent {
    constructor(props) {
        super(props);

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.toggleReadingModal = this.toggleReadingModal.bind(this);
        this.toggleTaskModal = this.toggleTaskModal.bind(this);
        this.state = {
            dropdownOpen: false,
            showCreateReadingModal: false,
            showCreateTaskModal: false
        };
    }

    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    toggleReadingModal() {
        this.setState({
            showCreateReadingModal: !this.state.showCreateReadingModal
        });

    }

    toggleTaskModal() {
        this.setState({
            showCreateTaskModal: !this.state.showCreateTaskModal
        });
    }

    render() {
        return (<div>
            {!this.global.permissions.includes('Users.ReadOnly') &&
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                    {!this.props.hideQueryBtn && <DropdownToggle className='pull-right service-button' color='primary'>
                        <i className="icon ion-plus-round"></i>&nbsp;&nbsp;{i18n.t('header.new')}
                    </DropdownToggle>}
                    {this.props.items && <DropdownMenu>
                        {this.props.items.map(i => <DropdownItem onClick={() => this.props.history.push(i.path)}>{i.label}</DropdownItem>)}
                    </DropdownMenu>}
                    {!this.props.items && <DropdownMenu>
                        <DropdownItem onClick={() => this.props.history.push('/CreateReading')}>Reading</DropdownItem>
                        <DropdownItem onClick={() => this.props.history.push('/readsUpload')}>Bulk Reading</DropdownItem>
                        <DropdownItem onClick={() => this.props.history.push('/CreateTask')}>Query</DropdownItem>
                    </DropdownMenu>}
                </Dropdown>
            }
        </div>);
    }
}

export default withApollo(withRouter(ServiceHeader));
