import React from 'react'
import {post} from 'axios';
import PropTypes from "prop-types";
import i18n from "../Pages/Login/i18n";
import LoadingImage from "../../images/loading-grey-small.svg";

class FileSelector extends React.Component {
    constructor(props) {
        super(props);
        const {
            options,
            value,
            required,
            disabled,
            readonly,
            autofocus,
            onBlur,
            onFocus,
            onChange,
            formContext,
            id
        } = props;
    }


    render() {
        return (
            <SimpleReactFileUpload {...this.props} />
        );
    }
}

if (process.env.NODE_ENV !== "production") {
    FileSelector.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.shape({
            rows: PropTypes.number,
        }),
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}

class SimpleReactFileUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            fileName: i18n.t("switching-form.no-file-chosen"),
            loading: false
        };
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
        this.trackGoogleEvents = this.trackGoogleEvents.bind(this);
    }

    onFormSubmit(e) {
        e.preventDefault(); // Stop form submit
        this.fileUpload(this.state.file, this.props.uiSchema["ui:options"] && this.props.uiSchema["ui:options"].uuid && this.props.formContext.uuid).then((response) => {
            const uploadData = response.data.split(":");
            const fileName = uploadData[uploadData.length - 1];
            const urn = uploadData[uploadData.length - 2];
            this.props.onChange(JSON.parse("{\"fileName\":\"" + fileName + "\", \"urn\":\"" + urn + "\"}"));
        }).then((response) => {
            // this.setState({loading: false});
        }).catch((error) => {
            console.log(error);
        });
    }

    trackGoogleEvents() {
        console.log("Event " + this.props.schema.event);
        const params = {};
        const paramPairs = this.props.schema.gaEvent.split(',');
        for (let i = 0; i < paramPairs.length; i++) {
            const key = paramPairs[i];
            let value = paramPairs[++i];
            if(i === paramPairs.length - 1){
                value = parseInt(value);
            }
            params[key] = value;
        }
        ga('send', 'event', params);
    }

    onChange(e) {
        if (this.props.schema.gaEvent) {
            this.trackGoogleEvents();
        }

        const file = e.target.files[0];
        let fileNameToSave
        if (this.props.uiSchema["ui:options"] && this.props.uiSchema["ui:options"].fileName) {
            fileNameToSave = this.props.uiSchema["ui:options"].fileName;
        } else {
            fileNameToSave = file.name;
        }
        this.props.formData.isUploading = i18n.t("switching-form.upload-file-processing");
        this.setState({file: file, fileName: fileNameToSave, loading: true});
        this.fileUpload(file, this.props.uiSchema["ui:options"] && this.props.uiSchema["ui:options"].uuid && this.props.formContext.uuid, fileNameToSave).then((response) => {
            const uploadData = response.data.split(":");
            const fileName = uploadData[uploadData.length - 1];
            const urn = uploadData[uploadData.length - 2];
            this.props.onChange(JSON.parse("{\"fileName\":\"" + fileName + "\", \"urn\":\"" + response.data + "\"}"));
            const success = i18n.t("switching-form.upload-file-success");
            const fName = file.name + " " + success + (this.props.uiSchema.message ? (
                <h2>{this.props.uiSchema.message}</h2>) : "");
            if (this.props.uiSchema.successUrl) window.location.href = this.props.uiSchema.successUrl;
            delete this.props.formData.isUploading;
            this.setState({fileName: fName, loading: false});

        }).catch((error) => {
            console.log(error);
        });
    }

    fileUpload(file, uuid, fileName) {
        const url = `${(window.config.consul.GRAPHQL_URL || (typeof GRAPHQL_URL !== 'undefined' ? GRAPHQL_URL : '' ))}/upload`;
        const formData = new FormData();
        formData.append('file', file);
        if (uuid) formData.append('uuid', uuid);
        if (fileName) formData.append('fileName', fileName);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        return post(url, formData, config)
    }

    render() {
        console.log("this.props", this.props);
        const errorSchema = this.props.errorSchema;
        return (
            <form onSubmit={this.onFormSubmit}>
                <div style={{display: this.state.loading ? 'block': 'none'}} className="loading-img">
                    <img src={LoadingImage}/>
                </div>
                <label
                    className="col-form-label upload col-md-12">{this.props.schema.title}{this.props.required ? '*' : ''}</label>
                <div className="col-md-12">
                    <label htmlFor={this.props.name || this.props.path.join('_')} className="custom-file-upload">
                        {i18n.t("switching-form.upload-file")}
                    </label>
                    <input type="file" id={this.props.name || this.props.path.join('_')} onChange={this.onChange}/>
                    {/*<button className="btn-upload" id="upload-button" type="submit">*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="25" viewBox="0 0 48 48">*/}
                    {/*        <path d="M0 0h48v48h-48z" fill="none"/>*/}
                    {/*        <path*/}
                    {/*            d="M38.71 20.07c-1.36-6.88-7.43-12.07-14.71-12.07-5.78 0-10.79 3.28-13.3 8.07-6.01.65-10.7 5.74-10.7 11.93 0 6.63 5.37 12 12 12h26c5.52 0 10-4.48 10-10 0-5.28-4.11-9.56-9.29-9.93zm-10.71 5.93v8h-8v-8h-6l10-10 10 10h-6z"/>*/}
                    {/*    </svg>*/}
                    {/*</button>*/}
                    <label>{this.state.fileName}</label>
                </div>
                {errorSchema && errorSchema.fileName && <div>
                    <ul className="error-detail bs-callout bs-callout-info">
                        {errorSchema.fileName.__errors.map(e => <li className="text-danger">{e}</li>)}
                    </ul>
                </div>}
            </form>
        )
    }
}

export default FileSelector;