import React, { useState, useMemo } from "react";
import { Col, Row, FormGroup } from "reactstrap";
import { Query } from "react-apollo";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Loading from "../Loading/Loading";
import EditDeleteButton from "../EditDeleteButton";
import gql from "graphql-tag";
import { dateFormatter } from "../../utils/Formatters";
import { getBillingSchemaQuery } from "../../queries/Queries";

// Temp Dummy mutation for delete
const dummyDeleteMutation = gql`
  mutation {
    dummy
  }
`;

const BillingSchemaList = ({ history }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState(null);

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    setPageNumber(page);
    setPageSize(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
  };

  const columns = useMemo(
    () => [
      {
        dataField: "name",
        text: "Name",
        sort: true,
      },
      {
        dataField: "created",
        text: "Created",
        sort: true,
        formatter: (cell, row) => dateFormatter(row.created),
      },
      {
        dataField: "createdBy",
        text: "Created By",
        sort: true,
        formatter: (cell, row) => row.createdBy || " ",
      },
      {
        dataField: "updated",
        text: "Updated",
        sort: true,
        formatter: (cell, row) =>
          row.updated ? dateFormatter(row.updated) : " ",
      },
      {
        dataField: "updatedBy",
        text: "Updated By",
        sort: true,
        formatter: (cell, row) => row.updatedBy || " ",
      },
      {
        dataField: "actions",
        text: " ",
        isDummyField: true,
        formatter: (cell, row) => (
          <EditDeleteButton
            id={row.id}
            history={history}
            editUrl={`/schema-document/edit/${row.id}`}
            deleteMutation={dummyDeleteMutation}
            deleteRefetchQueries={[
              {
                query: getBillingSchemaQuery,
                variables: {
                  pageNumber: pageNumber - 1,
                  pageSize,
                  sort: {
                    orders: [{ property: sortField, direction: sortOrder }],
                  },
                },
              },
            ]}
          />
        ),
      },
    ],
    [history, pageNumber, pageSize, sortField, sortOrder, data]
  );

  return (
    <div className='manage-users'>
      <Row className='subheader'>
        <Col className='col-8'>
          <h1>Billing Details</h1>
        </Col>
        <Col className='col-4'>
          <FormGroup>
            <Link
              to={"/schema-document/create"}
              className='btn btn-primary pull-right'
            >
              <i className='icon ion-plus-round'></i>&nbsp;&nbsp;New{" "}
            </Link>
          </FormGroup>
        </Col>
      </Row>
      <div className='page-cnt'>
        <Row>
          <Query
            query={getBillingSchemaQuery}
            variables={{
              pageNumber: pageNumber - 1,
              pageSize,
              sort: { orders: [{ property: sortField, direction: sortOrder }] },
            }}
            onCompleted={(data) => {
              const newTotalItems = data.BillingSchema.totalElements;
              setTotalItems(newTotalItems);
              if (pageNumber > Math.ceil(newTotalItems / pageSize)) {
                setPageNumber(Math.ceil(newTotalItems / pageSize));
              }
              setData(data);
            }}
          >
            {({ loading, error }) => {
              if (loading) return <Loading />;
              if (error) return <p>Error</p>;

              if (
                !data ||
                !data.BillingSchema ||
                !Array.isArray(data.BillingSchema.content)
              )
                return <p>No Data</p>;

              return (
                <React.Fragment>
                  <Col>
                    <h3>Billing Schema Document</h3>
                  </Col>
                  <Col sm='12'>
                    <BootstrapTable
                      classes='table-hover'
                      wrapperClasses='table-responsive'
                      bordered={false}
                      keyField='id'
                      data={data.BillingSchema.content}
                      columns={columns}
                      remote={{
                        filter: false,
                        pagination: true,
                        sort: true,
                      }}
                      sort={{
                        dataField: sortField,
                        order: sortOrder,
                      }}
                      pagination={paginationFactory({
                        page: pageNumber,
                        sizePerPage: pageSize,
                        totalSize: totalItems,
                        sizePerPageList: [
                          { text: "5", value: 5 },
                          { text: "10", value: 10 },
                          { text: "20", value: 20 },
                        ],
                      })}
                      onTableChange={handleTableChange}
                      rowStyle={{ cursor: "pointer" }}
                      rowEvents={{
                        onClick: (e, row) => {
                          if (e.target.tagName !== "TD") return;
                          history.push(`/schema-document/${row.id}`);
                        },
                      }}
                    />
                  </Col>
                </React.Fragment>
              );
            }}
          </Query>
        </Row>
      </div>
    </div>
  );
};

export default withRouter(BillingSchemaList);
