import React from "react";
import ReactDOM from "react-dom";
import ExpiringAlert from "../components/ExpiringAlert";
import ButtonWithModal from "../components/ButtonWithModal";
import axios from "./Client";
import jsonata from "./../../node_modules/jsonata/jsonata-es5";
import fileDownload from "js-file-download";
import _ from "underscore";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import fileSaver from "file-saver";
import { isInMobileView } from './Helpers';
import { Mutation } from "react-apollo";
import GridDropDown from "../components/GridDropDown";
import GridDropDownMenu from "../components/GridDropDownMenu";
import CronFormatter from "../components/CronGenerator/CronFormatter";
import PeriodFormatter from "../components/CronGenerator/PeriodFormatter";
import gql from "graphql-tag";
import { queryTypeMapping } from "../schemas/task-dyce.schema";
import moment from "moment";


export function tickFormatter(cell) {
  return cell ? "✓" : "✗";
};

export function priceCustomFormatter(cell, row) {
  const findCurrency = function (row) {
    let result = null;
    if (row.currency) {
      result = row.currency;
    } else {
      const properties = Object.keys(row);
      for (let prop of properties) {
        if (
          !result &&
          (row[prop] instanceof Object || row[prop] instanceof Array)
        ) {
          result = findCurrency(row[prop]);
        }
      }
    }
    return result;
  };
  const currency = row ? findCurrency(row) : { iSOCode: "GBP" };
  const signDigits = this.significantDigits
    ? {
        maximumSignificantDigits: this.significantDigits,
        minimumSignificantDigits: this.significantDigits,
      }
    : {};
  return cell
    ? cell.toLocaleString(undefined, {
        style: "currency",
        currency: currency && currency.iSOCode ? currency.iSOCode : "GBP",
        ...signDigits,
      })
    : "";
}

export const calculateAmount = (cell, row, path) => {
  const amount =
    cell && typeof cell !== "string"
      ? cell
          .flatMap((el) => {
            if (el.hasOwnProperty("account")) {
              return el.account.balance.map((balance) => balance.amount);
            } else {
              return el.balance.map((balance) => balance.amount);
            }
          })
          .reduce((amount, curr) => (amount += curr), 0)
      : cell;

  return priceFormatter(amount, row);
};

export function priceFormatter(cell, row) {
  if (isNaN(Number(cell))) return cell;
  cell = Number(cell);

  const findObject = function (row, objectName) {
    let result = null;
    if (row[objectName]) {
      result = row[objectName];
    } else {
      const properties = Object.keys(row);
      for (let prop of properties) {
        if (
          !result &&
          (row[prop] instanceof Object || row[prop] instanceof Array)
        ) {
          result = findObject(row[prop], objectName);
        }
      }
    }
    return result;
  };
  const currency = row ? findObject(row, "currency") : { iSOCode: "GBP" };
  const subUnit = row ? findObject(row, "mdmCoins") : "p";
  const useSubUnit = row ? findObject(row, "isMdmDisplayCoins") : false;
  if (!useSubUnit) {
    const fractionalDigits =
      Math.floor(cell) !== cell
        ? Math.max(
            ("" + Math.round(cell * Math.pow(10, 10)) / Math.pow(10, 10)).split(
              "."
            )[1].length,
            2
          )
        : 2;

    const formattedPrice = Math.abs(cell).toLocaleString(undefined, {
      style: "currency",
      currency: currency && currency.iSOCode ? currency.iSOCode : "GBP",
      minimumFractionDigits: fractionalDigits,
    });
    return cell && cell < 0 ? `${formattedPrice} In credit` : formattedPrice;
  } else {
    return (
      (cell * Math.pow(10, currency.standardPrecision)).toFixed(2) + subUnit
    );
  }
}

export function agreementsPriceFormatter(cell, row) {
  const findObject = function (row, objectName) {
    let result = null;
    if (row[objectName]) {
      result = row[objectName];
    } else {
      const properties = Object.keys(row);
      for (let prop of properties) {
        if (
          !result &&
          (row[prop] instanceof Object || row[prop] instanceof Array)
        ) {
          result = findObject(row[prop], objectName);
        }
      }
    }
    return result;
  };
  const currency = row ? findObject(row, "currency") : { iSOCode: "GBP" };
  const subUnit = row ? findObject(row, "mdmCoins") : "p";
  const useSubUnit = row ? findObject(row, "isMdmDisplayCoins") : false;
  const isCreditNote = row
    ? findObject(row, "totalAmount") < 0
      ? true
      : false
    : false;
  const totalAmmount = isCreditNote ? -1 * cell : cell;

  if (!useSubUnit) {
    const fractionalDigits =
      totalAmmount && Math.floor(totalAmmount) !== totalAmmount
        ? Math.max(
            (
              "" +
              Math.round(totalAmmount * Math.pow(10, 10)) / Math.pow(10, 10)
            ).split(".")[1].length,
            2
          )
        : 2;
    const curr = totalAmmount
      ? totalAmmount.toLocaleString(undefined, {
          style: "currency",
          currency: currency && currency.iSOCode ? currency.iSOCode : "GBP",
          minimumFractionDigits: fractionalDigits,
        })
      : "";

    return isCreditNote ? "CR " + curr : curr;
  } else {
    const ammount =
      (totalAmmount * Math.pow(10, currency.standardPrecision)).toFixed(2) +
      subUnit;
    return isCreditNote ? "CR " + ammount : ammount;
  }
}

export function dateFormatter(cell, row, rowIndex, formatExtraData) {
  return cell
    ? new Date(cell).toLocaleDateString(window.config.consul.DEFAULT_LOCALE)
    : null;
}

export function datetimeFormatter(cell, row) {
  return cell
    ? new Date(cell).toLocaleDateString("en-GB") +
        " " +
        new Date(cell).toLocaleTimeString("en-GB")
    : null;
}

export function dateTimeFormatter(cell, row) {
  return cell ? new Date(cell).toLocaleString() : null;
}

export function momentFormatter(date) {
  return moment(date).format("YYYY/MM/DD h:mm [GMT]Z");
}

export function periodFormatter(cell, row) {
  return cell
    ? new Date(cell.startingDate).toLocaleDateString() +
        " – " +
        new Date(cell.endingDate).toLocaleDateString()
    : null;
}

export function productFormatter(cell, row) {
  return cell.map((item) => item.bOMProduct.name).join(", ");
}

export function invoiceEstateFormatter(cell, row) {
	return cell
		? cell.map((item) => item.serviceInstance.estate.consumernumber).join(", ")
		: "";
}

export function reportedAmountFormatter(cell, row) {
  if (row) {
    var reportedAmount =
      row.mDMMeterMeasurement && row.mDMMeterMeasurement.reportedamount
        ? row.mDMMeterMeasurement.reportedamount
        : 0;
    return reportedAmount + row.product.uOM.name;
  }
}

export function rolesFormatter(cell, row) {
  return row.map((contact) => contact.cRMContactRole.name).join(", ");
}

export function linkFormatter(cell, row, rowIndex, formatExtraData) {
  const compile = _.template(formatExtraData[1]);
  return <Link to={compile(row)}>{formatExtraData[0]}</Link>;
}

export function iconFormatterFlo(cell, row, rowIndex, formatExtraData) {
  //Flogas TODO
  const image = formatExtraData[0];
  let images = [];
  if (image.includes(";")) {
    images = image.split(";");
  }
  let expression = formatExtraData[2] ? cell + " " + formatExtraData[2] : "";

  const compile = _.template(formatExtraData[1]);

  let result = "";
  if (image.startsWith("<svg")) {
    if (images.length > 0) {
      eval(expression)
        ? (result = (
            <Link to={compile(row)}>
              <div style={{ height: "20px", width: "20px" }}>
                <img src={`data:image/svg+xml;utf8,${images[0]}`} />
              </div>
            </Link>
          ))
        : (result = (
            <Link to={compile(row)}>
              <div style={{ height: "20px", width: "20px" }}>
                <img src={`data:image/svg+xml;utf8,${images[1]}`} />
              </div>
            </Link>
          ));
    } else {
      result = (
        <Link to={compile(row)}>
          <div style={{ height: "20px", width: "20px" }}>
            <img src={`data:image/svg+xml;utf8,${image}`} />
          </div>
        </Link>
      );
    }
  } else if (image.endsWith(".svg")) {
    result = (
      <Link to={compile(row)}>
        <img
          style={{ height: "20px", width: "20px" }}
          src={"/img/categories/" + image}
          alt='icon'
        />
      </Link>
    );
  } else {
    result = (
      <Link to={compile(row)}>
        <img src={"/img/categories/" + image} alt='text describing the image' />
      </Link>
    );
  }
  return result;
}

export function prefCommChannelFormatter(cell, row) {
  return cell && cell.cRMCommunicationChannelList
    ? cell.cRMCommunicationChannelList
        .map((channel) => channel.preferredChannel)
        .join(", ")
    : cell;
}

export function imageFormatter(cell, row) {
  return <ButtonWithModal id={row.id} />;
}

export function noteIconFormatter(cell, row) {
  return row.noteList.length > 0 ? (
    <i className='icon ion-android-document' title={"Notes"}></i>
  ) : null;
}

export function attachmentIconFormatter(cell, row) {
  return row.attachmentList.length > 0 ? (
    <i className='icon ion-android-attach' title={"Attachments"}></i>
  ) : null;
}

export function paymentCompleteFormatter(cell, row) {
  return cell ? (
    <svg
      width='20'
      height='20'
      xmlns='http://www.w3.org/2000/svg'
      fill-rule='evenodd'
      clip-rule='evenodd'
    >
      <path d='M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z' />
    </svg>
  ) : null;
}

export function loginAsFormatter(cell, row) {
  if (row.role === "USER") {
    return (
      <a
        href={location.origin + "/#?externalQueryParameter=&login_as=" + cell}
        target='_blank'
      >
        Login as {cell}
      </a>
    );
  }
  return null;
}

export function geIconFormatter(cell, row) {
  return cell == "G" ? (
    <div style={{ display: "flex" }}>
      <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        width={20}
        height={20}
        viewBox='0 0 85 85'
      >
        <g id='Group_29_1_' transform='translate(0 0)'>
          <path
            id='Path_42_1_'
            style={{ fill: "#4E2877" }}
            d='M45.1,77.9c-1.1,0-1.9-0.9-1.9-1.9c0-0.7,0.4-1.4,1.1-1.7c3.4-1.6,5.2-5.8,6.1-10.3
                    	c1.4-7-1.8-14.9-4.5-18.9c-1.9,3.5-4,6.7-6.4,9.8c-2.5,3.4-4.9,6.6-5.8,9.7c-1.1,4-0.8,7.9,2.3,9.6c1,0.4,1.5,1.5,1.1,2.5
                        c-0.3,0.7-1,1.2-1.8,1.2c-0.1,0-0.3,0-0.4,0c-12-2.3-19.6-13.5-18.7-25.6c0.7-8.7,5.4-14.3,9.9-19.7c4.7-5.6,9.1-10.8,9.8-19.5
                        c0.1-1.5,0.1-3-0.1-4.5c-0.1-1.1,0.6-2,1.7-2.2c0.5-0.1,0.9,0,1.3,0.3c14.9,9.6,30.2,26.6,28.7,45.8c-0.9,11.8-10.1,23-22.1,25.3
	                    C45.4,77.8,45.2,77.9,45.1,77.9z M39.9,12.1c0,0.4,0,0.8-0.1,1.2C39.1,23.2,34,29.2,29.2,35c-4.3,5.2-8.4,10.1-9,17.5
                        c-0.6,7.7,3.3,15.1,10,19c-0.9-2.6-0.9-5.4-0.2-8c1.1-3.7,3.6-7.2,6.4-10.9c2.7-3.6,5.5-7.4,7.2-11.8c0.4-1,1.5-1.5,2.5-1.1
                        c0.2,0.1,0.4,0.2,0.5,0.3c3.7,3.1,9.6,14.6,7.6,24.7c-0.4,2.1-1.2,4.1-2.2,5.9c6.1-3.9,11.1-10.5,11.7-18.4
                        C65,36,52.8,21.3,39.9,12.1z'
          />
        </g>
      </svg>
      <span>Gas</span>
    </div>
  ) : (
    <div style={{ display: "flex" }}>
      <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        width={20}
        height={20}
        viewBox='0 0 85 85'
      >
        <path
          id='Path_41_2_'
          style={{ fill: "#4E2877" }}
          d='M30.2,77.9c-1,0-1.8-0.8-1.8-1.8c0-0.2,0.1-0.5,0.1-0.7l11.1-25.6H14.4c-1,0-1.8-0.8-1.8-1.8
                 	c0-0.5,0.2-0.9,0.5-1.3L52.8,7c0.7-0.7,1.9-0.7,2.6,0c0.5,0.5,0.7,1.3,0.4,2L44.6,34.6H70c1,0,1.8,0.8,1.8,1.8
                    c0,0.5-0.2,0.9-0.5,1.3L31.5,77.4C31.2,77.7,30.7,77.9,30.2,77.9z M18.7,46.2h23.8c1,0,1.8,0.8,1.8,1.8c0,0.2-0.1,0.5-0.1,0.7
                   	l-8.4,19.4l29.9-29.9H41.9c-1,0-1.8-0.8-1.8-1.8c0-0.2,0.1-0.5,0.2-0.7l8.4-19.4L18.7,46.2z'
        />
      </svg>
      <span>Electricity</span>
    </div>
  );
}

export function downloadFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <i
      className={"icon ion-android-download"}
      style={{ fontSize: "18px", color: "#0DAEFF" }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        let win = null;
        if (!(window.navigator && window.navigator.msSaveOrOpenBlob)) {
          // for IE
          win = window.open("", "_blank");
          win.document.write("Loading invoice...");
        }
        axios
          .get(
            `${
              window.config.consul.CORE_URL ||
              (typeof CORE_URL !== "undefined" ? CORE_URL : "")
            }/${formatExtraData ? formatExtraData : "invoice"}/${row.id}`,
            {
              headers: { Authorization: "Bearer " + localStorage.token },
              responseType: "blob",
            }
          )
          .then((response) => {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              // for IE
              window.navigator.msSaveOrOpenBlob(response.data, row.id + ".pdf");
            } else {
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: "application/pdf" })
              );
              fileSaver.saveAs(url, row.documentNo + ".pdf");
              // win.location.href = URL.createObjectURL(response.data);
              // win.focus();
            }
          });
      }}
    ></i>
  );
}

export function invoiceDownloadFormatter(cell, row, rowIndex, formatExtraData) {
	return (
		<i
			className={"icon ion-android-download"}
			style={{ fontSize: "18px", color: "rgb(94, 178, 198)" }}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();

				let endPoint = `/essence-services/essence-services/v1/${
					formatExtraData ? formatExtraData : "invoice"
				}/${row.id}/download`;
				let fileExtension = ".pdf";
				let fileType = "application/pdf";

				if (formatExtraData.includes("jasper")) {
					sessionStorage.setItem("jasperDownload", "");
				}

				if (formatExtraData === "jasper/backingDataReport") {
					endPoint = `${endPoint}/BackingData.xlsx`;
					fileType =
						"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
					fileExtension = ".xlsx";
				}

				axios
					.get(endPoint, {
						headers: { Authorization: "Bearer " + localStorage.token },
						responseType: "blob",
					})
					.then((response) => {
						const fileName = row.id + fileExtension;

						if (isInMobileView()) {
							const reader = new FileReader();
							reader.readAsDataURL(response.data);
							reader.onloadend = function () {
								const base64data = reader.result;
								window.nsWebViewBridge.emit("download", {
									blob: base64data,
									name: fileName,
								});
								sessionStorage.removeItem("jasperDownload");
							};
						} else {
							sessionStorage.removeItem("jasperDownload");
							if (window.navigator && window.navigator.msSaveOrOpenBlob) {
								// for IE
								window.navigator.msSaveOrOpenBlob(response.data, fileName);
							} else {
								const url = window.URL.createObjectURL(
									new Blob([response.data], { type: fileType })
								);
								fileSaver.saveAs(url, fileName);
								window.URL.revokeObjectURL(url); // Clean up the URL object
							}
						}
					})
					.catch((error) => {
						console.error("Download error:", error);
						sessionStorage.removeItem("jasperDownload");
					});
			}}
		></i>
	);
}

export function invoicePriceFormatter(cell, row) {
  const findObject = function (row, objectName) {
    let result = null;
    if (row[objectName]) {
      result = row[objectName];
    } else {
      const properties = Object.keys(row);
      for (let prop of properties) {
        if (
          !result &&
          (row[prop] instanceof Object || row[prop] instanceof Array)
        ) {
          result = findObject(row[prop], objectName);
        }
      }
    }
    return result;
  };
  const currency = row ? findObject(row, "currency") : { iSOCode: "GBP" };
  const subUnit = row ? findObject(row, "mdmCoins") : "p";
  const useSubUnit = row ? findObject(row, "isMdmDisplayCoins") : false;
  const isCreditNote = row
    ? findObject(row, "totalAmount") < 0
      ? true
      : false
    : false;
  const totalAmount = isCreditNote ? -1 * cell : cell;

  if (!useSubUnit) {
    const fractionalDigits = 2;
    const curr =
      totalAmount !== null && totalAmount !== undefined
        ? totalAmount.toLocaleString(undefined, {
            style: "currency",
            currency:
              currency && currency.iSOCode
                ? currency.iSOCode
                : typeof currency === "string" || currency instanceof String
                ? currency
                : "GBP",
            minimumFractionDigits: fractionalDigits,
            maximumFractionDigits: fractionalDigits,
          })
        : "";

    return isCreditNote ? "CR " + curr : curr;
  } else {
    const amount =
      (totalAmount * Math.pow(10, currency.standardPrecision)).toFixed(2) +
      subUnit;
    return isCreditNote ? "CR " + amount : amount;
  }
}

export function invoiceGraphQLDownloadFormatter(
  cell,
  row,
  rowIndex,
  formatExtraData
) {
  return (
    <i
      className={"icon ion-android-download"}
      style={{ fontSize: "18px", color: "#5eb2c6" }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        formatExtraData.client
          .query({
            query: gql`
              query flogasInvoiceDownload($docId: uuid) {
                flogasv_invoice_file(where: { invoice_id: { _eq: $docId } }) {
                  content
                }
              }
            `,
            fetchPolicy: "network-only",
            variables: {
              docId: cell,
            },
          })
          .then((response) => {
            if (response.data) {
              const { flogasv_invoice_file } = response.data;
              if (
                Array.isArray(flogasv_invoice_file) &&
                flogasv_invoice_file.length
              ) {
                const [data] = flogasv_invoice_file;
                if (typeof data === "object" && data.content) {
                  window.open(data.content);
                }
              } else {
                ReactDOM.render(
                  <ExpiringAlert
                    color='danger'
                    message={
                      "This information cannot be seen at this time. For more information please contact our Customer Support Team on 041 2149500."
                    }
                  ></ExpiringAlert>,
                  document
                    .getElementById("alert")
                    .appendChild(document.createElement("div"))
                );
              }
            }
          })
          .catch((error) => {
            ReactDOM.render(
              <ExpiringAlert
                color='danger'
                message='This information cannot be seen at this time. For more information please contact our Customer Support Team on 041 2149500.'
              ></ExpiringAlert>,
              document
                .getElementById("alert")
                .appendChild(document.createElement("div"))
            );
            console.log("there was an error ...", error);
          });
      }}
    ></i>
  );
}

export function cdnDownloadFormatter(cell, row, rowIndex, formatExtraData) {
  const expression =
    formatExtraData && formatExtraData.expression
      ? jsonata(formatExtraData.expression)
      : null;
  const fileName = expression ? expression.evaluate(row) : row.fileName;
  return (
    <i
      className={"icon ion-android-download"}
      style={{ fontSize: "18px", color: "#0DAEFF", cursor: "pointer" }}
      onClick={(e) => cdnDownloadOnClick(e, cell, fileName)}
    ></i>
  );
}

export function cdnDownloadFormatterName(url, displayName, fontSize = "12pt") {
  const i = url.lastIndexOf(":");
  const fileName = url.slice(i + 1);
  return (
    <i
      className={"icon ion-android-download"}
      style={{ fontSize, color: "#12B5D3", cursor: "pointer" }}
      onClick={(e) => cdnDownloadOnClick(e, url, fileName)}
    >
      {displayName ? `\u00A0${displayName}` : ""}
    </i>
  );
}

function cdnDownloadOnClick(e, url, fileName) {
  e.preventDefault();
  e.stopPropagation();

  if (typeof REST_URL === "undefined") {
    var REST_URL = "";
  }

  axios
    .get(`${REST_URL}/cdn/cdn/resource/${url}/download`, {
      headers: { Authorization: "Bearer " + localStorage.token },
      responseType: "blob",
    })
    .then((response) => {
      fileDownload(response.data, fileName);
    });
}

export function jsonValueFormatter(cell, row, rowIndex, formatExtraData) {
  return formatExtraData.paths
    .map((path) => {
      let pathArray = path.split(".");
      let value = JSON.parse(cell);
      while (pathArray.length > 0) {
        value = value[pathArray[0]];
        pathArray = pathArray.slice(1);
      }
      return value;
    })
    .reduce(eval(formatExtraData.reducer), null);
}

export function jsonToStringFormatter(cell, row, rowIndex, formatExtraData) {
  let result = "";
  let keys = formatExtraData.split(",");
  let obj = JSON.parse(cell);
  for (let p in obj) {
    const keyStartsWith = keys.filter((key) =>
      key.toLocaleLowerCase().startsWith(p.toLocaleLowerCase() + "=")
    );
    if (obj.hasOwnProperty(p) && keyStartsWith.length == 1) {
      let keyName = keyStartsWith[0].split("=");
      result += keyName[1] + " " + obj[p] + "\n";
    }
  }
  return <div style={{ "white-space": "pre-wrap" }}>{result}</div>;
}

export function checkBoxFormatter(cell, row) {
  return cell ? (
    <svg
      width='20'
      height='20'
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      clipRule='evenodd'
    >
      <path d='M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z' />
    </svg>
  ) : null;
}

export function disableFormatter(cell, row) {
  if (row.status === "Active") {
    return (
      <Button
        className={"btn btn-primary pull-right"}
        color='primary'
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          axios
            .put(`${TARIFFS_URL}/tariff/${row.id}/disable`, {
              headers: { Authorization: "Bearer " + localStorage.token },
              responseType: "blob",
            })
            .then((response) => {
              // Refresh the page
              window.location.reload();
            });
        }}
      >
        {" "}
        Disable{" "}
      </Button>
    );
  }
  return null;
}

export function menuFotmatter(cell, row, rowIndex, formatExtraData) {
  return <GridDropDownMenu formatExtraData={formatExtraData} row={row} />;
}

export function jsonataFormatter(cell, row, rowIndex, formatExtraData) {
  const expression = jsonata(formatExtraData);
  const transformedData = expression ? expression.evaluate(row) : row;
  return JSON.stringify(transformedData || "")
    .replace(/,/g, ", ")
    .replace(/[\[\]\"]/g, "");
}

export function billingDocumentTypeFormatter(cell) {
	if (cell) {
		if (
			cell.transactionDocument &&
			cell.transactionDocument.isReversal !== undefined
		) {
			return !cell.transactionDocument.isReversal ? "Invoice" : "Credit Memo";
		} else if (
			cell.documentType &&
			cell.documentType.isReversal !== undefined
		) {
			return !cell.documentType.isReversal ? "Invoice" : "Credit Memo";
		}
	}
	return "";
}

export function queryTypeFotmatter(cell, row) {
  //TODO to make it possible to filter by a tenant
  const res = queryTypeMapping.G.filter(
    (x) => x.enum.indexOf(parseInt(cell)) > -1
  );
  return res.length ? res[0].title : "Unknown";
}

export function statusFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <GridDropDown cell={cell} row={row} formatExtraData={formatExtraData} />
  );
}

//skip period
export function cronFormatter(cell, row, rowIndex, formatExtraData) {
  return <CronFormatter cell={cell} row={row} />;
}

export function reportPeriodFormatter(cell, row, rowIndex, formatExtraData) {
  return <PeriodFormatter cell={cell} row={row} />;
}

// export function iconFormatter(cell, row, rowIndex, formatExtraData) {
//     const image = formatExtraData[0];
//     const type = formatExtraData[1].type;
//
//     let result = '';
//     if(formatExtraData[1].hideWhen && eval(formatExtraData[1].hideWhen)){
//         return <div></div>;
//     }
//     if (image.startsWith("<svg")) {
//         if (type === 'link') {
//             const compile = _.template(formatExtraData[1].url);
//             result = <Link to={compile(row)}>
//                 <div style={{height: '20px', width: '20px'}}>
//                     <img src={`data:image/svg+xml;utf8,${image}`}/>
//                 </div>
//             </Link>;
//         }
//         if (type === 'query') {
//             const compile = _.template(JSON.stringify(formatExtraData[1].variables));
//             let variables = compile(row);
//             const updateKeys = formatExtraData[1].updateKeys;
//             const refetchQueries = [];
//             for(let i = 0; i < updateKeys.length; i++){
//                 const refetchQuery = updateKeys[i] && sessionStorage[updateKeys[i]] && JSON.parse(sessionStorage[updateKeys[i]]);
//                 if (refetchQuery) {
//                     refetchQuery.query = refetchQuery.query && gql`${refetchQuery.query}`;
//                     refetchQueries.push(refetchQuery);
//                 }
//             }
//             result = <Mutation mutation={gql`${formatExtraData[1].mutation}`} variables={JSON.parse(variables)} refetchQueries={refetchQueries}>
//                 {mutation => (
//                     <button onClick={mutation} style={{"background-color": "transparent" , border: "none", outline: "none"}}>
//                         <div style={{height: '20px', width: '20px'}}>
//                             <img src={`data:image/svg+xml;utf8,${image}`}/>
//                         </div>
//                     </button>
//                 )}
//             </Mutation>
//         }
//     } else if (image.endsWith(".svg")) {
//         result = <Link to={compile(row)}><img style={{height: '20px', width: '20px'}} src={"/img/categories/" + image}
//                                               alt="icon"/></Link>;
//     } else {
//         result = <Link to={compile(row)}><img src={"/img/categories/" + image} alt="text describing the image"/></Link>;
//     }
//     return result;
// }

export function iconFormatter(cell, row, rowIndex, formatExtraData) {
  const image = formatExtraData[0];
  const compile = _.template(formatExtraData[1]);

  let result = "";
  if (image.startsWith("<svg")) {
    result = (
      <Link to={compile(row)}>
        <div style={{ height: "20px", width: "20px" }}>
          <img src={`data:image/svg+xml;utf8,${image}`} />
        </div>
      </Link>
    );
  } else if (image.endsWith(".svg")) {
    result = (
      <Link to={compile(row)}>
        <img
          style={{ height: "20px", width: "20px" }}
          src={"/img/categories/" + image}
          alt='icon'
        />
      </Link>
    );
  } else {
    result = (
      <Link to={compile(row)}>
        <img src={"/img/categories/" + image} alt='text describing the image' />
      </Link>
    );
  }
  return result;
}

export function hiddenColFormatter(cell, row, rowIndex, formatExtraData) {
  return <div style={{ display: "none" }}>{cell}</div>;
}

export function downloadLinkFormatter(cell, row, rowIndex, formatExtraData) {
  const link = cell;
  return (
    <a href={link} target='_blank' download>
      <i
        className={"icon ion-android-download"}
        style={{ fontSize: "18px", color: "#0DAEFF" }}
      ></i>
    </a>
  );
}

export const universalFormatter = (value) => {
  return value
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const documentCategoryFormatter = (category) => {
  let formattedCategory = universalFormatter(category);

  formattedCategory = formattedCategory.replace(/\bDno\b/, "DNO");
  return formattedCategory;
};

export const executionContextFormatter = (context) => {
  return universalFormatter(context);
};

export const printFormatter = (cell, row, rowIndex, formatExtraData) => {
	let path = "Invoice";
	let extension = "pdf";
	if (formatExtraData) {
		if (formatExtraData.expression) {
			const expression = jsonata(formatExtraData.expression);
			path = expression.evaluate(row);
		} else if (typeof formatExtraData === "string") {
			[path = "Invoice", extension = "pdf"] = formatExtraData.split(".");
		}
	}
	const fileName = `${row.id}.${extension}`;
  const requestBody = {
    DOCUMENT_ID: `${row.id}`,
    ...(extension === 'xlsx' && { ignorePagination: true })
};

	return (
		<i
			className={"icon ion-android-download"}
			style={{ fontSize: "18px", color: "#0DAEFF" }}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				let win = null;
				if (!(window.navigator && window.navigator.msSaveOrOpenBlob)) {
					// for IE
					win = window.open("", "_blank");
					win.document.write("Loading document...");
				}

				axios
					.post(
						`print/v2/${path}/${extension}/print`,
						requestBody,
						{
							headers: { Authorization: "Bearer " + localStorage.token },
							responseType: "blob",
						}
					)
					.then((response) => {
						if (window.navigator && window.navigator.msSaveOrOpenBlob) {
							// for IE
							window.navigator.msSaveOrOpenBlob(response.data, fileName);
						} else {
							// create a URL and anchor element
							const blobUrl = URL.createObjectURL(response.data);
							const a = document.createElement("a");
							a.href = blobUrl;
							a.download = fileName;

							// append the anchor to the body (required for Firefox)
							document.body.appendChild(a);

							// simulate click to trigger the download
							a.click();

							// clean up
							document.body.removeChild(a);
							URL.revokeObjectURL(blobUrl);
						}
					})
					.catch((error) => {
						console.log("There was an error downloading the file: ", error);
					});
			}}
		></i>
	);
};
