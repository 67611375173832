import React, {Component} from 'react';
import {withRouter} from 'react-router';
import i18n from '../Pages/Login/i18n';
import Loading from '../../components/Loading';
import {Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import ReactDOM from 'react-dom';
import ExpiringAlert from "../../components/ExpiringAlert";
import BootstrapTable from 'react-bootstrap-table-next';
import axios from "axios";
import gql from "graphql-tag";
import {withApollo} from "react-apollo";

class BulkUploadReadings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            schema: 'e43afeb2-c74d-4e6b-861e-85aa43d8feee'
        }
    }

    handleSubmit() {

    }

    render() {
        const query = gql`
            query findRunQuery($id: UUID){
                findRun(id: $id){
                    content
                    created
                    createdBy
                    footer
                    header
                    id
                    name
                    requestType
                    runErrors{
                        content
                        created
                        createdBy
                        errorMessage
                        group{
                            id
                            name
                        }
                        id
                        name
                        tenant
                        updated
                        updatedBy
                    }
                    schema{
                        id
                        name
                    }
                    status
                    tenant
                    updated
                    updatedBy
                }
            }
        `;
        return ( <div className="busines-parnter-cnt">



            <Form>
                <FormGroup>
            <Row className='subheader'>
                <Col className='col-8'>
                    <h1>{i18n.t('readings.upload-file')}</h1>
                </Col>
                <Col className="col-4">
                    <div className="dropdown show">
                        <Button className="pull-right" color="primary"
                                onClick={this.props.history.goBack}><i
                            className="ion ion-ios-arrow-back"/> {i18n.t('readings.back-to-list')}</Button>
                    </div>
                </Col>
            </Row>
            <Row className='subheader' style={{borderBottom:"none"}}><Col><div><a className="btn btn-primary" href="/documents/bulk-upload-read_v0.2.xlsx"><i className="fa fa-download"></i> Download Template</a></div></Col></Row>
                <Row className='subheader' style={{borderBottom:"none"}}>
                    <Col xs={"6"}>
                        <Label for="exampleFile">Select File to Upload:</Label>
                        <Input name={"exampleFile"} id={"exampleFile"}
                               type='file' accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" label='Upload'
                               onChange={(e) => {
                                   this.setState({file: e.target.files[0]});
                               }}
                        />
                    </Col>
                </Row>
            </FormGroup>

            <Row className='subheader' style={{borderBottom:"none"}}><Col><Button color="primary" className="buttonSpace buttonSpaceTop" onClick={(e)=>{
                const data = new FormData();
                data.append('file', this.state.file);
                data.append('schema', this.state.schema);
                ReactDOM.render(<Loading/>, document.getElementById('alert'));
                const config = {
                    headers: {'Authorization': "Bearer " + localStorage.token}
                };
                axios.post(`${CORE_URL}/processfiles`, data, config).then(({data}) => {
                    this.setState({file: null});
                    console.log('got data', data);
                    this.props.client.query({
                        query: query,
                        variables: {id: data.id},
                        fetchPolicy: 'network-only'
                    }).then(({data}) => {
                        this.setState({data: data});
                        if (data && data.findRun && data.findRun.runErrors && data.findRun.runErrors.length==0) {
                            ReactDOM.render(<ExpiringAlert
                                color="success"
                                message={"File successfully uploaded"}/>, document.getElementById('alert'));
                        } else {
                            ReactDOM.render(null, document.getElementById('alert'));
                        }
                    });
                }).catch((error) => {
                    ReactDOM.render(<ExpiringAlert
                        color="danger"
                        message={error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                    console.log('there was an error sending the query', error.message);
                });
            }}>{i18n.t('common.submit')}</Button>
            </Col></Row></Form>

            {this.state.data &&  <div className="invoice-details">
                        <Row  className='subheader' style={{borderBottom:"none"}}>
                            <Col sm="12">
                                <Card>
                                    <CardBody>
                                        <Row className='subheader'>
                                            <Col className='col-8'>
                                                <h1>Results</h1>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="12">
                                                <BootstrapTable
                                                    wrapperClasses="table-responsive"
                                                    data={this.state.data.findRun.runErrors}
                                                    keyField={'findRun.runErrors.id'}
                                                    columns={[ {
                                                        dataField: 'errorMessage',
                                                        text: 'Message',
                                                        //sort: true
                                                    }, {
                                                        dataField: 'created',
                                                        text: 'Created',
                                                        formatter: (cell, row) => cell ? new Date(cell).toLocaleString() : null
                                                        //sort: true
                                                    }, {
                                                        dataField: 'content',
                                                        text: 'Content',
                                                        //formatter: (cell, row) => cell ? new Date(cell).toLocaleString() : null
                                                        //sort: true
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>

            }



        </div> )
    }
}


const LoadingComponent = () => <Loading/>

export default withApollo(withRouter(BulkUploadReadings));
