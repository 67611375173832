import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import fileSaver from "file-saver";
import Loader from "react-loader-spinner";

class DownloadResource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.download = this.download.bind(this);
        this.getPosition = this.getPosition.bind(this);
    }

    componentDidMount() {
        this.download();
    }

    onSubmit() {
        this.download();
    }

    getPosition(path, subString, index) {
        return path.split(subString, index).join(subString).length;
    }

    download() {
        this.setState({loading: true})
        let path = this.props.location.pathname;
        let index = this.getPosition(path, '/', 2) + 1;
        const client = {
            'DYCE-SSP': window.config.consul.CLIENT === 'dyce-ssp',
            TOTAL: window.config.consul.CLIENT === 'total',
        }

        // Todo: Move this code to the merge version in the client help folders it belongs to
        //  and import dynamically per client.
        if (client['DYCE-SSP']) {
            let invoiceId = path.substring(index);
            axios.get(`/essence-services/essence-services/v1/jasper/invoice/${invoiceId}/download`, {
                headers: {'Authorization': "Bearer " + localStorage.token},
                responseType: 'blob'
            }).then((response) => {
                sessionStorage.removeItem("jasperDownload");
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                    window.navigator.msSaveOrOpenBlob(response.data, invoiceId + '.pdf');
                } else {
                    const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                    fileSaver.saveAs(url, invoiceId + ".pdf");
                }
                this.setState({loading: false});
            }).catch(error => {
                sessionStorage.removeItem("jasperDownload");
                this.setState({loading: false});
            })
        }
        else if (client.TOTAL) {
            let urn = path.substring(index);
            index = path.lastIndexOf('.') + 1;
            let format = path.substring(index);

            axios.get(`${CDN_URL}/cdn/resource/${urn}/download`, {
                responseType: 'blob'
            }).then((response) => {
                fileDownload(response.data, 'ScheduledReport' + moment(new Date()).format('YYYYMMDD-HHmm') + '.' + format);
                this.setState({loading: false});
            }).catch((error) => {
                console.log('there was an error downloading the report', error);
                this.setState({loading: false});
            });
        }
    }

    render() {
        return (
            <form ref="form" onSubmit={this.onSubmit}>
                <div className="download-panel">
                    <h4>Your file should download automatically.</h4>
                    <h5>If it doesn't start within a few moments, try using the link below.</h5>

                    <button style={{display: 'inline-flex'}} type="submit" className="btn btn-primary download-btn">
                        Download
                        {this.state.loading && <Loader type="Oval" className='downloadResource' height={18} width={18}/>}
                    </button>
                </div>
            </form>
        );
    }
}

export default withRouter(DownloadResource);
