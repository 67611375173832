import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withApollo } from "react-apollo";
import moment from "moment-timezone";
import { DateRangePicker } from "react-dates";
import {
	Alert,
	Button,
	Col,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from "reactstrap";
import Select from "react-select";
import {
	findLastNominationsByIdAndDate 
} from "../../queries/Queries";
import Loading from "../Loading/Loading";

const NOMINATION_VALUES_CONFIG = 48; // 24/48/96 supported values
const NOMINATION_TIME_BEGIN = "00:00";
const NOMINATION_TIME_END = "23:30";
const NOMINATION_MAX_FUTURE_DATE_IN_DAYS = 30;
const NOMINATION_SAME_DAY_HOURS_OFFSET = 2;
const RENOMINATION_RESTRICTIONS_ENABLED = true;

const generateTimeStamps = (val) => {
	let boxes = [];
	let { [0]: hours, [1]: mins } = NOMINATION_TIME_BEGIN.split(":");
	let fullHourIndex = null;
	let minutesSplitValue = (24 / val) * 60;
	let minutesIncrement = 1;
	let hoursInt = parseInt(hours, 10);
	for (let i = 0; i < val; i++) {
		fullHourIndex = val / 24;
		if (i % fullHourIndex == 0) {
			hours = `${hoursInt < 10 ? "0" : ""}${hoursInt}`;
			mins = "00";
			minutesIncrement = 1;
			if (hoursInt == 23) {
				hoursInt = 0;
			} else {
				hoursInt++;
			}
		} else {
			mins = `${minutesSplitValue * minutesIncrement}`;
			minutesIncrement++;
		}
		boxes.push(`${hours}:${mins}`);
	}
	return boxes;
};

const CreateNomination = ({ client, isOpen, onClose, billingAccounts, mutation}) => {
	const [nominationValue, setNominationValue] = useState("");
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [selectedBillingAccount, setSelectedBillingAccount] = useState(null);
	const [dateRange, setDateRange] = useState({
		startDate: null,
		endDate: null,
	});
	const [focus, setFocus] = useState(null);
	const [timestamps, setTimestamps] = useState(() =>
		generateTimeStamps(NOMINATION_VALUES_CONFIG)
	);
	const [nominationValues, setNominationValues] = useState(() => [
		...Array(NOMINATION_VALUES_CONFIG).fill(""),
	]);
	const [startTime, setStartTime] = useState(NOMINATION_TIME_BEGIN);
	const [endTime, setEndTime] = useState(NOMINATION_TIME_BEGIN);
	const [firstAvailableTime, setFirstAvailableTime] = useState(
		NOMINATION_TIME_BEGIN
	);
	const [isRenomination, setIsRenomination] = useState(false);
	const [hasNoActiveNominationsForToday, setHasNoActiveNominationsForToday] =
		useState(false);
	const [hasInputValueOutsideRange, setHasInputValueOutsideRange] =
		useState(false);
	const dispatch = useDispatch();

	const handleRefetch = () => {
		// Dispatch the action to toggle refetchNominationsQuery
		dispatch({
			type: "TOGGLE_REFETCH_NOMINATIONS_QUERY",
		});
	}

	useEffect(() => {
		const setCurrentDayNominations = async () => {
			let updatedValues = [...Array(NOMINATION_VALUES_CONFIG).fill("")];

			if (selectedBillingAccount) {
				setLoading(true);

				const nominationsForDay = await client.query({
					query: findLastNominationsByIdAndDate,
					variables: {
						date: dateRange.startDate.format(moment.HTML5_FMT.DATE),
						executionIdentifier: selectedBillingAccount,
					},
					fetchPolicy: "network-only",
				});

				const response = nominationsForDay.data.findLastNominationsByIdAndDate;
				if (response.length > 0) {
					setHasNoActiveNominationsForToday(false);
					updatedValues = response.map((val) => val.quantity);
				} else {
					setHasNoActiveNominationsForToday(true);
				}
				setLoading(false);
			}
			setNominationValues(updatedValues);
		};

		let earliestTime = NOMINATION_TIME_BEGIN;

		if (dateRange.startDate && dateRange.startDate.isSame(moment(), "day")) {
			setIsRenomination(true);

			setDateRange((prevState) => ({
				...prevState,
				endDate: prevState.startDate,
			}));

			earliestTime = timestamps.find(
				(ts) =>
					ts >
					moment()
						.tz("Europe/London")
						.add(NOMINATION_SAME_DAY_HOURS_OFFSET, "hours")
						.format("HH:mm")
			);

			setCurrentDayNominations();
		} else {
			setIsRenomination(false);
			setHasNoActiveNominationsForToday(false);
		}

		setFirstAvailableTime(earliestTime);
		setStartTime(earliestTime);
		setEndTime(earliestTime);
	}, [dateRange.startDate, selectedBillingAccount]);

	useEffect(() => {
		setFocus(null);
	}, [dateRange.endDate]);

	useEffect(() => {
		if (!isRenomination) {
			setNominationValues([...Array(NOMINATION_VALUES_CONFIG).fill("")]);
		}
	}, [isRenomination]);

	useEffect(() => {
		setHasInputValueOutsideRange(
			nominationValues.some((val) => isInvalidInput(val))
		);
	}, [JSON.stringify(nominationValues)]);

	const isInvalidInput = (val) =>
		parseFloat(val) < -100 || parseFloat(val) > 100;

	const submitNomination = () => {
		setLoading(true);
		const { [0]: startDateHours, [1]: startDateMins } =
			NOMINATION_TIME_BEGIN.split(":");
		const { [0]: endDateHours, [1]: endDateMins } =
			NOMINATION_TIME_END.split(":");

		const formatRequestDate = (date, hour, mins) =>
			date.set("hour", hour).set("minutes", mins).format().split("+")[0];

		const vars = {
			executionContextIdentifier: selectedBillingAccount,
			executionContextType: "BILLING_ACCOUNT",
			serviceProduct: "SERVICE_ELECTRICITY",
			startDate: formatRequestDate(
				dateRange.startDate,
				startDateHours,
				startDateMins
			),
			endDate: formatRequestDate(dateRange.endDate, endDateHours, endDateMins),
			volumes: nominationValues.map((v) => ({ volume: parseFloat(v) })),
		};

		client
			.mutate({
				mutation: mutation,
				variables: vars,
			})
			.then(() => {
				setLoading(false);
				setSuccess(true);
				handleRefetch();
			});
	};

	const isSubmitDisabled = () => {
		return (
			loading ||
			!selectedBillingAccount ||
			Object.values(dateRange).some((date) => date == null) ||
			nominationValues.some((nom) => !nom) ||
			hasInputValueOutsideRange
		);
	};

	const toggleClose = () => {
		// toggle refetch on modal close if nommination
		// was submitted successfully
		// if (success) {
		// 	dispatch({
		// 		type: "TOGGLE_REFETCH_NOMINATIONS_QUERY",
		// 	});
		// }

		onClose();
	};

	const renderBoxes = () => {
		const isInputDisabled = (i) => {
			const time = timestamps[i];
			const currentTime = moment
				.tz("Europe/London")
				.add(NOMINATION_SAME_DAY_HOURS_OFFSET, "hours")
				.format("HH:mm");
			return (
				!dateRange.startDate ||
				!dateRange.endDate ||
				hasNoActiveNominationsForToday ||
				(RENOMINATION_RESTRICTIONS_ENABLED &&
					dateRange.startDate &&
					dateRange.startDate.isSame(moment(), "day") &&
					time < currentTime)
			);
		};

		const updateNomValue = (index, newValue) => {
			const nextNomValues = nominationValues.map((v, i) =>
				i == index ? newValue : v
			);
			setNominationValues(nextNomValues);
		};

		const boxes = [];
		for (let i = 0; i < NOMINATION_VALUES_CONFIG; i = i + 8) {
			boxes.push(
				<Row className="nomValuesRow">
					<Col className="nomValuesRowCol_1" xs={6} sm={6} md={6} lg={6}>
						<Row>
							<Col xs={3} sm={3} md={3} lg={3}>
								<Label for={`nomValueInput_${i}`}>{timestamps[i]}</Label>
								<Input
									type="text"
									id={`nomValueInput_${i}`}
									value={nominationValues[i]}
									disabled={isInputDisabled(i)}
									onChange={(e) => updateNomValue(i, e.target.value)}
									className={
										isInvalidInput(nominationValues[i]) ? "is-invalid" : ""
									}
								/>
							</Col>
							<Col xs={3} sm={3} md={3} lg={3}>
								<Label for={`nomValueInput_${i + 1}`}>
									{timestamps[i + 1]}
								</Label>
								<div className="">
									<Input
										type="text"
										id={`nomValueInput_${i + 1}`}
										value={nominationValues[i + 1]}
										disabled={isInputDisabled(i + 1)}
										onChange={(e) => updateNomValue(i + 1, e.target.value)}
										className={
											isInvalidInput(nominationValues[i + 1])
												? "is-invalid"
												: ""
										}
									/>
								</div>
							</Col>
							<Col xs={3} sm={3} md={3} lg={3}>
								<Label for={`nomValueInput_${i + 2}`}>
									{timestamps[i + 2]}
								</Label>
								<Input
									type="text"
									id={`nomValueInput_${i + 2}`}
									value={nominationValues[i + 2]}
									disabled={isInputDisabled(i + 2)}
									onChange={(e) => updateNomValue(i + 2, e.target.value)}
									className={
										isInvalidInput(nominationValues[i + 2]) ? "is-invalid" : ""
									}
								/>
							</Col>
							<Col xs={3} sm={3} md={3} lg={3}>
								<Label for={`nomValueInput_${i + 3}`}>
									{timestamps[i + 3]}
								</Label>
								<Input
									type="text"
									id={`nomValueInput_${i + 3}`}
									value={nominationValues[i + 3]}
									disabled={isInputDisabled(i + 3)}
									onChange={(e) => updateNomValue(i + 3, e.target.value)}
									className={
										isInvalidInput(nominationValues[i + 3]) ? "is-invalid" : ""
									}
								/>
							</Col>
						</Row>
					</Col>
					<Col className="nomValuesRowCol_2" xs={6} sm={6} md={6} lg={6}>
						<Row>
							<Col xs={3} sm={3} md={3} lg={3}>
								<Label for={`nomValueInput_${i + 4}`}>
									{timestamps[i + 4]}
								</Label>
								<Input
									type="text"
									id={`nomValueInput_${i + 4}`}
									value={nominationValues[i + 4]}
									disabled={isInputDisabled(i + 4)}
									onChange={(e) => updateNomValue(i + 4, e.target.value)}
									className={
										isInvalidInput(nominationValues[i + 4]) ? "is-invalid" : ""
									}
								/>
							</Col>
							<Col xs={3} sm={3} md={3} lg={3}>
								<Label for={`nomValueInput_${i + 5}`}>
									{timestamps[i + 5]}
								</Label>
								<Input
									type="text"
									id={`nomValueInput_${i + 5}`}
									value={nominationValues[i + 5]}
									disabled={isInputDisabled(i + 5)}
									onChange={(e) => updateNomValue(i + 5, e.target.value)}
									className={
										isInvalidInput(nominationValues[i + 5]) ? "is-invalid" : ""
									}
								/>
							</Col>
							<Col xs={3} sm={3} md={3} lg={3}>
								<Label for={`nomValueInput_${i + 6}`}>
									{timestamps[i + 6]}
								</Label>
								<Input
									type="text"
									id={`nomValueInput_${i + 6}`}
									value={nominationValues[i + 6]}
									disabled={isInputDisabled(i + 6)}
									onChange={(e) => updateNomValue(i + 6, e.target.value)}
									className={
										isInvalidInput(nominationValues[i + 6]) ? "is-invalid" : ""
									}
								/>
							</Col>
							<Col xs={3} sm={3} md={3} lg={3}>
								<Label for={`nomValueInput_${i + 7}`}>
									{timestamps[i + 7]}
								</Label>
								<Input
									type="text"
									id={`nomValueInput_${i + 7}`}
									value={nominationValues[i + 7]}
									disabled={isInputDisabled(i + 7)}
									onChange={(e) => updateNomValue(i + 7, e.target.value)}
									className={
										isInvalidInput(nominationValues[i + 7]) ? "is-invalid" : ""
									}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			);
		}
		return boxes;
	};

	const applyNomination = () => {
		const startRange = timestamps.indexOf(startTime);
		const endRange = timestamps.indexOf(endTime);

		const updatedValues = nominationValues.map((val, i) => {
			if (i >= startRange && i <= endRange) {
				val = nominationValue;
			}
			return val;
		});

		setNominationValues(updatedValues);
		setStartTime(firstAvailableTime);
		setEndTime(firstAvailableTime);
	};

	return (
		<Modal
			isOpen={isOpen}
			toggle={toggleClose}
			size="lg"
			className="create-nomination-modal"
			backdrop="static"
		>
			<ModalHeader toggle={toggleClose}>Create Nomination</ModalHeader>
			<ModalBody>
				{success ? (
					<p>Your nomination was submitted successfully.</p>
				) : (
					<Fragment>
						{loading && <Loading />}
						<Row>
							<Col>
								<Label>Billing Account:</Label>
								<Input
									type="select"
									value={selectedBillingAccount}
									onChange={(e) => {
										setSelectedBillingAccount(e.target.value);
									}}
								>
									<option value="">---</option>
									{billingAccounts.map((acc) => (
										<option
											key={acc.billingAccountNumber}
											value={acc.billingAccountNumber}
										>
											{acc.billingAccountNumber}
										</option>
									))}
								</Input>
							</Col>
							<Col>
								<Label>Date:</Label>
								<DateRangePicker
									small
									startDate={dateRange.startDate}
									endDate={dateRange.endDate}
									onDatesChange={(date) => setDateRange(date)}
									displayFormat={"DD/MM/YYYY"}
									focusedInput={focus}
									onFocusChange={(focus) => setFocus(focus)}
									showClearDates={true}
									showDefaultInputIcon={true}
									noBorder={false}
									minimumNights={0}
									isOutsideRange={(day) =>
										(!day.isSame(moment(), "day") && day.isBefore(moment())) ||
										day.isAfter(
											moment().add(NOMINATION_MAX_FUTURE_DATE_IN_DAYS, "days")
										) ||
										(dateRange.startDate &&
											dateRange.startDate.isSame(moment(), "day") &&
											!day.isSame(moment(), "day"))
									}
								/>
							</Col>
						</Row>
						<Row className="justify-content-between">
							<Col>
								<Select
									clearable={false}
									value={startTime}
									options={timestamps.map((ts) => ({
										value: ts,
										label: ts,
										disabled:
											hasNoActiveNominationsForToday ||
											Object.values(dateRange).some((date) => date == null) ||
											(dateRange.startDate &&
												dateRange.startDate.isSame(moment(), "day") &&
												ts <
													moment()
														.tz("Europe/London")
														.add(NOMINATION_SAME_DAY_HOURS_OFFSET, "hours")
														.format("HH:mm")),
									}))}
									placeholder="Start Time"
									onChange={(e) => {
										setStartTime(e.value);
										setEndTime(e.value);
									}}
								/>
							</Col>
							<Col>
								<Select
									clearable={false}
									value={endTime}
									options={timestamps.map((ts) => ({
										value: ts,
										label: ts,
										disabled:
											hasNoActiveNominationsForToday ||
											Object.values(dateRange).some((date) => date == null) ||
											ts < startTime ||
											(dateRange.startDate &&
												dateRange.startDate.isSame(moment(), "day") &&
												ts <
													moment()
														.tz("Europe/London")
														.add(NOMINATION_SAME_DAY_HOURS_OFFSET, "hours")
														.format("HH:mm")),
									}))}
									placeholder="End Time"
									onChange={(e) => setEndTime(e.value)}
								/>
							</Col>
							<Col>
								<Input
									type="text"
									value={nominationValue}
									onChange={(e) => {
										setNominationValue(e.target.value);
									}}
								/>
							</Col>
							<Col>
								<Button onClick={applyNomination}>Apply</Button>
							</Col>
						</Row>
						{renderBoxes()}
						{hasInputValueOutsideRange && (
							<Alert color="danger">
								Nomination input contains invalid values. Please enter a value
								between -100 and 100.
							</Alert>
						)}
					</Fragment>
				)}
			</ModalBody>
			<ModalFooter>
				{!success && (
					<Button
						className="btn-submit-nomination"
						onClick={() => submitNomination()}
						//disabled={isSubmitDisabled()}
						color="primary"
					>
						Submit
					</Button>
				)}
				<Button onClick={toggleClose} color="secondary">
					{success ? "Close" : "Cancel"}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default withApollo(CreateNomination);
