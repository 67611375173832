import React, {Component} from 'react';
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from "react-accessible-accordion";
import {Col, Row} from "reactstrap";
import Form from "react-jsonschema-form";
import axios from "../../utils/Client";
import fileDownload from "js-file-download";
import moment from "moment";
import {withRouter} from "react-router-dom";
import widgets from "../FormWidget";
import _ from "underscore";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from "react-loader-spinner";
import ReactDOM from "react-dom";

class ConfigurableReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data ? this.applyTemplateToObject(props.data, props) : {},
            loading: false
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onFormDataChange = this.onFormDataChange.bind(this);
    }

    applyTemplateToObject(obj, variables) {
        if (typeof obj === "string") {
            const compiled = _.template(obj);
            return compiled(variables);
        }
        for (var i in obj) {
            if (obj.hasOwnProperty(i)) {
                obj[i] = this.applyTemplateToObject(obj[i], variables);
            }
        }
        return obj;
    };

    onSubmit() {
        if (this.props.graphParams && this.props.view) {
            const path = this.props.graphParams.map(p => this.state.data[p]).join("/");
            this.props.history.push('view/' + this.props.view + '/' + path)
        } else {
            let reportParams = "layout=" + this.props.layout;
            let submittedParams = this.state.data;
            if (this.props.action && this.props.action === "schedule") {
                const reportName = this.props.reportName ? this.props.reportName : this.props.title;
                let schedule = '';
                let skipNextExecutionDate = false;
                let periodCount = '';
                let periodType = '';
                if (submittedParams.hasOwnProperty("cronExpression")) {
                    schedule = submittedParams.cronExpression.cron;
                    skipNextExecutionDate = submittedParams.cronExpression.skipNextExecutionDate;
                    //delete submittedParams.cronExpression;
                }
                if (submittedParams.hasOwnProperty("period")) {
                    periodCount = submittedParams.period.periodCount;
                    periodType = submittedParams.period.periodType;
                    //delete submittedParams.period;
                }

                const parameters = {};
                parameters.layout = this.props.layout;
                for (let key in submittedParams) {
                    if (key !== 'cronExpression' && key !== 'period')
                        parameters[key] = submittedParams[key];
                }

                parameters.format = this.props.format ? this.props.format : 'xls';

                const reportSchedule = {};
                reportSchedule.reportName = reportName;
                reportSchedule.schedule = schedule;
                if (periodCount !== '') {
                    reportSchedule.periodCount = parseInt(periodCount, 10);
                }
                if (periodType !== '') {
                    reportSchedule.periodType = periodType;
                }
                reportSchedule.parameters = JSON.stringify(parameters);
                reportSchedule.status = 'Active';
                reportSchedule.skipNextExecutionDate = skipNextExecutionDate === 'true';

                let valid = schedule !== undefined && schedule !== '' && schedule !== '* * * * *';
                let required = this.props.schema.required;
                if (required && required.includes("period")) {
                    valid = valid !== false && periodType !== undefined && periodType !== '' && periodCount !== undefined && periodCount !== '';
                }

                if (valid) {
                    this.props.client.mutate({
                        mutation: scheduleReport,
                        variables: {
                            reportSchedule: reportSchedule
                        }
                        , refetchQueries: [{
                            query: scheduleExecution,
                            variables: {
                                "pageSize": 1,
                                "pageNumber": 0,
                                "sort": {"orders": [{"property": "id", "direction": "asc"}]},
                                "where": null
                            }
                        }]
                    }).then(({data}) => {
                        console.log(data);
                        this.reloadTable();
                        ReactDOM.render(<ExpiringAlert color="success"
                                                       message={i18n.t('reports.reportScheduled')}/>, document.getElementById('alert'));
                    }).catch((error) => {
                        ReactDOM.render(<ExpiringAlert color="danger"
                                                       message={'There was an error scheduling the report'}/>, document.getElementById('alert'));
                        // console.log('there was an error scheduling the report', error);
                        this.showErrorReportsCount();
                    });
                }
            } else {
                for (let key in submittedParams) {
                    if (submittedParams[key].includes('&')) {
                        let re = new RegExp('&', 'g');
                        submittedParams[key] = submittedParams[key].replace(re, '%26');
                    }
                    reportParams += '&' + key + '=' + submittedParams[key];
                }
                let format = this.props.format ? this.props.format : 'xls';
                reportParams += '&format=' + format;
                let reportName = this.props.reportName ? this.props.reportName : this.props.title;
                this.setState({loading: true});
                axios.get(`${CORE_URL}/report/${reportName}?${reportParams}`, {
                    headers: {'Authorization': "Bearer " + localStorage.token},
                    responseType: 'blob'
                }).then((response) => {
                    this.setState({loading: false});
                    fileDownload(response.data, reportName + moment(new Date()).format('YYYYMMDD-HHmm') + '.' + format);
                    this.resetForm();
                }).catch((error) => {
                    this.setState({loading: false});
                    ReactDOM.render(<ExpiringAlert color="danger"
                                                   message={'There was an error executing the report'}/>, document.getElementById('alert'));
                    // console.log('there was an error executing the report', error);
                    this.resetForm();
                });
            }
        }
    }

    onFormDataChange({formData}) {
        this.state.data = formData;
    }

    render() {
        const form = <Row>
            <Col className="col-sm-6">
                <Form schema={this.props.schema}
                      widgets={widgets.widgets}
                      uiSchema={this.props.uiSchema || {}}
                      formData={this.state.data}
                      onChange={this.onFormDataChange}
                      noHtml5Validate={true}
                      showErrorList={false}
                      onSubmit={this.onSubmit}
                >
                    <div className="buttons">
                        <button type="submit"
                                className="btn btn-primary">{this.props.actionText || "Download"}
                        </button>
                    </div>
                </Form>
            </Col>
        </Row>;
        return this.props.naked ? form : (
            <div className="report-item">
                <Accordion>
                    <AccordionItem>
                        <AccordionItemTitle>
                            {this.props.title}
                            <i className="icon ion-chevron-down"/>
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            {form}
                            {this.state.loading ?
                                <Loader type="ThreeDots" color="#00BFFF" height={80} width={80}/> : <div></div>}
                        </AccordionItemBody>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }
}

export default withRouter(ConfigurableReport);