import React, { useGlobal } from "reactn";
import { Query, withApollo } from "react-apollo";
import { Col, Row } from "reactstrap";
import { cdnDownloadFormatterName } from "../../../../utils/Formatters";
import {
	DNOdashboard2,
	insertReportActionMutation,
} from "../../../../queries/Queries";
import { ResponsiveContainer } from "recharts";
import BootstrapTable from "react-bootstrap-table-next";
import Loading from "../../../../components/Loading";
import ErrorPage from "../../../../components/ErrorPage";

const ReportActions = {
	Approve: "approve",
	Cancel: "cancel",
};

const DNOInvoices = ({ client }) => {
	const [username, setUsername] = useGlobal('username');

	const onReportActionClick = (reportId, action) => {
		client.mutate({
			variables: { report_id: reportId, action: action, createdBy: username },
			mutation: insertReportActionMutation,
			refetchQueries: [{ query: DNOdashboard2 }],
		});
	};

	return (
		<div>
			<Row className="subheader">
				<Col className="col-8">
					<h1>Invoices</h1>
				</Col>
			</Row>

			<Row className="dashboard-content justify-content-center my-5">
				<Col className="col-10">
					<Query query={DNOdashboard2}>
						{({
							loading,
							error,
							data: { dno_dashboard_latest2: queryData },
						}) => {
							if (loading) return <Loading />;
							if (error) return <ErrorPage />;
							return (
								<ResponsiveContainer>
									<BootstrapTable
										noDataIndication={() => (
											<div>No records in table for this date.</div>
										)}
										columns={[
											{
												dataField: "run_date",
												text: "Run Date",
												formatter: (cell) => new Date(cell).toLocaleString(),
												sort: true,
											},
											{
												dataField: "reportdesc",
												text: "Invoice",
												sort: true,
											},
											{
												dataField: "reportlink",
												text: "Export",
												formatter: (cell) => {
													return <div>{cdnDownloadFormatterName(cell)}</div>;
												},
											},
											{
												dataField: "createdby",
												text: "Approved By",
												formatter: (cell, row) => (
													<div> 
														{["Sending", "Sent"].includes(row.status) // only show email when approved
															? cell
															: ""}
													</div>
												),
												sort: true,
											},
											{
												dataField: "action",
												text: "Action",
												formatter: (cell, row) => {
													return row.status === "Review" ? (
														<Row className="justify-content-around">
															<button
																onClick={() =>
																	onReportActionClick(
																		row.id,
																		ReportActions.Approve
																	)
																}
															>
																<i className={"icon ion-android-send"}></i>
															</button>
															<button
																onClick={() =>
																	onReportActionClick(
																		row.id,
																		ReportActions.Cancel
																	)
																}
															>
																<i className={"icon ion-android-refresh"}></i>
															</button>
														</Row>
													) : null;
												},
											},
											{
												dataField: "status",
												text: "Status",
												sort: true,
											},
										]}
										data={queryData || []}
										keyField={"id"}
										loading={loading}
										bordered={false}
										hover
										remote={{
											filter: true,
											pagination: false,
											sort: false,
											cellEdit: false,
										}}
									/>
								</ResponsiveContainer>
							);
						}}
					</Query>
				</Col>
			</Row>
		</div>
	);
};

export default withApollo(DNOInvoices);