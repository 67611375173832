import React, { useState } from "react";
import { Query } from "react-apollo";
import { Button, FormGroup, Row } from "reactstrap";
import CreateTask from "../../views/CreateTask/CreateTask";
import Loading from "../Loading";
import {customersList} from "../../queries/Queries";

const QueryContactUsModalBtn = () => {
  const [showCreateTask, setShowCreateTask] = useState(false);

  return (
    <div>
      <FormGroup>
        <Button
          onClick={() => setShowCreateTask(!showCreateTask)}
          className="pull-right"
          color="primary"
        >
          {" "}
          <i className="icon ion-plus-round"></i>&nbsp;&nbsp;Add Query
        </Button>
      </FormGroup>
      <Row>
        <Query
          query={customersList}
          variables={{
            pageSize: 100,
            pageNumber: 0,
            sort: {
              orders: [
                {
                  property: "name",
                  direction: "asc",
                },
              ],
            },
            number: false,
            name: false,
            referenceNumber: false,
            status: false,
            address: false,
            contracts: false,
            where: null,
          }}
          skip={!showCreateTask}
        >
          {({ loading, error, data }) => {
            if (loading) return <Loading />;
            if (error) return `Error! ${error.message}`;

            return data ? (
              <CreateTask
                modalOpen={showCreateTask}
                onClose={() => setShowCreateTask(!showCreateTask)}
                user={global.username}
                properties={
                  data.Customer.content.length > 0 &&
                  data.Customer.content.flatMap((account) =>
                      account.accounts.filter(
                          (properties) => properties.properties.length > 0
                      )
                  )
                }
                meterPointsData={
                    data.Customer.content.length > 0 &&
                    data.Customer.content.flatMap((account) =>
                        account.accounts.flatMap((x) =>
                            x["properties"].flatMap((y) => y["site"].meterPoints)
                        )
                    )
                }
                accounts={
                    data.Customer.content.length > 0 &&
                    data.Customer.content.flatMap((account) =>
                        account.accounts.flatMap((x) =>
                            x["properties"].flatMap((consumerNumber) => consumerNumber.name)
                        )
                    )
                }
              />
            ) : null;
          }}
        </Query>
      </Row>
    </div>
  );
};

export default QueryContactUsModalBtn;
