import React from 'reactn';
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import gql from "graphql-tag";
import {Query} from 'react-apollo';
import {Input} from "reactstrap";
import {getUsers} from "../queries/Queries"
import ReactDOM from "react-dom";
import CustomerSearch from "../components/CustomerSearch";
import 'react-dates/initialize';
import {SingleDatePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Textarea from "react-textarea-autosize";
import moment from "moment";

class UserDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {options: []};
    }

    render() {
        return (
            <Query query={gql`${getUsers}`}>
                {(result) => {
                    if (result.loading) return "Loading...";
                    if (result.error) ReactDOM.render(<ExpiringAlert color="danger"
                                                                     message={result.error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                    const {data} = result;

                    return <Typeahead ref="typeahead"
                                      options={data.getUsers.filter(
                                          user => !this.global.roles.includes("SALES")
                                              || this.global.roles.includes("SALESADMIN")
                                              && user.roles.filter(({role}) => role === "SALESMANAGER" || role === "SALESAGENT").length > 0
                                              || this.global.roles.includes("SALESMANAGER")
                                              && (user.roles.filter(({role}) => role === "SALESADMIN").length > 0
                                                  || user.roles.filter(({role}) => role === "SALESMANAGER" || role === "SALESAGENT").length > 0
                                                  && user.organizations.filter(({businessPartnerId}) => businessPartnerId === this.global.salesOrganization).length > 0)
                                              || this.global.roles.includes("SALESAGENT")
                                              && user.roles.filter(({role}) => role === "SALESMANAGER" || role === "SALESAGENT").length > 0
                                              && user.organizations.filter(({businessPartnerId}) => businessPartnerId === this.global.salesOrganization).length > 0)}
                                      labelKey="username"
                                      selectHintOnEnter={true}
                                      placeholder={this.props.placeholder}
                                      filterBy={['username']}
                                      clearButton
                                      onChange={(event) => {
                                          this.props.onChange(event[0] ? event[0].username : null);
                                      }}
                                      selected={this.props.value ? [{username: this.props.value}] : []}
                    />
                }
                }
            </Query>
        );
    }
}

class TaskCOODate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <SingleDatePicker
                date={this.props.value ? moment(this.props.value) : null} // momentPropTypes.momentObj or null
                numberOfMonths={1}
                onDateChange={date => {
                    this.props.onChange(date ? date.format("YYYY-MM-DD") : null);
                }}
                readOnly={this.props.readonly}
                displayFormat="DD/MM/YYYY"
                focused={this.state.focused} // PropTypes.bool
                onFocusChange={({focused}) => this.setState({focused})} // PropTypes.func.isRequired
                isOutsideRange={(day) => false}
                id="task-coo-date" // PropTypes.string.isRequired,
            />
        );
    }
}

class MeterReads extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            meterRead: "",
            readings: this.props.formContext.meterPointsData && Array.isArray(this.props.formContext.meterPointsData) && this.props.formContext.meterPointsData.length
                ? this.props.formContext.meterPointsData.map(meterPoint => {

                    if (!meterPoint.registers || !meterPoint.registers.length || !Array.isArray(meterPoint.registers)) return [];

                    return meterPoint.registers
                        .map(register => ({id: register.meter.id, serialNumber: register.meter.serialNumber}))
                        .filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index)
                        .map(msn => meterPoint.registers
                            .filter(register => register.meter.id === msn.id && register.meter.type === 'N')
                            .filter((value, index, self) => self.map(x => x.meterRegisterId).indexOf(value.meterRegisterId) == index)
                            .map(register => ({
                                    mpan: meterPoint.identifier,
                                    msn: msn.serialNumber,
                                    reg: register.meterRegisterId,
                                    regId: register.id,
                                    reading: null,
                                })
                            )
                        )
                }).flat(2)
                : []
        };
    }

    render() {

        if (this.props.formContext.meterPointsData[0].purpose === "Gas") {
            return (
                <div className="meter-reads">
                    <div className="meter-reads-gas">
                        <label className="control-label" for="root_change_of_od_meter_read">Meter Read<span
                            class="required">*</span></label>
                        <p id="root_change_of_od_meter_read__description" class="field-description">Please provide a
                            meter read and date taken (If not taken, an estimate will be used)</p>
                        <input class="form-control" id="root_change_of_od_meter_read" label="Meter Read" type="text"
                               value={this.state.meterRead} onChange={e => {
                            this.setState({meterRead: e.target.value}, () => {
                                this.props.onChange(this.state.meterRead)
                            })
                        }}></input>
                    </div>
                </div>
            )
        }

        if (!Array.isArray(this.state.readings)) return null;

        return (
            <div className="meter-reads">
                <div className="meter-reads-power">
                    <table>
                        <thead>
                        <tr>{["MPAN", "MSN", "Register", "Reading"].map(header => <th>{header}</th>)}</tr>
                        </thead>
                        <tbody>
                        {
                            this.state.readings.map((reading, key) => (
                                <tr key={key}>
                                    <td>{reading.mpan}</td>
                                    <td>{reading.msn}</td>
                                    <td>{reading.reg}</td>
                                    <td>
                                        <input
                                            type="text"
                                            maxLength={20}
                                            onChange={e => {
                                                if (key !== -1) {
                                                    let temp = this.state.readings.slice();

                                                    temp[key] = {
                                                        reading: e.target.value,
                                                        regId: reading.regId,
                                                        reg: reading.reg,
                                                        mpan: reading.mpan,
                                                        msn: reading.msn,
                                                    };

                                                    this.setState({readings: temp}, () => {
                                                        if (!this.state.readings.filter(reading => !reading.reading).length) {
                                                            this.props.onChange(JSON.stringify(this.state.readings));
                                                        }
                                                    });
                                                }
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

class TaskMPRs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <SingleDatePicker
                date={this.props.value ? moment(this.props.value) : null} // momentPropTypes.momentObj or null
                numberOfMonths={1}
                onDateChange={date => {
                    this.props.onChange(date ? date.format("YYYY-MM-DD") : null);
                }}
                readOnly={this.props.readonly}
                displayFormat="DD/MM/YYYY"
                focused={this.state.focused} // PropTypes.bool
                onFocusChange={({focused}) => this.setState({focused})} // PropTypes.func.isRequired
                isOutsideRange={(day) => false}
                id="task-coo-date" // PropTypes.string.isRequired,
            />
        );
    }
}

class TaskDueDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <SingleDatePicker
                date={this.props.value === "" ? null : moment(this.props.value)} // momentPropTypes.momentObj or null
                numberOfMonths={1}
                onDateChange={date => {
                    this.props.onChange(date ? date.format("YYYY-MM-DD") : null);
                }}
                displayFormat="DD.MM.YYYY"
                focused={this.state.focused} // PropTypes.bool
                onFocusChange={({focused}) => this.setState({focused})} // PropTypes.func.isRequired
                id="task-due-date" // PropTypes.string.isRequired,
            />
        );
    }
}

const BOPuischema = {
    id: {
        "ui:widget": "hidden"
    },
    name: {
        "ui:widget": (props) => {
            return (
                <div>
                <span className="task-status icon ion-android-checkbox-blank" onClick={
                    () => {
                        props.formContext.submit({formData: {...props.formContext.formdata, status: "Closed"}});
                    }
                }/>
                    <Textarea className={props.schema.classes}
                              value={props.value} required={props.required}
                              placeholder={props.schema.placeholder} autoFocus={true}
                              onChange={(event) => {
                                  props.onChange(event.target.value)
                              }}/>
                </div>
            );
        },
        "classNames": 'task-title',
        "ui:autofocus": true
    },
    type: {
        "ui:placeholder": 'Select Type',
        "ui:enumDisabled": ['Select Type'],
    },
    process: {
        "ui:placeholder": 'Select Process',
        "ui:enumDisabled": ['Select Process'],
    },
    assignee: {
        "ui:widget": (props) => {
            return (
                <UserDropdown {...props}/>
            );
        },
        "ui:placeholder": "Select assignee",
    },
    customer: {
        "ui:widget": (props) => {
            return <CustomerSearch {...props} />
        },
        "ui:placeholder": "Select Customer",
    },
    mprn: {
        "ui:widget": (props) => {
            const options = props.formContext.customerMeterPoints
                && props.formContext.customerMeterPoints.map((mp, i) => <option key={i} value={mp}>{mp}</option>)

            return (
                <Input type="select" onChange={(event) => {
                    props.onChange(event.target.value)
                }}>
                    <option value=''></option>
                    {options}
                </Input>
            )
        }
    },
    status: {
        "ui:widget": "hidden",
        "ui:readonly": true
    },
    estate: {
        "ui:readonly": false
    },
    dueDate: {
        "ui:widget": (props) => {
            return <TaskDueDate {...props}/>
        },
        "ui:placeholder": "Select Date",
        "classNames": "duedate"
    },
    priority: {
        "ui:widget": "hidden"
    },
    description: {
        "ui:widget": props => (
            <div><Textarea value={props.value ? props.value : ""} placeholder="Query Description"
                           minRows="10"
                           countLimit={4000}
                           onChange={(event) => {
                               props.onChange(event.target.value.substr(0, 4000))
                           }}/>
                <div className="pull-right"> {props.value ? props.value.length : 0}/4000</div>
            </div>
        ),
        "classNames": 'description',
    },
    account_code: {
        "ui:widget": (props) => {
            const options = props.formContext.accounts && props.formContext.accounts.map(acc => <option
                value={acc}>{acc}</option>);
            return <Input type="select" onChange={(event) => {
                props.onChange(event.target.value)
            }}>{options}</Input>
        }
    },
    change_of_od: {
        date_of_coo: {
            "ui:widget": (props) => {
                return <TaskCOODate {...props}/>
            }
        },
        meter_read: {
            "ui:field": (props) => {
                return <MeterReads {...props} />
            },
            "classNames": "meter-reads-wrapper"
        }
    },
    closed_on: {
        "ui:readonly": true,
        "ui:widget": "hidden"
    },
    closed_by: {
        "ui:readonly": true,
        "ui:widget": "hidden"
    },
    "ui:order": ["id", "name", "type", "process", "moving_premises", "assignee", "customer", 'mprn', "status", "dueDate", "priority", 'change_of_od', 'outgoing_customer', 'incomming_customer', 'coo_pricing', 'description', 'contact_name', 'contact_number', 'contact_email', 'estate', 'account_code', 'closed_on', 'closed_by']
}

export default BOPuischema;