import React from "react";
import {NavItem, NavLink, Nav, TabContent} from "reactstrap";
import i18n from '../../views/Pages/Login/i18n';
import classnames from 'classnames';
import {withRouter, matchPath} from 'react-router'

class NavTabs extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.getActiveTab = this.getActiveTab.bind(this);
        this.tabKey = 'tab_' + this.props.tabKey || 'tab_default';


        for (const key in this.props.items) {
            const item = this.props.items[key];
            if (item.path) {
                const match = matchPath(location.hash.substr(1), {
                    path: "/view/:name" + item.path,
                    exact: false,
                    strict: false
                })
                if (match) {
                    sessionStorage[this.tabKey] = item.key;
                }
            }
        }

        this.state = {
            activeTab: this.getActiveTab() || sessionStorage[this.tabKey] || '1'
        };
    }

    getActiveTab() {
        const tabPos = this.props.location.pathname.indexOf(this.tabKey);
        if (tabPos > -1) {
            const endPos = this.props.location.pathname.indexOf('/', tabPos);
            const param = this.props.location.pathname.substring(tabPos + this.tabKey.length + 1, endPos === -1 ? undefined : endPos);
            const linkedItem = this.props.items.find(item => item.title === param);
            if (linkedItem && linkedItem.key) {
                return linkedItem.key
            }
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            sessionStorage[this.tabKey] = tab;
            this.setState({
                activeTab: tab
            });

            if (window.gtag) {
                const location = document.location;
                const title = this.props.items.filter(i => i.key === tab)[0].title;
                const path = location.hash.substr(1) + '/tab_' + title;
                window.gtag('config', window.config.consul.tracking["ga-code"], {
                    'page_title': title,
                    'page_path': path
                });
            }
        }
    }

    render() {

        const navItems = [];
        for (const key in this.props.items) {
            const item = this.props.items[key];
            navItems.push(
                <NavItem key={item.key}>
                    <NavLink
                        className={classnames({active: this.state.activeTab === item.key})}
                        onClick={() => {
                            this.toggle(item.key);
                        }}
                    >
                        {i18n.t(item.title)}
                    </NavLink>
                </NavItem>
            )
        }

        const activeChildrenIndex = this.props.children.findIndex((child, i) => {
            if (i % 2 !== 0) return child.props.tabId === this.state.activeTab
        });
        const childToDisplay = this.props.children.slice(activeChildrenIndex - 1, activeChildrenIndex + 1);

        return (
            <div style={{width: "100%"}}>
                <div className='bp-nav-items' style={{display: this.props.displayNone ? 'none' : 'block'}}>
                    <Nav tabs>
                        {navItems}
                    </Nav>
                </div>
                <TabContent activeTab={this.state.activeTab} className='bp-tab-content'>
                    {childToDisplay}
                </TabContent>
            </div>
        );
    }
}

export default withRouter(NavTabs);