import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

/**
 * BulkApproveConfirmModal Component
 * Renders a confirmation modal for bulk approval action.
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Modal open state.
 * @param {function} props.toggle - Function to toggle modal visibility.
 * @param {function} props.onConfirm - Function to execute on confirmation.
 * @param {number} props.selectedCount - Number of selected readings.
 */
const BulkApproveConfirmModal = ({
  isOpen,
  toggle,
  onConfirm,
  selectedCount,
}) => (
  <Modal isOpen={isOpen} toggle={toggle}>
    <ModalHeader toggle={toggle}>Confirm Bulk Approval</ModalHeader>
    <ModalBody>
      Are you sure you want to approve {selectedCount} selected readings?
    </ModalBody>
    <ModalFooter>
      <Button color='secondary' onClick={toggle}>
        Cancel
      </Button>
      <Button color='primary' onClick={onConfirm}>
        Approve
      </Button>
    </ModalFooter>
  </Modal>
);

export default BulkApproveConfirmModal;
