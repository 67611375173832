import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {Badge, Nav, NavItem, NavLink as RsNavLink} from 'reactstrap';
import classNames from 'classnames';
import SidebarFooter from './../SidebarFooter';
import SidebarForm from './../SidebarForm';
import SidebarHeader from '../SidebarHeader/SidebarHeader';


class Sidebar extends Component {

  handleClick(e) {
    e.preventDefault();
    e.target.parentElement.classList.toggle('open');
  }

  activeRoute(routeName, props) {
    // return this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
    return props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';

  }

  // todo Sidebar nav secondLevel
  // secondLevelActive(routeName) {
  //   return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
  // }


  render() {
    window.addEventListener('resize', function (event) {
      if (window.innerWidth <= 990) {
        document.body.classList.add('sidebar-minimized');
        document.body.classList.add('brand-minimized');
      } else {
        document.body.classList.remove('sidebar-minimized');
        document.body.classList.remove('brand-minimized');
      }
    }, true);

    if (window.innerWidth <= 990) {
      document.body.classList.add('sidebar-minimized');
      document.body.classList.add('brand-minimized');
    } else {
      document.body.classList.remove('sidebar-minimized');
      document.body.classList.remove('brand-minimized');
    }


    const props = this.props;
    const activeRoute = this.activeRoute;
    const handleClick = this.handleClick;

    // badge addon to NavItem
    const badge = (badge) => {
      if (badge) {
        const classes = classNames( badge.class );
        return (<Badge className={ classes } color={ badge.variant }>{ badge.text }</Badge>)
      }
    };

    // simple wrapper for nav-title item
    const wrapper = item => { return (item.wrapper && item.wrapper.element ? (React.createElement(item.wrapper.element, item.wrapper.attributes, item.name)): item.name ) };

    // nav list section title
    const title =  (title, key) => {
      const classes = classNames( 'nav-title', title.class);
      return (<li key={key} className={ classes }>{wrapper(title)} </li>);
    };

    // nav list divider
    const divider = (divider, key) => {
      const classes = classNames( 'divider', divider.class);
      return (<li key={key} className={ classes }></li>);
    };

    // nav item with nav link
    const navItem = (item, key, addon) => {
      const classes = {
        item: classNames( item.class) ,
        link: classNames( 'nav-link', item.variant ? `nav-link-${item.variant}` : ''),
        icon: classNames( item.icon )
      };

      return (navLink(item, key, classes,addon));
    };

    // nav link
    const navLink = (item, key, classes, addon) => {
      const url = item.url ? item.url : '';
      return (
        <NavItem key={key} className={classes.item}>
          { isExternal(url) ?
            <RsNavLink href={url} className={classes.link} target={"_blank"} rel={"noopener noreferrer"}>
              <i className={classes.icon}></i><span className='nav-link-text'>{item.name}</span>{badge(item.badge)}
            </RsNavLink>
            :
            <NavLink to={url} className={classes.link} activeClassName="active">
              <i className={classes.icon}></i><span className='nav-link-text'>{item.name}</span>{badge(item.badge)}
              {addon &&
                <span style={{
                  float: "right",
                  border: "2px solid #AD3597",
                  width: "25px",
                  height: "25px",
                  textAlign: "center",
                  borderRadius: "50%",
                }}>{addon}</span>
              }
            </NavLink>
          }
        </NavItem>
      )
    };

    // nav dropdown
    const navDropdown = (item, key) => {
      return (
        <li key={key} className={activeRoute(item.url, props)}>
          <a className="nav-link nav-dropdown-toggle" href="#" onClick={handleClick.bind(this)}>
            <i className={item.icon}></i>
            <span className='nav-link-text'>{item.name}</span>
          </a>
          <ul className="nav-dropdown-items">
            {navList(item.children)}
          </ul>
        </li>)
    };

    // nav type
    const navType = (item, idx) => {
      return item.title
      ? title(item, idx) : item.divider 
        ? divider(item, idx) : item.children 
          ? navDropdown(item, idx) : item.addonJSX 
            ? navItem(item, idx, item.addonJSX) : navItem(item, idx) ;
    }

    // nav list
    const navList = (items) => {
      return items.map( (item, index) => {
          // menu name 'Notifications' is custom for DYCE SSP
          // https://jira.methodia.com/browse/DYCE-1966
          if(item.name === "Messages" || item.name === "Notifications"){
            const newObjItem = {...item, addonJSX: this.props.unreadMessages}
            return navType(newObjItem, index)
          } else { 
            return navType(item, index);
          }
      } );
    };

    const isExternal = (url) => {
      const link = url ? url.substring(0, 4) : '';
      return link === 'http';
    };

    // sidebar-nav root
    return (
      <div className="sidebar">
        <SidebarHeader/>
        <SidebarForm/>
        <nav className="sidebar-nav">
          <Nav>
            {navList(this.props.nav.items)}
          </Nav>
        </nav>
        <SidebarFooter/>
      </div>
    )
  }
}

export default Sidebar;
